import {
  Card,
  DataTable,
  EmptyState,
  Layout,
  Page,
  Stack,
  Toast,
  Loading,
  Button,
  Select,
  Link,
} from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import debounce from "lodash.debounce";
import {
  TickSmallMinor,
  LockMinor,
  ArchiveMinor,
} from "@shopify/polaris-icons";
import emptyIMG from "../../media/empty.png";
import Pagination from "../../components/pagination";
import { clearError, getEntities } from "../../store/posts.store.reducer";
import PostsFilter from "./filter";
import helpers from "../../helpers";
import date from "date-and-time";
import { getEntities as getCategoires } from "../../store/category.store.reducer";

export default function General() {
  const [initial_loading, setInitial_loading] = useState<boolean>(true);

  const entities = useAppSelector((state) => state.posts.entities);
  const loading = useAppSelector((state) => state.posts.loading);
  const errorMessage = useAppSelector((state) => state.posts.errorMessage);
  const totalItems = useAppSelector((state) => state.posts.totalItems);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  const entitycategories = useAppSelector((state) => state.category.entities);

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...{
      page: 1,
      limit: 20,
      order_by: "DESC",
      // order_type: "time",
    },
  });
  const [queryValue, setQueryValue] = useState("");

  useEffect(() => {
    dispatch(getCategoires({}));
  }, []);

  /**
   * Change page number
   */
  const onChangePageNumber = useCallback((numPage) => {
    setMainQuery({ ...mainQuery, page: numPage });
  }, []);

  /**
   * Filter input
   */

  const [input, setInput] = useState("");
  const handleFiltersQueryChange = useCallback((value) => setInput(value), []);

  useEffect(() => {
    setMainQuery({ ...mainQuery, ...{ search: input } });
  }, [input]);

  /**
   * Filter selected
   */

  const [selectedParentId, setSelectedParentId] = useState("");
  const handleSelectedChange = useCallback((_value) => {
    setSelectedParentId(_value);
  }, []);

  useEffect(() => {
    setMainQuery({
      ...mainQuery,
      ...{ post_language: `${selectedParentId}`, page: 1 },
    });
  }, [selectedParentId]);

  /**
   * Category list
   */

  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    const optionType = [
      {
        label: "Category",
        value: "",
      },
    ];
    for (let { _id, category_title } of entitycategories) {
      optionType.push({
        label: String(category_title),
        value: String(_id),
      });
    }
    setCategoryList(optionType);
  }, [entitycategories]);

  /**
   * Select category
   */

  const [post_category_selected, setPost_category_selected] = useState("");
  const handNewChangeCategory = useCallback((value) => {
    setPost_category_selected(value);
  }, []);

  useEffect(() => {
    setMainQuery({
      ...mainQuery,
      ...{ post_category: `${post_category_selected}`, page: 1 },
    });
  }, [post_category_selected]);

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch) history("/news" + buildURLSearch);
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  const onChangeCallback = useMemo(
    () =>
      debounce((_value) => {
        if (_value !== "")
          setMainQuery({ ...mainQuery, query: _value ? _value : "" });
      }, 500),
    []
  );

  useEffect(() => {
    onChangeCallback(queryValue);
  }, [queryValue]);

  useEffect(() => {
    if (loading === false) setInitial_loading(false);
  }, [loading]);

  /**
   *
   * @param post_id
   */
  const shortcutActions = (post_id: number) => {
    history("/news/edit/" + post_id);
  };

  const emptyData = (
    <EmptyState heading="No news here!" image={emptyIMG}>
      <p>Oh! There is no news here! Try remove filter or add a new record!</p>
    </EmptyState>
  );

  const handleSort = useCallback(
    (index, direction) => {
      let _direction = direction === "descending" ? "desc" : "asc";
      let sort = "";
      if (index === 6) {
        sort = "updateAt," + _direction;
      } else {
        sort = "createAt," + _direction;
      }
      setMainQuery({ ...mainQuery, sort: sort });
    },
    [entities]
  );

  const renderItem = (posts: any) => {
    const {
      _id,
      user_id,
      language,
      post_content,
      post_excerpt,
      post_slug,
      post_parent,
      post_status,
      post_category,
      post_avatar,
      post_title,
      post_type,
      post_view,
      seo_title,
      seo_description,
      seo_keyword,
      post_language,
      createdAt,
      updatedAt,
    } = posts;
    return [
      <Link removeUnderline monochrome url={"/news/edit/" + _id} key={_id}>
        {user_id.display_name}
      </Link>,

      <Link removeUnderline monochrome url={"/news/edit/" + _id} key={_id}>
        <div className="small-icon">
          {post_status === "publish" ? <TickSmallMinor /> : <LockMinor />}
        </div>
      </Link>,

      <Link removeUnderline monochrome url={"/news/edit/" + _id} key={_id}>
        {post_title}
      </Link>,

      <Link removeUnderline monochrome url={"/news/edit/" + _id} key={_id}>
        {post_category?.category_content ?? ""}
      </Link>,

      <Link removeUnderline monochrome url={"/news/edit/" + _id} key={_id}>
        {post_language === "en"
          ? "English"
          : post_language === "sp"
          ? "Spanish"
          : "Vietnamese"}
      </Link>,

      <Link removeUnderline monochrome url={"/news/edit/" + _id} key={_id}>
        {helpers.trimContentString(post_excerpt)}
      </Link>,

      <Link removeUnderline monochrome url={"/news/edit/" + _id} key={_id}>
        <time>
          {createdAt
            ? date.format(new Date(createdAt), "DD-MM-YYYY HH:mm:ss")
            : "-"}
        </time>
      </Link>,

      <Link removeUnderline monochrome url={"/news/edit/" + _id} key={_id}>
        <time>
          {updatedAt
            ? date.format(new Date(updatedAt), "DD-MM-YYYY HH:mm:ss")
            : "-"}
        </time>
      </Link>,

      <Link removeUnderline monochrome url={"/news/edit/" + _id} key={_id}>
        {_id}
      </Link>,
    ];
  };
  const PagesList =
    totalItems > 0 ? (
      <>
        <DataTable
          sortable={[false, false, false, false, false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={7}
          onSort={handleSort}
          columnContentTypes={[
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
          ]}
          headings={[
            "Author",
            "Status",
            "Title",
            "Category",
            "Language",
            "Exceprt",
            "Create At",
            "Update At",
            "ID",
          ]}
          rows={entities?.map(renderItem)}
          hideScrollIndicator
          footerContent={`Display page ${mainQuery.page} of total ${totalItems} results...`}
        />
        <style>{`
        .small-icon {
          font-size: 12px;
          padding: 0;
          width: 15px;
          height: auto;
        }
      `}</style>
      </>
    ) : (
      emptyData
    );

  const Actual_page = (
    <Page
      title="News"
      primaryAction={{
        content: "Create new",
        disabled: false,
        onAction: () => {
          history("/news/new");
        },
      }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <div style={{ padding: "16px", display: "flex" }}>
              <Stack distribution="equalSpacing">
                <PostsFilter
                  queryValue={StringQuery?.query}
                  onChange={handleFiltersQueryChange}
                />

                {/* <Select
                  label=""
                  value={selectedParentId}
                  onChange={handleSelectedChange}
                  options={[
                    { label: "Language", value: "" },
                    { label: "English", value: "en" },
                    { label: "Vietnamese", value: "vi" },
                    { label: "Spanish", value: "sp" },
                  ]}
                /> */}

                <Select
                  label=""
                  options={categoryList}
                  onChange={handNewChangeCategory}
                  value={post_category_selected?.toString()}
                />
              </Stack>
            </div>
            {PagesList}
          </Card>
          <br />
          {totalItems > mainQuery.limit ? (
            <Pagination
              TotalRecord={totalItems}
              activeCurrentPage={mainQuery.page}
              pageSize={mainQuery.limit}
              onChangePage={onChangePageNumber}
            />
          ) : null}
        </Layout.Section>
      </Layout>
    </Page>
  );

  const toastMarkup = errorMessage ? (
    <Toast content={errorMessage} error onDismiss={toggleActive} />
  ) : null;

  return (
    <>
      {toastMarkup}
      {loading ? <Loading /> : null}
      {Actual_page}
      {/* {initial_loading ? skeleton_loading : Actual_page} */}
    </>
  );
}
