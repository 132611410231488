import axios from "axios";
import { createAsyncThunk, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import helpers from "helpers";
import { createEntitySlice, serializeAxiosError } from "../config/reducer.utils";
import { TypedHandle_service } from "../interface/handle_service.model";

/**
 *   Reducer used for front-end, with handle_service.model.ts
 *   Interface.ts can be use in both front-end and back-end! But prefer using handle_service.model.ts
 */

const initialState = {
  loading: false,
  errorMessage: [] as any,
  entities: [],
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = "plan";

// Actions

export const getEntities = createAsyncThunk("handle_service/fetch_entity_list", async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}/list-service${EndURL}`;
  return axios.get<any>(requestUrl);
});

export const getEntity = createAsyncThunk(
  "handle_service/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/detail-service/${id}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  "handle_service/create_entity",
  async (entity: TypedHandle_service, thunkAPI) => {
    const result = await axios.post<TypedHandle_service>(`${apiUrl}/create-service`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "handle_service/update_entity",
  async (entity: TypedHandle_service, thunkAPI) => {
    const result = await axios.patch<TypedHandle_service>(`${apiUrl}/update-service`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  "handle_service/partial_update_entity",
  async (entity: TypedHandle_service, thunkAPI) => {
    const result = await axios.patch<TypedHandle_service>(`${apiUrl}/${entity._id}`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  "handle_service/delete_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/delete-handle/${id}`;
    return axios.delete<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_Handle_service = createEntitySlice({
  name: "handle_service",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(createEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = action.payload;
      })

      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers["x-total-count"], 10),
        };
      })
      .addMatcher(isRejected(createEntity, updateEntity, partialUpdateEntity, deleteEntity), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.payload;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(deleteEntity), (state) => {
        state.updating = true;
        state.loading = true;
        state.updateSuccess = false;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { clearError, reset } = Reducer_Handle_service.actions;

// Reducer
export default Reducer_Handle_service.reducer;

