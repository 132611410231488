import React from "react";
import { useParams } from "react-router-dom";
import Theme404 from "../../layout/404";

import plan_list from "./plan.list";
import plan_edit from "./plan.edit";

/**
 *   Create index file for Plan
 */

export default function List_plan() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || "list";

  let ActualPage: any;
  switch (Param) {
    case "list":
      ActualPage = plan_list;
      break;

    case "edit":
      ActualPage = plan_edit;
      break;

    case "new":
      ActualPage = plan_edit;
      break;

    default:
      ActualPage = Theme404;
      break;
  }

  return <>{<ActualPage />}</>;
}

