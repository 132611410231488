import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import {
  Banner,
  Button,
  Card,
  Form,
  FormLayout,
  Layout,
  Loading,
  Page,
  Stack,
  TextField,
} from "@shopify/polaris";
import {
  clearError,
  getEntity,
  updateEntity,
  createEntity,
} from "store/email.store.reducer";
import {
  lengthLessThan,
  lengthMoreThan,
  useField,
  useForm,
} from "@shopify/react-form";
import helpers from "helpers";

export default function EmailTemplateCreateNew() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.email.loading);
  const entity = useAppSelector((state) => state.email.entity);
  const errorMessage = useAppSelector((state) => state.email.errorMessage);
  const updateSuccess = useAppSelector((state) => state.email.updateSuccess);
  let email_status_internal = "draft";
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useParams();
  let emailID = useParam.sub_slug;

  useEffect(() => {
    dispatch(getEntity(emailID));
  }, [emailID]);

  useEffect(() => {
    if (updateSuccess === true) {
      navigate("/email_queue");
    }
  }, [updateSuccess]);

  const useFields = {
    email_subject: useField<string>({
      value: entity?.email_subject ?? "",
      validates: [
        lengthLessThan(200, "No more than 200 characters."),
        lengthMoreThan(2, "No shorter than 2 characters."),
        (inputValue) => {
          if (inputValue.length < 2) {
            return "Your title is too short, or it is empty.";
          }
        },
      ],
    }),

    email_content: useField<string>({
      value: entity?.email_content ?? "",
      validates: [
        lengthLessThan(250, "No more than 250 characters."),
        lengthMoreThan(2, "No shorter than 2 characters."),
        (inputValue) => {
          if (inputValue.length < 2) {
            return "Your title is too short, or it is empty.";
          }
        },
      ],
    }),

    email_from: useField<string>({
      value: entity?.email_from ?? "",
      validates: [
        lengthLessThan(200, "Your content is too long."),
        lengthMoreThan(5, "From is required."),
        (inputValue) => {
          if (!helpers.isEmail(inputValue)) {
            return "Your email is not valid.";
          }
          if (helpers.isUTF8(inputValue)) {
            return "Email không nên có mã Unicode, bạn vui lòng kiểm tra!";
          }
        },
      ],
    }),

    email_to: useField<string>({
      value: entity?.email_to ?? "",
      validates: [
        lengthLessThan(200, "Your content is too long."),
        lengthMoreThan(5, "To is required!."),
      ],
    }),

    email_cc: useField<string>({
      value: entity?.email_cc ?? "",
      validates: [
        lengthLessThan(200, "Your content is too long."),
        lengthMoreThan(5, "To is required!."),
      ],
    }),

    email_bcc: useField<string>({
      value: entity?.email_bcc ?? "",
      validates: [
        lengthLessThan(200, "Your content is too long."),
        lengthMoreThan(5, "To is required!."),
      ],
    }),
  };

  const { fields, submit, submitting, dirty, reset, submitErrors, makeClean } =
    useForm({
      fields: useFields,
      async onSubmit(values) {
        // create new
        dispatch(clearError());
        if (emailID) {
          dispatch(
            updateEntity({
              email_id: emailID,
              email_subject: values.email_subject,
              email_content: values.email_content,
              email_from: values.email_from,
              email_to: values.email_to,
              email_cc: values.email_cc,
              email_bcc: values.email_bcc,
              email_status: email_status_internal,
            })
          );
        } else {
          dispatch(
            createEntity({
              email_subject: values.email_subject,
              email_content: values.email_content,
              email_from: values.email_from,
              email_to: values.email_to,
              email_cc: values.email_cc,
              email_bcc: values.email_bcc,
              email_status: email_status_internal,
            })
          );
        }
        return { status: "success" };
      },
    });

  useEffect(() => {
    reset();
    makeClean();
  }, []);

  const errorBanner = errorMessage?.length ? (
    <Layout.Section>
      <Banner status="critical">
        <p>There were some issues with your form submission:</p>
        <ul>
          {errorMessage?.map(({ field, message }, index) => {
            return (
              <li key={`${message}${index}`}>
                {field}: {message}
              </li>
            );
          })}
        </ul>
      </Banner>
    </Layout.Section>
  ) : null;

  return (
    <>
      {loading ? (
        <div style={{ height: "100px" }}>
          <Loading />
        </div>
      ) : null}
      <Page
        breadcrumbs={[{ content: "All email", url: "/email_queue" }]}
        primaryAction={{
          content: "Sent",
          disabled: !dirty,
          onAction: () => {
            email_status_internal = "pending";
            setTimeout(() => {
              submit();
            }, 200);
          },
          loading: loading,
        }}
        secondaryActions={[
          {
            content: "Save draft",
            disabled: !dirty,
            onAction: submit,
            loading: loading,
          },
        ]}
        title={emailID ? `Edit Email` : `Compose Email`}
      >
        <Layout>
          {errorBanner}
          <Layout.Section>
            <Card title="Email template details" sectioned>
              <Form onSubmit={submit}>
                <FormLayout>
                  <TextField
                    autoComplete="off"
                    maxLength={250}
                    autoFocus
                    label="Email from"
                    {...fields.email_from}
                  />
                  <TextField
                    autoComplete="off"
                    maxLength={250}
                    label="Email to"
                    {...fields.email_to}
                    helpText={"Send to many people by using comma(,)"}
                  />
                  <TextField
                    autoComplete="off"
                    maxLength={250}
                    label="Email CC"
                    {...fields.email_cc}
                    helpText={"Send to many people by using comma(,)"}
                  />
                  <TextField
                    autoComplete="off"
                    maxLength={250}
                    label="Email BCC"
                    {...fields.email_bcc}
                    helpText={"Send to many people by using comma(,)"}
                  />
                  <TextField
                    autoComplete="off"
                    label="Email subject"
                    {...fields.email_subject}
                  />
                  <TextField
                    autoComplete="off"
                    showCharacterCount
                    max={50000}
                    maxLength={50000}
                    label="Email content"
                    {...fields.email_content}
                    multiline={4}
                    helpText={<></>}
                  />
                </FormLayout>
              </Form>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card title="Notice" sectioned>
              <p>
                Email templates contain prefilled data that you specify, so you
                don't have to re-enter the same information for each article.
              </p>
              <p>All email is send from this CRM use a template.</p>
            </Card>
          </Layout.Section>
        </Layout>
        <Stack>
          <Stack.Item fill></Stack.Item>
          <Stack.Item>
            <Button onClick={submit} plain disabled={!dirty}>
              Safe Draft
            </Button>
            &nbsp; &nbsp; &nbsp;
            <Button
              primary
              onClick={() => {
                email_status_internal = "pending";
                setTimeout(() => {
                  submit();
                }, 200);
              }}
              disabled={!dirty}
            >
              Sent
            </Button>
          </Stack.Item>
        </Stack>
      </Page>
    </>
  );
}
