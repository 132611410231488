import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import dateandtime from "date-and-time";
import { Button, Page, Toast, Loading, Badge } from "@shopify/polaris";
import { getEntity, updateEntity } from "store/email.store.reducer";
import Parser from "html-react-parser";

export default function EmailQueue() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.sub_slug; // sub_slug

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const entity = useAppSelector((state) => state.email.entity);
  const loading = useAppSelector((state) => state.email.loading);
  const updateSuccess = useAppSelector((state) => state.email.updateSuccess);

  const [showtoast, setShowtoast] = useState("Chafo");

  useEffect(() => {
    dispatch(getEntity(Param));
  }, []);

  function partialUpdate(val: string) {
    dispatch(
      updateEntity({
        email_id: Number(Param),
        email_status: String(val),
      })
    );
  }

  useEffect(() => {
    setShowtoast("Update successfully!");
  }, [updateSuccess]);

  const retry = (
    <Button
      size="slim"
      destructive
      outline
      onClick={() => partialUpdate("pending")}
    >
      Retry
    </Button>
  );

  const resent = (
    <Button
      size="slim"
      destructive
      outline
      onClick={() => partialUpdate("pending")}
    >
      ReSend
    </Button>
  );

  const send = (
    <Button size="slim" primary onClick={() => partialUpdate("queue")}>
      Send now
    </Button>
  );

  const cancel_queue = (
    <Button size="slim" destructive onClick={() => partialUpdate("cancel")}>
      Cancel
    </Button>
  );

  const actualContent = entity ? (
    <>
      <div>From: {entity.email_from}</div>
      <div>To: {entity.email_to}</div>
      {entity.email_cc !== "" ? <div>CC: {entity.email_cc}</div> : ""}
      {entity.email_bcc !== "" ? <div>BCC: {entity.email_bcc}</div> : ""}
      <div>CreateBy: {entity?.users?.user_email}</div>
      <br />
      {entity.email_status === "draft" ? send : null}
      {entity.email_status === "cancel" ? resent : null}
      {entity.email_status === "fail" ? retry : null}
      {entity.email_status === "queue" || entity.email_status === "pending"
        ? cancel_queue
        : null}
      <hr />
      <br />
      <div>{Parser(entity?.email_content || " ")}</div>
    </>
  ) : null;

  const editButton = () => {
    if (entity?.email_status !== "draft") {
      return;
    }
    navigate("/email_queue/create_new/" + entity.email_id);
  };

  const toasty = showtoast ? (
    <Toast content={showtoast} onDismiss={null} />
  ) : null;

  return (
    <>
      {toasty}
      <Page
        title={entity?.email_subject}
        titleMetadata={<Badge>{entity?.email_status || "draft"}</Badge>}
        subtitle={dateandtime.format(
          new Date(Number(entity?.createAt)),
          "DD/MM/YYYY HH:mm"
        )}
        breadcrumbs={[{ content: "Email Queue", url: "/email_queue" }]}
        primaryAction={{
          content: "Edit",
          disabled: entity?.email_status !== "draft",
          onClick: editButton,
        }}
      >
        {loading ? <Loading /> : null}
        {actualContent}
      </Page>
    </>
  );
}
