import Textarea from '@mui/joy/Textarea'
import { Box, Button, Chip, FormControl, IconButton, List, ListItem, ListItemText, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useState, useEffect, useRef } from 'react'
import { FaUpload } from 'react-icons/fa'
import { updateCourse, uploadMedia } from '../service'
import { ListItemDataUpdate } from '../interface'
import dayjs from 'dayjs';
import { MdDelete } from "react-icons/md";
import CustomSnackbar from 'components/snackbar'

const EditCourse = ({ getDetailCourse, style, updateDetailCourse, setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity  }) => {
    const fileInputRef = useRef(null);

    const [formData, setFormData] = useState<ListItemDataUpdate>({
        _id: '',
        price: '',
        title: '',
        avatar: '',
        country: 'country',
        version: 'version',
        end_time: '',
        language: 'en',
        media_id: '',
        product_id: 'product_id',
        start_time: '',
        description: '',
        trash_status: 'trash_status',
        public_status: 'public_status',
        long_description: '',
        product_status: '',
        labels: '',
        level: '',
        skills: [],
        type: 'Call 1-1',
        coupon_id: null,
    });
    const [avatarUrl, setAvatarUrl] = useState<string | null>(getDetailCourse?.avatar?.media_url || null);
    const [inputValue, setInputValue] = useState('');
    const [items, setItems] = useState([]);
    const handleFileChangeAndUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const files = event.target.files;
            if (files && files.length > 0) {
                const file = files[0];
                const headers = {
                    'X-Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQ1Nzc3MDAsImRhdGEiOnsiX2lkIjoiNjU4MjVhYTQ5ZDY2YWM4YzQ3YzZiMDFkIiwia2V5IjoiOTNiOTgxN2VkZmQ1ZjU1NDBkZTI2MDNiM2M3N2JlZmEiLCJzaWduYXR1cmUiOiI2ZGI5M2RhMzE1YzRjNzBkNjY2YjNiNWRjZjIwYzUzMiIsInNlc3Npb24iOiI2NTgyNWFhNDlkNjZhYzhjNDdjNmIwMWYifSwiaWF0IjoxNzAzMDQxNzAwfQ.R0CrGfSMvi_V3T445vlp75Eetz_x6RHCrWtaSkXo0A8'
                };
                const { id, src } = await uploadMedia(file, headers);

                setFormData(prevData => ({
                    ...prevData,
                    avatar: id
                }));
                setAvatarUrl(src);
            }
        } catch (error) {
            console.error('Upload failed:', error);
        } finally {
            console.log("Upload completed");
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };


    useEffect(() => {
        if (getDetailCourse) {
            setFormData({
                ...formData,
                _id: getDetailCourse?._id,
                price: getDetailCourse?.price?.toString(),
                title: getDetailCourse?.title,
                avatar: getDetailCourse?.avatar?._id,
                country: getDetailCourse?.country,
                version: getDetailCourse?.version,
                end_time: getDetailCourse?.end_time,
                language: getDetailCourse?.language,
                media_id: getDetailCourse?.media_id?.toString(),
                product_id: getDetailCourse?.product_id,
                start_time: getDetailCourse?.start_time,
                description: getDetailCourse?.description,
                trash_status: getDetailCourse?.trash_status,
                public_status: getDetailCourse?.public_status,
                long_description: getDetailCourse?.long_description,
                product_status: getDetailCourse?.product_status,
                labels: getDetailCourse?.labels,
                level: getDetailCourse?.level,
                skills: getDetailCourse?.skills,
                type: getDetailCourse?.type,
                coupon_id: null,
            });
        }
    }, [getDetailCourse]);


    const handleSave = async () => {
        try {
            await updateCourse(
                formData?._id,
                formData.price,
                formData.title,
                formData.avatar,
                formData.country,
                formData.version,
                formData.end_time,
                formData.language,
                formData.media_id,
                formData.product_id,
                formData.start_time,
                formData.description,
                formData.trash_status,
                formData.public_status,
                formData.long_description,
                formData.product_status,
                formData.labels,
                formData.level,
                formData.skills,
                formData.type,
                formData.coupon_id
            );
            updateDetailCourse(formData);
            setSnackbarMessage('Chỉnh sửa thành công');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage('Chỉnh sửa thất bại');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleChangeCourse = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // const handleInputChange = (event) => {
    //     setInputValue(event.target.value);
    // };

    // const handleKeyPress = (event) => {
    //     if (event.key === 'Enter' && inputValue.trim() !== '') {
    //         setItems([...items, inputValue]);
    //         setInputValue('');
    //     }
    // };

    // const handleDelete = (index) => {
    //     const newItems = items.filter((_, i) => i !== index);
    //     setItems(newItems);
    // };
    const skillsOptions = ["Listening", "Reading", "Writing", "Speaking"];
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 224,
                width: 250,
            },
        },
    };

    const handleChangeSkills = (event) => {
        const {
            target: { value },
        } = event;
        setFormData({
            ...formData,
            skills: typeof value === 'string' ? value.split(',') : value,
        });
    };

    return (
        <Box sx={style}>
            <Typography variant='h6' mb={2}>Chỉnh sửa Khóa học</Typography>
            <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
                <Box sx={{ width: '450px' }}>
                    <TextField value={formData?._id} onChange={(e) => handleChangeCourse("id", e.target.value)} name="title" fullWidth placeholder='Điền tiêu đề' size="small" sx={{ mb: 1, display: 'none' }} />

                    <Stack direction='column' gap={1} >
                        <Typography variant='body1' fontWeight={550}>Tiêu đề: </Typography>
                        <TextField value={formData.title} onChange={(e) => handleChangeCourse("title", e.target.value)} name="title" fullWidth placeholder='Điền tiêu đề' size="small" sx={{ mb: 1 }} />
                    </Stack>
                    <Stack direction='column' gap={1} mb={2}>
                        <Typography variant='body1' fontWeight={550}>Thời gian học:</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack direction="row" alignItems="center" gap={2} justifyContent="space-between">
                                <DatePicker
                                    className='datePicker'
                                    name="start_time"
                                    value={dayjs(formData.start_time)}
                                    onChange={(date) => handleChangeCourse("start_time", date)}
                                    slotProps={{ textField: { placeholder: 'Chọn ngày bắt đầu' } }}
                                    sx={{ width: '100%' }}
                                />
                                <DatePicker
                                    className='datePicker'
                                    name="end_time"
                                    value={dayjs(formData.end_time)}
                                    onChange={(date) => handleChangeCourse("end_time", date)}
                                    slotProps={{ textField: { placeholder: 'Chọn ngày kết thúc' } }}
                                    sx={{ width: '100%' }}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Stack>

                    <Stack direction='column' gap={1} >
                        <Typography variant='body1' fontWeight={550}>Mô tả:</Typography>
                        <Textarea color="neutral" value={formData.description} onChange={(e) => handleChangeCourse("description", e.target.value)} name="description" minRows={3} placeholder='Mô tả' sx={{ mb: 2 }} />
                    </Stack>

                    <Stack direction='column' gap={1} >
                        <Typography variant='body1' fontWeight={550}>Chi tiết khóa học:</Typography>
                        <Textarea color="neutral" value={formData.long_description} onChange={(e) => handleChangeCourse("long_description", e.target.value)} name="long_description" minRows={5} placeholder='Chi tiết khóa học' sx={{ mb: 2 }} />
                    </Stack>

                    {/* <Stack direction='column' gap={1} mb={2}>
                    <Typography variant='body1' fontWeight={550}>Thời gian học:</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack direction="row" alignItems="center" gap={2} justifyContent="space-between">
                            <DatePicker 
                                className='datePicker' 
                                onChange={(date) => handleChangeCourse("start_time", date)}
                                name="start_time"
                                slotProps={{ textField: { placeholder: 'Chọn ngày bắt đầu' } }}
                                sx={{ width: '100%' }}/>
                            <DatePicker 
                                className='datePicker' 
                                onChange={(date) => handleChangeCourse("end_time", date)}
                                name="end_time"
                                slotProps={{ textField: { placeholder: 'Chọn ngày kết thúc' } }}
                                sx={{ width: '100%' }}/>
                        </Stack>
                    </LocalizationProvider>
                </Stack> */}

                    <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                        <Box flex={1}>
                            <Typography variant='body1' fontWeight={550} mb={1}>Kiểu khóa học:</Typography>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formData.type} // Use formData.type as the value
                                    onChange={(e) => handleChangeCourse("type", e.target.value)}
                                    name="type"
                                    fullWidth
                                    size="small"
                                >
                                    <MenuItem value="Self-learning">Self-learning</MenuItem>
                                    <MenuItem value="Call 1-1">Call 1 - 1</MenuItem>
                                    <MenuItem value="Call group">Call group</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box flex={1}>
                            <Typography variant='body1' fontWeight={550} mb={1}>Giá khóa học:</Typography>
                            <TextField fullWidth value={formData.price} onChange={(e) => handleChangeCourse("price", e.target.value)} name="price" placeholder='Giá khóa học' size="small" />
                        </Box>
                    </Box>
                    <Box >
                        <Box flex={1}>
                            <Typography variant='body1' fontWeight={550} mb={1}>Ky nang:</Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="demo-multiple-chip"
                                    multiple
                                    value={formData.skills}
                                    onChange={handleChangeSkills}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {Array.isArray(selected) ? (
                                                selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))
                                            ) : (
                                                <Chip key={selected} label={selected} />
                                            )}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                    size="small"
                                >
                                    {skillsOptions.map((skill) => (
                                        <MenuItem key={skill} value={skill}>
                                            {skill}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box flex={1}>
                            <Typography variant='body1' fontWeight={550} mb={1}>Cap do:</Typography>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formData.level}
                                    onChange={(e) => handleChangeCourse("level", e.target.value)}
                                    name="level"
                                    fullWidth
                                    size="small"
                                >
                                    <MenuItem value="4+">4+</MenuItem>
                                    <MenuItem value="5+">5+</MenuItem>
                                    <MenuItem value="6+">6+</MenuItem>
                                    <MenuItem value="7+">7+</MenuItem>
                                    <MenuItem value="8+">8+</MenuItem>
                                    <MenuItem value="9">9</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    {/* <Stack direction='column' gap={1} >
                        <Typography variant='body1' fontWeight={550}>Nhan:</Typography>
                        <TextField
                            placeholder='Nhap nhan dan'
                            variant="outlined"
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                            fullWidth
                        />
                        <List>
                            {items.map((item, index) => (
                                <ListItem sx={{ background: '#e9e9e9', borderRadius: '30px' }} key={index} secondaryAction={
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index)}>
                                        <MdDelete />
                                    </IconButton>
                                }>
                                    <ListItemText primary={item} />
                                </ListItem>
                            ))}
                        </List>
                    </Stack> */}

                </Box>
                <Box sx={{ width: '250px' }}>
                    <Typography variant='body1' fontWeight={550} mb={1}>Hình ảnh/video giới thiệu khóa học:</Typography>
                    <Box>
                        {/* {mediaType === 'image' && <img src={mediaSrc} alt="Media preview" width={250} />}
                // {mediaType === 'video' && <video src={mediaSrc} controls width={250}></video>} */}
                        {avatarUrl && (
                            <img
                                src={avatarUrl}
                                alt="banner"
                                style={{ width: '250px', cursor: 'pointer' }}
                                onClick={handleButtonClick}
                            />
                        )}
                        {!avatarUrl && getDetailCourse?.avatar?.media_url && (
                            <img
                                src={getDetailCourse.avatar.media_url}
                                alt="banner"
                                style={{ width: '250px', cursor: 'pointer' }}
                                onClick={handleButtonClick}
                            />
                        )}
                    </Box>
                    <input
                        id="file-input"
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={handleFileChangeAndUpload}
                    />
                    <Button
                        variant="contained"
                        startIcon={<FaUpload />}
                        sx={{
                            width: '250px',
                            height: '180px',
                            background: 'transparent',
                            color: '#333',
                            fontWeight: 550,
                            border: '1px dashed',
                            marginBottom: 2,
                            display: 'none', // Hide the button by default
                        }}
                    >
                        Tải ảnh/video
                    </Button>
                </Box>
            </Stack>
            <Stack direction="row" gap={2} justifyContent="space-between" mt={2}>
                <Button fullWidth size='small' variant='outlined'>Thoát</Button>
                <Button fullWidth size='small' variant='contained' onClick={handleSave}>Tạo</Button>
            </Stack>
        </Box>
    )
}

export default EditCourse