import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import {
  Banner,
  Card,
  ContextualSaveBar,
  Form,
  FormLayout,
  Layout,
  List,
  Loading,
  Page,
  RadioButton,
  Stack,
  TextField,
} from "@shopify/polaris";
import { clearError, createEntity } from "store/email_template.store.reducer";
import {
  asChoiceField,
  lengthLessThan,
  lengthMoreThan,
  useField,
  useForm,
} from "@shopify/react-form";

export default function EmailTemplateCreateNew() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.email_template.loading);
  const errorMessage = useAppSelector(
    (state) => state.email_template.errorMessage
  );
  const updating = useAppSelector((state) => state.email_template.updating);
  const createSuccess = useAppSelector(
    (state) => state.email_template.createSuccess
  );

  useEffect(() => {
    if (createSuccess === true) {
      navigate("/email_template");
    }
  }, [createSuccess]);

  const useFields = {
    template_title: useField<string>({
      value: "",
      validates: [
        lengthLessThan(200, "No more than 200 characters."),
        lengthMoreThan(2, "No shorter than 2 characters."),
        (inputValue) => {
          if (inputValue.length < 2) {
            return "Your title is too short, or it is empty.";
          }
        },
      ],
    }),
    template_slug: useField<string>({
      value: "",
      validates: [
        lengthLessThan(250, "No more than 250 characters."),
        lengthMoreThan(2, "No shorter than 2 characters."),
        (inputValue) => {
          if (inputValue.length < 2) {
            return "Your title is too short, or it is empty.";
          }
        },
      ],
    }),
    template_content: useField<string>({
      value: "",
      validates: [
        lengthLessThan(50000, "Your content is too long."),
        lengthMoreThan(2, "Your content is too short."),
      ],
    }),
    template_status: useField<string>({
      value: "0",
      validates: [],
    }),
  };

  const { fields, submit, submitting, dirty, reset, submitErrors, makeClean } =
    useForm({
      fields: useFields,
      async onSubmit(values) {
        // create new
        dispatch(clearError());
        dispatch(
          createEntity({
            template_title: values.template_title,
            template_slug: values.template_slug,
            template_content: values.template_content,
            template_status: Number(values.template_status),
          })
        );
        return { status: "success" };
      },
    });

  useEffect(() => {
    reset();
    makeClean();
  }, []);

  const contextBar = dirty ? (
    <ContextualSaveBar
      message="Unsaved email template"
      saveAction={{
        onAction: submit,
        loading: loading,
        disabled: false,
      }}
      discardAction={{
        onAction: reset,
      }}
    />
  ) : null;

  const errorBanner = errorMessage?.length ? (
    <Layout.Section>
      <Banner status="critical">
        <p>There were some issues with your form submission:</p>
        <ul>
          {errorMessage.map(({ field, message }, index) => {
            return (
              <li key={`${message}${index}`}>
                {field}: {message}
              </li>
            );
          })}
        </ul>
      </Banner>
    </Layout.Section>
  ) : null;

  return (
    <>
      {loading ? (
        <div style={{ height: "100px" }}>
          <Loading />
        </div>
      ) : null}
      <Page
        breadcrumbs={[{ content: "All template", url: "/email_template" }]}
        primaryAction={{
          content: "Save",
          disabled: !dirty,
          onAction: submit,
          loading: loading,
        }}
        title={`Add new Email template`}
      >
        {contextBar}
        <Layout>
          {errorBanner}
          <Layout.Section>
            <Card title="Email template details" sectioned>
              <Form onSubmit={submit}>
                <FormLayout>
                  <TextField
                    autoComplete="off"
                    autoFocus
                    label="Template Title"
                    {...fields.template_title}
                  />
                  <TextField
                    autoComplete="off"
                    maxLength={250}
                    label="Template Slug"
                    {...fields.template_slug}
                    helpText={
                      "Slug will be use to show in other select form. Once created, this field can not be changed!"
                    }
                  />
                  <p>Template status</p>
                  <Stack vertical>
                    <RadioButton
                      label="Disabled"
                      {...asChoiceField(fields.template_status, "0")}
                      helpText="Archived, don't use"
                    />
                    <RadioButton
                      label="Enable"
                      {...asChoiceField(fields.template_status, "1")}
                      helpText="Can be used in Email Setting's section"
                    />
                  </Stack>
                  <TextField
                    autoComplete="off"
                    showCharacterCount
                    max={50000}
                    maxLength={50000}
                    label="Template content"
                    {...fields.template_content}
                    multiline={4}
                    helpText={
                      <>
                        <p>
                          HTML is supported, Use these placeholders in your
                          template HTML:
                        </p>
                        <br />
                        <List type="bullet">
                          <List.Item>
                            {`{{customer_name}}`} for customer name
                          </List.Item>
                          <List.Item>{`{{user_name}}`} for user name</List.Item>
                          <List.Item>
                            {`{{content}}`} for main content
                          </List.Item>
                        </List>
                      </>
                    }
                  />
                </FormLayout>
              </Form>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card title="Notice" sectioned>
              <p>
                Email templates contain prefilled data that you specify, so you
                don't have to re-enter the same information for each article.
              </p>
              <p>All email is send from this CRM use a template.</p>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
}
