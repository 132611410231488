import {
  Card,
  EmptyState,
  Form,
  FormLayout,
  Layout,
  Modal,
  Page,
  PageActions,
  Stack,
  TextContainer,
  TextField,
  Toast,
  Tag,
  Button,
  Autocomplete,
  Select,
  DropZone,
  Thumbnail,
  Caption,
  ProgressBar,
} from "@shopify/polaris";
import { NoteMinor, MobileCancelMajor } from "@shopify/polaris-icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import emptyIMG from "../../media/empty.png";
import helpers from "../../helpers";
import SkeletonLoading from "components/skeleton_loading";
import axios from "axios";

import {
  clearError,
  getEntity,
  updateEntity,
  deleteEntity,
  createEntity,
  reset,
} from "../../store/posts.store.reducer";

import {
  lengthLessThan,
  lengthMoreThan,
  useField,
  useForm,
} from "@shopify/react-form";
import QuickUploadImage from "components/oneclick-upload";
import uploadImage from "../../media/choose-file.png";
import { TinyMCE } from "components/tinyMCE";

import ReactAudioPlayer from "react-audio-player";

/**
 * Convert Tag object to String and join with comma
 * @param tags Object
 */

/**
 * Main function
 * @returns
 */
export default function NewEdit() {
  const entity = useAppSelector((state) => state.posts.entity);
  const updating = useAppSelector((state) => state.posts.updating);
  const loading = useAppSelector((state) => state.posts.loading);
  const errorMessage = useAppSelector((state) => state.posts.errorMessage);
  const updateSuccess = useAppSelector((state) => state.posts.updateSuccess);
  const dispatch = useAppDispatch();
  const history = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [notification, setNotification] = useState<string | null>(null);
  const [internalErrorNotice, setInternalErrorNotice] = useState("");
  const [textContent, setTextContent] = useState("");

  /**
   * Language type
   */
  const [languageType, setLanguageType] = useState("en");
  const handleLanguageChange = useCallback(
    (value) => setLanguageType(value),
    []
  );
  const languages = [
    { label: "English", value: "en" },
    { label: "Vietnamese", value: "vi" },
    { label: "Spanish", value: "sp" },
  ];

  const toggleActive = useCallback(() => {
    setNotification(errorMessage);
  }, []);

  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.news_slug || false;

  useEffect(() => {
    if (Param) {
      dispatch(getEntity(Param));
    } else {
      setTextContent(" ");
      dispatch(reset());
    }
  }, []);

  useEffect(() => {
    if (entity && entity.post_content) {
      if (Param) {
        setTimeout(() => {
          setTextContent(entity.post_content);
        }, 10);
      } else {
        setTextContent(" ");
      }
    }
  }, [entity]);

  /**
   * upload image
   */
  const [srcImage, setSrcImage] = useState(uploadImage);
  const [srcImageId, setSrcImageId] = useState("");
  const [fileDownload, setFileDownload] = useState([]);

  useEffect(() => {
    if (entity) {
      if (Param) {
        setLanguageType(entity?.post_language);
        setTimeout(() => {
          setSrcImage(entity?.post_avatar?.media_url);
        }, 10);
        setSrcImageId(entity?.post_avatar?._id);
        setPostView(entity?.post_view);

        let file = [];
        entity?.downloads?.map(({ _id, media_url }) => {
          file = [...file, ...[_id]];
        });
        setFileDownload(file);
      } else {
        setSrcImage(uploadImage);
        setSrcImageId("");
        setLanguageType("en");
        setPostView(0);
      }
    }
  }, [entity]);

  const remove_image_element = (element: number) => {
    var ar = fileDownload;
    ar.splice(ar.indexOf(element), 1);
    console.log(ar, "arrrrrrrr");

    setFileDownload(ar);
  };

  useEffect(() => {
    console.log(fileDownload, "fileDownload");
  }, [fileDownload]);

  function handQuickUpdateSuccess(res: any) {
    console.log(res);
    setSrcImageId(res[0].callback._id);
    setSrcImage(res[0].src);
  }

  useEffect(() => {
    if (updateSuccess === true) {
      dataFileRef.current = [];
      history("/news/edit/" + entity?._id, { replace: true });
      dispatch(getEntity(Param));

      setNotification("This post has been updated!");
    }
  }, [updateSuccess]);

  /**
   * Upload Image
   */

  const [medias, setMedias] = useState([]);
  const [processing_media, setProcessing_media] = useState(-1);
  let processing_media_error = [];
  const [resultData, setResultData] = useState([]);
  const dataRef = useRef([]);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [updateProgress, setUpdateProgress] = useState(0);

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setMedias((files) => [...files, ...acceptedFiles]),
    []
  );

  const mediaUpload = !medias.length && <DropZone.FileUpload />;
  const remove_upload_element = (element: number) => {
    if (uploading) return null;
    delete medias[element];
    var filtered = medias.filter((el) => {
      return el != null;
    });
    setMedias(filtered);
  };

  const uploadedFileMedias = medias.length > 0 && (
    <Stack vertical>
      {medias.map((file, index) =>
        file?.size < 50282820 ? (
          <Stack alignment="center" key={index}>
            <div
              style={{ width: "10px", cursor: "pointer" }}
              onClick={() => remove_upload_element(index)}
            >
              <MobileCancelMajor />
            </div>
            <Thumbnail
              size="small"
              alt={file.name}
              source={
                validImageTypes.includes(file.type)
                  ? window.URL.createObjectURL(file)
                  : NoteMinor
              }
            />
            <div>
              {helpers.trimMiddleString(file.name, 5, 10)}{" "}
              <Caption>{helpers.bytesToSize(file.size)}</Caption>
              {processing_media === index ? (
                <ProgressBar
                  progress={updateProgress}
                  size="small"
                  color="success"
                />
              ) : null}
            </div>
          </Stack>
        ) : null
      )}
    </Stack>
  );

  /**
   * Upload File
   */

  const [files, setFiles] = useState([]);
  const [processing_file, setProcessing_file] = useState(-1);
  let processing_file_error = [];
  const [uploading, setUploading] = useState(false);
  const dataFileRef = useRef([]);

  const start_upload = async () => {
    let index_file = -1;
    let clone_files = [...[], ...files];
    let clone_data_file = [...[], ...dataFileRef.current];

    for (let element of clone_files) {
      index_file++;
      setProcessing_file(index_file);
      // if (element?.size > 50282820) continue;
      var bodyFormDataFile = new FormData();
      bodyFormDataFile.append("file", element);
      try {
        let resultFile = await axios.post(
          // process.env.REACT_APP_AJAX_UPLOAD_FILE,
          `https://dynamic-admin.iceo.tech/upload-file?callback=${localStorage.getItem(
            "AJAX_URL"
          )}/chat-media/create`,
          bodyFormDataFile,
          {
            onUploadProgress: (progressEvent: any) => {
              let progress_percent =
                Math.floor(progressEvent.loaded / progressEvent.total) * 100;
              setUpdateProgress(progress_percent);
            },
          }
        );

        delete files[index_file];
        setFiles(files);
        let res = resultFile?.data[0]?.callback._id;
        clone_data_file = [...clone_data_file, ...[res]];

        setUpdateProgress(0);
      } catch (error) {
        processing_file_error.push(index_file);
      }
    }
    dataFileRef.current = clone_data_file;

    if (helpers.isEmpty(processing_file_error)) {
      setUploading(false);
    }

    setUpdateProgress(0);
    setUploading(true);
    let index = -1;
    let clone_files_media = [...[], ...medias];
    // let clone_data = [...[], ...resultData];
    let clone_data = [...[], ...dataRef.current];

    for (let element of clone_files_media) {
      index++;
      setProcessing_media(index);
      if (element?.size > 50282820) continue;
      var bodyFormData = new FormData();
      bodyFormData.append("file", element);
      try {
        let result = await axios.post(
          // process.env.REACT_APP_AJAX_UPLOAD_FILE,
          `https://dynamic-admin.iceo.tech/upload-file?callback=${localStorage.getItem(
            "AJAX_URL"
          )}/chat-media/create`,
          bodyFormData,
          {
            onUploadProgress: (progressEvent: any) => {
              let progress_percent =
                Math.floor(progressEvent.loaded / progressEvent.total) * 100;
              setUpdateProgress(progress_percent);
            },
          }
        );

        delete medias[index];
        setMedias(medias);
        // let res = result?.data[0].src;
        let res = result?.data[0]?.callback?._id;
        clone_data = [...clone_data, ...[res]];

        // await helpers.sleep(2000);
        setUpdateProgress(0);
      } catch (error) {
        processing_media_error.push(index);
      }
    }
    // setResultData(clone_data);
    dataRef.current = clone_data;

    if (helpers.isEmpty(processing_media_error)) {
      setUploading(false);
    }
  };

  const handleDropZoneDropFile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );

  const fileUpload = !files.length && <DropZone.FileUpload />;

  const remove_upload_element_file = (element: number) => {
    if (uploading) return null;
    delete files[element];
    var filtered = files.filter((el) => {
      return el != null;
    });
    setFiles(filtered);
  };

  const uploadedFiles = files.length > 0 && (
    <Stack vertical>
      {files.map((file, index) =>
        file?.size < 50282820 ? (
          <Stack alignment="center" key={index}>
            <div
              style={{ width: "10px", cursor: "pointer" }}
              onClick={() => remove_upload_element_file(index)}
            >
              <MobileCancelMajor />
            </div>
            <Thumbnail
              size="small"
              alt={file.name}
              source={
                validImageTypes.includes(file.type)
                  ? window.URL.createObjectURL(file)
                  : NoteMinor
              }
            />
            <div>
              {helpers.trimMiddleString(file.name, 5, 10)}{" "}
              <Caption>{helpers.bytesToSize(file.size)}</Caption>
              {processing_file === index ? (
                <ProgressBar
                  progress={updateProgress}
                  size="small"
                  color="success"
                />
              ) : null}
            </div>
          </Stack>
        ) : null
      )}
    </Stack>
  );

  useEffect(() => {
    if (errorMessage) {
      setNotification(errorMessage);
      dispatch(clearError());
    }
  }, [errorMessage]);

  function _deleteEntityAction() {
    setOpenModal(true);
  }

  function onModalAgree() {
    dispatch(deleteEntity(Param));
    setOpenModal(false);
  }

  const emptyData = (
    <Page title="News" breadcrumbs={[{ content: "Page list", url: "/news" }]}>
      <EmptyState heading="No post here!" image={emptyIMG}>
        <p>
          Oh! There is no record here! Try remove filter or add a new record!
        </p>
      </EmptyState>
    </Page>
  );
  const toastMarkup = notification ? (
    <Toast content={notification} onDismiss={toggleActive} />
  ) : null;

  function handUploadError(err: any) {
    setInternalErrorNotice("Tải ảnh thất bại! Ảnh quá lớn!");
  }

  const [post_view, setPostView] = useState(0);
  const handleChange = useCallback((newValue) => setPostView(newValue), []);

  const useFields = {
    post_title: useField<string>({
      value: entity?.post_title ?? "",
      validates: [
        lengthLessThan(200, "Không được dài hơn 200 ký tự."),
        lengthMoreThan(2, "Không được ngắn hơn 2 ký tự."),
        (inputValue) => {
          if (inputValue.length < 2) {
            return "Tiêu đề quá ngắn hoặc trống.";
          }
        },
      ],
    }),

    post_status: useField<string>({
      value: entity?.post_status ?? "",
      validates: [
        lengthLessThan(250, "Không được dài hơn 250 ký tự."),
        lengthMoreThan(2, "Không được ngắn hơn 2 ký tự."),
      ],
    }),

    post_excerpt: useField<string>({
      value: entity?.post_excerpt ?? "",
      validates: [
        lengthLessThan(250, "Không được dài hơn 250 ký tự."),
        lengthMoreThan(2, "Không được ngắn hơn 2 ký tự."),
      ],
    }),

    post_slug: useField<string>({
      value: entity?.post_slug ?? "",
      validates: [
        lengthLessThan(250, "Không được dài hơn 250 ký tự."),
        lengthMoreThan(2, "Không được ngắn hơn 2 ký tự."),
      ],
    }),

    post_view: useField<string>({
      value: entity?.post_view ?? "",
      validates: [],
    }),

    seo_title: useField<string>({
      value: entity?.seo_title ?? "",
      validates: [],
    }),

    seo_description: useField<string>({
      value: entity?.seo_description ?? "",
      validates: [],
    }),

    seo_keyword: useField<string>({
      value: entity?.seo_keyword ?? "",
      validates: [],
    }),
  };

  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset,
    submitErrors,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      await start_upload();
      let a = dataFileRef.current;
      let b = [...fileDownload, ...a];
      console.log(typeof values.post_view);
      try {
        if (!Param) {
          // create new
          dispatch(
            createEntity({
              post_title: values.post_title,
              post_content: textContent,
              post_excerpt: values.post_excerpt,
              post_avatar: srcImageId,
              post_language: languageType,
              post_type: "news",
              post_status: "publish",
              // post_view: Number(values.post_view),
              post_view: post_view,
              seo_title: values.seo_title,
              seo_description: values.seo_description,
              seo_keyword: values.seo_keyword,
              // public_album: JSON.stringify(dataRef.current),
              downloads: JSON.stringify(dataFileRef.current),
              public_album: JSON.stringify(dataRef.current),
            })
          );
        } else {
          dispatch(
            updateEntity({
              _id: entity?._id,
              post_title: values.post_title,
              post_content: textContent,
              post_excerpt: values.post_excerpt,
              // post_excerpt: JSON.parse(JSON.stringify(values.post_excerpt)),
              post_avatar: srcImageId,
              post_language: languageType,
              post_type: "news",
              post_status: "publish",
              // post_view: Number(values.post_view),
              // post_view: post_view,
              seo_title: values.seo_title,
              seo_description: values.seo_description,
              seo_keyword: values.seo_keyword,
              post_slug: values.post_slug,
              downloads: JSON.stringify(b),

              public_album: JSON.stringify(dataRef.current),
            })
          );
        }
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message =
          e?.response?.data?.title ??
          "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  const Actual_page =
    entity || !Param ? (
      <>
        <Page
          title="News"
          breadcrumbs={[{ content: "Page list", url: "/news" }]}
          primaryAction={{
            content: "Save",
            // disabled: !dirty,
            // loading: updating,
            onAction: submit,
            loading: uploading,
          }}
        >
          <Layout>
            <Layout.Section>
              <Form onSubmit={submit}>
                <Card>
                  <Card.Section>
                    <FormLayout>
                      <TextField
                        autoComplete="off"
                        autoFocus
                        requiredIndicator
                        label="Title"
                        {...fields.post_title}
                      />

                      <div>
                        <span>Content</span>
                        <div className="editor" style={{ minHeight: "500px" }}>
                          <TinyMCE
                            value={`${textContent}` ? `${textContent}` : " "}
                            onChange={(data) => {
                              setTextContent(data);
                            }}
                          />
                        </div>
                      </div>

                      <p>File</p>
                      <DropZone
                        // allowMultiple={false}
                        onDrop={handleDropZoneDropFile}
                        // accept="image/*"
                        // type="image"
                      >
                        {uploadedFiles}
                        {fileUpload}
                      </DropZone>

                      {entity?.downloads &&
                        entity?.downloads?.map(
                          ({
                            media_url,
                            media_file_name,
                            media_mime_type,
                            media_thumbnail,
                            _id,
                          }) => (
                            <>
                              <Stack alignment="center" key={_id}>
                                <div
                                  style={{ width: "10px", cursor: "pointer" }}
                                  onClick={() => remove_image_element(_id)}
                                >
                                  <MobileCancelMajor />
                                </div>

                                <Thumbnail
                                  size="small"
                                  alt={media_file_name}
                                  source={NoteMinor}
                                />
                                <div>
                                  <p>{media_file_name}</p>
                                  <ReactAudioPlayer
                                    src={media_url}
                                    autoPlay
                                    controls
                                    style={{ width: "300px", height: "30px" }}
                                  />
                                </div>
                              </Stack>
                            </>
                          )
                        )}
                    </FormLayout>
                  </Card.Section>
                </Card>
              </Form>
            </Layout.Section>

            <Layout.Section secondary>
              <Card title="Information" sectioned>
                <FormLayout>
                  <Select
                    label="Language"
                    options={languages}
                    requiredIndicator
                    onChange={handleLanguageChange}
                    value={languageType}
                  />

                  <TextField
                    autoComplete="off"
                    disabled={!Param ? true : false}
                    maxLength={250}
                    label="Slug"
                    {...fields.post_slug}
                  />

                  {/* <TextField
                    autoComplete="off"
                    maxLength={250}
                    label="Magazine"
                    type="number"
                    {...fields.post_view}
                  /> */}

                  <TextField
                    label="Magazine"
                    value={post_view.toString()}
                    onChange={handleChange}
                    type="number"
                    autoComplete="off"
                  />

                  <TextField
                    autoComplete="off"
                    maxLength={250}
                    label="Excerpt"
                    {...fields.post_excerpt}
                    multiline={4}
                  />

                  <p>Thumbnail</p>
                  <div id="profile_heading">
                    <div className="news_thumbnail_inner">
                      <div className="news_thumbnail_avatar">
                        <QuickUploadImage
                          onSuccess={handQuickUpdateSuccess}
                          onError={handUploadError}
                        />
                        <img src={srcImage} style={{ width: "115px" }} />
                      </div>
                    </div>
                  </div>
                </FormLayout>
              </Card>

              <Card title="SEO" sectioned>
                <FormLayout>
                  <TextField
                    autoFocus
                    autoComplete="off"
                    maxLength={250}
                    label="Seo Title"
                    {...fields.seo_title}
                  />

                  <TextField
                    autoComplete="off"
                    maxLength={250}
                    label="Seo Description"
                    {...fields.seo_description}
                    multiline={2}
                  />
                  <TextField
                    autoComplete="off"
                    maxLength={250}
                    label="Seo Keyword"
                    {...fields.seo_keyword}
                  />
                </FormLayout>
              </Card>

              <Card title="Public Album" sectioned>
                <FormLayout.Group condensed>
                  <DropZone
                    onDrop={handleDropZoneDrop}
                    accept="image/*"
                    type="image"
                  >
                    {uploadedFileMedias}
                    {mediaUpload}
                  </DropZone>
                  {entity?.public_album &&
                    entity?.public_album?.map(
                      ({ media_url, media_mime_type, _id }) => (
                        <>
                          {/* <Stack alignment="center" key={_id}>
                            <div
                              style={{ width: "10px", cursor: "pointer" }}
                              onClick={() => remove_image_element(_id)}
                            >
                              <MobileCancelMajor />
                            </div> */}
                          <img
                            src={media_url}
                            style={{
                              margin: "auto",
                              width: "100%",
                              float: "left",
                            }}
                            alt={media_mime_type}
                            key={_id}
                          />
                          {/* </Stack> */}
                        </>
                      )
                    )}
                </FormLayout.Group>
              </Card>
            </Layout.Section>
          </Layout>
          <PageActions
            primaryAction={{
              content: "Save",
              onAction: submit,
              // disabled: !dirty,
              loading: updating,
            }}
            secondaryActions={
              Param
                ? [
                    {
                      content: "Delete",
                      destructive: true,
                      onAction: _deleteEntityAction,
                    },
                  ]
                : []
            }
          />
        </Page>
      </>
    ) : (
      emptyData
    );

  const _Modal = openModal ? (
    <Modal
      activator={null}
      open={true}
      onClose={() => setOpenModal(false)}
      title="Delete entity?"
      primaryAction={{
        content: "Delete",
        onAction: onModalAgree,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>After delete, you can not undo this action!</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  ) : null;

  return (
    <>
      {_Modal}
      {toastMarkup}
      {loading ? <SkeletonLoading /> : Actual_page}
    </>
  );
}
