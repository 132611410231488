import {
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Modal,
    SelectChangeEvent,
    Stack,
    Typography,
  } from "@mui/material";
  import { useCallback, useEffect, useState } from "react";
  import { createCategory, deleteCategory, getListPodcastCategory, updateCategory, uploadMedia } from "../service";
  import { HiOutlineDotsVertical } from "react-icons/hi";
  import { DataGrid } from "@mui/x-data-grid";
  import { CategoryItem } from "../interface";
  import CreateCategory from "../components/category.create";
  import CustomSnackbar from "components/snackbar";
import { EmptyState } from "@shopify/polaris";
import emptyIMG from "../../../media/empty.png";
  
  const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '500px',
      maxHeight: '600px',
      overflowY: 'auto',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      bgcolor: 'background.paper',
      borderRadius: '3px',
      p: 2,
    };
  
  function ManagePodcastCategory() {
    const [getDataPostcastCategory, setGetDataPostcastCategory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [currentRow, setCurrentRow] = useState<null | any>(null);
    const [mediaSrc, setMediaSrc] = useState<string>('');
    const [mediaType, setMediaType] = useState<string>('');
    const [openModal, setOpenModal] = useState(false);
    const [listAudio, setListAudio] = useState<any>([])
    const [formData, setFormData] = useState<CategoryItem>({
        category_language: "vi",
        category_content: "",
        category_title: "",
        category_status: "active",
        category_type: "",
        public_status: "1",
        category_avatar: "",
        category_parent: "",
        category_excerpt: "" 
    })
    const [isUploading, setIsUploading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  
    const [isEdit, setIsEdit] = useState(false);
  
    const open = Boolean(anchorEl);
  
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
      setAnchorEl(event.currentTarget);
      setCurrentRow(row);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleOpenModal = () => setOpenModal(true);
  
    const handleCloseModal = () => setOpenModal(false);
    
    const handleFileChangeAndUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploading(true)
      try {
        const files = event.target.files;
        if (files && files.length > 0) {
          const file = files[0];
          const headers = {
            'X-Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQ1Nzc3MDAsImRhdGEiOnsiX2lkIjoiNjU4MjVhYTQ5ZDY2YWM4YzQ3YzZiMDFkIiwia2V5IjoiOTNiOTgxN2VkZmQ1ZjU1NDBkZTI2MDNiM2M3N2JlZmEiLCJzaWduYXR1cmUiOiI2ZGI5M2RhMzE1YzRjNzBkNjY2YjNiNWRjZjIwYzUzMiIsInNlc3Npb24iOiI2NTgyNWFhNDlkNjZhYzhjNDdjNmIwMWYifSwiaWF0IjoxNzAzMDQxNzAwfQ.R0CrGfSMvi_V3T445vlp75Eetz_x6RHCrWtaSkXo0A8'
          };
          const { id, src } = await uploadMedia(file, headers);
  
          if (file.type.startsWith('image/')) {
            setFormData((prevFormData) => {
              const updatedFormData = {
                ...prevFormData,
                category_avatar: id
              };
              return updatedFormData;
            });
            setMediaType('image');
          }
          setMediaSrc(src);
        }
      } catch (error) {
        console.error('Upload failed:', error);
      } finally {
        console.log("Upload complete");
        setIsUploading(false);
      }
    };
    const handleSubmit = async() => {
      try {
          const cloneData: any = {...formData};
          cloneData.public_status = cloneData.public_status+"";
        //   cloneData.excerpt = cloneData.category;
          let sendRequest = null
          if(isEdit) {
              sendRequest = await updateCategory(cloneData);
              // update data in state
              fetchData();
          }else {
              sendRequest =  await createCategory(cloneData)
              fetchData();
          }
              setSnackbarMessage(`${isEdit ? 'Sửa': 'Thêm mới'} thành công`);
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
              setOpenModal(false)
      }
      catch(error) {
          console.log(error);
          setOpenModal(false)
      }
    }
  
    const editCategory = (key: any) => {
          setFormData({...key, category_avatar: key.category_avatar?._id});
          setMediaType('image');
          setMediaSrc(key.category_avatar?.media_url);
          setOpenModal(true);
          setIsEdit(true);
    }
    const handleChangeCourse = (name, value) => {
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleDeleteCategory = async (id: string) => {
      // if(confirm('Bạn muốn xoá Podcast này không')) {
          try {
              await deleteCategory(id);
              setGetDataPostcastCategory(prevPodcast => prevPodcast.filter(podcast => podcast._id !== id));
              setSnackbarMessage('Xóa thành công');
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
            } catch (error) {
              console.error('Failed to delete redeem', error);
              setSnackbarMessage('Xóa thất bại');
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            }
      // }
    };
    const columns = [
      { field: "category_title", headerName: "Tiêu đề", width: 200 },
      { field: "user_id", headerName: "Người tạo", width: 200, renderCell: (params) => {
          return params.row?.user_id?.display_name
      } },
      { field: "category_content", headerName: "Mô tả", width: 400 },
      {
        field: "actions",
        headerName: "Hành động",
        width: 150,
        renderCell: (params) => {
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButton
                aria-label="more"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(e) => handleMenuClick(e, params.row)}
              >
                <HiOutlineDotsVertical style={{ color: "#333" }} />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open && currentRow && currentRow._id === params.row._id}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => editCategory(params.row)}>Sửa</MenuItem>
                <MenuItem onClick={() => {
                   if(window.confirm('Bạn muốn xoá danh mục này không')) {
                  
                      handleDeleteCategory(params.row._id)
                  
                    }
                }}>Xóa</MenuItem>
              </Menu>
            </div>
          );
        },
      },
    ];
    const removeImageCover = useCallback(() => {

        setFormData((prevFormData) => {
            const updatedFormData = {
              ...prevFormData,
              category_avatar: ''
            };
            return updatedFormData;
          });
          setMediaType('');
        setMediaSrc('');
    },[])
    const fetchData = async () => {
        setLoading(true);
        try {
          const dataPodcastCategory = await getListPodcastCategory();
          const podcastListCate = dataPodcastCategory.data;
          setGetDataPostcastCategory(podcastListCate)
        } catch (error) {
          setLoading(false);
          console.error("Failed to fetch data", error);
        }
      };
    useEffect(() => {
      
  
      fetchData();
    }, []);
    useEffect(() => {
      // detect close modal clean data
      if(!openModal) {
          setFormData({
            category_language: "vi",
            category_content: "",
            category_title: "",
            category_status: "active",
            category_type: "",
            public_status: "1",
            category_avatar: "",
            category_parent: "",
            category_excerpt: ""  
            })
            setMediaSrc('');
            setMediaType('');
            setListAudio([]);
            setIsEdit(false);
      }
    }, [openModal])

    const emptyData = (
        <EmptyState heading="No category here!" image={emptyIMG}>
          <p>Oh! There is no category here! Try add a new record!</p>
        </EmptyState>
      );
    return (
      <>
        <Box
          sx={{
            width: "100%",
            maxWidth: "1000px",
            margin: "0 auto",
            padding: "30px 0px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5"> Danh mục Podcast</Typography>
            <Button
              variant="contained"
              color="success"
              sx={{ fontWeight: 550, height: "45px" }}
              onClick={handleOpenModal}
            >
              Tạo mới
            </Button>
          </Stack>
          <Box sx={{ padding: "10px 0px" }}>
           {
            getDataPostcastCategory.length > 0 ? (
                <DataGrid
                rows={getDataPostcastCategory}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                // checkboxSelection
                getRowId={(row) => row._id}
              />
            ): emptyData
           }
          </Box>
          <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={()=>setSnackbarOpen(false)}
        />
        </Box>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <CreateCategory
            mediaSrc={mediaSrc}
            mediaType={mediaType}
            handleChangeCourse={handleChangeCourse}
            handleSubmit={handleSubmit}
            formData={formData}
            style={style}
            handleFileChangeAndUpload={handleFileChangeAndUpload}
            isEdit={isEdit}
            isUploading={isUploading}
            removeImageCover={removeImageCover}
          onClose={() => setOpenModal(false)}
          />
        </Modal>
      </>
    );
  }
  
  export default ManagePodcastCategory;
  