import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'config/reducer.utils';


/**
*   Reducer used for front-end, with settings.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'system_information';

// Actions

export const getEntity = createAsyncThunk('system_information/fetch_entity_list', async () => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<any>(requestUrl);
});

export const cleanSession = createAsyncThunk('system_information/clear_session', async () => {
  const requestUrl = `${apiUrl}/clear_session?cacheBuster=${new Date().getTime()}`;
  return axios.get<any>(requestUrl);
});


// slice

export const Reducer_System = createEntitySlice({
  name: 'system_information',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(cleanSession.rejected, (state, action) => {
        state.loading = false;
        state.updating = false;
        state.errorMessage = action.error.message;
      })
      .addMatcher(isFulfilled(cleanSession), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(cleanSession), state => {
        state.updating = true;
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});


// Reducer
export default Reducer_System.reducer;
