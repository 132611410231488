import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import dateandtime from "date-and-time";
import {
  Card,
  Page,
  TextStyle,
  Toast,
  Layout,
  Icon,
  ResourceItem,
  ResourceList,
  Stack,
  Badge,
} from "@shopify/polaris";
import {
  clearError,
  getEntities,
  partialUpdateEntity,
} from "store/email.store.reducer";
// import { setMessage,  clearMessage } from "store/toast.store.reducer";
// import { asChoiceField, lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from "@shopify/react-form";
import {
  ArchiveMajor,
  EmailMajor,
  PlayCircleMajor,
  CircleDisableMinor,
  InviteMinor,
  LandingPageMajor,
  EnvelopeMajor,
} from "@shopify/polaris-icons";
import QuickSearch from "components/quick_search";
import Pagination from "components/pagination";
import helpers from "../../helpers";

export default function EmailQueue() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const entities = useAppSelector((state) => state.email.entities);
  const loading = useAppSelector((state) => state.email.loading);
  const totalItems = useAppSelector((state) => state.email.totalItems);

  const [selectedItems, setSelectedItems] = useState([]);
  const [onItem, setOnItem] = useState([]);
  const [toastit, setToastit] = useState(null);

  useEffect(() => {
    //if ( updateSuccess === true ) {
    //};
  }, []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery = helpers.ExtractUrl(useParam.search);

  const initialQuery = {
    query: "",
    page: 1,
    limit: 20,
    sort: "email_id,desc",
  };

  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...initialQuery,
  });

  const [selectedParentId, setSelectedParentId] = useState(
    StringQuery?.email_status || ""
  );
  const handleSelectedChange = useCallback(
    (_value) => {
      setSelectedParentId(_value);
      setMainQuery({ ...mainQuery, ...{ email_status: _value, page: 1 } });
    },
    [mainQuery]
  );

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePageNumber = useCallback(
    (numPage) => {
      setMainQuery({ ...mainQuery, page: numPage });
    },
    [mainQuery]
  );

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch)
      window.history.replaceState(
        null,
        "Email",
        "/email_queue" + buildURLSearch
      );
    // history.push( '/media' + buildURLSearch );
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  const handleFiltersQueryChange = useCallback(
    (_value) => {
      setMainQuery({ ...mainQuery, ...{ query: _value, page: 1 } });
    },
    [mainQuery]
  );

  const resourceName = {
    singular: "Email",
    plural: "Emails",
  };

  let items = [];
  useEffect(() => {
    if (entities)
      entities.map((d) => {
        let item = {
          id: d.email_id,
          url: `/email_queue/view/${d.email_id}`,
          subject: d.email_subject,
          message: `From: ${d.email_from}, To: ${d.email_to}`,
          status: d.email_status,
          createAt: d.createAt,
        };
        items.push(item);
      });
    setOnItem(items);
  }, [entities]);

  const promotedBulkActions = [
    {
      content: "Cancel queue",
      onAction: () => {
        const updaty = {
          email_status: "cancel",
          items: selectedItems,
        };
        dispatch(partialUpdateEntity(updaty));
      },
    },
  ];

  function renderItem(item: any) {
    const { id, url, subject, message, createAt, status } = item;
    return (
      <ResourceItem
        id={id}
        url={url}
        media={null}
        accessibilityLabel={`View details for ${subject}`}
      >
        <Stack alignment="center">
          <Stack.Item fill>
            <h3>
              <TextStyle variation="strong">
                {helpers.trimMiddleString(subject, 20, 10)}
              </TextStyle>{" "}
              <Badge>{status}</Badge>
            </h3>
            <div style={{ color: "#999" }}>{message}</div>
          </Stack.Item>
          <Stack.Item>
            {dateandtime.format(new Date(Number(createAt)), "DD/MM/YYYY HH:mm")}
          </Stack.Item>
        </Stack>
      </ResourceItem>
    );
  }

  const toastMarkup = toastit ? (
    <Toast
      content={toastit}
      onDismiss={() => {
        setToastit(null);
        dispatch(clearError());
      }}
    />
  ) : null;

  const MainMenu = [
    {
      label: "All Email",
      active: "",
      icon: EnvelopeMajor,
      click: () => handleSelectedChange(""),
    },
    {
      label: "Draft",
      active: "draft",
      icon: InviteMinor,
      click: () => handleSelectedChange("draft"),
    },
    {
      label: "Sent",
      active: "sent",
      icon: EmailMajor,
      click: () => handleSelectedChange("sent"),
    },
    {
      label: "Cancel",
      active: "cancel",
      icon: ArchiveMajor,
      click: () => handleSelectedChange("cancel"),
    },
    {
      label: "Fail",
      active: "fail",
      icon: CircleDisableMinor,
      click: () => handleSelectedChange("fail"),
    },
    {
      label: "Pending",
      active: "pending",
      icon: LandingPageMajor,
      click: () => handleSelectedChange("pending"),
    },
    {
      label: "In Queue",
      active: "queue",
      icon: PlayCircleMajor,
      click: () => handleSelectedChange("queue"),
    },
  ];

  return (
    <Page
      title="Email Queue"
      subtitle="All email outgoing will in queue here"
      primaryAction={{
        content: "Compose new Email",
        disabled: false,
        loading: false,
        onClick: () => {
          navigate("/email_queue/create_new");
        },
      }}
    >
      {toastMarkup}
      <Layout>
        <Layout.Section secondary>
          <Card>
            <div className="email_menu">
              {MainMenu?.map((el, index) => {
                let classactive =
                  selectedParentId === el.active ? "active" : "";
                return (
                  <div
                    className={`email_menu_item ${classactive}`}
                    key={`email_element_${index}`}
                  >
                    <a onClick={el.click}>
                      <Stack>
                        <Icon source={el.icon} color="base" />{" "}
                        <span>{el.label}</span>
                      </Stack>
                    </a>
                  </div>
                );
              })}
            </div>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <div style={{ padding: "16px" }}>
              <Stack alignment="center">
                <Stack.Item fill>
                  <QuickSearch
                    queryValue={StringQuery?.query}
                    onChange={handleFiltersQueryChange}
                  />
                </Stack.Item>
              </Stack>
            </div>
            <ResourceList
              resourceName={resourceName}
              items={onItem}
              loading={loading}
              renderItem={renderItem}
              selectedItems={selectedItems}
              onSelectionChange={(v) => {
                // @ts-ignore
                setSelectedItems(v);
              }}
              promotedBulkActions={promotedBulkActions}
              bulkActions={null}
            />
          </Card>
          <br />
          {totalItems > 0 ? (
            <Pagination
              TotalRecord={totalItems}
              onChangePage={onChangePageNumber}
              pageSize={Number(mainQuery.limit)}
              activeCurrentPage={Number(mainQuery.page)}
            />
          ) : null}
        </Layout.Section>
      </Layout>
    </Page>
  );
}
