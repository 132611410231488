import { Page } from "@shopify/polaris";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { ExportMinor } from "@shopify/polaris-icons";
import TypeUpload from "./type.upload";
import AppList from "./type.list";

/**
 *   Create index file for Type
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let useParam = {} as any;
  useParam = useLocation();

  const [uploadModelactive, setUploadModelactive] = useState(false);
  const toggleUpdateActive = useCallback(() => setUploadModelactive((active) => !active), []);

  return (
    <>
      <Page
        primaryAction={{
          content: `Create type`,
          onAction: toggleUpdateActive,
          icon: ExportMinor,
        }}
        title="Type"
      ></Page>
      <TypeUpload showModal={uploadModelactive} closeModal={toggleUpdateActive} />
      {<AppList />}
    </>
  );
};

