import Textarea from '@mui/joy/Textarea'
import { Box, Button, CircularProgress, FormControl, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React from 'react'
import { FaUpload } from 'react-icons/fa'

const CreateCourse = ({ handleCloseModal, handleImageClickOneOne, mediaSrcVideo, mediaSrcImage, handleFileChangeAndUploadCallVideo, showUploadButtonImage, showUploadButtonVideo, loadingCirImage, loadingCirVideo, handleFileChangeAndUploadCallOneOne, handleImageClick, showUploadButton, loadingCir, mediaType, mediaSrc, handleChange, handleSubmit, style, handleFileChangeAndUpload, type, handleChangeCourse, formData }) => {
  return (
    <Box sx={style}>
        <Typography variant='h6' mb={2}>Tạo khóa học</Typography>
        <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
            <Box sx={{ width: '450px' }}>
                <Stack direction='column' gap={1} >
                    <Typography variant='body1' fontWeight={550}>Tiêu đề:</Typography>
                    <TextField value={formData.title} onChange={(e) => handleChangeCourse("title", e.target.value)} name="title" fullWidth placeholder='Điền tiêu đề' size="small" sx={{ mb: 1 }}/>
                </Stack>
                {formData.type === 'Call 1-1' && (
                <Stack direction='row' gap={1} mb={2}>
                    <Stack direction='column' gap={1} >
                        <Typography variant='body1' fontWeight={550}>Số ngày học:</Typography>
                        <TextField value={formData.lession_count} onChange={(e) => handleChangeCourse("lession_count", e.target.value)} name="lession_count" fullWidth placeholder='Điền số ngày' size="small" sx={{ mb: 1 }}/>
                    </Stack>
                    <Stack direction='column' gap={1} >
                        <Typography variant='body1' fontWeight={550}>Số giờ học:</Typography>
                        <TextField value={formData.class_duration} onChange={(e) => handleChangeCourse("class_duration", e.target.value)} name="class_duration" fullWidth placeholder='Điền số giờ: 0.5 - 1 - 2' size="small" sx={{ mb: 1 }}/>
                    </Stack>
                </Stack>
                )}
                <Stack direction='column' gap={1} mb={2}>
                    <Typography variant='body1' fontWeight={550}>Thời gian học:</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack direction="row" alignItems="center" gap={2} justifyContent="space-between">
                            <DatePicker 
                                className='datePicker' 
                                onChange={(date) => handleChangeCourse("start_time", date)}
                                name="start_time"
                                slotProps={{ textField: { placeholder: 'Chọn ngày bắt đầu' } }}
                                sx={{ width: '100%' }}/>
                            <DatePicker 
                                className='datePicker' 
                                onChange={(date) => handleChangeCourse("end_time", date)}
                                name="end_time"
                                slotProps={{ textField: { placeholder: 'Chọn ngày kết thúc' } }}
                                sx={{ width: '100%' }}/>
                        </Stack>
                    </LocalizationProvider>
                </Stack>

                <Stack direction='column' gap={1} >
                    <Typography variant='body1' fontWeight={550}>Mô tả:</Typography>
                    <Textarea color="neutral" value={formData.description} onChange={(e) => handleChangeCourse("description", e.target.value)} name="description" minRows={3} placeholder='Mô tả' sx={{ mb: 2 }}/>
                </Stack>

                <Stack direction='column' gap={1} >
                    <Typography variant='body1' fontWeight={550}>Chi tiết khóa học:</Typography>
                    <Textarea color="neutral" value={formData.long_description} onChange={(e) => handleChangeCourse("long_description", e.target.value)} name="long_description" minRows={5} placeholder='Chi tiết khóa học' sx={{ mb: 2 }}/>
                </Stack>

                {/* <Stack direction='column' gap={1} mb={2}>
                    <Typography variant='body1' fontWeight={550}>Thời gian học:</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack direction="row" alignItems="center" gap={2} justifyContent="space-between">
                            <DatePicker 
                                className='datePicker' 
                                onChange={(date) => handleChangeCourse("start_time", date)}
                                name="start_time"
                                slotProps={{ textField: { placeholder: 'Chọn ngày bắt đầu' } }}
                                sx={{ width: '100%' }}/>
                            <DatePicker 
                                className='datePicker' 
                                onChange={(date) => handleChangeCourse("end_time", date)}
                                name="end_time"
                                slotProps={{ textField: { placeholder: 'Chọn ngày kết thúc' } }}
                                sx={{ width: '100%' }}/>
                        </Stack>
                    </LocalizationProvider>
                </Stack> */}

                <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                    <Box flex={1}>
                        <Typography variant='body1' fontWeight={550} mb={1}>Kiểu khóa học:</Typography>
                        <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData.type} 
                            onChange={(e) => handleChangeCourse("type", e.target.value)}
                            name="type"
                            fullWidth
                            size="small"
                        >
                            <MenuItem value="Self-learning">Self-learning</MenuItem>
                            <MenuItem value="Call 1-1">Call 1 - 1</MenuItem>
                            <MenuItem value="Call group">Call group</MenuItem>
                        </Select>
                        </FormControl>
                    </Box>

                    {formData.type === 'Call 1-1' ? (
                        <Box flex={1}>
                            <Typography variant='body1' fontWeight={550} mb={1}>Giá khóa học:</Typography>
                            <TextField
                                fullWidth
                                value={formData.price}
                                onChange={(e) => handleChangeCourse("price", e.target.value)}
                                name="price"
                                placeholder='Giá khóa học'
                                size="small"
                            />
                        </Box>
                    ) : (
                    <Box flex={1}>
                        <Typography variant='body1' fontWeight={550} mb={1}>Giá khóa học:</Typography>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.price} 
                                onChange={(e) => handleChangeCourse("price", e.target.value)}
                                name="price"
                                fullWidth
                                size="small"
                            >
                            <MenuItem value="599000">599000</MenuItem>
                            <MenuItem value="699000">699000</MenuItem>
                            <MenuItem value="799000">799000</MenuItem>
                            <MenuItem value="999000">999000</MenuItem>
                            <MenuItem value="1999000">1999000</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                )}
                </Box>
            </Box>
          
                <Stack direction='column' gap={2}>
                    <Box sx={{ width: '250px' }}>
                    <Typography variant='body1' fontWeight={550} mb={1}>Hình ảnh giới thiệu khóa học:</Typography>
                    {loadingCirImage ? (
                        <CircularProgress /> 
                        ) : (
                        <>
                            {mediaSrcImage && 
                                <Box
                                sx={{
                                position: 'relative',
                                display: 'inline-block',
                                width: '250px',
                                '&:hover .hoverText': {
                                    opacity: 1,
                                },
                                }}
                                onClick={handleImageClickOneOne}
                            >
                                <Typography
                                    variant="body2"
                                    className="hoverText"
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: 1,
                                        opacity: 0,
                                        transition: 'opacity 0.3s',
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '20px',
                                        color: '#fff',
                                        fontWeight: 'bold',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        cursor: 'pointer'
                                    }}
                                >
                                Thay đổi hình ảnh
                                </Typography>
                                    <img
                                    src={mediaSrcImage}
                                    alt="Media preview"
                                    width={250}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Box>
                            }
                        </>
                        )}
                    {showUploadButtonImage && (
                        <Button
                        component="label"
                        variant="contained"
                        startIcon={<FaUpload />}
                        sx={{
                            width: '250px',
                            height: '180px',
                            background: 'transparent',
                            color: '#333',
                            fontWeight: 550,
                            border: '1px dashed',
                            marginBottom: 2,
                            '&:hover': {
                            backgroundColor: 'rgba(0,0,0,0.1)',
                            },
                        }}
                        >
                        Tải ảnh/video
                        <input
                            type="file"
                            accept="image/*,video/mp4"
                            style={{ display: 'none' }}
                            onChange={handleFileChangeAndUploadCallOneOne}
                        />
                        </Button>
                    )}
                    </Box>
                    <Box sx={{ width: '250px' }}>
                    <Typography variant='body1' fontWeight={550} mb={1}>Video giới thiệu khóa học:</Typography>
                    {loadingCirVideo ? (
                        <CircularProgress /> 
                        ) : (
                        <>
                            {mediaSrcVideo && <video src={mediaSrcVideo} controls width={250}></video>}
                        </>
                        )}
                    {showUploadButtonVideo && (
                        <Button
                        component="label"
                        variant="contained"
                        startIcon={<FaUpload />}
                        sx={{
                            width: '250px',
                            height: '180px',
                            background: 'transparent',
                            color: '#333',
                            fontWeight: 550,
                            border: '1px dashed',
                            marginBottom: 2,
                            '&:hover': {
                            backgroundColor: 'rgba(0,0,0,0.1)',
                            },
                        }}
                        >
                        Tải ảnh/video
                        <input
                            type="file"
                            accept="image/*,video/mp4"
                            style={{ display: 'none' }}
                            onChange={handleFileChangeAndUploadCallVideo}
                        />
                        </Button>
                    )}
                    </Box>
                </Stack>
        </Stack>
        <Stack direction="row" gap={2} justifyContent="space-between" mt={2}>
            <Button fullWidth size='small' variant='outlined' onClick={handleCloseModal}>Thoát</Button>
            <Button fullWidth size='small' onClick={handleSubmit} variant='contained'>Tạo</Button>
        </Stack>
    </Box>
  )
}

export default CreateCourse