import {
  AppliedFilterInterface,
  Avatar,
  Badge,
  Card,
  ChoiceList,
  DataTable,
  EmptyState,
  FilterInterface,
  Filters,
  Layout,
  Link,
  Modal,
  Page,
  ResourceItem,
  ResourceList,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import debounce from "lodash.debounce";
import { TickSmallMinor, LockMinor } from "@shopify/polaris-icons";
import emptyIMG from "../../media/empty.png";
import Pagination from "../../components/pagination";
import {
  clearError,
  getEntities,
  reset as resetMainData,
} from "../../store/prompt.store.reducer";
import PromptFilter from "./filter";
import helpers from "../../helpers";
import dateandtime from "date-and-time";
import { getEntities as getCategoires } from "../../store/category.store.reducer";

export default function General_prompt_List() {
  const [initial_loading, setInitial_loading] = useState<boolean>(true);

  const entity = useAppSelector((state) => state.prompt.entity);
  const entities = useAppSelector((state) => state.prompt.entities);
  const loading = useAppSelector((state) => state.prompt.loading);
  const errorMessage = useAppSelector((state) => state.prompt.errorMessage);
  const totalItems = useAppSelector((state) => state.prompt.totalItems);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  const entitycategories = useAppSelector((state) => state.category.entities);

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  const [mainQuery, setMainQuery] = useState({
    ...{
      page: 1,
      limit: 20,
      order_by: "DESC",
    },
    ...StringQuery,
  });
  // const [queryValue, setQueryValue] = useState("");

  const [input, setInput] = useState("");
  const handleFiltersQueryChange = useCallback((value) => setInput(value), []);

  // useEffect(() => {
  //   setMainQuery({ ...mainQuery, ...{ search: input } });
  // }, [input]);

  // const [selectedParentId, setSelectedParentId] = useState("");
  // const handleSelectedChange = useCallback((_value) => {
  //   setSelectedParentId(_value);
  // }, []);

  // useEffect(() => {
  //   setMainQuery({
  //     ...mainQuery,
  //     ...{ webhook_method: `${selectedParentId}`, page: 1 },
  //   });
  // }, [selectedParentId]);

  /**
   * Change page number
   */

  const [numberPage, setNumberPage] = useState(1);

  const onChangePageNumber = useCallback((numPage) => {
    setNumberPage(numPage);
  }, []);

  /**
   * Category list
   */

  const [mainQueryCategory, setMainQueryCategory] = useState({
    category_type: localStorage.getItem("CATEGORY_TYPE"),
  });

  useEffect(() => {
    dispatch(getCategoires(mainQueryCategory));
    dispatch(resetMainData());
  }, []);

  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    const optionType = [
      {
        label: "Category",
        value: "",
      },
    ];
    for (let { _id, category_title } of entitycategories) {
      optionType.push({
        label: String(category_title),
        value: String(_id),
      });
    }
    setCategoryList(optionType);
  }, [entitycategories]);

  /**
   * Select category
   */

  const [post_category_selected, setPost_category_selected] = useState("");
  const handNewChangeCategory = useCallback((value: string) => {
    setPost_category_selected(value);
    localStorage.setItem("current_category", value);
  }, []);

  useEffect(() => {
    setMainQuery({
      ...mainQuery,
      ...{ post_category: `${post_category_selected}`, page: 1 },
    });
  }, [post_category_selected]);

  useEffect(() => {
    setMainQuery({ ...mainQuery, page: numberPage });
  }, [numberPage]);

  const reduceQuery = useCallback((mainQuery) => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch) history("/prompt" + buildURLSearch);
    dispatch(getEntities(mainQuery));
    return mainQuery;
  }, []);

  const delayQuery = useMemo(() => {
    return debounce((mainQuery) => {
      reduceQuery?.call(this, mainQuery);
    }, 500);
  }, []);

  useEffect(() => {
    delayQuery(mainQuery);
  }, [mainQuery]);

  useEffect(() => {
    /**
     * Load from local to form
     */
    let category_selected = localStorage.getItem("current_category");
    if (category_selected) {
      setPost_category_selected(category_selected);
      // post_category
    }
  }, []);

  // const onChangeCallback = useMemo(
  //   () =>
  //     debounce((_value) => {
  //       if (_value !== "")
  //         setMainQuery({ ...mainQuery, query: _value ? _value : "" });
  //     }, 500),
  //   []
  // );

  // useEffect(() => {
  //   onChangeCallback(queryValue);
  // }, [queryValue]);

  useEffect(() => {
    if (loading === false) setInitial_loading(false);
  }, [loading]);

  const emptyData = (
    <EmptyState heading="No post here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>
  );

  const renderItem = (prompt: any) => {
    // let userContent = prompt.interest;

    const {
      _id,
      user_id,
      post_language,
      post_category,
      text_to_view,
      text_to_ai,
      text_to_image,
      placeholder,
      post_type,
      post_parent,
      post_status,
      post_avatar,
      createdAt,
      updatedAt,
    } = prompt;
    return [
      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {user_id?.display_name}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {post_category?.category_title}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {post_language}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {text_to_view}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {/* {text_to_ai} */}
        <p>{helpers.trimContentString(text_to_ai)}</p>
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {post_type}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        <time>
          {createdAt
            ? dateandtime.format(new Date(createdAt), "DD-MM-YYYY HH:mm:ss")
            : "-"}
        </time>
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {_id}
      </Link>,
    ];
  };
  const PagesList = (
    // totalItems > 0 ? (
    <>
      <DataTable
        columnContentTypes={[
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
        ]}
        headings={[
          "User",
          "Category",
          "Language",
          "Text to view",
          "Text to AI",
          "Type",
          "Create At",
          "ID",
        ]}
        rows={entities?.map(renderItem)}
        hideScrollIndicator
        footerContent={`Display page ${mainQuery.page} of total ${totalItems} results...`}
      />
      <style>{`
        .small-icon {
          font-size: 12px;
          padding: 0;
          width: 15px;
          height: auto;
        }
      `}</style>
    </>
  );
  // ) : (
  //   emptyData
  // );

  const Actual_page = (
    <Page
      title="Prompt"
      fullWidth
      primaryAction={{
        content: "Create new",
        disabled: false,
        onAction: () => {
          history("new");
        },
      }}
      secondaryActions={[
        {
          content: "Import",
          onAction: () => {
            history("import");
          },
        },
      ]}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <div style={{ padding: "16px", display: "flex" }}>
              <Stack distribution="equalSpacing">
                <PromptFilter
                  queryValue={StringQuery?.query}
                  onChange={handleFiltersQueryChange}
                />
                {/* <Select
                  label=""
                  value={selectedParentId}
                  onChange={handleSelectedChange}
                  options={[
                    { label: "All", value: "" },
                    { label: "Get", value: "get" },
                    { label: "Post", value: "post" },
                    { label: "Patch", value: "patch" },
                    { label: "Delete", value: "delete" },
                  ]}
                /> */}

                <Select
                  label="User change category for Prompt"
                  labelHidden
                  options={categoryList}
                  onChange={handNewChangeCategory}
                  value={post_category_selected}
                />
              </Stack>
            </div>
            {PagesList}
          </Card>
          <br />
          {/* {totalItems > mainQuery.limit ? ( */}
          <Pagination
            TotalRecord={totalItems}
            // TotalRecord={100}
            activeCurrentPage={mainQuery.page}
            pageSize={mainQuery.limit}
            onChangePage={onChangePageNumber}
          />
          {/* ) : null} */}
        </Layout.Section>
      </Layout>
    </Page>
  );

  const skeleton_loading = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const toastMarkup = errorMessage ? (
    <>
      {errorMessage?.length > 0 ? (
        <Toast content={errorMessage} error onDismiss={toggleActive} />
      ) : null}
    </>
  ) : null;

  return (
    <>
      {toastMarkup}
      {/* {loading ? skeleton_loading : Actual_page} */}
      {initial_loading ? skeleton_loading : Actual_page}
    </>
  );
}
