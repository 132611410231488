import { useCallback, useEffect, useState } from "react";
import { Form, useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import {
  Button,
  Card,
  ChoiceList,
  FormLayout,
  Icon,
  Label,
  Modal,
  Page,
  PageActions,
  RadioButton,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import {
  clearError,
  clearMessage,
  getEntities,
  getEntity,
  reset,
  createEntity,
  updateEntity,
} from "store/auto_message.store.reducer";
import { MobilePlusMajor } from "@shopify/polaris-icons";

import {
  lengthLessThan,
  notEmpty,
  useField,
  useForm,
} from "@shopify/react-form";
import helpers from "../../helpers";

export default function General() {
  const entity = useAppSelector((state) => state.auto_message.entity);
  const entities = useAppSelector((state) => state.auto_message.entities);
  const loading = useAppSelector((state) => state.auto_message.loading);
  const updating = useAppSelector((state) => state.auto_message.updating);
  const message = useAppSelector((state) => state.auto_message.message);
  const updateSuccess = useAppSelector(
    (state) => state.auto_message.updateSuccess
  );

  const dispatch = useAppDispatch();

  /**
   * Notification
   */

  const toggleToastActive = useCallback(() => {
    dispatch(clearError());
    dispatch(clearMessage());
  }, []);

  /**
   * Initial
   */

  useEffect(() => {
    dispatch(getEntity("VN_message"));
  }, []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  //useParam = useLocation();

  /**
   * Modal
   */

  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const ModalAddContent = () => {
    setShowEditModal(true);
  };

  const closeModal = useCallback(() => {
    setShowEditModal((active) => !active);
  }, [showEditModal]);

  const saveForm = () => {
    let newContent = [];
    if (datafilter) {
      newContent = [...datafilter, ...[valueContent]];
    } else {
      newContent = [valueContent];
    }
    setDataFilter(newContent);
    setValueContent("");
    setShowEditModal(false);
  };

  const [valueContent, setValueContent] = useState("");
  const handleChangeValue = useCallback(
    (newValue) => setValueContent(newValue),
    []
  );

  const contentModal = (
    <Modal
      open={showEditModal}
      onClose={closeModal}
      title="Add Text"
      titleHidden
      primaryAction={{
        content: "Save",
        onAction: saveForm,
      }}
      secondaryActions={[
        {
          content: "Close",
          onAction: closeModal,
        },
      ]}
    >
      <Modal.Section>
        {" "}
        <FormLayout>
          <FormLayout.Group condensed>
            <TextField
              label="Text"
              value={valueContent}
              onChange={handleChangeValue}
              autoComplete="off"
            />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  /**
   * Type
   */

  const [type, setType] = useState("VN_message");
  const handleChange = useCallback((newValue) => setType(newValue), []);

  const [datafilter, setDataFilter] = useState<any>(null);

  useEffect(() => {
    setDataFilter(entity?.config?.data_filter);
  }, [entity]);

  const handleClick = () => {
    dispatch(getEntity(type));
  };

  const useFields = {
    type: useField<string>({
      value: entities?.config?.type ?? "",
      validates: [],
    }),
  };
  useParam = useParams();

  const { submit, fields } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!entity?.config?._id) {
          dispatch(
            createEntity({
              type: type,
              data_filter: JSON.stringify(datafilter),
            })
          );
        } else {
          dispatch(
            updateEntity({
              _id: entity?.config?._id,
              type: type,
              data_filter: JSON.stringify(datafilter),
            })
          );
        }

        reset();
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message =
          e?.response?.data?.title ??
          "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  useEffect(() => {
    if (updateSuccess) {
      dispatch(getEntity(type));
    }
  }, [updateSuccess]);

  const toastMarkup = message ? (
    <Toast content={message} onDismiss={toggleToastActive} duration={4500} />
  ) : null;

  function handChangeValueChange(e: any, _id: any) {
    let newContent = [];

    datafilter.map((element, index) => {
      if (index === _id) {
        element = e;
      }
      newContent.push(element);
    });
    setDataFilter(newContent);
  }

  return (
    <>
      {toastMarkup}
      <Page
        narrowWidth
        title="Auto Message"
        primaryAction={{
          content: "Save",
          onAction: submit,
          disabled: false,
          loading: updating,
        }}
      >
        <Card>
          <Card.Section title="Type">
            <TextField
              label="Type"
              helpText="Country_type (Country : VN, US, CN... ; type : message, morning, noon, afternoon, evening, online)"
              value={type}
              onChange={handleChange}
              autoComplete="off"
              connectedRight={<Button onClick={handleClick}>Search</Button>}
            />
          </Card.Section>

          <Card.Section title="Data filter">
            {datafilter &&
              datafilter.map((ele, idx) => {
                return (
                  <>
                    <TextField
                      label="Text"
                      autoComplete="off"
                      value={ele}
                      onChange={(e) => handChangeValueChange(e, idx)}
                    />
                  </>
                );
              })}
            <br />
            {contentModal}
            <Button primary onClick={ModalAddContent} disabled={!entity}>
              Add
            </Button>
          </Card.Section>
        </Card>

        <PageActions
          primaryAction={{
            content: "Save",
            onAction: submit,
            disabled: false,
            loading: updating,
          }}
        />
      </Page>
    </>
  );
}
