import {
  FolderMajor,
  CustomersMajor,
  OrdersMajor,
  NotificationMajor,
  PackageMajor,
  FormsMajor,
  CategoriesMajor,
  HashtagMajor,
  ConfettiMajor,
  ViewMajor,
  LegalMajor,
  StoreMajor,
  PaymentsMajor,
  FraudProtectPendingMajor,
  DomainNewMajor,
  QuestionMarkMajor,
  SmileyHappyMajor,
  SettingsMinor,
  ChatMajor,
  ClipboardMinor,
  NoteMajor,
  ChecklistMajor,
  EmailMajor,
  ToolsMajor,
  EmailNewsletterMajor,
  MarketingMajor,
} from "@shopify/polaris-icons";

import { Navigation, Page, Link } from "@shopify/polaris";
import { useAppSelector } from "../config/store";
import { useLocation } from "react-router-dom";
import { ItemProps } from "@shopify/polaris/build/ts/latest/src/components/Navigation/components";

/**
 * Public, no login
 */
const navigationItems: ItemProps[] = [
  // {
  //   url: "/",
  //   label: "Trang chủ",
  //   icon: HomeMajor,
  //   subNavigationItems: [
  //     {
  //       url: "/",
  //       exactMatch: true,
  //       label: "Mới nhất",
  //     },
  //     {
  //       url: "/dashboard/customer",
  //       label: "Hot nhất",
  //     },
  //     {
  //       url: "/dashboard/order",
  //       label: "Dành cho bạn",
  //     },
  //   ],
  // },
  // {
  //   url: "/pages",
  //   label: "Pages",
  //   icon: BlogMajor,
  // },

  {
    url: "/media",
    label: "Media",
    icon: FolderMajor,
  },

  // {
  //   url: "/chat",
  //   label: "Chat",
  //   icon: ChatMajor,
  // },

  {
    url: "/notification",
    label: "Notification",
    icon: NotificationMajor,
  },

  // {
  //   url: "/banner",
  //   label: "Banner",
  //   icon: SlideshowMajor,
  // },

  {
    url: "/report",
    label: "Contact Form",
    icon: FormsMajor,
  },
];

const orderMenuItems = [
  {
    url: "/order",
    label: "Order",
    icon: PaymentsMajor,
  },
  {
    url: "/transaction",
    label: "Transaction",
    icon: LegalMajor,
  },
];

// const eventMenuItems = [
//   {
//     url: "/event",
//     label: "Event",
//     icon: ConfettiMajor,
//   },

//   {
//     url: "/rating",
//     label: "Rating",
//     icon: ViewMajor,
//   },
//   {
//     url: "/city",
//     label: "City",
//     icon: StoreMajor,
//   },
//   {
//     url: "/type",
//     label: "Type",
//     icon: HashtagMajor,
//   },
//   {
//     url: "/category",
//     label: "Category",
//     icon: CategoriesMajor,
//   },
// ];

const otherMenuItems = [
  {
    url: "/prompt",
    label: "Prompt",
    icon: NoteMajor,
  },

  {
    url: "/user_question",
    label: "User Question",
    icon: QuestionMarkMajor,
  },

  {
    url: "/user_interest",
    label: "User Interest",
    icon: SmileyHappyMajor,
  },

  {
    url: "/chat_system",
    label: "Chat System",
    icon: FraudProtectPendingMajor,
  },
];

/**
 * For admin
 */
const adminMenuItems = [
  // {
  //   url: "/email_setup",
  //   label: "Email setup",
  //   icon: EmailMajor,
  //   subNavigationItems: [
  //     {
  //       url: "/email_setup",
  //       icon: HorizontalDotsMinor,
  //       exactMatch: true,
  //       label: "Email setup",
  //     },
  //     {
  //       url: "/email_template",
  //       icon: HorizontalDotsMinor,
  //       label: "Email template",
  //     },
  //     {
  //       url: "/email_queue",
  //       icon: HorizontalDotsMinor,
  //       label: "Email queue",
  //     },
  //   ],
  // },

  {
    url: "/users",
    label: "Users",
    icon: CustomersMajor,
  },

  {
    url: "/category",
    label: "Category",
    icon: CategoriesMajor,
  },

  // {
  //   url: "/user_question",
  //   label: "User Question",
  //   icon: QuestionMarkMajor,
  // },

  // {
  //   url: "/user_interest",
  //   label: "User Interest",
  //   icon: SmileyHappyMajor,
  // },

  {
    url: "/podcast",
    label: "Podcast",
    icon: FolderMajor,
  },
  {
    url: "/mission",
    label: "Mission",
    icon: ChecklistMajor,
  },
  {
    url: "/course",
    label: "Course",
    icon: ClipboardMinor,
  },

  // {
  //   url: "/chat_system",
  //   label: "Chat System",
  //   icon: FraudProtectPendingMajor,
  // },

 

  // {
  //   url: "/auto_message/",
  //   label: "Auto Messages",
  //   icon: ChatMajor,
  // },

  // {
  //   url: "https://email-manager.iceo.tech/",
  //   label: "Email Template",
  //   icon: EmailMajor,
  // },

 

  // {
  //   url: "https://eco-system.iceo.tech?auth=" + localStorage.getItem("session"),
  //   label: "Eco System",
  //   icon: ToolsMajor,
  // },

];

/**
 * START ...
 * @returns
 */
function NavMakeUp() {
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  // const isAdmin = account.user_role === "admin";
  const location = useLocation();
  const version = process.env.REACT_APP_VERSION;
  /**
   *
   */
  /*
    useEffect( () => {
      if (isAuthenticated)
        navigations = navigationItems.concat(navigationItemsForLoginUser);
    }, [isAuthenticated]);
  */
    const account = useAppSelector((state) => state.user.account);
    const isAdmin = account.user_role === "admin";
    const isTeacher = account.user_role === "teacher";
    const isUser = account.user_role === "user";
  return (
    <Navigation location={location.pathname}>
      <Navigation.Section title="Quản trị Giao dịch" items={orderMenuItems} />

      {/* <Navigation.Section items={navigationItems} /> */}

      {/* <Navigation.Section title="Quản trị Events" separator items={eventMenuItems} /> */}

      {/* {isAdmin ? */}
      {(isAdmin || isTeacher) && (
        <Navigation.Section
          title="Quản trị chung"
          separator
          items={adminMenuItems}
        />
      )}


      {/* : null} */}

      <Navigation.Section fill items={[]} />

      <Page fullWidth>
        <div style={{ fontSize: "11px" }}>
          &copy; 2024 IKIGAICOACH, ver: {version} <br />
        </div>
      </Page>
    </Navigation>
  );
}
export default NavMakeUp;
