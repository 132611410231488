import {
  DatePicker,
  FormLayout,
  Modal,
  Stack,
  TextField,
  TextStyle,
  Form,
  Card,
  Toast,
  Loading,
  Select,
  DisplayText,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "config/store";
import { clearError, createEntity, getEntity, updateEntity } from "store/notification.store.reducer";
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from "@shopify/react-form";
import { useParams } from "react-router-dom";
import QuickUploadImage from "components/oneclick-upload";

/**
 *   Create upload Modal for Notification
 */

export default function NotificationUpload({ showModal, closeModal }) {
  const entity = useAppSelector((state) => state.notification.entity);
  const updating = useAppSelector((state) => state.notification.updating);
  const loading = useAppSelector((state) => state.notification.loading);
  const errorMessage = useAppSelector((state) => state.notification.errorMessage);

  const [uploading, setUploading] = useState(false);

  const dispatch = useAppDispatch();

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, [dispatch]);

  /* Date picker */
  function pad(num: number, size = 2) {
    let numbr = num.toString();
    while (numbr.length < size) numbr = "0" + numbr;
    return numbr;
  }

  /**
   * Format date and time
   */
  function extractTimeAndDateFromSource(source: string) {
    let DateObject = new Date();
    try {
      if (source) {
        DateObject = new Date(String(source));
      }
      const _date =
        pad(DateObject.getDate(), 2) + "/" + pad(DateObject.getMonth() + 1, 2) + "/" + DateObject.getFullYear();
      const _timestamp = DateObject.getTime();
      return {
        date: _date,
        timestamp: _timestamp,
        day: pad(DateObject.getDate(), 2),
        month: DateObject.getMonth(),
        year: DateObject.getFullYear(),
      };
    } catch (_) {
      return {
        date: "",
        timestamp: 0,
        day: 0,
        month: 0,
        year: 0,
      };
    }
  }

  const today_active = extractTimeAndDateFromSource("");
  const [{ month, year }, setDate] = useState({
    month: today_active.month,
    year: today_active.year,
  });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  const handleMonthChange = useCallback((_month, _year) => setDate({ month: _month, year: _year }), []);

  /* Hours */
  const [notification_hour, setNotification_hour] = useState("00");
  const handleHourChange = useCallback((value) => setNotification_hour(value), []);
  const hours: any = [];
  for (let i = 0; i < 24; i++) {
    hours.push({ label: pad(i), value: pad(i) });
  }

  /* Minutes */
  const [notification_minute, setNotification_minute] = useState("00");
  const handleMinuteChange = useCallback((value) => setNotification_minute(value), []);
  const minutes = [
    { label: "00", value: "00" },
    { label: "15", value: "15" },
    { label: "30", value: "30" },
    { label: "45", value: "45" },
  ];

  /**
   * Hide notification user
   */

  const [hideNotificationUser, setHideNotificationUser] = useState<boolean>(false);

  /**
   * Option mode
   */

  const [manual, setManual] = useState("0");

  const mode = [
    { label: "", value: "0" },
    { label: "Right now", value: "2" },
    { label: "Set time", value: "1" },
  ];

  const handleSelectChange = useCallback((value) => setManual(value), []);

  /**
   * Upload image
   */
  const [srcImage, setSrcImage] = useState(
    "https://lgbtapp.s3.ap-southeast-1.amazonaws.com/2022/10/13/6331de50394dbf50eedb4242-IMG_0003.PNG"
  );

  const [internalErrorNotice, setInternalErrorNotice] = useState("");

  function handQuickUpdateSuccess(res: any) {
    setSrcImage(res[0].thumbnail);
  }

  function handUploadError(err: any) {
    setInternalErrorNotice("Upload Fail! Image is too large!");
  }

  useEffect(() => {
    // if (notiChannel !== "") {
    //   setHideNotificationUser(true);
    // } else {
    //   setHideNotificationUser(false);
    // }
  }, []);

  //Set notiChannel = notification_channel
  useEffect(() => {
    if (entity) {
    }
  }, [entity]);

  const [uploadModelactive, setUploadModelactive] = useState(false);

  const toggleUpdateActive = useCallback(() => {
    setUploadModelactive((active) => !active);
  }, []);

  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.users_slug || false;
  useEffect(() => {
    if (Param) {
      dispatch(getEntity(Param));
    } else {
      formReset();
    }
  }, []);

  /**
   * Must be improve
   * Jamviet.com
   * closeModal
   */
  useEffect(() => {
    setUploadModelactive(showModal);
    showUploadModal();
  }, [showModal]);

  function showUploadModal() {
    setUploading(false);
  }

  /* Create notification form */
  const {
    fields,
    submit,
    dirty,
    reset: formReset,
  } = useForm({
    fields: {
      title: useField<string>({
        value: "",
        validates: [
          notEmpty("Title must be not empty"),
          lengthMoreThan(5, "Title must be more than 5 characters"),
          lengthLessThan(200, "No more than 200 characters."),
        ],
      }),
      content: useField<string>({
        value: "",
        validates: [notEmpty("Content must be not empty"), lengthMoreThan(5, "Content must be more than 5 characters")],
      }),
      channel: useField<string>({
        value: "",
        validates: [],
      }),
      userId: useField<string>({
        value: "",
        validates: [
          // (inputValue) => {
          //   if (!helpers.isNumber(inputValue)) {
          //     return "Notification user must be an user id!";
          //   }
          // },
        ],
      }),
    },
    async onSubmit(values) {
      try {
        const datety = new Date(selectedDates.start).getDate();
        if (!Param) {
          // create new
          dispatch(
            createEntity({
              title: values.title,
              content: `${values.content}`,
              user_id: String(values.userId),
              channel: "user",
              type_action: "link",
              image: srcImage,
              manual_mode: manual,
              send_start:
                `${year}-${pad(month + 1, 2)}-${pad(datety)}` + ` ${notification_hour}:${notification_minute}:00`,
            })
          );
        } else {
          dispatch(
            updateEntity({
              // notification_title: values.notification_title,
              // notification_content: values.notification_content,
              // notification_channel: notiChannel,
              // notification_user: String(values.notification_user),
              // notification_schedule:
              //   `${year}/${pad(month + 1, 2)}/${datety}` +
              //   ` ${notification_hour}:${notification_minute}`,
            })
          );
        }
        formReset();
        closeModal();
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message = e?.response?.data?.title ?? "Undefined error. Try again!";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  let notiData = null;
  try {
    notiData = JSON.parse(entity.notification_data);
  } catch (e) {
    notiData = String(notiData);
  }

  const Actual_page = (
    <Modal
      open={uploadModelactive}
      onClose={toggleUpdateActive}
      title={uploading ? "Do NOT close this modal or refresh your browser!" : "Create notification"}
      primaryAction={{
        content: "Create",
        disabled: !dirty,
        loading: updating,
        onAction: submit,
      }}
      secondaryActions={[
        {
          content: "Close",
          disabled: uploading,
          onAction: toggleUpdateActive,
        },
      ]}
    >
      <Modal.Section>
        <Form onSubmit={submit}>
          <Card sectioned>
            <FormLayout>
              <DisplayText size="small">Image</DisplayText>
              <div id="profile_heading">
                <div className="banner_thumbnail_inner">
                  <div className="banner_thumbnail_avatar">
                    <QuickUploadImage onSuccess={handQuickUpdateSuccess} onError={handUploadError} />
                    <img
                      src={srcImage}
                      // alt="Upload banner here"
                      style={{ width: "115px" }}
                    />
                  </div>
                </div>
              </div>

              <TextField label="Title" requiredIndicator autoFocus autoComplete="off" {...fields.title} />
              <TextField label="Content" requiredIndicator multiline autoComplete="off" {...fields.content} />

              <TextField
                label="User ID"
                requiredIndicator
                multiline
                disabled={hideNotificationUser}
                autoComplete="off"
                {...fields.userId}
              />

              <Select label="Type" options={mode} requiredIndicator onChange={handleSelectChange} value={manual} />

              <TextStyle>Publish date</TextStyle>
              <DatePicker
                month={month}
                year={year}
                onChange={setSelectedDates}
                onMonthChange={handleMonthChange}
                selected={selectedDates}
              />
              <Stack>
                <Stack.Item>
                  <Select label="Hour" options={hours} onChange={handleHourChange} value={notification_hour} />
                </Stack.Item>
                <Stack.Item>
                  <Select label="Minute" options={minutes} onChange={handleMinuteChange} value={notification_minute} />
                </Stack.Item>
              </Stack>
            </FormLayout>
          </Card>
        </Form>
      </Modal.Section>
    </Modal>
  );

  const toastMarkup = errorMessage ? <Toast content={errorMessage} error onDismiss={toggleActive} /> : null;

  return (
    <>
      {toastMarkup}
      {loading ? <Loading /> : null}
      {Actual_page}
    </>
  );
}

