import {
  AppliedFilterInterface,
  Avatar,
  Badge,
  Card,
  ChoiceList,
  DataTable,
  EmptyState,
  FilterInterface,
  Filters,
  Layout,
  Link,
  Modal,
  Page,
  ResourceItem,
  ResourceList,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import debounce from "lodash.debounce";
import { TickSmallMinor, LockMinor } from "@shopify/polaris-icons";
import emptyIMG from "../../media/empty.png";
import Pagination from "../../components/pagination";
import { clearError, getEntities } from "../../store/report.store.reducer";
import ReportFilter from "./filter";
import helpers from "../../helpers";
import dateandtime from "date-and-time";

export default function General_report() {
  const [initial_loading, setInitial_loading] = useState<boolean>(true);

  const entity = useAppSelector((state) => state.report.entity);
  const entities = useAppSelector((state) => state.report.entities);
  const loading = useAppSelector((state) => state.report.loading);
  const errorMessage = useAppSelector((state) => state.report.errorMessage);
  const totalItems = useAppSelector((state) => state.report.totalItems);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  const [mainQuery, setMainQuery] = useState({
    ...{
      page: 1,
      limit: 20,
      order_by: "DESC",
    },
    ...StringQuery,
  });
  const [queryValue, setQueryValue] = useState("");

  const [input, setInput] = useState("");
  const handleFiltersQueryChange = useCallback((value) => setInput(value), []);

  useEffect(() => {
    setMainQuery({ ...mainQuery, ...{ query: input } });
  }, [input]);

  const [selectedParentId, setSelectedParentId] = useState("");
  const handleSelectedChange = useCallback((_value) => {
    setSelectedParentId(_value);
  }, []);

  useEffect(() => {
    setMainQuery({
      ...mainQuery,
      ...{ report_type: `${selectedParentId}`, page: 1 },
    });
  }, [selectedParentId]);

  /**
   * Change page number
   */

  const [numberPage, setNumberPage] = useState(1);

  const onChangePageNumber = useCallback((numPage) => {
    setNumberPage(numPage);
  }, []);

  useEffect(() => {
    setMainQuery({ ...mainQuery, page: numberPage });
  }, [numberPage]);

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch) history("/report" + buildURLSearch);
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  const onChangeCallback = useMemo(
    () =>
      debounce((_value) => {
        if (_value !== "") setMainQuery({ ...mainQuery, query: _value ? _value : "" });
      }, 500),
    []
  );

  useEffect(() => {
    onChangeCallback(queryValue);
  }, [queryValue]);

  useEffect(() => {
    if (loading === false) setInitial_loading(false);
  }, [loading]);

  /**
   *
   * @param _id
   */
  const shortcutActions = (_id: string) => {
    history("edit/" + _id);
  };

  const emptyData = (
    <EmptyState heading="No post here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>
  );

  const renderItem = (report: any) => {
    const {
      _id,
      user_id,
      partner_id,
      report_type,
      report_content,
      report_image,
      report_email,
      createdAt,
      report_name,
    } = report;
    return [
      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {_id}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {report_type}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {report_email}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        <p>{helpers.trimContentString(report_content)}</p>
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        <time>{createdAt ? dateandtime.format(new Date(createdAt), "DD-MM-YYYY HH:mm:ss") : "-"}</time>
      </Link>,
    ];
  };
  const PagesList = (
    // totalItems > 0 ? (
    <>
      <DataTable
        columnContentTypes={["text", "text", "text", "text", "text"]}
        headings={["ID", "Type", "Email", "Content", "Create At"]}
        rows={entities?.map(renderItem)}
        hideScrollIndicator
        footerContent={`Display page ${mainQuery.page} of total 100 results...`}
      />
      <style>{`
        .small-icon {
          font-size: 12px;
          padding: 0;
          width: 15px;
          height: auto;
        }
      `}</style>
    </>
  );
  // ) : (
  //   emptyData
  // );

  const Actual_page = (
    <Page
      title="Report"
      fullWidth
      // primaryAction={{
      //   content: "Create new",
      //   disabled: false,
      //   onAction: () => {
      //     history("new");
      //   },
      // }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <div style={{ padding: "16px", display: "flex" }}>
              <Stack distribution="equalSpacing">
                <ReportFilter queryValue={StringQuery?.query} onChange={handleFiltersQueryChange} />
                <Select
                  label=""
                  value={selectedParentId}
                  onChange={handleSelectedChange}
                  options={[
                    { label: "All", value: "" },
                    { label: "Contact Form", value: "contact_form" },
                    { label: "Remove User", value: "remove_user" },
                  ]}
                />
              </Stack>
            </div>
            {PagesList}
          </Card>
          <br />
          {/* {totalItems > mainQuery.limit ? ( */}
          <Pagination
            TotalRecord={100}
            activeCurrentPage={mainQuery.page}
            pageSize={mainQuery.limit}
            onChangePage={onChangePageNumber}
          />
          {/* ) : null} */}
        </Layout.Section>
      </Layout>
    </Page>
  );

  const skeleton_loading = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const toastMarkup = errorMessage ? <Toast content={errorMessage} error onDismiss={toggleActive} /> : null;

  return (
    <>
      {toastMarkup}
      {/* {loading ? skeleton_loading : Actual_page} */}
      {initial_loading ? skeleton_loading : Actual_page}
    </>
  );
}

