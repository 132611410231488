import {
  Button,
  Card,
  EmptyState,
  Form,
  FormLayout,
  Layout,
  Modal,
  Page,
  PageActions,
  RadioButton,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import { MobilePlusMajor } from "@shopify/polaris-icons";

import emptyIMG from "../../media/empty.png";
import {
  clearError,
  getEntity,
  updateEntity,
  deleteEntity,
  createEntity,
  importEntity,
  reset,
} from "../../store/prompt.store.reducer";
import {
  lengthLessThan,
  lengthMoreThan,
  useField,
  useForm,
  notEmpty,
} from "@shopify/react-form";
import helpers from "helpers";
import QuickUploadImage from "components/oneclick-upload";
import uploadimage from "../../media/choose-file.png";
import {
  getEntities as getCategories,
  getEntity as getCategory,
} from "../../store/category.store.reducer";

export default function Edit_prompt() {
  const entity = useAppSelector((state) => state.prompt.entity);
  const updating = useAppSelector((state) => state.prompt.updating);
  const entities = useAppSelector((state) => state.prompt.entities);
  const loading = useAppSelector((state) => state.prompt.loading);
  const errorMessage = useAppSelector((state) => state.prompt.errorMessage);
  const updateSuccess = useAppSelector((state) => state.prompt.updateSuccess);

  const entitycategory = useAppSelector((state) => state.category.entity);
  const entitycategories = useAppSelector((state) => state.category.entities);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  // jamviet.com
  const [message, setMessage] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const [notification, setNotification] = useState(null);

  const toggleActive = useCallback(() => {
    setNotification(null);
    setMessage(null);
    dispatch(clearError());
  }, []);

  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.prompt_slug || false;

  const [mainQueryCategory, setMainQueryCategory] = useState({
    category_type: localStorage.getItem("CATEGORY_TYPE"),
  });

  useEffect(() => {
    dispatch(getCategories(mainQueryCategory));

    if (Param) dispatch(getEntity(Param));
    else dispatch(reset());
  }, []);

  useEffect(() => {
    if (entity) {
      dispatch(getCategory(entity?._id));

      if (Param) {
        setPost_category_selected(entity?.post_category?._id);
      }
    }
  }, [entity]);

  /**
   * Category list
   */

  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    const optionType = [
      {
        label: "",
        value: "0",
      },
    ];
    for (let { _id, category_title } of entitycategories) {
      optionType.push({
        label: String(category_title),
        value: String(_id),
      });
    }
    setCategoryList(optionType);
  }, [entitycategories]);

  /**
   * Select category
   */

  const [post_category_selected, setPost_category_selected] = useState("");
  const handNewChangeCategory = useCallback((value) => {
    setPost_category_selected(value);
  }, []);

  useEffect(() => {
    if (entitycategory) {
      if (Param) {
        setPost_category_selected(entitycategory?._id);
      } else {
        setPost_category_selected("");
      }
    }
  }, [entitycategory]);

  useEffect(() => {
    if (updateSuccess) {
      history("/prompt", {
        replace: true,
      });

      setNotification("This prompt has been updated!");
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (errorMessage) {
      setNotification(errorMessage);
      dispatch(clearError());
    }
  }, [errorMessage]);

  function _deleteEntityAction() {
    setOpenModal(true);
  }

  function onModalAgree() {
    dispatch(deleteEntity(Param));
    setOpenModal(false);
  }

  /**
   * Value
   *
   *
   *
   *
   *   Xin chào tôi là một Prompt mới [đây là placeholder] và đây là số [placeholder 2] tôi rất vui [xin chao] aieieieie
   *
   */

  const useFields = {
    text_to_view: useField<string>({
      value: entity?.text ?? "",
      validates: [],
    }),
  };

  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset,
    submitErrors,
    makeClean,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!Param) {
          // create new
          dispatch(
            importEntity({
              // text_to_view: values.text_to_view,
              // text_to_ai: values.text_to_ai,
              text: values.text_to_view,
              category_id: post_category_selected,
            })
          );
        }
        // else {
        //   dispatch(
        //     updateEntity({
        //       _id: entity._id,
        //       text_to_view: valueView,
        //       text_to_ai: valueAI,
        //       text_to_image: values.text_to_image,
        //       post_status: values.post_status,
        //       post_language: languageType,
        //       post_type: postType,
        //       post_category: post_category_selected,
        //       placeholder: JSON.stringify(dataFilter),
        //     })
        //   );
        // }
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message =
          e?.response?.data?.title ??
          "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  const emptyData = (
    <Page
      title="Prompt"
      breadcrumbs={[{ content: "Prompt list", url: "/prompt" }]}
    >
      <EmptyState heading="No post here!" image={emptyIMG}>
        <p>This record maybe not exist!</p>
      </EmptyState>
    </Page>
  );

  const toastMarkup = notification ? (
    <Toast content={notification} onDismiss={toggleActive} duration={1000} />
  ) : null;

  const toastNotification = message ? (
    <Toast content={message} onDismiss={toggleActive} duration={1000} />
  ) : null;

  const Actual_page =
    entity || !Param ? (
      <>
        <Page
          title="Prompt"
          breadcrumbs={[{ content: "Prompt list", url: "/prompt" }]}
          primaryAction={{
            content: "Save",
            // disabled: !dirty,
            loading: updating,
            onAction: submit,
          }}
        >
          <Layout>
            <Layout.Section>
              <Form onSubmit={submit}>
                <Card>
                  <Card.Section>
                    <FormLayout>
                      <TextField
                        label="Text to View"
                        autoComplete="off"
                        multiline={10}
                        {...fields.text_to_view}
                      />

                      <Select
                        requiredIndicator
                        label="Category"
                        options={categoryList}
                        onChange={handNewChangeCategory}
                        value={post_category_selected?.toString()}
                      />
                    </FormLayout>
                  </Card.Section>
                </Card>
              </Form>
            </Layout.Section>
          </Layout>
          <PageActions
            primaryAction={{
              content: "Save",
              onAction: submit,
              // disabled: !dirty,
              loading: updating,
            }}
            secondaryActions={
              Param
                ? [
                    {
                      content: "Delete",
                      destructive: true,
                      onAction: _deleteEntityAction,
                    },
                  ]
                : []
            }
          />
        </Page>
      </>
    ) : (
      emptyData
    );

  const skeleton_loading = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const _Modal = openModal ? (
    <Modal
      activator={null}
      open={true}
      onClose={() => setOpenModal(false)}
      title="Delete entity?"
      primaryAction={{
        content: "Delete",
        onAction: onModalAgree,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>After delete, you can not undo this action!</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  ) : null;

  return (
    <>
      {_Modal}
      {toastMarkup}
      {toastNotification}
      {loading ? skeleton_loading : Actual_page}
    </>
  );
}
