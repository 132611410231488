import { Card, DataTable, EmptyState, Layout, Page, Select, Stack, Toast } from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import debounce from "lodash.debounce";
import { TickSmallMinor, LockMinor } from "@shopify/polaris-icons";
import emptyIMG from "../../media/empty.png";
import Pagination from "../../components/pagination";
import { clearError, getEntities } from "../../store/posts.store.reducer";
import helpers from "../../helpers";
import SearchFilter from "./filter";
import SleketonLoading from "components/skeleton_loading";
import dateandtime from "date-and-time";

export default function General() {
  const entities = useAppSelector((state) => state.posts.entities);
  const loading = useAppSelector((state) => state.posts.loading);
  const errorMessage = useAppSelector((state) => state.posts.errorMessage);
  const totalItems = useAppSelector((state) => state.posts.totalItems);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);

  const [initial_loading, setInitial_loading] = useState<boolean>(true);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  const [mainQuery, setMainQuery] = useState({
    ...{
      query: "",
      page: 1,
      limit: 20,
      sort: "post_id,desc",
    },
    ...StringQuery,
  });
  const [queryValue, setQueryValue] = useState("");
  const handleFiltersQueryChange = useCallback((value) => setQueryValue(value), []);

  const [selectedParentId, setSelectedParentId] = useState("");
  const handleSelectedChange = useCallback((_value) => {
    setSelectedParentId(_value);
    setMainQuery({
      ...mainQuery,
      ...{ query: mainQuery.query + `&post_type=${_value}` },
    });
  }, []);

  /**
   * Change page number
   */
  const onChangePageNumber = useCallback((numPage) => {
    setMainQuery({ ...mainQuery, page: numPage });
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch) history("/pages" + buildURLSearch);
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  const onChangeCallback = useMemo(
    () =>
      debounce((_value) => {
        if (_value !== "") setMainQuery({ ...mainQuery, query: _value ? _value : "" });
      }, 500),
    [mainQuery]
  );

  useEffect(() => {
    if (loading === false) setInitial_loading(false);
  }, [loading]);

  useEffect(() => {
    onChangeCallback(queryValue);
  }, [queryValue]);

  /**
   *
   * @param post_id
   */
  const shortcutActions = (post_id: number) => {
    history("/pages/edit/" + post_id);
  };

  const emptyData = (
    <EmptyState heading="No post here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>
  );

  const renderItem = (posts: any) => {
    const { post_id, post_title, post_status, post_type, comment_count, createAt, updateAt } = posts;
    return [
      <div className="clickable" key={post_id} onClick={() => shortcutActions(post_id)}>
        {post_id}
      </div>,
      <div className="small-icon" key={post_id} onClick={() => shortcutActions(post_id)}>
        {post_status === "publish" ? <TickSmallMinor /> : <LockMinor />}
      </div>,
      <div className="clickable" key={post_id} onClick={() => shortcutActions(post_id)}>
        {post_title}
      </div>,
      <div className="clickable" key={post_id} onClick={() => shortcutActions(post_id)}>
        <div style={{ textAlign: "center" }}>{post_type}</div>
      </div>,
      <div className="clickable" key={post_id} onClick={() => shortcutActions(post_id)}>
        {createAt ? dateandtime.format(new Date(Number(createAt)), "DD-MM-YYYY HH:mm:ss") : "-"}
      </div>,
      <div className="clickable" key={post_id} onClick={() => shortcutActions(post_id)}>
        <div style={{ textAlign: "center" }}>
          {updateAt ? dateandtime.format(new Date(Number(updateAt)), "DD-MM-YYYY HH:mm:ss") : "-"}
        </div>
      </div>,
    ];
  };
  const PagesList =
    totalItems > 0 ? (
      <>
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text", "text"]}
          headings={["ID", "Status", "Title", "Post type", "Create At", "Update At"]}
          rows={entities?.map(renderItem)}
          hideScrollIndicator
          footerContent={`Display page ${mainQuery.page} of total ${totalItems} results...`}
        />
        <style>{`
        .small-icon {
          margin-left: 0.7rem;
          font-size: 12px;
          padding: 0;
          width: 15px;
          height: auto;
        }
      `}</style>
      </>
    ) : (
      emptyData
    );

  const Actual_page = (
    <Page
      title="Pages"
      primaryAction={{
        content: "Create new",
        disabled: false,
        onAction: () => {
          history("/pages/new");
        },
      }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <div style={{ padding: "16px", display: "flex" }}>
              <Stack alignment="center">
                <Stack.Item fill>
                  <SearchFilter queryValue={StringQuery?.query} onChange={handleFiltersQueryChange} />
                </Stack.Item>
                <Stack.Item>
                  <Select
                    label=""
                    value={selectedParentId}
                    onChange={handleSelectedChange}
                    options={[
                      { label: "All", value: "" },
                      { label: "Page", value: "page" },
                      { label: "News", value: "news" },
                      { label: "Q&A", value: "qanda" },
                    ]}
                  />
                </Stack.Item>
              </Stack>
            </div>
            {PagesList}
          </Card>
          <br />
          {totalItems > mainQuery.limit ? (
            <Pagination
              TotalRecord={totalItems}
              activeCurrentPage={mainQuery.page}
              pageSize={mainQuery.limit}
              onChangePage={onChangePageNumber}
            />
          ) : null}
        </Layout.Section>
      </Layout>
    </Page>
  );

  const toastMarkup = errorMessage ? <Toast content={errorMessage} error onDismiss={toggleActive} /> : null;

  return (
    <>
      {toastMarkup}
      {initial_loading ? <SleketonLoading /> : Actual_page}
    </>
  );
}

