import React, { useState, useEffect } from 'react'
import { createRedeemMission, uploadRedeemMission } from '../service';
import { Box, Button, FormControl, InputLabel, MenuItem, Stack, TextField, Typography } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { IoCloseOutline } from "react-icons/io5";

const EditMission = ({ setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, updateEditMissionList, dataMission, dataMissionId, selectedMissionId, handleCloseModalEditMission, selectedMission, selectedDataMission}) => {
    const [editDataMission, setEditDataMission] = useState({
        _id: '',
        redeem_id: '',
        title: '',
        point: 0,
        coin: 0,
        action_type: '',
        action_target: '',
        action_amount: 0,
        navigate: '',
      });

      useEffect(() => {
        if (dataMission) {
            setEditDataMission({
            ...editDataMission,
            _id: dataMission?._id,
            redeem_id: dataMission?.redeem_id,
            title: dataMission?.title,
            point: dataMission?.point,
            coin: dataMission?.coin,
            action_type: dataMission?.action_type,
            action_target: dataMission?.action_target,
            action_amount: dataMission?.action_amount,
            navigate: dataMission?.navigate,
          });
        }
      }, [dataMission]);

      const handleInputChangeTextField = (fieldName, value) => {
        setEditDataMission({
          ...editDataMission,
          [fieldName]: value,
        });
    
      };

      const handleSave = async () => {
        try {
          await uploadRedeemMission(
            editDataMission._id,
            editDataMission.title,
            editDataMission.redeem_id,
            editDataMission.point,
            editDataMission.coin,
            editDataMission.action_type,
            editDataMission.action_target,
            editDataMission.action_amount,
            editDataMission.navigate,
          );
          updateEditMissionList(editDataMission);
          setSnackbarMessage('Cập nhật thành công');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          handleCloseModalEditMission();
        } catch (error) {
          setSnackbarMessage('Cập nhật thất bại');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      };


      const getActionType = (type: string): string => {
        switch (type) {
          case 'results':
            return 'Kết quả';
          case 'buy':
            return 'Mua';
          case 'comment':
            return 'Bình luận';
          case 'complete':
            return 'Hoàn thành';
          case 'join':
            return 'Tham gia';
          case 'like':
            return 'Thích';
          case 'post':
            return 'Đăng';
          case 'referral':
            return 'Giới thiệu';
          case 'share':
            return 'Chia sẻ';
          case 'view':
            return 'Xem';
          case 'watch':
            return 'Theo dõi';
          default:
            return 'Khác';
        }
      };

      const getActionTargetLabel = (target: string): string => {
        switch (target) {
          case 'community':
            return 'Cộng đồng';
          case 'course':
            return 'Khóa học';
          case 'test':
            return 'Kiểm tra';
          case 'account':
            return 'Tài khoản';
          case 'product':
            return 'Sản phẩm';
          case 'class':
            return 'Lớp học';
          case 'oneone':
            return 'Một kèm một';
          case 'freecourse':
            return 'Khóa học miễn phí';
          case 'instagram':
            return 'Instagram';
          case 'telegram':
            return 'Telegram';
          case 'tiktok':
            return 'TikTok';
          case 'twitter':
            return 'Twitter';
          case 'facebook':
            return 'Facebook';
          case 'youtube':
            return 'YouTube';
          default:
            return 'Khác';
        }
      };
      
      const actionTypes = [
        'results',
        'buy',
        'comment',
        'complete',
        'join',
        'like',
        'post',
        'referral',
        'share',
        'view',
        'watch',
      ];

      const actionTargets = [
        'community',
        'course',
        'test',
        'account',
        'product',
        'class',
        'oneone',
        'freecourse',
        'instagram',
        'telegram',
        'tiktok',
        'twitter',
        'facebook',
        'youtube',
      ];
      

  return (
    <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Create Mission</Typography>
            <IoCloseOutline onClick={handleCloseModalEditMission} fontSize={20} cursor="pointer"/>
        </Stack>

        <TextField fullWidth disabled label="Redeem ID" value={selectedMissionId} sx={{ display: 'none' }} />
        <TextField fullWidth disabled label="ID Mission" value={dataMissionId} sx={{ display: 'none' }}  />
        <TextField fullWidth label="Title" value={editDataMission.title} onChange={(event) => handleInputChangeTextField('title', event.target.value)}  placeholder='Enter title' />
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
            <FormControl fullWidth variant="outlined">
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Type"
                    fullWidth
                    value={editDataMission.action_type}
                    onChange={(event) => handleInputChangeTextField('action_type', event.target.value)} 
                >
                    {actionTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                            {getActionType(type)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Target</InputLabel>
                <Select
                    labelId="action-target-select-label"
                    id="action-target-select"
                    value={editDataMission.action_target}
                    onChange={(event) => handleInputChangeTextField('action_target', event.target.value)} 
                    fullWidth
                    label="Target"
                >
                    {actionTargets.map((target) => (
                        <MenuItem key={target} value={target}>
                        {getActionTargetLabel(target)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
            <TextField fullWidth value={editDataMission.point} onChange={(event) => handleInputChangeTextField('point', parseInt(event.target.value, 10))}   label="Point"/>
            <TextField fullWidth value={editDataMission.coin} onChange={(event) => handleInputChangeTextField('coin', parseInt(event.target.value, 10))}    label="Coin"/>
            <TextField fullWidth value={editDataMission.action_amount} onChange={(event) => handleInputChangeTextField('action_amount', parseInt(event.target.value, 10))}   label="Amount"/>
        </Stack>
        <TextField fullWidth disabled label="Navigate" value={editDataMission.navigate}  />

        <Stack direction="row" gap={1} alignItems="center" justifyContent="end">
            <Button variant='outlined' onClick={handleCloseModalEditMission}>Cancel</Button>
            <Button variant='contained' onClick={handleSave} >Create</Button>
        </Stack>
    </Box>
  )
}

export default EditMission