import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import helpers from "../../helpers";
import {
  Card,
  Page,
  Stack,
  TextStyle,
  Toast,
  Layout,
  EmptyState,
  DataTable,
  Loading,
  Link,
  Icon,
} from "@shopify/polaris";
import { clearError, getEntities, partialUpdateEntity } from "store/email_template.store.reducer";
import { ExportMinor, TickSmallMinor, ArchiveMajor, LockMinor } from "@shopify/polaris-icons";
import QuickSearch from "components/quick_search";
import Pagination from "components/pagination";
import emptyIMG from "media/empty.png";

/**
 * Setting emails
 * For internal use, eg: System sent notification ...
 */
export default function EmailTemplateSetup() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const entity = useAppSelector((state) => state.email_template.entity);
  const entities = useAppSelector((state) => state.email_template.entities);
  const loading = useAppSelector((state) => state.email_template.loading);
  const errorMessage = useAppSelector((state) => state.email_template.errorMessage);
  const totalItems = useAppSelector((state) => state.email_template.totalItems);
  const updating = useAppSelector((state) => state.email_template.updating);
  const updateSuccess = useAppSelector((state) => state.email_template.updateSuccess);
  const createSuccess = useAppSelector((state) => state.email_template.createSuccess);

  const [toastit, setToastit] = useState(null);

  useEffect(() => {
    if (updateSuccess === true) {
      setToastit("Template status has been updated!");
      /************
       * I need clear all error, to reset update status...
       */
      dispatch(clearError());
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (createSuccess === true) {
      setToastit("New template has been created!");
      dispatch(clearError());
    }
  }, [createSuccess]);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery = helpers.ExtractUrl(useParam.search) || false;

  const initialQuery = {
    query: "",
    page: 1,
    limit: 20,
    sort: "template_id,desc",
  };

  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...initialQuery,
  });

  /**
   * Router
   * @param media_id
   */
  function getMediaDetail(media_id: any): void {
    navigate(`/email_template/view_and_edit/${media_id}`);
  }

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePageNumber = useCallback(
    (numPage) => {
      setMainQuery({ ...mainQuery, page: numPage });
    },
    [mainQuery]
  );

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch)
      window.history.replaceState(null, "Email template", "/email_template" + buildURLSearch);
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  const handleFiltersQueryChange = useCallback(
    (_value) => {
      setMainQuery({ ...mainQuery, ...{ query: _value, page: 1 } });
    },
    [mainQuery]
  );

  const archiveButtonClick = (template_id: number, template_status: number) => {
    var boolean_status = Boolean(template_status);
    dispatch(
      partialUpdateEntity({
        template_id: template_id,
        template_status: Number(!boolean_status),
      })
    );
  };

  const emptyData = (
    <EmptyState heading="No Email template here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>
  );

  const renderItem = (emailtemplatedata: any) => {
    const { template_id, template_title, template_slug, template_status, createAt, users } = emailtemplatedata;
    return [
      <div className="small-icon" key={template_id}>
        {template_status === 0 ? <LockMinor /> : <TickSmallMinor />}
      </div>,
      <div className="clickable" key={template_id} onClick={() => getMediaDetail(template_id)}>
        <p>{helpers.trimMiddleString(template_title)}</p>
        {template_slug ? <TextStyle variation="positive">{helpers.getTrimContent(template_slug, 30)}</TextStyle> : null}
      </div>,
      <div className="clickable" key={template_id} onClick={() => getMediaDetail(template_id)}>
        {users.user_email}
      </div>,
      <div className="clickable" key={template_id} onClick={() => getMediaDetail(template_id)}>
        {createAt}
      </div>,
      <div key={template_title}>
        <Link removeUnderline onClick={() => archiveButtonClick(template_id, template_status)}>
          <Stack>
            <Icon source={ArchiveMajor} color="warning" />
          </Stack>{" "}
        </Link>
      </div>,
    ];
  };

  const PagesList =
    totalItems > 0 ? (
      <>
        <DataTable
          sortable={[false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={6}
          onSort={null}
          columnContentTypes={["text", "text", "text", "text", "text", "text"]}
          headings={["", "Title", "Author", "CreateAt", "Tool"]}
          rows={entities?.map(renderItem)}
          hideScrollIndicator
          footerContent={`Display page ${mainQuery.page} of total ${totalItems} results...`}
        />
        <style>{`
        .small-icon {
          font-size: 12px;
          padding: 0;
          width: 15px;
          height: auto;
        }
      `}</style>
      </>
    ) : (
      emptyData
    );

  function CreateNewbuttonClick() {
    //create_new
    navigate("/email_template/create_new");
  }

  const toastMarkup = toastit ? (
    <Toast
      content={toastit}
      onDismiss={() => {
        setToastit(null);
      }}
    />
  ) : null;

  return (
    <>
      {loading ? (
        <div style={{ height: "100px" }}>
          <Loading />
        </div>
      ) : null}
      <Page
        primaryAction={{
          content: `New Template`,
          onAction: CreateNewbuttonClick,
          icon: ExportMinor,
        }}
        title="Email template"
        subtitle="All email in this system need a template to send email"
      >
        {toastMarkup}
        <Layout>
          <Layout.Section>
            <Card>
              <div style={{ padding: "16px" }}>
                <Stack alignment="center">
                  <Stack.Item fill>
                    <QuickSearch queryValue={StringQuery?.query} onChange={handleFiltersQueryChange} />
                  </Stack.Item>
                </Stack>
              </div>
              {PagesList}
            </Card>
            <br />
            {totalItems > 0 ? (
              <Pagination
                TotalRecord={totalItems}
                onChangePage={onChangePageNumber}
                pageSize={Number(mainQuery.limit)}
                activeCurrentPage={Number(mainQuery.page)}
              />
            ) : null}
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
}

