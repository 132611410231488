export default () => next => action => {
  /**
     *
     * Header parse!
     * for get session id and save it to localStorage
     * and then sent to api
     */
    /**
     * It need not run in production
     *
    if (process.env.NODE_ENV === 'development') {
      const header = action?.payload?.headers ?? {};
      const session = header['x-authorization'] || false;
      console.info("JamDevSession: " + session );
    } */

    // Dispatch initial action
    return next(action);
}