import { Box, Button, Chip, Divider, Modal, Rating, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { FaBookOpen } from 'react-icons/fa'
import { HiOutlineUserGroup } from 'react-icons/hi2'
import { LiaBookReaderSolid } from 'react-icons/lia'
import EditCourse from './course.edit'
import { style, styleModule, styleButtonUpload } from '../assets/css/module'
import '../assets/css/course.css'
import { ListItemModuleData } from '../interface'
import { createCourseModule, getListCallGroup, getListCourseModule, uploadMedia } from '../service'
import ModuleCourse from './course.moduleCourse'
import CallCourse from './course.call-group'
import CallOneOne from './course.call-one-one'
import CustomSnackbar from 'components/snackbar'

const ListDetail = ({ handleDelete, styleDetail, getDetailCourse, formattedAmount, formatDate, statusColorMap }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openModule, setOpenModule] = useState(false);
  const [openCall, setOpenCall] = useState(false);
  const [openCallOneOne, setOpenCallOneOne] = useState(false);
  const [getIdEdit, setGetIdEdit] = useState('');
  const [getIdModule, setGetIdModule] = useState('');
  const [showNewModuleBox, setShowNewModuleBox] = useState(false);
  const [mediaUrl, setMediaUrl] = useState('');
  const [modules, setModules] = useState([]);
  const [callGroup, setCallGroup] = useState([]);
  const [detailCourse, setDetailCourse] = useState(getDetailCourse);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [formDataModule, setFormDataModule] = useState<ListItemModuleData>({
    course_id: '',
    title: '',
    media_id: '',
    type: '',
    parent_id: null,
  });
  const [formDataCall, setFormDataCall] = useState<ListItemModuleData>({
    course_id: '',
    title: '',
    media_id: '',
    type: '',
    parent_id: null,
  });

  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const handleOpenEdit = (id: string) => {
    setGetIdEdit(id)
    setOpenEdit(true);
  };
  const handleCloseEdit = () => setOpenEdit(false);

  const handleOpenModule = async (id) => {
    setGetIdModule(id);
    setFormDataModule((prevState) => ({
      ...prevState,
      course_id: id,
    }));
    setOpenModule(true);

    try {
      const data = await getListCourseModule(id);
      setCallGroup(data.data);
      setModules(data.data)
    } catch (error) {
      console.error('Failed to fetch modules:', error);
    }
  };

  const updateDetailCourse = (updatedCourse) => {
    setDetailCourse(updatedCourse);
    console.log(updatedCourse)
    handleCloseEdit();
  };

  const handleCloseModule = () => {
    setOpenModule(false);
  };

  const handleOpenCall = async (id) => {
    setGetIdModule(id);
    setFormDataModule((prevState) => ({
      ...prevState,
      course_id: id,
    }));
    setOpenCall(true);

    try {
      const data = await getListCallGroup(id);
      setCallGroup(data.data);
    } catch (error) {
      console.error('Failed to fetch modules:', error);
    }
  };


  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCloseCall = () => {
    setOpenCall(false);
  };

  
  const handleOpenCallOneOne = async (id) => {
    console.log(id)
    setFormDataModule((prevState) => ({
      ...prevState,
      course_id: id,
    }));
    setOpenCallOneOne(true);
  };

  const handleCloseCallOneOne = () => {
    setOpenCallOneOne(false);
  };

  const handleFileChangeAndUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const buttonType = event.target.closest('label')?.getAttribute('data-type');
  
    try {
      const files = event.target.files;
      if (files && files.length > 0) {
        const file = files[0];
        const headers = {
          'X-Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQ1Nzc3MDAsImRhdGEiOnsiX2lkIjoiNjU4MjVhYTQ5ZDY2YWM4YzQ3YzZiMDFkIiwia2V5IjoiOTNiOTgxN2VkZmQ1ZjU1NDBkZTI2MDNiM2M3N2JlZmEiLCJzaWduYXR1cmUiOiI2ZGI5M2RhMzE1YzRjNzBkNjY2YjNiNWRjZjIwYzUzMiIsInNlc3Npb24iOiI2NTgyNWFhNDlkNjZhYzhjNDdjNmIwMWYifSwiaWF0IjoxNzAzMDQxNzAwfQ.R0CrGfSMvi_V3T445vlp75Eetz_x6RHCrWtaSkXo0A8'
        };
        const { id, type, src } = await uploadMedia(file, headers);
  
        setFormDataModule(prevData => ({
          ...prevData,
          media_id: id,
          type: buttonType || type 
        }));
        setMediaUrl(src);
        console.log(src)
      }
    } catch (error) {
      console.error('Upload failed:', error);
    } finally {
      console.log("Upload completed");
    }
  };
  
  const handleShowNewModuleBox = () => {
    setShowNewModuleBox(true);
  };

  const handleSubmit = async () => {
    try {
      const response = await createCourseModule(
        formDataModule?.course_id,
        formDataModule?.title,
        formDataModule?.media_id,
        formDataModule?.type,
        formDataModule?.parent_id,
      );

      setFormDataModule(response)

    } catch (error) {
    }
  };

  const handleChangeCourseModule = (name, value) => {
    setFormDataModule({
      ...formDataModule,
      [name]: value,
    });
  };


  const formatSkills = (skills) => {
    if (!Array.isArray(skills)) {
      return '';
    }
    return skills.join(', ');
  };


  return (
    <Box sx={styleDetail}>
      {getDetailCourse && (
        <Box>
          <Modal
            open={openEdit}
            onClose={handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <EditCourse setSnackbarOpen={setSnackbarOpen} setSnackbarSeverity={setSnackbarSeverity} setSnackbarMessage={setSnackbarMessage} updateDetailCourse ={updateDetailCourse} getDetailCourse={getDetailCourse} style={style} />
          </Modal>

          <Modal
            open={openModule}
            onClose={handleCloseModule}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
           <ModuleCourse 
              styleModule={styleModule} 
              showNewModuleBox={showNewModuleBox} 
              formDataModule={formDataModule} 
              setFormDataModule={setFormDataModule} 
              isEditingTitle={isEditingTitle} 
              handleChangeCourseModule={handleChangeCourseModule} 
              handleFileChangeAndUpload={handleFileChangeAndUpload}
              mediaUrl={mediaUrl} 
              setIsEditingTitle={setIsEditingTitle} 
              styleButtonUpload={styleButtonUpload} 
              modules={modules}
              handleShowNewModuleBox={handleShowNewModuleBox}
              handleSubmit={handleSubmit}
            />
          </Modal>

          <Modal
            open={openCall}
            onClose={handleCloseCall}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <CallCourse callGroup={callGroup} getDetailCourse={getDetailCourse} styleModule={styleModule} />
          </Modal>

          {/* <Modal
            open={openCallOneOne}
            onClose={handleCloseCallOneOne}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <CallOneOne getDetailCourse={getDetailCourse} styleModule={styleModule} />
          </Modal> */}

          <Stack direction='row' alignItems='center' justifyContent='space-between' mb={2} p={2}>
            <Typography variant='h6'>Chi tiết danh mục</Typography>
            <Stack direction='row' alignItems='center' gap={2}>
              <Button variant='outlined' onClick={() => handleDelete(getDetailCourse._id)} >Xóa</Button>
              <Button variant='contained' onClick={(e) => handleOpenEdit(getDetailCourse._id)}>Chỉnh sửa</Button>
            </Stack>
          </Stack>

          <img src={detailCourse?.avatar?.media_url} alt="banner" style={{ width: '100%' }} />


          <Box p={2}>
            {detailCourse.skills && detailCourse.skills.map((skill, index) => (
              <Chip key={index} label={skill} sx={{ mr: 1, mt: 2, mb: 1 }} />
            ))}

            <Typography variant="body1" fontSize={26} fontWeight={550} mt={1} mb={1}>
              {detailCourse.title}
            </Typography>
            <Stack direction='row' gap={2} alignItems='center'>
              <Typography variant="body1" fontWeight={550} mt={1} mb={1}>
                {formattedAmount.format(detailCourse.price)}  -
              </Typography>
              <Box>
                <Rating
                  name="rating"
                  value={detailCourse.rating}
                  precision={0.1}
                  readOnly
                />
              </Box>
              <Chip
                label={detailCourse.public_status}
                color={statusColorMap[detailCourse.public_status]}
                sx={{
                  backgroundColor: detailCourse.public_status === 'active' ? '#50C878' : '#FF5733',
                  color: '#fff'
                }}
              />
            </Stack>
            <Typography variant="body2" fontWeight={500} mt={1} mb={2}>
              {detailCourse.description}
            </Typography>
            <Typography variant="body1" fontWeight={550} mb={1}>
              Mô tả:
            </Typography>
            <Typography variant="body2" fontWeight={500} mt={1} mb={1}>
              {detailCourse.long_description}
            </Typography>
            <Typography variant="body1" fontWeight={550} mt={4} mb={1}>
              Khóa học này bao gồm:
            </Typography>
            <Stack direction="row" gap={3} alignItems="center" mb={2}>
              <Stack direction="row" gap={1} alignItems="center">
                <LiaBookReaderSolid fontSize={18} />
                <Stack direction='column'>
                  <Typography variant="body2">Kiểu học: {detailCourse.type}</Typography>
                  {/* {detailCourse.type === 'Call 1-1' && (
                  <Typography variant="body2" sx={{ cursor: 'pointer', '&:hover': { opacity: 0.7 } }} onClick={(e) => handleOpenCallOneOne(getDetailCourse._id)}>
                    Có {Array.isArray(detailCourse.classes) ? detailCourse.classes.length : 0} lịch học
                  </Typography>
                  )} */}
                  {detailCourse.type === 'Call group' && (
                  <Typography variant="body2" sx={{ cursor: 'pointer', '&:hover': { opacity: 0.7 } }} onClick={(e) => handleOpenCall(getDetailCourse._id)}>
                    Có {Array.isArray(detailCourse.classes) ? detailCourse.classes.length : 0} lịch học
                  </Typography>
                  )}
                </Stack>
              </Stack>
              <Stack direction="row" gap={1} alignItems="center">
                <HiOutlineUserGroup fontSize={18} />
                <Typography variant="body2">{detailCourse.join_number} Học viên</Typography>
              </Stack>
              {detailCourse.type !== 'Call 1-1' && detailCourse.type !== 'Call group' &&(
                <Stack direction="row" gap={1} alignItems="center">
                  <FaBookOpen fontSize={18} />
                  <Typography variant="body2" onClick={(e) => handleOpenModule(detailCourse._id)} sx={{ cursor: 'pointer', '&:hover': { opacity: 0.7 } }} >{getDetailCourse.module_count} Khóa học</Typography>
                </Stack>
              )}
              <Typography variant="body2">Level <strong>{detailCourse.level}</strong></Typography>
            </Stack>
            <Typography variant="body1" fontWeight={550} mt={4} mb={1}>
              Ky nang bao gom:
            </Typography>
            <Typography variant="body2" fontWeight={500} mt={1} mb={4}>
              {formatSkills(detailCourse.skills)}
            </Typography>
            <Typography variant="body1" fontWeight={550} mt={4} mb={1}>
              Thời gian & địa điểm:
            </Typography>
            <Stack direction="row" gap={3} alignItems="center" mb={2}>
              <Stack direction="column" gap={1}>
                <Typography variant="body2" fontWeight={550}>Thời gian bắt đầu</Typography>
                <Typography variant="body2">{formatDate(detailCourse.start_time)}</Typography>
              </Stack>
              <Stack direction="column" gap={1}>
                <Typography variant="body2" fontWeight={550}>Thời gian kết thúc</Typography>
                <Typography variant="body2">{formatDate(detailCourse.end_time)}</Typography>
              </Stack>
              <Stack direction="column" gap={1}>
                <Typography variant="body2" fontWeight={550}>Thành phố</Typography>
                <Typography variant="body2">{detailCourse.country}</Typography>
              </Stack>
            </Stack>
            <Divider></Divider>

            <Box mt={2}>
              <Typography variant="body1">Chuyên gia   <strong>{detailCourse.user_id?.display_name}</strong></Typography>
              <Box>
                <Rating
                  name="rating"
                  value={detailCourse.user_id?.rating}
                  precision={0.1}
                  readOnly
                />
              </Box>
              <Stack direction='row' gap={1} alignItems='center'>
                <img src={detailCourse.user_id?.user_avatar} alt={detailCourse.user_id?.display_name} style={{ width: 100, borderRadius: '50%' }} />
                <Stack direction='column' gap={1}>
                  <Typography variant="body2">{detailCourse.user_id?.course_count} Danh mục</Typography>
                  <Typography variant="body2">{detailCourse.user_id?.member_count} Học viên</Typography>
                  <Typography variant="body2">{detailCourse.user_id?.rating_count} Lượt đánh giá</Typography>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Box>
      )}
        <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  )
}

export default ListDetail