import Textarea from '@mui/joy/Textarea'
import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { uploadUser } from '../service';

const ListUser = ({ style, userData, imageURL, handleFileChangeAndUpload, handleChange, blockUser, role}) => {
    const [countries, setCountries] = useState([]);
    const [updateUser, setUpdateUser] = useState({
        _id: '',
        bio: '',
        link: '',
        user_avatar: imageURL,
        display_name: '',
        user_address: '',
        official_status: '',
        phone_number: '',
        badge: '',
        user_role: '',
      });
      

      useEffect(() => {
        if (userData) {
            setUpdateUser(prevUser => ({
                ...prevUser,
                _id: userData._id,
                bio: userData.bio,
                link: userData.link,
                display_name: userData.display_name,
                user_address: userData.user_address,
                official_status: userData.official_status,
                phone_number: userData.phone_number,
                badge: userData.badge,
                user_role: userData.user_role,
            }));
        }
    }, [userData]);
    

    useEffect(() => {
        setUpdateUser(prevUser => ({
            ...prevUser,
            user_avatar: imageURL,
        }));
    }, [imageURL]);

    const handleInputChangeTextField = (field, value) => {
        setUpdateUser(prevUser => ({
            ...prevUser,
            [field]: value,
        }));
    };

      const getRoleType = (type: string): string => {
        switch (type) {
          case 'admin':
            return 'Quản trị';
          case 'teacher':
            return 'Giáo viên';
          case 'user':
            return 'Người dùng';
          default:
            return 'Khác';
        }
      };

      const roleTypes = [
        'admin',
        'teacher',
        'user',
      ];

      const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          handleFileChangeAndUpload(event); // Call the function received as prop
        }
      };

      const handleSave = async () => {
        console.log("lỗi", this)
        try {
          await uploadUser(
            updateUser._id,
            updateUser.bio,
            updateUser.link,
            updateUser.user_avatar,
            updateUser.display_name,
            updateUser.user_address,
            updateUser.official_status,
            updateUser.phone_number,
            updateUser.badge,
            updateUser.user_role,
          );
          console.log("thành công")
        } catch (error) {
          console.log("lỗi lòi ra")

        }
      };

return (
    <Box sx={style}>
            <Typography variant="h5" mb={2}>Chi tiết người dùng</Typography>
            {userData && (
                <Box>
                    <TextField
                            variant="outlined"
                            value={userData._id}
                            size="small"
                            sx={{ display: 'none' }}
                        />
                    <Box mb={3}>
                        <Stack direction="row" gap={2} alignItems="center">
                            <img src={updateUser.user_avatar} alt={userData.display_name} width={100} style={{ borderRadius: '50%' }}/>
                            <Stack direction="column" gap={2}>
                                <Button
                                    component="label"
                                    variant="contained"
                                    sx={{
                                        width: '230px',
                                        height: '40px',
                                        background: '#D01F27',
                                        color: '#fff',
                                        fontWeight: 550,
                                        transition: '0.2s ease-in',
                                        '&:hover': {
                                            backgroundColor: 'rgba(208, 31, 39, 0.7)',
                                        },
                                    }}
                                    >
                                    Thay đổi ảnh đại diện
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                </Button>
                               <Stack direction="row" gap={2} alignItems="center" >
                                   <Typography variant="body2">Cấp: <strong>{userData.level}</strong></Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            backgroundColor: userData.is_pending_to_became_teacher ? '21ff84' : '#e6443a',
                                            color: '#fff',
                                            borderRadius: '4px',
                                            padding: '4px 8px',
                                            width: '145px',
                                            textAlign: 'center',
                                            fontWeight: '550'
                                        }}
                                    >
                                        {userData.is_pending_to_became_teacher ? "Đang là giáo viên" : "Chưa là giáo viên"}
                                    </Typography>
                               </Stack>

                            </Stack>
                        </Stack>
                    </Box>
                    <FormControl fullWidth>
                        {/**Thông tin*/}
                        <TextField
                            label="Họ và tên"
                            variant="outlined"
                            value={updateUser.display_name}
                            onChange={(event) => handleInputChangeTextField('display_name', event.target.value)}
                            size="small"
                            sx={{ mb: 1 }}
                        />
                        <Stack direction="row" alignItems="center" gap={2} mb={1}>
                            <TextField
                                fullWidth
                                label="Địa chỉ"
                                value={updateUser.user_address}
                                onChange={(event) => handleInputChangeTextField('user_address', event.target.value)}
                                variant="outlined"
                                size="small"
                            />
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label">Quốc gia</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={userData.country}
                                    label="Quốc gia"
                                    onChange={handleChange}
                                >
                                    <MenuItem value='admin'>Admin</MenuItem>
                                    <MenuItem value='teacher'>Teacher</MenuItem>
                                    <MenuItem value='user'>User</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        
                        <Divider></Divider>

                        {/**Thông tin liên hệ*/}
                        <Typography variant="body1" mt={1} mb={2} fontWeight={550}>Thông tin liên hệ</Typography>
                        <TextField
                            fullWidth
                            label="Tài khoản"
                            variant="outlined"
                            value={userData.user_login}
                            size="small"
                            sx={{ mb: 1 }}
                        />
                        <Stack direction="row" alignItems="center" gap={2} mb={2}>
                            <TextField
                                fullWidth
                                label="Điện thoại"
                                variant="outlined"
                                value={updateUser.phone_number}
                                onChange={(event) => handleInputChangeTextField('phone_number', event.target.value)}
                                size="small"
                            />
                            <TextField
                                fullWidth
                                label="Phiên điện thoại"
                                variant="outlined"
                                value={userData.phone_session}
                                size="small"
                            />
                        </Stack>
                                                
                        <Divider></Divider>

                        {/**Thông tin hồ sơ*/}
                        <Typography variant="body1" mt={1} mb={2} fontWeight={550}>Thông tin hồ sơ</Typography>
                        <Stack direction="row" alignItems="center" gap={2}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label">Quyền</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={updateUser.user_role}
                                    onChange={(event) => handleInputChangeTextField('user_role', event.target.value)} 
                                    label="Quyền"
                                >
                                    {roleTypes.map((roles) => (
                                        <MenuItem key={roles} value={roles}>
                                            {getRoleType(roles)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                label="Verify Code"
                                variant="outlined"
                                size="small"
                                value={userData.verify_code}
                             />
                        </Stack>
                        
                        <Textarea value={updateUser.bio} onChange={(event) => handleInputChangeTextField('bio', event.target.value)}color="neutral" minRows={2} placeholder='Phần giới thiệu' sx={{ mb: 3 }}/>

                        <Divider></Divider>

                        {/**Quản lý người dùng*/}
                        <Typography variant="body1" mt={1} mb={2} fontWeight={550}>Quản lý người dùng</Typography>
                        <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between" sx={{ width: '100%', minWidth: '750px' }}>
                            <Box sx={{ width: "375px", border: '1px solid rgba(0,0,0,0.4)', padding: 1, borderRadius: '3px', height: '400px', overflowY: 'auto' }}>
                                <Typography variant="body2" mb={1} fontWeight={550}>Danh sách chặn người dùng</Typography>
                                {blockUser.length > 0 ? (
                                    blockUser.map((user, index) => (
                                        <Box key={index} mt={2}>
                                            <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                                                <Stack direction="row" gap={2} alignItems="center">
                                                    <img src={user.avatar} alt="" width={30} style={{ borderRadius: '50%' }} />
                                                    <Typography variant="body2">{user.name}</Typography>
                                                </Stack>
                                                <Button
                                                    sx={{
                                                        width: '100px',
                                                        height: '30px',
                                                        background: '#D01F27',
                                                        color: '#fff',
                                                        fontWeight: 550,
                                                        transition: '0.2s ease-in',
                                                        '&:hover': {
                                                            backgroundColor: 'rgba(208, 31, 39, 0.7)',
                                                        },
                                                    }}
                                                >
                                                    Bỏ chặn
                                                </Button>
                                            </Stack>
                                        </Box>
                                    ))
                                ) : (
                                    <Box mt={2}>
                                        <Typography variant="body1">Không có người dùng chặn nào</Typography>
                                    </Box>
                                )}
                            </Box>
                            <Box sx={{ width: "375px", border: '1px solid rgba(0,0,0,0.4)', padding: 1, borderRadius: '3px', height: '400px', overflowY: 'auto' }}>
                                <Typography variant="body2" mb={1} fontWeight={550}>Danh sách theo dõi người dùng</Typography>
                                {blockUser.length > 0 ? (
                                    blockUser.map((user, index) => (
                                        <Box key={index} mt={2}>
                                            <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                                                <Stack direction="row" gap={2} alignItems="center">
                                                    <img src={user.avatar} alt="" width={30} style={{ borderRadius: '50%' }} />
                                                    <Typography variant="body2">{user.name}</Typography>
                                                </Stack>
                                                <Button
                                                    sx={{
                                                        width: '120px',
                                                        height: '30px',
                                                        background: '#D01F27',
                                                        color: '#fff',
                                                        fontWeight: 550,
                                                        transition: '0.2s ease-in',
                                                        '&:hover': {
                                                            backgroundColor: 'rgba(208, 31, 39, 0.7)',
                                                        },
                                                    }}
                                                >
                                                    Bỏ theo dõi
                                                </Button>
                                            </Stack>
                                        </Box>
                                    ))
                                ) : (
                                    <Box mt={2}>
                                        <Typography variant="body1">Không có theo dõi người dùng nào</Typography>
                                    </Box>
                                )}
                            </Box>
                        </Stack>
                    </FormControl>

                    <Stack direction="row" gap={1} alignItems="center" justifyContent="flex-end" mt={2}>
                        <Button sx={{ color: '#333', fontWeight: '550' }}>
                            Thoát
                        </Button>
                        <Button
                            sx={{
                                width: '100px',
                                height: '30px',
                                background: '#D01F27',
                                color: '#fff',
                                fontWeight: 550,
                                transition: '0.2s ease-in',
                                '&:hover': {
                                    backgroundColor: 'rgba(208, 31, 39, 0.7)',
                                },
                            }}
                            onClick={handleSave}
                        >
                            Lưu
                        </Button>
                    </Stack>
                </Box>
            )}
        </Box>
  )
}

export default ListUser