import Textarea from '@mui/joy/Textarea'
import { Box, Button, FormControl, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { Spinner } from '@shopify/polaris'
import { FaUpload } from 'react-icons/fa'

const CreatePodcast = ({ mediaType, mediaSrc, handleSubmit, style, handleFileChangeAndUpload, handleFileChangeAndUploadAudio, handleChangeCourse, formData, listAudio, listCategory, isEdit, isUploading, removeImageCover, removeAudio, onClose }) => {
    const loadingElement = (
        <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
    
      <Spinner accessibilityLabel="Spinner example" size="large" />
      <p>Uploading</p>
        </div>
      )
    return (
    <Box>
        <Typography variant='h6' mb={2}>{isEdit ? 'Sửa' : 'Thêm mới'} Podcast</Typography>
        <Stack>
            <Box>
                <Stack direction='column' gap={1} >
                    <Typography variant='body1' fontWeight={550}>Tiêu đề:</Typography>
                    <TextField value={formData.title} onChange={(e) => handleChangeCourse("title", e.target.value)} name="title" fullWidth placeholder='Điền tiêu đề' size="small" sx={{ mb: 1 }}/>
                </Stack>
                

                <Stack direction='column' gap={1} >
                    <Typography variant='body1' fontWeight={550}>Mô tả:</Typography>
                    <Textarea color="neutral" value={formData.content} onChange={(e) => handleChangeCourse("content", e.target.value)} name="description" minRows={3} placeholder='Mô tả' sx={{ mb: 2 }}/>
                </Stack>


                <Box>
                    <Box>
                        <Typography variant='body1' fontWeight={550} mb={1}>Danh mục:</Typography>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={listCategory.length > 0 ? formData.podcast_category : 'none'} // Use formData.type as the value
                                onChange={(e) => handleChangeCourse("podcast_category", e.target.value)}
                                name="type"
                                fullWidth
                                size="small"
                                disabled={listCategory.length == 0 ? true : false}
                            >
                                {
                                    listCategory.length > 0 ? listCategory.map((cate: any, index: number) => (
                                <MenuItem value={cate._id} key={index}>{cate.category_title}</MenuItem>

                                    )) : (
                                    <MenuItem value="none">Không có danh mục</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Typography variant='body1' fontWeight={550} mb={1}>Ảnh bìa Podcast:</Typography>
                <Box>
                {
                    isUploading.image ? loadingElement : mediaType === 'image' && <>
                    <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                    <img src={mediaSrc} alt="Media preview" width={250} />
                    <p style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={removeImageCover}>Xoá</p>
                    </div>
                    </>
                }
                </Box>
                {
                    mediaSrc == '' && !isUploading.image ? (
                        <Button
                component="label"
                variant="contained"
                startIcon={<FaUpload />}
                sx={{
                width: '150px',
                height: '80px',
                background: 'transparent',
                color: '#333',
                fontWeight: 550,
                border: '1px dashed',
                marginBottom: 2,
                '&:hover': {
                    backgroundColor: 'rgba(0,0,0,0.1)',
                },
                    }}
                >
                    Tải Ảnh bìa
                    <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileChangeAndUpload}
                />
            </Button>
                    ) : null
                }
            </Box>
            <Box position={'relative'}>
                <Typography variant='body1' fontWeight={550} mb={1}>Danh sách Podcast:</Typography>
                    {listAudio && listAudio.map((audio: any, index: number) => (
                        <Box key={index} display={'flex'} alignItems={'center'} gap={8}>
                            <audio src={audio.src} controls />
                            <p style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={removeAudio}>Xoá</p>
                        </Box>
                    ))}
                {isUploading.audio ? loadingElement : listAudio.length == 0 && (

                <Button
                component="label"
                variant="contained"
                startIcon={<FaUpload />}
                sx={{
                width: '150px',
                height: '80px',
                background: 'transparent',
                color: '#333',
                fontWeight: 550,
                border: '1px dashed',
                marginBottom: 2,
                '&:hover': {
                    backgroundColor: 'rgba(0,0,0,0.1)',
                },
                    }}
                >
                    Tải Audio
                    <input
                    type="file"
                    accept="audio/mpeg"
                    style={{ display: 'none' }}
                    onChange={handleFileChangeAndUploadAudio}
                />
            </Button>
                )}
            </Box>
              <Stack direction="row" gap={2} justifyContent="space-between" mt={2}>
            <Button fullWidth size='small' variant='outlined' onClick={onClose}>Thoát</Button>
            <Button fullWidth size='small' onClick={handleSubmit} variant='contained'>{isEdit ? 'Cập nhật' : 'Tạo'}</Button>
        </Stack>
        </Stack>
      
    </Box>
  )
}

export default CreatePodcast