import {
  Card,
  EmptyState,
  Form,
  FormLayout,
  Layout,
  Modal,
  Page,
  PageActions,
  Stack,
  TextContainer,
  TextField,
  Toast,
  Tag,
  Button,
  Autocomplete,
  Select,
  DropZone,
  Thumbnail,
  Caption,
  ProgressBar,
} from "@shopify/polaris";
import { NoteMinor, MobileCancelMajor } from "@shopify/polaris-icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import emptyIMG from "../../media/empty.png";
import helpers from "../../helpers";
import SkeletonLoading from "components/skeleton_loading";
import axios from "axios";

import {
  clearError,
  getEntity,
  updateEntity,
  deleteEntity,
  createEntity,
  reset,
} from "../../store/category.store.reducer";

import {
  lengthLessThan,
  lengthMoreThan,
  useField,
  useForm,
} from "@shopify/react-form";
import QuickUploadImage from "components/oneclick-upload";
import uploadImage from "../../media/choose-file.png";
import { TinyMCE } from "components/tinyMCE";

import ReactAudioPlayer from "react-audio-player";

/**
 * Convert Tag object to String and join with comma
 * @param tags Object
 */

/**
 * Main function
 * @returns
 */
export default function NewEdit() {
  const entity = useAppSelector((state) => state.category.entity);
  const updating = useAppSelector((state) => state.category.updating);
  const loading = useAppSelector((state) => state.category.loading);
  const errorMessage = useAppSelector((state) => state.category.errorMessage);
  const updateSuccess = useAppSelector((state) => state.category.updateSuccess);
  const dispatch = useAppDispatch();
  const history = useNavigate();

  const entitycategory = useAppSelector((state) => state.category.entity);
  const entitycategories = useAppSelector((state) => state.category.entities);

  const [openModal, setOpenModal] = useState(false);
  const [notification, setNotification] = useState<string | null>(null);
  const [internalErrorNotice, setInternalErrorNotice] = useState("");
  const [textContent, setTextContent] = useState("");

  /**
   * Language type
   */
  const [languageType, setLanguageType] = useState("en");
  const handleLanguageChange = useCallback(
    (value) => setLanguageType(value),
    []
  );
  const languages = [
    { label: "English", value: "en" },
    { label: "Vietnamese", value: "vi" },
    { label: "Spanish", value: "sp" },
  ];

  const toggleActive = useCallback(() => {
    setNotification(errorMessage);
  }, []);

  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.category_slug || false;

  useEffect(() => {
    if (Param) {
      dispatch(getEntity(Param));
    } else {
      setTextContent(" ");
      dispatch(reset());
    }
  }, []);

  useEffect(() => {
    if (entity && entity.category_content) {
      if (Param) {
        setTimeout(() => {
          setTextContent(entity.category_content);
        }, 10);
      } else {
        setTextContent(" ");
      }
    }
  }, [entity]);

  /**
   * upload image
   */
  const [srcImage, setSrcImage] = useState(uploadImage);
  const [srcImageId, setSrcImageId] = useState("");

  useEffect(() => {
    if (entity) {
      if (Param) {
        setLanguageType(entity?.category_language);
        setTimeout(() => {
          setSrcImage(entity?.category_avatar?.media_url);
        }, 10);
        setSrcImageId(entity?.category_avatar?._id);
      } else {
        setSrcImage(uploadImage);
        setSrcImageId("");
        setLanguageType("en");
      }
    }
  }, [entity]);

  // const remove_image_element = (element: number) => {
  //   console.log(element, "element: number");
  //   delete fileDownload[element];
  //   var filtered = files.filter((el) => {
  //     return el != null;
  //   });
  //   setFiles(filtered);
  // };

  function handQuickUpdateSuccess(res: any) {
    console.log(res);
    setSrcImageId(res[0].callback._id);
    setSrcImage(res[0].src);
  }

  useEffect(() => {
    if (updateSuccess === true) {
      history("/category/edit/" + entity?._id, { replace: true });
      dispatch(getEntity(Param));

      setNotification("This category has been updated!");
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (errorMessage) {
      setNotification(errorMessage);
      dispatch(clearError());
    }
  }, [errorMessage]);

  function _deleteEntityAction() {
    setOpenModal(true);
  }

  function onModalAgree() {
    dispatch(deleteEntity(Param));
    setOpenModal(false);
  }

  const emptyData = (
    <Page
      title="News"
      breadcrumbs={[{ content: "Page list", url: "/category" }]}
    >
      <EmptyState heading="No category here!" image={emptyIMG}>
        <p>
          Oh! There is no record here! Try remove filter or add a new record!
        </p>
      </EmptyState>
    </Page>
  );
  const toastMarkup = notification ? (
    <Toast content={notification} onDismiss={toggleActive} />
  ) : null;

  function handUploadError(err: any) {
    setInternalErrorNotice("Tải ảnh thất bại! Ảnh quá lớn!");
  }

  const useFields = {
    category_title: useField<string>({
      value: entity?.category_title ?? "",
      validates: [
        lengthLessThan(200, "Không được dài hơn 200 ký tự."),
        lengthMoreThan(2, "Không được ngắn hơn 2 ký tự."),
        (inputValue) => {
          if (inputValue.length < 2) {
            return "Tiêu đề quá ngắn hoặc trống.";
          }
        },
      ],
    }),

    category_status: useField<string>({
      value: entity?.category_status ?? "",
      validates: [
        lengthLessThan(250, "Không được dài hơn 250 ký tự."),
        lengthMoreThan(2, "Không được ngắn hơn 2 ký tự."),
      ],
    }),

    category_excerpt: useField<string>({
      value: entity?.category_excerpt ?? "",
      validates: [
        lengthLessThan(250, "Không được dài hơn 250 ký tự."),
        lengthMoreThan(2, "Không được ngắn hơn 2 ký tự."),
      ],
    }),

    category_type: useField<string>({
      value: entity?.category_type
        ? entity?.category_type
        : localStorage.getItem("CATEGORY_TYPE"),
      validates: [
        lengthLessThan(250, "Không được dài hơn 250 ký tự."),
        lengthMoreThan(2, "Không được ngắn hơn 2 ký tự."),
      ],
    }),

    category_slug: useField<string>({
      value: entity?.category_slug ?? "",
      validates: [
        lengthLessThan(250, "Không được dài hơn 250 ký tự."),
        lengthMoreThan(2, "Không được ngắn hơn 2 ký tự."),
      ],
    }),

    seo_title: useField<string>({
      value: entity?.seo_title ?? "",
      validates: [],
    }),

    seo_description: useField<string>({
      value: entity?.seo_description ?? "",
      validates: [],
    }),

    seo_keyword: useField<string>({
      value: entity?.seo_keyword ?? "",
      validates: [],
    }),
  };

  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset,
    submitErrors,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!Param) {
          // create new
          dispatch(
            createEntity({
              category_title: values.category_title,
              category_content: textContent,
              category_excerpt: values.category_excerpt,
              category_avatar: srcImageId,
              category_language: languageType,
              category_type: values.category_type,
              category_status: "publish",
              seo_title: values.seo_title,
              seo_description: values.seo_description,
              seo_keyword: values.seo_keyword,
              // public_album: JSON.stringify(dataRef.current),
            })
          );
        } else {
          dispatch(
            updateEntity({
              _id: entity?._id,
              category_title: values.category_title,
              category_content: textContent,
              category_excerpt: values.category_excerpt,
              category_avatar: srcImageId,
              category_language: languageType,
              category_type: values.category_type,
              category_status: "publish",
              seo_title: values.seo_title,
              seo_description: values.seo_description,
              seo_keyword: values.seo_keyword,
              category_slug: values.category_slug,

              // public_album: JSON.stringify(dataRef.current),
            })
          );
        }
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message =
          e?.response?.data?.title ??
          "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  const Actual_page =
    entity || !Param ? (
      <>
        <Page
          title="News"
          breadcrumbs={[{ content: "Page list", url: "/category" }]}
          primaryAction={{
            content: "Save",
            // disabled: !dirty,
            // loading: updating,
            onAction: submit,
          }}
        >
          <Layout>
            <Layout.Section>
              <Form onSubmit={submit}>
                <Card>
                  <Card.Section>
                    <FormLayout>
                      <TextField
                        autoComplete="off"
                        autoFocus
                        requiredIndicator
                        label="Title"
                        {...fields.category_title}
                      />

                      <div>
                        <span>Content</span>
                        <div className="editor" style={{ minHeight: "500px" }}>
                          <TinyMCE
                            value={`${textContent}` ? `${textContent}` : " "}
                            onChange={(data) => {
                              setTextContent(data);
                            }}
                          />
                        </div>
                      </div>
                    </FormLayout>
                  </Card.Section>
                </Card>
              </Form>
            </Layout.Section>

            <Layout.Section secondary>
              <Card title="Information" sectioned>
                <FormLayout>
                  <TextField
                    requiredIndicator
                    autoComplete="off"
                    maxLength={250}
                    label="Type"
                    {...fields.category_type}
                  />

                  <Select
                    label="Language"
                    options={languages}
                    requiredIndicator
                    onChange={handleLanguageChange}
                    value={languageType}
                  />

                  <TextField
                    autoComplete="off"
                    disabled={!Param ? true : false}
                    maxLength={250}
                    label="Slug"
                    {...fields.category_slug}
                  />
                  <TextField
                    autoComplete="off"
                    maxLength={250}
                    label="Excerpt"
                    {...fields.category_excerpt}
                    multiline={4}
                  />

                  <p>Thumbnail</p>
                  <div id="profile_heading">
                    <div className="news_thumbnail_inner">
                      <div className="news_thumbnail_avatar">
                        <QuickUploadImage
                          onSuccess={handQuickUpdateSuccess}
                          onError={handUploadError}
                        />
                        <img src={srcImage} style={{ width: "115px" }} />
                      </div>
                    </div>
                  </div>
                </FormLayout>
              </Card>

              <Card title="SEO" sectioned>
                <FormLayout>
                  <TextField
                    autoFocus
                    autoComplete="off"
                    maxLength={250}
                    label="Seo Title"
                    {...fields.seo_title}
                  />

                  <TextField
                    autoComplete="off"
                    maxLength={250}
                    label="Seo Description"
                    {...fields.seo_description}
                    multiline={2}
                  />
                  <TextField
                    autoComplete="off"
                    maxLength={250}
                    label="Seo Keyword"
                    {...fields.seo_keyword}
                  />
                </FormLayout>
              </Card>
            </Layout.Section>
          </Layout>
          <PageActions
            primaryAction={{
              content: "Save",
              onAction: submit,
              // disabled: !dirty,
              loading: updating,
            }}
            secondaryActions={
              Param
                ? [
                    {
                      content: "Delete",
                      destructive: true,
                      onAction: _deleteEntityAction,
                    },
                  ]
                : []
            }
          />
        </Page>
      </>
    ) : (
      emptyData
    );

  const _Modal = openModal ? (
    <Modal
      activator={null}
      open={true}
      onClose={() => setOpenModal(false)}
      title="Delete entity?"
      primaryAction={{
        content: "Delete",
        onAction: onModalAgree,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>After delete, you can not undo this action!</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  ) : null;

  return (
    <>
      {_Modal}
      {toastMarkup}
      {loading ? <SkeletonLoading /> : Actual_page}
    </>
  );
}
