import { createSlice } from '@reduxjs/toolkit'

export const toast = createSlice({
  name: 'toast',
  initialState: {
    message: null
  },
  reducers: {
    setMessage: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.message = action.payload
    },
    clearMessage: state => {
      state.message = null
    }
  }
})

// Action creators are generated for each case reducer function
export const { setMessage, clearMessage } = toast.actions

export default toast.reducer