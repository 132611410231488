import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { createNew, deletePodcast, getListPodcast, getListPodcastCategory, updatePodcast, uploadMedia } from "../service";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { DataGrid } from "@mui/x-data-grid";
import { podcastItemData } from "../interface";
import CreatePodcast from "../components/podcast.create";
import CustomSnackbar from "components/snackbar";
import { EmptyState, Modal } from "@shopify/polaris";
import emptyIMG from "../../../media/empty.png";
import '../css/style.css';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '500px',
    maxHeight: '600px',
    overflowY: 'auto',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    bgcolor: 'background.paper',
    borderRadius: '3px',
    p: 2,
  };

function ManagePodcast() {
  const [getDataPostcast, setGetDataPostcast] = useState([]);
  const [getDataPostcastCategory, setGetDataPostcastCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentRow, setCurrentRow] = useState<null | any>(null);
  const [mediaSrc, setMediaSrc] = useState<string>('');
  const [mediaType, setMediaType] = useState<string>('');
  const [openModal, setOpenModal] = useState(false);
  const [listAudio, setListAudio] = useState<any>([])
  const [formData, setFormData] = useState<podcastItemData>({
    title: "", 
          content: "", 
          excerpt: "", 
          post_avatar: "", 
          podcast_type: "string", 
          podcast_status: "string", 
          attach_files: "", 
          podcast_category: "", 
          podcast_language: "vi", 
  })
  const [isUploading, setIsUploading] = useState({
    image: false,
    audio: false
  })
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const [isEdit, setIsEdit] = useState(false);

  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  

  const handleChange = useCallback(() => setOpenModal(!openModal), [openModal]);
  
  const handleFileChangeAndUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploading((isUploading) => {
      return {...isUploading, image: true}
    })
    try {
      const files = event.target.files;
      if (files && files.length > 0) {
        const file = files[0];
        const headers = {
          'X-Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQ1Nzc3MDAsImRhdGEiOnsiX2lkIjoiNjU4MjVhYTQ5ZDY2YWM4YzQ3YzZiMDFkIiwia2V5IjoiOTNiOTgxN2VkZmQ1ZjU1NDBkZTI2MDNiM2M3N2JlZmEiLCJzaWduYXR1cmUiOiI2ZGI5M2RhMzE1YzRjNzBkNjY2YjNiNWRjZjIwYzUzMiIsInNlc3Npb24iOiI2NTgyNWFhNDlkNjZhYzhjNDdjNmIwMWYifSwiaWF0IjoxNzAzMDQxNzAwfQ.R0CrGfSMvi_V3T445vlp75Eetz_x6RHCrWtaSkXo0A8'
        };
        const { id, src } = await uploadMedia(file, headers);

        if (file.type.startsWith('image/')) {
          setFormData((prevFormData) => {
            const updatedFormData = {
              ...prevFormData,
              post_avatar: id
            };
            return updatedFormData;
          });
          setMediaType('image');
        } else if (file.type === 'video/mp4') {
          setFormData((prevFormData) => {
            const updatedFormData = {
              ...prevFormData,
              media_id: id
            };
            return updatedFormData;
          });
          setMediaType('video');
        }
        setMediaSrc(src);
      }
    } catch (error) {
      console.error('Upload failed:', error);
    } finally {
      setIsUploading((isUploading) => {
        return {...isUploading, image: false}
      })
      console.log("Upload complete");
    }
  };
  const handleFileChangeAndUploadAudio = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploading((isUploading) => {
      return {...isUploading, audio: true}
    })
    try {
      const files = event.target.files;
      if (files && files.length > 0) {
        const file = files[0];
        const headers = {
          'X-Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQ1Nzc3MDAsImRhdGEiOnsiX2lkIjoiNjU4MjVhYTQ5ZDY2YWM4YzQ3YzZiMDFkIiwia2V5IjoiOTNiOTgxN2VkZmQ1ZjU1NDBkZTI2MDNiM2M3N2JlZmEiLCJzaWduYXR1cmUiOiI2ZGI5M2RhMzE1YzRjNzBkNjY2YjNiNWRjZjIwYzUzMiIsInNlc3Npb24iOiI2NTgyNWFhNDlkNjZhYzhjNDdjNmIwMWYifSwiaWF0IjoxNzAzMDQxNzAwfQ.R0CrGfSMvi_V3T445vlp75Eetz_x6RHCrWtaSkXo0A8'
        };
        const { id, src } = await uploadMedia(file, headers);
        setListAudio([...listAudio, {id: id, src: src}])
       
      }
    } catch (error) {
      console.error('Upload failed:', error);
    } finally {
      console.log("Upload complete");
      setIsUploading((isUploading) => {
        return {...isUploading, audio: false}
      })
    }
  };
  const handleSubmit = async() => {
    try {
        const cloneData: any = {...formData};
        cloneData.excerpt = cloneData.content;
        cloneData.attach_files = JSON.stringify(listAudio.map((key: any) => key.id))
        let sendRequest = null
        if(isEdit) {
            sendRequest = await updatePodcast(cloneData);
            // update data in state
            const findIndex = getDataPostcast.findIndex((item: any) => item._id == cloneData._id)
            const updateData = JSON.parse(JSON.stringify(getDataPostcast));
            updateData[findIndex] = sendRequest.data;
            setGetDataPostcast(updateData) 
        }else {
            sendRequest =  await createNew(cloneData)
        setGetDataPostcast(prevPodcast => [...prevPodcast, sendRequest.data]);
        }
        setSnackbarMessage(`${isEdit ? 'Sửa': 'Thêm mới'} thành công`);
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setOpenModal(false)
    }
    catch(error) {
        console.log(error);
        setSnackbarMessage(`Bạn vui lòng điền thông tin podcast`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
    }
  }

  const editPodcast = (key: any) => {
        setFormData({...key, podcast_category: key?.podcast_category?._id, post_avatar: key?.post_avatar?._id});
        setMediaType('image');
        setMediaSrc(key.post_avatar?.media_url);
        setListAudio(key.attach_files.map((item: any) => {
            return {
                id: item._id,
                src: item.media_url
            }
        }))
        setOpenModal(true);
        setIsEdit(true);
  }
  const handleChangeCourse = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDeletePodcast = async (id: string) => {
    // if(confirm('Bạn muốn xoá Podcast này không')) {
        try {
            await deletePodcast(id);
            setGetDataPostcast(prevPodcast => prevPodcast.filter(podcast => podcast._id !== id));
            setSnackbarMessage('Xóa thành công');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
          } catch (error) {
            console.error('Failed to delete redeem', error);
            setSnackbarMessage('Xóa thất bại');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          }
    // }
  };
  const columns = [
    { field: "title", headerName: "Tiêu đề", width: 200 },
    { field: "podcast_category", headerName: "Danh mục", width: 200, renderCell: (params) => {
        return params.row?.podcast_category?.category_title
    }},
    { field: "user_id", headerName: "Người đăng", width: 200, renderCell: (params) => {
        return params.row?.user_id?.display_name
    } },
    { field: "content", headerName: "Mô tả", width: 250 },
    {
      field: "actions",
      headerName: "Hành động",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <IconButton
              aria-label="more"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => handleMenuClick(e, params.row)}
            >
              <HiOutlineDotsVertical style={{ color: "#333" }} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open && currentRow && currentRow._id === params.row._id}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => editPodcast(params.row)}>Sửa</MenuItem>
              <MenuItem onClick={() => {
                 if(window.confirm('Bạn muốn xoá Podcast này không')) {
                
                    handleDeletePodcast(params.row._id)
                
                  }
              }}>Xóa</MenuItem>
            </Menu>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const dataPodcast = await getListPodcast();
        const podcastList = dataPodcast.data;
        podcastList.reverse();
        const dataPodcastCategory = await getListPodcastCategory();
        const podcastListCate = dataPodcastCategory.data;
        setGetDataPostcast(podcastList);
        setGetDataPostcastCategory(podcastListCate)
      } catch (error) {
        setLoading(false);
        console.error("Failed to fetch data", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    // detect close modal clean data
    if(!openModal) {
        setFormData({
            title: "", 
                  content: "", 
                  excerpt: "", 
                  post_avatar: "", 
                  podcast_type: "string", 
                  podcast_status: "string", 
                  attach_files: "", 
                  podcast_category: "", 
                  podcast_language: "vi", 
          })
          setMediaSrc('');
          setMediaType('');
          setListAudio([]);
          setIsEdit(false);
    }
  }, [openModal])

  const emptyData = (
    <EmptyState heading="No podcast here!" image={emptyIMG}>
      <p>Oh! There is no podcast here! Try add a new record!</p>
    </EmptyState>
  );
  const removeImageCover = useCallback(() => {

    setFormData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          post_avatar: ''
        };
        return updatedFormData;
      });
      setMediaType('');
    setMediaSrc('');
},[])
  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "1000px",
          margin: "0 auto",
          padding: "30px 0px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">Podcast</Typography>
          <Button
            variant="contained"
            color="success"
            sx={{ fontWeight: 550, height: "45px" }}
            onClick={handleChange}
          >
            Tạo mới Podcast
          </Button>
        </Stack>
        <Box sx={{ padding: "10px 0px" }}>
          {
            getDataPostcast && getDataPostcast.length > 0 ? (
              <DataGrid
            rows={getDataPostcast}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            // checkboxSelection
            getRowId={(row) => row._id}
          />
            ) : emptyData
          }
        </Box>
        <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={()=>setSnackbarOpen(false)}
      />
      </Box>
      <Modal open={openModal} onClose={handleChange} title="">
      <Modal.Section>
      <CreatePodcast
          mediaSrc={mediaSrc}
          mediaType={mediaType}
          handleChangeCourse={handleChangeCourse}
          handleSubmit={handleSubmit}
          formData={formData}
          style={style}
          handleFileChangeAndUpload={handleFileChangeAndUpload}
          handleFileChangeAndUploadAudio={handleFileChangeAndUploadAudio}
          listAudio={listAudio}
          listCategory={getDataPostcastCategory}
          isEdit={isEdit}
          isUploading={isUploading}
          removeImageCover={removeImageCover}
          removeAudio={() => setListAudio([])}
          onClose={() => setOpenModal(false)}
        />
      </Modal.Section>
    </Modal>
    </>
  );
}

export default ManagePodcast;
