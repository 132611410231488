import {
  Badge,
  Button,
  ButtonGroup,
  Caption,
  Card,
  DropZone,
  FormLayout,
  Heading,
  Layout,
  Modal,
  Page,
  ProgressBar,
  Stack,
  TextField,
  TextStyle,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import {
  DeleteMajor,
  DeleteMinor,
  ExportMinor,
  TickSmallMinor,
  LockMinor,
  CirclePlusMajor,
  QuestionMarkInverseMinor,
  AlertMinor,
  ClockMinor,
  EditMinor,
  ArrowDownMinor,
  ResetMinor,
  ArchiveMinor,
  CategoriesMajor,
  CancelSmallMinor,
  MobileCancelMajor,
  NoteMinor,
} from "@shopify/polaris-icons";
import Theme404 from "../../layout/404";
import App_list from "./media.list";
import { useAppDispatch, useAppSelector } from "config/store";
import axios from "axios";
import { getEntities } from "store/media.store.reducer";
import MediaFilter from "../../components/quick_search";
import helpers from "helpers";
/**
 *   Create upload Modal for Medias
 */
export default function MediaUpload(props: any) {
  const [updateProgress, setUpdateProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const dispatch = useAppDispatch();

  const initialQuery = {
    page: 1,
    limit: 100,
  };
  const [mainQuery, setMainQuery] = useState(initialQuery);

  /**
   * OPEN UPLOAD MODAL
   * OPEN UPLOAD MODAL
   * OPEN UPLOAD MODAL
   * OPEN UPLOAD MODAL
   * OPEN UPLOAD MODAL
   * OPEN UPLOAD MODAL
   * OPEN UPLOAD MODAL
   * OPEN UPLOAD MODAL
   * OPEN UPLOAD MODAL
   * OPEN UPLOAD MODAL
   * OPEN UPLOAD MODAL
   * OPEN UPLOAD MODAL
   * OPEN UPLOAD MODAL
   * OPEN UPLOAD MODAL
   * OPEN UPLOAD MODAL
   */
  const [uploadModelactive, setUploadModelactive] = useState(false);

  const toggleUpdateActive = useCallback(() => {
    setUploadModelactive((active) => !active);
    props.onClose();
  }, [uploadModelactive]);

  const [files, setFiles] = useState([]);

  useEffect(() => {
    setUploadModelactive(props.show);
    showUploadModal();
  }, [props]);

  /**
   * Xác định xem file nào đang upload
   */
  const [processing_file, setProcessing_file] = useState(-1);
  let processing_file_error = [];
  //uploadProgress
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      // console.info(_dropFiles)
      setFiles((files) => [...files, ..._dropFiles]);
    },
    []
  );

  function showUploadModal() {
    // setUploadModelactive(true);
    setUpdateProgress(0);
    setFiles([]); // reset form
    setUploading(false);
  }

  const remove_upload_element = (element: number) => {
    if (uploading) return null;
    delete files[element];
    var filtered = files.filter((el) => {
      return el != null;
    });
    setFiles(filtered);
  };

  const start_upload = async () => {
    setUpdateProgress(0);
    setUploading(true);
    let index = -1;
    let clone_files = [...[], ...files];
    for (let element of clone_files) {
      index++;
      setProcessing_file(index);
      if (element.size > 50282820) continue;
      var bodyFormData = new FormData();
      bodyFormData.append("file", element);
      try {
        let Upload_state = await axios.post(
          process.env.REACT_APP_AJAX_UPLOAD_URL,
          bodyFormData,
          {
            onUploadProgress: (progressEvent: any) => {
              let progress_percent =
                Math.floor(progressEvent.loaded / progressEvent.total) * 100;
              setUpdateProgress(progress_percent);
            },
          }
        );
        // upload done? remove it
        delete files[index];
        setFiles(files);
        await helpers.sleep(2000);
        setUpdateProgress(0);
      } catch (error) {
        processing_file_error.push(index);
        //console.info('Error', JSON.stringify(error.response.data.message) );
      }
    }
    if (helpers.isEmpty(processing_file_error)) {
      setUploading(false);
      toggleUpdateActive();
      dispatch(getEntities(mainQuery));
    }
  };

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const uploadedFiles = files.length > 0 && (
    <Stack vertical>
      {files.map((file, index) =>
        file.size < 50282820 ? (
          <Stack alignment="center" key={index}>
            <div
              style={{ width: "10px", cursor: "pointer" }}
              onClick={() => remove_upload_element(index)}
            >
              <MobileCancelMajor />
            </div>
            <Thumbnail
              size="small"
              alt={file.name}
              source={
                validImageTypes.includes(file.type)
                  ? window.URL.createObjectURL(file)
                  : NoteMinor
              }
            />
            <div>
              {helpers.trimMiddleString(file.name, 20, 10)}{" "}
              <Caption>{helpers.bytesToSize(file.size)}</Caption>
              {processing_file === index ? (
                <ProgressBar
                  progress={updateProgress}
                  size="small"
                  color="success"
                />
              ) : null}
            </div>
          </Stack>
        ) : null
      )}
    </Stack>
  );

  return (
    <Modal
      large
      open={uploadModelactive}
      onClose={toggleUpdateActive}
      title={
        uploading
          ? "Do NOT close this modal or refresh your browser!"
          : "Upload files"
      }
      primaryAction={{
        content: "Start upload",
        onAction: start_upload,
        loading: uploading,
      }}
      secondaryActions={[
        {
          content: "Close",
          disabled: uploading,
          onAction: toggleUpdateActive,
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          {!uploading ? (
            <DropZone
              accept="*"
              variableHeight
              errorOverlayText="File type can be image, video, zip, docx, xlsx, ppt"
              type="file"
              label="File should be less than 20MB"
              onDrop={handleDropZoneDrop}
            >
              <DropZone.FileUpload />
            </DropZone>
          ) : null}
          {uploadedFiles}
        </Stack>
      </Modal.Section>
    </Modal>
  );

  /**
   * END UPLOAD FORM
   * END UPLOAD FORM
   * END UPLOAD FORM
   * END UPLOAD FORM
   * END UPLOAD FORM
   * END UPLOAD FORM
   * END UPLOAD FORM
   * END UPLOAD FORM
   * END UPLOAD FORM
   * END UPLOAD FORM
   * END UPLOAD FORM
   * END UPLOAD FORM
   * END UPLOAD FORM
   */
}
