import { Loading } from "@shopify/polaris";
import axios from "axios";
import { useCallback, useState } from "react";

interface IUpload {
  onSuccess: any;
  onError: any;
}
/**
 * Click to upload at the moment, support image only!
 */
export default function Quick_upload_image(props: IUpload) {
  const [loading, setLoading] = useState(0);
  const { onSuccess, onError } = props;

  /**
   * No use :D
   * @param file
   */
  function printFile(file: any) {
    const reader = new FileReader();
    reader.onload = function (evt) {
      console.info(String(evt.target.result));
    };
    reader.readAsDataURL(file);
  }

  /**
   * return to main progress file, callback
   */
  function uploadSuccess(rep: any) {
    onSuccess(rep);
  }

  function uploadFail(rep: any) {
    onError(rep);
  }

  /**
   * Step2. save to server
   *
   * @param {File} file
   */
  async function saveToServer(file: File) {
    const fd = new FormData();
    fd.append("files", file); // append selected file to the bag named 'file'
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-Authorization": localStorage.getItem("session"),
      },
    };
    try {
      let result = await axios.post(
        // process.env.REACT_APP_AJAX_UPLOAD_URL,
        // process.env.REACT_APP_AJAX_UPLOAD_FILE,
        `https://dynamic-admin.iceo.tech/upload-file?callback=${localStorage.getItem(
          "AJAX_URL"
        )}/chat-media/create`,
        fd,
        config
      );

      uploadSuccess(result.data);
      setLoading(0);
    } catch (_) {
      console.log("catch", _);
      setLoading(0);
      uploadFail(_);
    }
  }

  /**
   * Step1. select local image
   *
   */
  function selectLocalImage() {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0];
      // file type is only image.

      if (/^image\//.test(file.type)) {
        saveToServer(file);
      } else {
        return alert("Image only!");
      }
    };
  }

  return (
    <>
      {loading > 0 ? <Loading /> : null}
      <div className="quick_update_wrap">
        <a className="quick_upload" onClick={selectLocalImage}>
          <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
            <path
              d="M3 4.995C3 3.893 3.893 3 4.995 3h14.01C20.107 3 21 3.893 21 4.995v14.01A1.995 1.995 0 0119.005 21H4.995A1.995 1.995 0 013 19.005V4.995zM10.5 16.5L9 15l-3 3h12v-2.7L15 12l-4.5 4.5zM8 10a2 2 0 100-4 2 2 0 000 4z"
              fill="currentColor"
              fillRule="evenodd"
            ></path>
          </svg>
        </a>
      </div>
    </>
  );
}
