import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getCookie } from 'utils/setCookie';
import { CreateGroup, CreateOneOne, ListItemData, ListItemDataUpdate, ListItemDataUpdateModules, ListItemModuleData } from '../interface';

export const getListCourse = async (): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + 'course/v2/list';
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie('session'),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error('Failed to fetch user data');
  }
}

export const getListCourseModule = async (course_id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `course/list-module?order_by=DESC&course_id=${course_id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie('session'),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error('Failed to fetch user data');
  }
}


export const getDetailCourseById = async (id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `course/v2/detail/${id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie('session'),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error('Failed to fetch user data');
  }
}

export const getListCallGroup = async (course_id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `course/class/list?order_by=DESC&course_id=${course_id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie('session'),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error('Failed to fetch user data');
  }
}

export const getTime = async (course_id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `course/one-one/time-available?order_by=DESC&course_id=${course_id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie('session'),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error('Failed to fetch user data');
  }
}


export const getTimeAvailable = async (): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + 'course/teacher/time-available';
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie('session'),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error('Failed to fetch user data');
  }
}


export const createCourse = async (
  price: string,
  title: string,
  avatar: string,
  country: string,
  version: string,
  end_time: string,
  language: string,
  media_id: string,
  media_video: string,
  class_duration: string,
  lession_count: number,
  product_id: string,
  start_time: string,
  description: string,
  trash_status: string,
  public_status: string,
  long_description: string,
  type: string,
  coupon_id: string,
): Promise<any> => {
  const requestData: ListItemData = {
    price,
    title,
    avatar,
    country,
    version,
    end_time,
    language,
    lession_count,
    media_id,
    media_video,
    class_duration,
    product_id,
    start_time,
    description,
    trash_status,
    public_status,
    long_description,
    type,
    coupon_id,
  };
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie('session'),
    },
  };
  const url = process.env.REACT_APP_AJAX_URL + "course/v2/create";
  return axios.post(url, requestData, axiosConfig);
};


export const createCourseModule = async (
  course_id: string,
  title: string,
  media_id: string,
  type: string,
  parent_id: string,

): Promise<any> => {
  const requestData: ListItemModuleData = {
    course_id,
    title,
    media_id,
    type,
    parent_id,
  };
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie('session'),
    },
  };
  const url = process.env.REACT_APP_AJAX_URL + "course/create-module";
  return axios.post(url, requestData, axiosConfig);
};

export const getListTime = async (created_user_id: string): Promise<any> => {
  const requestData = {
    order_by: "DESC",
    created_user_id,
  };

  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie('session'),
    },
  };

  const url = process.env.REACT_APP_AJAX_URL + 'course/my-course';
  return axios.post(url, requestData, axiosConfig);
};


export const createCourseCallOneOne = async (
  user_id: string,
  course_id: string,
  time_available: { day: number; time_end: string; time_start: string; }[]
): Promise<any> => {
  const requestData: CreateOneOne = {
    user_id,
    course_id,
    time_available
  };
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie('session'),
    },
  };
  const url = process.env.REACT_APP_AJAX_URL + "course/one-one/teacher/create";
  return axios.post(url, requestData, axiosConfig);
};


export const createCourseCallGroup = async (
  name: string,
  end_time: string,
  course_id: string,
  start_time: string,
  limit_member: number,
  course_calendars: { day: number; time_start: string; time_duration: number; }[]
): Promise<any> => {
  const requestData: CreateGroup = {
    name,
    end_time,
    course_id,
    start_time,
    limit_member,
    course_calendars
  };
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie('session'),
    },
  };
  const url = process.env.REACT_APP_AJAX_URL + "course/class/create";
  return axios.post(url, requestData, axiosConfig);
};


export const uploadMedia = async (
  file: File,
  headers: AxiosRequestConfig["headers"]
): Promise<any> => {
  const TIMEOUT_DURATION = 30 * 60 * 1000;
  let uploadTimeout: NodeJS.Timeout;

  try {
    const formData = new FormData();
    formData.append('file[]', file);

    const config: AxiosRequestConfig = {
      method: 'post',
      url: `https://media.exam24h.com/upload-file?callback=${process.env.REACT_APP_AJAX_URL}media/create`,
      headers,
      data: formData,
      timeout: TIMEOUT_DURATION 
    };

    // Start the timeout
    uploadTimeout = setTimeout(() => {
      console.error('Upload timed out');
      throw new Error('Upload timed out');
    }, TIMEOUT_DURATION);

    const response = await axios.request(config);

    clearTimeout(uploadTimeout);

    if (response.data && response.data[0] && response.data[0].callback && response.data[0].callback._id && response.data[0].src) {
      return { id: response.data[0].callback._id, src: response.data[0].src };
    } else {
      throw new Error('Upload failed: Invalid response');
    }
  } catch (error) {
    clearTimeout(uploadTimeout); // Clear the timeout in case of error
    throw new Error(`Upload failed: ${error.message}`);
  }
};


export const updateCourse = async (
  _id: string,
  price: string,
  title: string,
  avatar: string,
  country: string,
  version: string,
  end_time: string,
  language: string,
  media_id: string,
  product_id: string,
  start_time: string,
  description: string,
  trash_status: string,
  public_status: string,
  long_description: string,
  product_status: string,
  labels: string,
  level: any,
  skills: string | string[],
  type: string,
  coupon_id: string,
): Promise<any> => {
  const requestData: ListItemDataUpdate = {
    _id,
    price,
    title,
    avatar,
    country,
    version,
    end_time,
    language,
    media_id,
    product_id,
    start_time,
    description,
    trash_status,
    public_status,
    long_description,
    product_status,
    labels,
    level,
    skills,
    type,
    coupon_id,
  };
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie('session'),
    },
  };
  const url = process.env.REACT_APP_AJAX_URL + "course/update";
  return axios.patch(url, requestData, axiosConfig);
};


// export const updateCourseModules = async (
//   _id: string,
//   type: string,
//   title: string,
//   media_id: string,
//   course_id: string,
//   parent_id: string,
// ): Promise<any> => {
//   const requestData: ListItemDataUpdateModules = {
//     _id,
//     type,
//     title,
//     media_id,
//     course_id,
//     parent_id,
//   };
//   const axiosConfig = {
//     headers: {
//       "X-Authorization": getCookie('session'),
//     },
//   };
//   const url = process.env.REACT_APP_AJAX_URL + "course/update-module";
//   return axios.patch(url, requestData, axiosConfig);
// };

export const updateModuleTitle = async (moduleId: string, newTitle: string): Promise<AxiosResponse<any>> => {
  try {
    const response = await axios.patch<any>(
      process.env.REACT_APP_AJAX_URL + "course/update-module",
      {
        _id: moduleId,
        title: newTitle
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating module title:', error);
    throw error;
  }
};


export const updateModuleMedia = async (moduleId: string, mediaId: string): Promise<AxiosResponse<any>> => {
  try {
    const response = await axios.patch<any>(
      process.env.REACT_APP_AJAX_URL + "course/update-module",
      {
        _id: moduleId,
        media_id: mediaId
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating module media:', error);
    throw error;
  }
};

export const AddModuleMedia = async (moduleId: string, mediaId: string): Promise<AxiosResponse<any>> => {
  try {
    const response = await axios.patch<any>(
      process.env.REACT_APP_AJAX_URL + "course/update/module/media",
      {
        id: moduleId,
        media_id: mediaId
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating module media:', error);
    throw error;
  }
};


export const updateCourseTime = async (schedule: any[]): Promise<AxiosResponse<any>> => {
  try {
    const url = process.env.REACT_APP_AJAX_URL + 'course/create/time-available';
    const token = getCookie('session'); 

    const response = await axios.patch(
      url,
      { schedule },
      {
        headers: {
          "X-Authorization": token,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error updating course time:', error);
    throw error;
  }
};

export const deleteCourse = async (id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `course/delete/${id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie('session'),
      },
    };

    return await axios.delete(url, axiosConfig);
  } catch (error) {
    throw new Error('Failed to fetch user data');
  }
}

export const deleteCallGroup = async (id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `course/class/delete/${id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie('session'),
      },
    };

    return await axios.delete(url, axiosConfig);
  } catch (error) {
    throw new Error('Failed to fetch user data');
  }
}