import axios from "axios";
import {
  createAsyncThunk,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import helpers from "helpers";
import {
  IQueryParams,
  createEntitySlice,
  EntityState,
  serializeAxiosError,
} from "config/reducer.utils";
import { ISettings, defaultISettings } from "interface/settings.model";
import { AppThunk } from "config/store";

/**
 *   Reducer used for front-end, with settings.model.ts
 *   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
 */

const initialState = {
  loading: false,
  errorMessage: null,
  message: null,
  entities: null,
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = "config";
const apiUrlChat = "chat-history";

// Actions

/**
 * get all setting, for admin only
 */
export const getEntities = createAsyncThunk(
  "settings/fetch_entity_list",
  async () => {
    const requestUrl = `${apiUrl}/list/system`;
    return axios.get<any>(requestUrl);
  }
);

/**
 * get one record, return string or null
 */
export const getEntity = createAsyncThunk(
  "settings/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/list/${id}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createChat = createAsyncThunk(
  "users/create_entity",
  async (entity: any) => {
    const result = await axios.post<any>(
      `${apiUrlChat}/chat-system`,
      helpers.cleanEntity(entity)
    );

    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  "settings/create_entity",
  async (entity: ISettings, thunkAPI) => {
    const result = await axios.post<ISettings>(
      `${apiUrl}/create`,
      helpers.cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "settings/update_entity",
  async (entity: any, thunkAPI) => {
    const result = await axios.post<any>(
      `${apiUrl}/admin-update`,
      helpers.cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);

/*
export const partialUpdateEntity = createAsyncThunk(
  'settings/partial_update_entity',
  async (entity: ISettings, thunkAPI) => {
    const result = await axios.post<ISettings>(`${apiUrl}/${entity.setting_id}?cacheBuster=${new Date().getTime()}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);
*/

export const deleteEntity = createAsyncThunk(
  "settings/delete_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_Settings = createEntitySlice({
  name: "settings",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(deleteEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = "Can not delete this Entity";
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers["x-total-count"], 10),
        };
      })
      .addMatcher(
        isFulfilled(updateEntity, createChat, createEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.message = "Setting has been saved!";
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(
        isRejected(updateEntity, createChat, createEntity, deleteEntity),
        (state, action) => {
          state.loading = false;
          state.updating = false;
          state.updateSuccess = false;
          state.errorMessage = action.payload;
        }
      )
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(deleteEntity), (state) => {
        state.updating = true;
        state.loading = true;
        state.updateSuccess = false;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(updateEntity, createChat, createEntity),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { clearError, clearMessage, reset } = Reducer_Settings.actions;

// Reducer
export default Reducer_Settings.reducer;
