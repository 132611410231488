/**
 * Config bắt đầu từ đây!
 * Quy định route với: Private, public, separate route ...
 */
import Login from "../layout/login";
import Register from "../layout/register";
import RecoverPassword from "../layout/recover-password";
import Profile from "layout/my-profile";
import Home from "../entities/home";
import Page from "../entities/page";
import Pages from "../entities/pages";
import Users from "../entities/users";
import Media from "../entities/media";
import Chat from "../entities/chat";
import Mission from "../entities/mission"
import Course from "../entities/course"
import Notification from "../entities/notification";
import Dashboard from "../entities/dashboard";
import Settings from "../entities/settings";
import Auto_message from "../entities/vn_message";
import EN_message from "../entities/en_message";
import EmailSetup from "../entities/email_setup";
import EmailTemplate from "../entities/email_template";
import EmailMarketing from "../entities/email_marketing";
import EmailQueue from "../entities/email_queue";
import Banner from "entities/banner";
import HandleService from "entities/handle_service";
import Plan from "entities/plan";
import Subscription from "entities/subscription";
import Order from "entities/order";
import Report from "entities/report";
import Category from "entities/category";
import Type from "entities/type";
import Event from "entities/event";
import Rating from "entities/rating";
import City from "entities/city";
import ChatSystem from "../entities/chat_system";
import News from "../entities/news";
import UserQuestion from "entities/user_question";
import UserInterest from "entities/user_interest";
import Prompt from "entities/prompt";
import Ads from "entities/ads";
import EcoSystem from "entities/eco_system";
import ManagePodcast from "entities/podcast/pages";
import ManagePodcastCategory from "entities/podcast/pages/category";
import ManageTransaction from "entities/transaction";
/**
 * Private, w/ <AppFrame />
 * Only authorization can access this route
 */
export const private_route = [
  {
    path: "/",
    main: Home,
  },
  {
    path: "/page/:pageslug",
    main: Page,
  },
  {
    path: "/dashboard",
    main: Dashboard,
  },
  {
    path: "/dashboard/:any",
    main: Dashboard,
  },
  {
    path: "/profile",
    main: Profile,
  },
  {
    path: "/profile/:user_id",
    main: Profile,
  },
  {
    path: "/settings",
    main: Settings,
  },
  {
    path: "/auto_message",
    main: Auto_message,
  },

  {
    path: "/en_message",
    main: EN_message,
  },

  {
    path: "/email_setup",
    main: EmailSetup,
  },
  {
    path: "/email_template/:slug/:sub_slug",
    main: EmailTemplate,
  },
  {
    path: "/email_template/:slug",
    main: EmailTemplate,
  },
  {
    path: "/email_template",
    main: EmailTemplate,
  },
  {
    path: "/email_marketing",
    main: EmailMarketing,
  },

  {
    path: "/email_queue/:slug/:sub_slug",
    main: EmailQueue,
  },
  {
    path: "/email_queue/:slug",
    main: EmailQueue,
  },
  {
    path: "/email_queue",
    main: EmailQueue,
  },

  {
    path: "/pages",
    main: Pages,
  },
  {
    path: "/pages/:slug/:pages_slug",
    main: Pages,
  },
  {
    path: "/pages/:slug",
    main: Pages,
  },

  {
    path: "/media",
    main: Media,
  },
  {
    path: "/media/:slug/:media_slug",
    main: Media,
  },
  {
    path: "/media/:slug",
    main: Media,
  },

  {
    path: "/chat",
    main: Chat,
  },
  {
    path: "/chat/:slug/:chat_slug",
    main: Chat,
  },
  {
    path: "/chaty/:slug",
    main: Chat,
  },

  {
    path: "/notification",
    main: Notification,
  },
  {
    path: "/notification/:slug/:notification_slug",
    main: Notification,
  },
  {
    path: "/notification/:slug",
    main: Notification,
  },

  {
    path: "/users",
    main: Users,
  },
  {
    path: "/users/:slug/:users_slug",
    main: Users,
  },
  {
    path: "/users/:slug",
    main: Users,
  },

  {
    path: "/banner",
    main: Banner,
  },
  {
    path: "/banner/:slug/:banner_slug",
    main: Banner,
  },
  {
    path: "/banner/:slug",
    main: Banner,
  },

  {
    path: "/handle_service",
    main: HandleService,
  },
  {
    path: "/handle_service/:slug/:handle_service_slug",
    main: HandleService,
  },
  {
    path: "/handle_service/:slug",
    main: HandleService,
  },

  {
    path: "/plan",
    main: Plan,
  },
  {
    path: "/plan/:slug/:plan_slug",
    main: Plan,
  },
  {
    path: "/plan/:slug",
    main: Plan,
  },

  {
    path: "/city",
    main: City,
  },
  {
    path: "/city/:slug/:city_slug",
    main: City,
  },
  {
    path: "/city/:slug",
    main: City,
  },

  {
    path: "/subscription",
    main: Subscription,
  },
  {
    path: "/subscription/:slug/:subscription_slug",
    main: Subscription,
  },
  {
    path: "/subscription/:slug",
    main: Subscription,
  },

  {
    path: "/report",
    main: Report,
  },
  {
    path: "/report/:slug/:report_slug",
    main: Report,
  },
  {
    path: "/report/:slug",
    main: Report,
  },

  {
    path: "/category",
    main: ManagePodcastCategory,
  },
  {
    path: "/category/:slug/:category_slug",
    main: Category,
  },
  {
    path: "/category/:slug",
    main: Category,
  },

  {
    path: "/type",
    main: Type,
  },
  {
    path: "/type/:slug/:type_slug",
    main: Type,
  },
  {
    path: "/type/:slug",
    main: Type,
  },

  {
    path: "/event",
    main: Event,
  },
  {
    path: "/event/:slug/:event_slug",
    main: Event,
  },
  {
    path: "/event/:slug",
    main: Event,
  },

  {
    path: "/rating",
    main: Rating,
  },
  {
    path: "/rating/:slug/:rating_slug",
    main: Rating,
  },
  {
    path: "/rating/:slug",
    main: Rating,
  },

  {
    path: "/news",
    main: News,
  },
  {
    path: "/news/:slug/:news_slug",
    main: News,
  },
  {
    path: "/news/:slug",
    main: News,
  },

  {
    path: "/order",
    main: Order,
  },
  {
    path: "/order/:slug/:order_slug",
    main: Order,
  },
  {
    path: "/order/:slug",
    main: Order,
  },

  {
    path: "/user_question",
    main: UserQuestion,
  },
  {
    path: "/user_question/:slug/:user_question_slug",
    main: UserQuestion,
  },
  {
    path: "/user_question/:slug",
    main: UserQuestion,
  },

  {
    path: "/user_interest",
    main: UserInterest,
  },
  {
    path: "/user_interest/:slug/:user_interest_slug",
    main: UserInterest,
  },
  {
    path: "/user_interest/:slug",
    main: UserInterest,
  },

  {
    path: "/prompt",
    main: Prompt,
  },
  {
    path: "/prompt/:slug/:prompt_slug",
    main: Prompt,
  },
  {
    path: "/prompt/:slug",
    main: Prompt,
  },
  {
    path: "/ads",
    main: Ads,
  },
  {
    path: "/ads/:slug/:ads_slug",
    main: Ads,
  },
  {
    path: "/ads/:slug",
    main: Ads,
  },
  {
    path: "/eco_system",
    main: EcoSystem,
  },

  {
    path: "/chat_system",
    main: ChatSystem,
  },
  {
    path: "/mission",
    main: Mission,
  },
  {
    path: "/course",
    main: Course,
  },
  {
    path: "/podcast",
    main: ManagePodcast
  },
  {
    path: "/transaction",
    main: ManageTransaction
  }
];

/**
 * Any one can access this URL
 */
export const public_route = [];

/**
 * Run first and wihout <AppFrame>,
 * It is like login page, register page
 */

export const separate_route = [
  {
    path: "/login",
    main: Login,
  },
  {
    path: "/register",
    main: Register,
  },
  {
    path: "/recover_password",
    main: RecoverPassword,
  },
];
