import React from 'react';

import { Card, EmptyState } from '@shopify/polaris';
import Image404 from '../media/404.svg';


//SEO({title: "Not Found!", description: "Sorry we can not found this page!"});

function PageNotFound() {
    return (
      <div>
          <EmptyState
            heading="404 not found!"
            image={Image404}
          >
            <p>Wow! Perhaps something is going wrong!</p>
          </EmptyState>
      </div>
    );
}

export default PageNotFound;
