import { Component, useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";

interface MCEConfig {
  value: string;
  onChange: any;
}

const REACT_APP_AJAX_UPLOAD_URL = process.env.REACT_APP_AJAX_UPLOAD_URL;
const REACT_APP_AJAX_DOWNLOAD_IMAGE = process.env.REACT_APP_AJAX_UPLOAD_IMAGE;

export const TinyMCE = (props: MCEConfig) => {
  const { onChange, value } = props;

  const [initialValue, setInitialValue] = useState<string>("");
  const [countSetValue, setCountSetValue] = useState<number>(1);
  useEffect(() => {
    if (value.trim()) {
      if (countSetValue === 1) {
        setInitialValue(value);
        setCountSetValue(countSetValue + 1);
      }
    }
  }, [value]);
  return (
    // @ts-ignore
    <Editor
      onEditorChange={(value, editor) => onChange(value)}
      initialValue={initialValue ? initialValue : ""}
      apiKey="essg033gpylrput49xgcnu9loj1p7u6x8ju14i7v6uzu2x1v"
      init={{
        height: 850,
        paste_data_images: true,
        menubar: true,
        fontsize_formats:
          "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
        plugins: "   image  autolink lists  media  table link",
        toolbar:
          "undo redo | formatselect | bold italic backcolor fontsizeselect | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent  | help | image | link code paste",
        //@ts-ignore
        images_upload_handler: function (blobInfo, success: any, failure: any) {
          // const config = {
          //   headers: {
          //     "Content-Type": "application/x-www-form-urlencoded",
          //     "X-Authorization": localStorage.getItem("session"),
          //   },
          // };

          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              "X-Authorization": localStorage.getItem("session"),
            },
          };

          const data = new FormData();

          data.append("file", blobInfo.blob());
          axios
            .post(
              // `${REACT_APP_AJAX_UPLOAD_URL}/upload-media?callback=https://devapi2.lgbt.appuni.io/api/chat-media/create`,
              // `${REACT_APP_AJAX_UPLOAD_URL}/upload-media`,
              `https://dynamic-admin.iceo.tech/upload-media?callback=${localStorage.getItem(
                "AJAX_URL"
              )}/chat-media/create`,
              data,
              config
            )
            .then((response) => {
              success(`${response?.data[0]?.thumbnail}`);
            })
            .catch((error) => {
              failure("HTTP Error: " + error.message);
            });
        },
        file_picker_types: "file image media",
        // content_style: "",
      }}
    />
  );
};
