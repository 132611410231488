// @ts-nocheck
process.env.TZ = "Asia/Ho_Chi_Minh";
var Promise = require("bluebird");

export default class GET {
  /* format 2 số 5 thành 05 */
  getPad = (n) => {
    return ("0" + n).slice(-2); // '04'
  };

  /*
   *	Trimmmm
   */
  getTrim = (s) => {
    return String(s || " ").trim();
  };

  /**
   * Đếm độ dài của cả Unicode luôn!
   * @McJamBi  from Jamviet.com
   */
  getStringLength = (s) => {
    try {
      let Str = String(s || " ");
      return [...Str].length;
    } catch (_) {
      return 0;
    }
  };

  /**
   * Check if passwords length enought and streng enought
   * you should take this password if it has strength point more than 2.
   * @param _password
   */
  getPasswordStrength = (_password) => {
    let strength = 0;
    let password = String(_password || " ");
    if (password.match(/[a-z]+/)) {
      strength += 1;
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1;
    }
    if (password.match(/[0-9]+/)) {
      strength += 1;
    }
    if (password.match(/[$@#&!]+/)) {
      strength += 1;
    }
    return strength;
  };

  /**
   * Get random hash from char and Number
   * @param {*} XLength
   * @returns
   */
  getRandomHash = (XLength) => {
    var text = "";

    // Default is 10;
    var Length = XLength ? parseInt(XLength) : 10;

    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (var i = 0; i < Length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return String(text);
  };

  /**
   * Get a random number only
   * @param {*} XLength
   * @returns
   */
  getRandomNumber = (XLength) => {
    var text = "";
    // Default is 10;
    var Length = XLength ? parseInt(XLength) : 10;

    var possible = "1234567890";
    for (var i = 0; i < Length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return String(text);
  };

  /**
   * MD5, auto generate if no string
   * @param {*} s
   * @returns MD5
   */
  MD5 = function (s) {
    if (s === void 0) s = Math.random();
    function L(k, d) {
      return (k << d) | (k >>> (32 - d));
    }
    function K(G, k) {
      var I, d, F, H, x;
      F = G & 2147483648;
      H = k & 2147483648;
      I = G & 1073741824;
      d = k & 1073741824;
      x = (G & 1073741823) + (k & 1073741823);
      if (I & d) {
        return x ^ 2147483648 ^ F ^ H;
      }
      if (I | d) {
        if (x & 1073741824) {
          return x ^ 3221225472 ^ F ^ H;
        } else {
          return x ^ 1073741824 ^ F ^ H;
        }
      } else {
        return x ^ F ^ H;
      }
    }
    function r(d, F, k) {
      return (d & F) | (~d & k);
    }
    function q(d, F, k) {
      return (d & k) | (F & ~k);
    }
    function p(d, F, k) {
      return d ^ F ^ k;
    }
    function n(d, F, k) {
      return F ^ (d | ~k);
    }
    function u(G, F, aa, Z, k, H, I) {
      G = K(G, K(K(r(F, aa, Z), k), I));
      return K(L(G, H), F);
    }
    function f(G, F, aa, Z, k, H, I) {
      G = K(G, K(K(q(F, aa, Z), k), I));
      return K(L(G, H), F);
    }
    function D(G, F, aa, Z, k, H, I) {
      G = K(G, K(K(p(F, aa, Z), k), I));
      return K(L(G, H), F);
    }
    function t(G, F, aa, Z, k, H, I) {
      G = K(G, K(K(n(F, aa, Z), k), I));
      return K(L(G, H), F);
    }
    function e(G) {
      var Z;
      var F = G.length;
      var x = F + 8;
      var k = (x - (x % 64)) / 64;
      var I = (k + 1) * 16;
      var aa = Array(I - 1);
      var d = 0;
      var H = 0;
      while (H < F) {
        Z = (H - (H % 4)) / 4;
        d = (H % 4) * 8;
        aa[Z] = aa[Z] | (G.charCodeAt(H) << d);
        H++;
      }
      Z = (H - (H % 4)) / 4;
      d = (H % 4) * 8;
      aa[Z] = aa[Z] | (128 << d);
      aa[I - 2] = F << 3;
      aa[I - 1] = F >>> 29;
      return aa;
    }
    function B(x) {
      var k = "",
        F = "",
        G,
        d;
      for (d = 0; d <= 3; d++) {
        G = (x >>> (d * 8)) & 255;
        F = "0" + G.toString(16);
        k = k + F.substr(F.length - 2, 2);
      }
      return k;
    }
    function J(k) {
      k = k.replace(/rn/g, "n");
      var d = "";
      for (var F = 0; F < k.length; F++) {
        var x = k.charCodeAt(F);
        if (x < 128) {
          d += String.fromCharCode(x);
        } else {
          if (x > 127 && x < 2048) {
            d += String.fromCharCode((x >> 6) | 192);
            d += String.fromCharCode((x & 63) | 128);
          } else {
            d += String.fromCharCode((x >> 12) | 224);
            d += String.fromCharCode(((x >> 6) & 63) | 128);
            d += String.fromCharCode((x & 63) | 128);
          }
        }
      }
      return d;
    }
    var C = Array();
    var P, h, E, v, g, Y, X, W, V;
    var S = 7,
      Q = 12,
      N = 17,
      M = 22;
    var A = 5,
      z = 9,
      y = 14,
      w = 20;
    var o = 4,
      m = 11,
      l = 16,
      j = 23;
    var U = 6,
      T = 10,
      R = 15,
      O = 21;
    s = J(s);
    C = e(s);
    Y = 1732584193;
    X = 4023233417;
    W = 2562383102;
    V = 271733878;
    for (P = 0; P < C.length; P += 16) {
      h = Y;
      E = X;
      v = W;
      g = V;
      Y = u(Y, X, W, V, C[P + 0], S, 3614090360);
      V = u(V, Y, X, W, C[P + 1], Q, 3905402710);
      W = u(W, V, Y, X, C[P + 2], N, 606105819);
      X = u(X, W, V, Y, C[P + 3], M, 3250441966);
      Y = u(Y, X, W, V, C[P + 4], S, 4118548399);
      V = u(V, Y, X, W, C[P + 5], Q, 1200080426);
      W = u(W, V, Y, X, C[P + 6], N, 2821735955);
      X = u(X, W, V, Y, C[P + 7], M, 4249261313);
      Y = u(Y, X, W, V, C[P + 8], S, 1770035416);
      V = u(V, Y, X, W, C[P + 9], Q, 2336552879);
      W = u(W, V, Y, X, C[P + 10], N, 4294925233);
      X = u(X, W, V, Y, C[P + 11], M, 2304563134);
      Y = u(Y, X, W, V, C[P + 12], S, 1804603682);
      V = u(V, Y, X, W, C[P + 13], Q, 4254626195);
      W = u(W, V, Y, X, C[P + 14], N, 2792965006);
      X = u(X, W, V, Y, C[P + 15], M, 1236535329);
      Y = f(Y, X, W, V, C[P + 1], A, 4129170786);
      V = f(V, Y, X, W, C[P + 6], z, 3225465664);
      W = f(W, V, Y, X, C[P + 11], y, 643717713);
      X = f(X, W, V, Y, C[P + 0], w, 3921069994);
      Y = f(Y, X, W, V, C[P + 5], A, 3593408605);
      V = f(V, Y, X, W, C[P + 10], z, 38016083);
      W = f(W, V, Y, X, C[P + 15], y, 3634488961);
      X = f(X, W, V, Y, C[P + 4], w, 3889429448);
      Y = f(Y, X, W, V, C[P + 9], A, 568446438);
      V = f(V, Y, X, W, C[P + 14], z, 3275163606);
      W = f(W, V, Y, X, C[P + 3], y, 4107603335);
      X = f(X, W, V, Y, C[P + 8], w, 1163531501);
      Y = f(Y, X, W, V, C[P + 13], A, 2850285829);
      V = f(V, Y, X, W, C[P + 2], z, 4243563512);
      W = f(W, V, Y, X, C[P + 7], y, 1735328473);
      X = f(X, W, V, Y, C[P + 12], w, 2368359562);
      Y = D(Y, X, W, V, C[P + 5], o, 4294588738);
      V = D(V, Y, X, W, C[P + 8], m, 2272392833);
      W = D(W, V, Y, X, C[P + 11], l, 1839030562);
      X = D(X, W, V, Y, C[P + 14], j, 4259657740);
      Y = D(Y, X, W, V, C[P + 1], o, 2763975236);
      V = D(V, Y, X, W, C[P + 4], m, 1272893353);
      W = D(W, V, Y, X, C[P + 7], l, 4139469664);
      X = D(X, W, V, Y, C[P + 10], j, 3200236656);
      Y = D(Y, X, W, V, C[P + 13], o, 681279174);
      V = D(V, Y, X, W, C[P + 0], m, 3936430074);
      W = D(W, V, Y, X, C[P + 3], l, 3572445317);
      X = D(X, W, V, Y, C[P + 6], j, 76029189);
      Y = D(Y, X, W, V, C[P + 9], o, 3654602809);
      V = D(V, Y, X, W, C[P + 12], m, 3873151461);
      W = D(W, V, Y, X, C[P + 15], l, 530742520);
      X = D(X, W, V, Y, C[P + 2], j, 3299628645);
      Y = t(Y, X, W, V, C[P + 0], U, 4096336452);
      V = t(V, Y, X, W, C[P + 7], T, 1126891415);
      W = t(W, V, Y, X, C[P + 14], R, 2878612391);
      X = t(X, W, V, Y, C[P + 5], O, 4237533241);
      Y = t(Y, X, W, V, C[P + 12], U, 1700485571);
      V = t(V, Y, X, W, C[P + 3], T, 2399980690);
      W = t(W, V, Y, X, C[P + 10], R, 4293915773);
      X = t(X, W, V, Y, C[P + 1], O, 2240044497);
      Y = t(Y, X, W, V, C[P + 8], U, 1873313359);
      V = t(V, Y, X, W, C[P + 15], T, 4264355552);
      W = t(W, V, Y, X, C[P + 6], R, 2734768916);
      X = t(X, W, V, Y, C[P + 13], O, 1309151649);
      Y = t(Y, X, W, V, C[P + 4], U, 4149444226);
      V = t(V, Y, X, W, C[P + 11], T, 3174756917);
      W = t(W, V, Y, X, C[P + 2], R, 718787259);
      X = t(X, W, V, Y, C[P + 9], O, 3951481745);
      Y = K(Y, h);
      X = K(X, E);
      W = K(W, v);
      V = K(V, g);
    }
    var i = B(Y) + B(X) + B(W) + B(V);
    return String(i || "")?.toLowerCase();
  };

  /**
   * Sleep for a while, miniseconds
   * @param {*} ms
   * @returns
   */
  sleep = function (ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  /**
   * Cắt content ra thành độ dài mong muốn, thêm dấu 3 chấm vào cho oách!
   * @McJamBi  from                     Jamviet.com
   * @DateTime 2021-07-31T19:53:03+0700
   * (text): String, (maxLength): Number of string length
   */
  getTrimContent = (text, maxLength) => {
    var ellipsis = "...";
    var text = String(text || " ").trim();
    var maxLength = isNaN(maxLength) ? 15 : parseInt(maxLength);

    if (text.length > maxLength) {
      text = text.substring(0, maxLength - ellipsis.length);
      return text.substring(0, text.lastIndexOf(" ")) + ellipsis;
    } else return text;
  };

  /**
   * Strip all slashes from a string
   * @param {*} str
   * @returns
   */
  getStripslashes = (str) => {
    if (typeof str === "undefined") str = " ";
    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   improved by: Ates Goral (http://magnetiq.com)
    // +      fixed by: Mick@el
    // +   improved by: marrtins
    // +   bugfixed by: Onno Marsman
    // +   improved by: rezna
    // +   input by: Rick Waldron
    // +   reimplemented by: Brett Zamir (http://brett-zamir.me)
    // +   input by: Brant Messenger (http://www.brantmessenger.com/)
    // +   bugfixed by: Brett Zamir (http://brett-zamir.me)
    // *     example 1: stripslashes('Kevin\'s code');
    // *     returns 1: "Kevin's code"
    // *     example 2: stripslashes('Kevin\\\'s code');
    // *     returns 2: "Kevin\'s code"

    return (str + "").replace(/\\(.?)/g, function (s, n1) {
      switch (n1) {
        case "\\":
          return "\\";
        case "0":
          return "\u0000";
        case "":
          return "";
        default:
          return n1;
      }
    });
  };

  /**
   * Convert UTF to ASCII
   * @param {*} string
   * @param {*} special
   * @returns
   */
  getNonUnicode = (_string: string, special?: boolean) => {
    var str = String(_string || " ").trim();
    if (str === "") return "";

    var unicode_char = {
      a: "á|à|ả|ã|ạ|ă|ắ|ặ|ằ|ẳ|ẵ|â|ấ|ầ|ẩ|ẫ|ậ",
      d: "đ",
      e: "é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ",
      i: "í|ì|ỉ|ĩ|ị",
      o: "ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ",
      u: "ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự",
      y: "ý|ỳ|ỷ|ỹ|ỵ",
      A: "Á|À|Ả|Ã|Ạ|Ă|Ắ|Ặ|Ằ|Ẳ|Ẵ|Â|Ấ|Ầ|Ẩ|Ẫ|Ậ",
      D: "Đ",
      E: "É|È|Ẻ|Ẽ|Ẹ|Ê|Ế|Ề|Ể|Ễ|Ệ",
      I: "Í|Ì|Ỉ|Ĩ|Ị",
      O: "Ó|Ò|Ỏ|Õ|Ọ|Ô|Ố|Ồ|Ổ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ở|Ỡ|Ợ",
      U: "Ú|Ù|Ủ|Ũ|Ụ|Ư|Ứ|Ừ|Ử|Ữ|Ự",
      Y: "Ý|Ỳ|Ỷ|Ỹ|Ỵ",
    };
    var unicode_map = {};
    for (var name in unicode_char) {
      var value = unicode_char[name].split("|");
      for (var name_u in value) {
        if (value[name_u]) {
          unicode_map[value[name_u]] = name;
        }
      }
    }

    if (special === void 0) {
      special = false;
    }

    str = str.replace(/[^\x00-\x80]/g, function (a) {
      return unicode_map[a] ? unicode_map[a] : a;
    });
    if (special === true) {
      str = str.replace(/[^a-zA-Z0-9]/g, "-");
      for (var i = 0; i <= 10; i++) {
        str = str.replace("--", "-");
      }
      str = str?.toLowerCase();
    }
    return String(str || " ")?.toLowerCase();
  };

  /**
   * Convert title to name or slug like: title-like-this
   * @param {*} slug
   * @returns slug
   */
  getSlug = (slug: string) => {
    if (slug === void 0) return "";
    if (slug === "") return "";
    try {
      slug = decodeURIComponent(String(slug || " "));
      slug = this.getNonUnicode(slug).replace(/[^a-zA-Z0-9]/g, " ");
      slug = String(slug).trim().replace(/\s/g, "-")?.toLowerCase();
      slug = String(slug).replace(/\-+/g, "-")?.toLowerCase();
      return slug;
    } catch (_) {
      return "";
    }
  };

  /**
   * Convert unicode symbols to string
   * @param {*} text
   * @returns
   */
  unicodeToChar = (text) => {
    return text.replace(/\\u[\dA-F]{4}/gi, function (match) {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
    });
  };

  /**
   * slugify
   * @param {*} srcPath
   * @returns
   */

  slugify = (_string) => {
    var ss = this.unicodeToChar(_string);
    return this.getSlug(ss);
  };

  /**
   * Get all directory in the path
   * @param {*} srcPath eg: User/mcjambi/src/
   * @returns array of directory
   */
  getDirList = (srcPath) => {
    const Dirr = fs.readdirSync(srcPath).filter((file) => fs.statSync(path.join(srcPath, file)).isDirectory());
    return Dirr.filter((file) => {
      if (/[^\u0000-\u007F]/.test(file)) return false;
      if (file.match(/[^a-zA-Z0-9\:\[\]_-]/g)) return false;
      return true;
    });
  };
  /**
   * recursive directory search in entities, and map it to route
   * @param {*} srcPath
   * @returns
   */
  getDirListRecursive = (srcPath) => {
    const get = (p) => {
      return fs.readdirSync(p).filter((file) => fs.statSync(path.join(p, file)).isDirectory());
    };
    const Dirr = get(srcPath);
    let all_dir = [...Dirr];
    for (let k of Dirr) {
      try {
        let subp = path.join(srcPath, k);
        if (fs.statSync(subp).isDirectory()) {
          let subb = get(subp);
          if (subb.length > 0) {
            if (Array.isArray(subb))
              all_dir = [
                ...all_dir,
                ...subb.map((e) => {
                  return k + "/" + e;
                }),
              ];
            else all_dir.push(k + "/" + subb);
          }
        }
      } catch (_) {
        console.log(_);
      }
    }
    return all_dir.filter((file) => {
      if (/[^\u0000-\u007F]/.test(file)) return false;
      if (file.match(/[^a-zA-Z0-9\@\:\_\-\/]/g)) return false;
      return true;
    });
  };

  /**
   * Get all file in a directory
   * @param {*} srcPath eg: User/mcjambi/src/
   * @returns array of file
   */
  getFileList = (srcPath) => fs.readdirSync(srcPath).filter((file) => fs.statSync(path.join(srcPath, file)).isFile());

  /*
		Tiền ...
	*/
  formatMoney = function (n, c, d, t) {
    var c = isNaN((c = Math.abs(c))) ? 2 : c,
      d = d == undefined ? "." : d,
      t = t == undefined ? "," : t,
      s = n < 0 ? "-" : "",
      i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
      j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      s +
      (j ? i.substr(0, j) + t : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
      (c
        ? d +
          Math.abs(n - i)
            .toFixed(c)
            .slice(2)
        : "")
    );
  };
}

