import {
  Button,
  Card,
  Collapsible,
  EmptyState,
  Form,
  FormLayout,
  Layout,
  Modal,
  Page,
  PageActions,
  RadioButton,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import { MobilePlusMajor } from "@shopify/polaris-icons";

import emptyIMG from "../../media/empty.png";
import {
  clearError,
  getEntity,
  updateEntity,
  deleteEntity,
  createEntity,
  reset,
} from "../../store/ads.store.reducer";
import {
  lengthLessThan,
  lengthMoreThan,
  useField,
  useForm,
  notEmpty,
} from "@shopify/react-form";
import helpers from "helpers";
import QuickUploadImage from "components/oneclick-upload";
import uploadimage from "../../media/choose-file.png";
import {
  getEntities as getCategories,
  getEntity as getCategory,
} from "../../store/category.store.reducer";

export default function Edit_ads() {
  const entity = useAppSelector((state) => state.ads.entity);
  const updating = useAppSelector((state) => state.ads.updating);
  const entities = useAppSelector((state) => state.ads.entities);
  const loading = useAppSelector((state) => state.ads.loading);
  const errorMessage = useAppSelector((state) => state.ads.errorMessage);
  const updateSuccess = useAppSelector((state) => state.ads.updateSuccess);

  const entitycategory = useAppSelector((state) => state.category.entity);
  const entitycategories = useAppSelector((state) => state.category.entities);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  // jamviet.com
  const [message, setMessage] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const [notification, setNotification] = useState(null);

  const toggleActive = useCallback(() => {
    setMessage(null);
    dispatch(clearError());
  }, []);

  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.ads_slug || false;

  useEffect(() => {
    if (Param) dispatch(getEntity(Param));
    else dispatch(reset());
  }, []);

  const [optionContent, setOptionContent] = useState<any>(null);
  useEffect(() => {
    if (entity && typeof entity?.config?.option_content !== "undefined") {
      setOptionContent(entity?.config?.option_content);
    } else {
      setOptionContent(null);
    }
  }, [entity]);

  /**
   * Modal  -------------------------------
   */

  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const ModalAddContent = () => {
    setShowEditModal(true);
  };

  const closeModal = useCallback(() => {
    setShowEditModal((active) => !active);
  }, [showEditModal]);

  const [valueContent, setValueContent] = useState("");
  const handleChangeValue = useCallback((newValue) => {
    setValueContent(newValue);
  }, []);

  const [keyContent, setKeyContent] = useState("");

  const handleChangeKey = useCallback(
    (newValue) => setKeyContent(newValue),
    []
  );

  const saveForm = () => {
    let newContent = [];
    if (optionContent) {
      newContent = [
        ...optionContent,
        ...[{ key: keyContent, value: valueContent }],
      ];
    } else {
      newContent = [{ key: keyContent, value: valueContent }];
    }

    setOptionContent(newContent);
    setValueContent("");
    setKeyContent("");
    setShowEditModal(false);
  };

  const contentModal = (
    <Modal
      open={showEditModal}
      onClose={closeModal}
      title="View Notification"
      titleHidden
      primaryAction={{
        content: "Save",
        onAction: saveForm,
      }}
      secondaryActions={[
        {
          content: "Close",
          onAction: closeModal,
        },
      ]}
    >
      <Modal.Section>
        {" "}
        <FormLayout>
          <FormLayout.Group condensed>
            <TextField
              label="Key"
              value={keyContent}
              onChange={handleChangeKey}
              autoComplete="off"
            />
            <TextField
              label="Value"
              value={valueContent}
              onChange={handleChangeValue}
              autoComplete="off"
            />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  function handChangeKeyChange(e: any, _id: any) {
    let newContent = [];

    optionContent.map((element, index) => {
      if (index === _id) {
        element = {
          ...element,
          ...{
            key: e,
          },
        };
      }
      newContent.push(element);
    });

    setOptionContent(newContent);
  }

  function handChangeValueChange(e: any, _id: any) {
    let newContent = [];

    optionContent.map((element, index) => {
      if (index === _id) {
        element = {
          ...element,
          ...{
            value: e,
          },
        };
      }
      newContent.push(element);
    });

    setOptionContent(newContent);
  }

  function handChangeDescriptionChange(e: any, _id: any) {
    let newContent = [];

    optionContent.map((element, index) => {
      if (index === _id) {
        element = {
          ...element,
          ...{
            description: e,
          },
        };
      }
      newContent.push(element);
    });

    setOptionContent(newContent);
  }

  useEffect(() => {
    if (updateSuccess) {
      history("/ads/edit/" + entity?._id, {
        replace: true,
      });
      dispatch(getEntity(Param));

      setNotification("This ads has been updated!");
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (errorMessage) {
      setNotification(errorMessage);
      dispatch(clearError());
    }
  }, [errorMessage]);

  function _deleteEntityAction() {
    setOpenModal(true);
  }

  function onModalAgree() {
    dispatch(deleteEntity(Param));
    setOpenModal(false);
  }

  const useFields = {};

  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset,
    submitErrors,
    makeClean,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        dispatch(
          updateEntity({
            _id: entity?.config?._id,
            type: "ads",
            option_content: JSON.stringify(optionContent),
            // data_filter: JSON.stringify(dataFilter),
          })
        );

        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message =
          e?.response?.data?.title ??
          "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  const emptyData = (
    <Page title="Ads" breadcrumbs={[{ content: "Ads list", url: "/ads" }]}>
      <EmptyState heading="No post here!" image={emptyIMG}>
        <p>This record maybe not exist!</p>
      </EmptyState>
    </Page>
  );

  const toastMarkup = notification ? (
    <Toast content={notification} onDismiss={toggleActive} />
  ) : null;

  const toastNotification = message ? (
    <Toast content={message} onDismiss={toggleActive} />
  ) : null;

  const Actual_page =
    entity || !Param ? (
      <>
        <Page
          fullWidth
          title="Ads"
          breadcrumbs={[{ content: "Ads list", url: "/ads" }]}
          primaryAction={{
            content: "Save",
            // disabled: !dirty,
            loading: updating,
            onAction: submit,
          }}
        >
          <Layout>
            <Layout.Section>
              <Form onSubmit={submit}>
                <Card>
                  <Card.Section title="Option Content (Cài đặt hệ thống)">
                    {optionContent &&
                      optionContent.map(
                        // ({ key, value, description, config_type, _id }) => {
                        (ele, idx) => {
                          return (
                            <>
                              <FormLayout.Group condensed>
                                <TextField
                                  key={ele?._id + "key"}
                                  label="Key"
                                  value={ele?.key}
                                  onChange={(e) => handChangeKeyChange(e, idx)}
                                  autoComplete="off"
                                />

                                <TextField
                                  key={ele?._id + "value"}
                                  label="Value"
                                  value={ele?.value}
                                  multiline={true}
                                  // onChange={() => {}}
                                  onChange={(e) =>
                                    handChangeValueChange(e, idx)
                                  }
                                  autoComplete="off"
                                />

                                <TextField
                                  key={ele?._id + "description"}
                                  label="Description"
                                  value={ele?.description}
                                  multiline={true}
                                  // onChange={() => {}}
                                  onChange={(e) =>
                                    handChangeDescriptionChange(e, idx)
                                  }
                                  autoComplete="off"
                                />

                                {/* <TextField
                                key={_id + "config_type"}
                                label="Type"
                                value={config_type}
                                multiline={true}
                                // onChange={() => {}}
                                onChange={(e) => handChangeTypeChange(e, _id)}
                                autoComplete="off"
                              /> */}
                              </FormLayout.Group>
                            </>
                          );
                        }
                      )}

                    <br />
                    {contentModal}
                    <Button primary onClick={ModalAddContent}>
                      Add
                    </Button>
                  </Card.Section>
                </Card>
              </Form>
            </Layout.Section>
          </Layout>
          <PageActions
            primaryAction={{
              content: "Save",
              onAction: submit,
              // disabled: !dirty,
              loading: updating,
            }}
            secondaryActions={
              Param
                ? [
                    {
                      content: "Delete",
                      destructive: true,
                      onAction: _deleteEntityAction,
                    },
                  ]
                : []
            }
          />
        </Page>
      </>
    ) : (
      emptyData
    );

  const skeleton_loading = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const _Modal = openModal ? (
    <Modal
      activator={null}
      open={true}
      onClose={() => setOpenModal(false)}
      title="Delete entity?"
      primaryAction={{
        content: "Delete",
        onAction: onModalAgree,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>After delete, you can not undo this action!</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  ) : null;

  return (
    <>
      {_Modal}
      {toastMarkup}
      {toastNotification}
      {loading ? skeleton_loading : Actual_page}
    </>
  );
}
