import {
  Autocomplete,
  Card,
  DatePicker,
  DisplayText,
  EmptyState,
  Form,
  FormLayout,
  Icon,
  Layout,
  Modal,
  Page,
  PageActions,
  RadioButton,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";

import emptyIMG from "../../media/empty.png";
import {
  clearError,
  getEntity,
  updateEntity,
  deleteEntity,
  createEntity,
  reset,
} from "../../store/event.store.reducer";

import { getEntities as getCities, getEntity as getCity, searchCities } from "store/city.store.reducer";

import { lengthLessThan, lengthMoreThan, useField, useForm, notEmpty } from "@shopify/react-form";
import helpers from "helpers";
import countriesData from "components/countryData";

import { getEntities as getCategoires, getEntity as getCategory } from "../../store/category.store.reducer";
import { getEntities as getTypes, getEntity as getType } from "../../store/type.store.reducer";

import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { DomainsMajor, GlobeMinor, SearchMinor } from "@shopify/polaris-icons";

export default function Edit_event() {
  const entity = useAppSelector((state) => state.event.entity);
  const updating = useAppSelector((state) => state.event.updating);
  const entities = useAppSelector((state) => state.event.entities);
  const loading = useAppSelector((state) => state.event.loading);
  const errorMessage = useAppSelector((state) => state.event.errorMessage);
  const updateSuccess = useAppSelector((state) => state.event.updateSuccess);

  const entitycategory = useAppSelector((state) => state.category.entity);
  const entitycategories = useAppSelector((state) => state.category.entities);

  const entitytype = useAppSelector((state) => state.type.entity);
  const entitytypes = useAppSelector((state) => state.type.entities);

  const entitycity = useAppSelector((state) => state.city.entity);
  const entitycities = useAppSelector((state) => state.city.entities);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  // jamviet.com
  const [message, setMessage] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const [notification, setNotification] = useState(null);

  const toggleActive = useCallback(() => {
    setMessage(null);
    dispatch(clearError());
  }, []);

  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.event_slug || false;

  const markerIcon = new L.Icon({
    iconUrl: require("../../media/marker.png"),
    iconSize: [35, 45],
    iconAnchor: [17, 46],
    popupAnchor: [0, -46],
  });

  const [center, setCenter] = useState({ lat: 20.993096509742454, lng: 105.78273504420798 });
  const ZOOM_LEVEL = 15;

  const markerRef = useRef(null);
  const [map, setMap] = useState(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setCenter(marker.getLatLng());
        }
      },
    }),
    []
  );

  const showMyLocation = useCallback(() => {
    // map.flyTo(center, ZOOM_LEVEL);
    map.setView(center, ZOOM_LEVEL);
  }, [map]);

  /**
   * Create city
   */

  const [inputValueCities, setInputValueCities] = useState("");
  const [optionCities, setOptionCities] = useState([]);
  const [selectedOptionCities, setSelectedOptionCities] = useState([]);
  const [cityId, setCityId] = useState("");

  useEffect(() => {
    dispatch(getCategoires({}));
    dispatch(getTypes({}));

    if (Param) dispatch(getEntity(Param));
    else dispatch(reset());
  }, []);

  /**
   * Coutry
   */

  const [country_selected, setCountry_selected] = useState("");
  const option_country = countriesData?.countriesData;

  const changeHandler = (value) => {
    setCountry_selected(value);
  };

  /* Date picker */
  function pad(num: number, size = 2) {
    let numbr = num.toString();
    while (numbr.length < size) numbr = "0" + numbr;
    return numbr;
  }

  /**
   * Format date and time start
   */
  function extractTimeAndDateFromSource(source: string) {
    let DateObject = new Date();
    try {
      if (source) {
        DateObject = new Date(String(source));
      }
      const _date =
        pad(DateObject.getDate(), 2) + "/" + pad(DateObject.getMonth() + 1, 2) + "/" + DateObject.getFullYear();
      const _timestamp = DateObject.getTime();
      return {
        date: _date,
        timestamp: _timestamp,
        day: pad(DateObject.getDate(), 2),
        month: DateObject.getMonth(),
        year: DateObject.getFullYear(),
      };
    } catch (_) {
      return {
        date: "",
        timestamp: 0,
        day: 0,
        month: 0,
        year: 0,
      };
    }
  }

  /**
   * Start date
   */

  const today_active = extractTimeAndDateFromSource("");
  const [{ month_start, year_start }, setDateStart] = useState({
    month_start: today_active.month,
    year_start: today_active.year,
  });
  const [selectedDateStart, setSelectedDateStart] = useState({
    start: new Date(),
    end: new Date(),
  });

  const handleMonthChangeStart = useCallback(
    (_month, _year) => setDateStart({ month_start: _month, year_start: _year }),
    []
  );

  /**
   * End date
   */

  const [{ month_end, year_end }, setDateEnd] = useState({
    month_end: today_active.month,
    year_end: today_active.year,
  });

  const [selectedDateEnd, setSelectedDateEnd] = useState({
    start: new Date(),
    end: new Date(),
  });

  const handleMonthChangeEnd = useCallback((_month, _year) => setDateEnd({ month_end: _month, year_end: _year }), []);

  const hours: any = [];
  for (let i = 0; i < 24; i++) {
    hours.push({ label: pad(i), value: pad(i) });
  }

  const minutes = [
    { label: "00", value: "00" },
    { label: "15", value: "15" },
    { label: "30", value: "30" },
    { label: "45", value: "45" },
  ];

  /* Open Hour */
  const [open_hour, setOpen_hour] = useState("00");
  const handleOpenHourChange = useCallback((value) => setOpen_hour(value), []);

  /* Open Minute */
  const [open_minute, setOpen_minute] = useState("00");
  const handleOpenMinuteChange = useCallback((value) => setOpen_minute(value), []);

  /* End Hour */
  const [end_hour, setEnd_hour] = useState("00");
  const handleEndHourChange = useCallback((value) => setEnd_hour(value), []);

  /* End Minute */
  const [end_minute, setEnd_minute] = useState("00");
  const handleEndMinuteChange = useCallback((value) => setEnd_minute(value), []);

  useEffect(() => {
    if (entity) {
      if (entity?._id !== null) {
        dispatch(getCity(entity?.city));
        dispatch(getCategory(entity?._id));
        dispatch(getType(entity?._id));
      }

      const isoStr1 = entity?.open_date;
      const dateStart = new Date(isoStr1?.slice(0, -1));

      const isoStr2 = entity?.end_date;
      const dateEnd = new Date(isoStr2?.slice(0, -1));

      if (Param) {
        setPost_type_selected(entity?.type?._id);
        setPost_category_selected(entity?.category[0]?._id);
        setCenter({ lat: entity?.loc?.coordinates[1], lng: entity?.loc?.coordinates[0] });

        setSelectedDateStart({ start: dateStart, end: dateStart });
        setDateStart({ month_start: dateStart.getMonth(), year_start: dateStart.getFullYear() });
        setSelectedDateEnd({ start: dateEnd, end: dateEnd });
        setDateEnd({ month_end: dateEnd.getMonth(), year_end: dateEnd.getFullYear() });
        setOpen_hour(
          dateStart.getHours() <= 9 ? "0" + dateStart.getHours().toString() : dateStart.getHours().toString()
        );
        setOpen_minute(dateStart.getMinutes().toString());
        setEnd_hour(dateEnd.getHours() <= 9 ? "0" + dateEnd.getHours().toString() : dateEnd.getHours().toString());
        setEnd_minute(dateEnd.getMinutes().toString());
        setInputValueCities(entity?.city);
      } else {
        setPost_type_selected("");
        setCenter({ lat: 20.993096509742454, lng: 105.78273504420798 });
        setSelectedDateStart({ start: new Date(), end: new Date() });
        setDateStart({ month_start: today_active.month, year_start: today_active.year });
        setSelectedDateEnd({ start: new Date(), end: new Date() });
        setDateEnd({ month_end: today_active.month, year_end: today_active.year });
        setOpen_hour("00");
        setOpen_minute("00");
        setEnd_hour("00");
        setEnd_minute("00");
      }
      setCountry_selected(entity?.country);
    }
  }, [entity]);

  useEffect(() => {
    if (updateSuccess) {
      history("/event/edit/" + entity?._id, {
        replace: true,
      });
      setNotification("This event has been updated!");
    }
  }, [updateSuccess]);

  /**
   * Get data city
   */

  const deselectedCities = entitycities?.map(({ _id, city_name }) => {
    return {
      label: String(city_name),
      value: String(_id),
    };
  });

  const updateCity = useCallback(
    (value) => {
      dispatch(searchCities(value));

      setInputValueCities(value);

      if (value === "") {
        setOptionCities(deselectedCities);
        return;
      }
    },
    [deselectedCities]
  );

  useEffect(() => {
    const filterRegex = new RegExp(inputValueCities, "i");
    const resultOptionCities = deselectedCities.filter((option) => option.label.match(filterRegex));
    setOptionCities(resultOptionCities);
  }, [entitycities]);

  const updateSelectionCities = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = optionCities.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.label;
      });

      setSelectedOptionCities(selected);
      setInputValueCities(selectedValue[0]);
      setCityId(selected[0]);
    },
    [optionCities]
  );

  const cityField = (
    <Autocomplete.TextField
      autoComplete={null}
      onChange={updateCity}
      label=""
      value={inputValueCities}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder="Search city"
      disabled={Param ? true : false}
    />
  );

  useEffect(() => {
    if (errorMessage) {
      setNotification(errorMessage);
      dispatch(clearError());
    }
  }, [errorMessage]);

  /**
   * Category list
   */

  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    const optionType = [
      {
        label: "",
        value: "0",
      },
    ];
    for (let { _id, name } of entitycategories) {
      optionType.push({
        label: String(name),
        value: String(_id),
      });
    }
    setCategoryList(optionType);
  }, [entitycategories]);

  /**
   * Select category
   */

  const [post_category_selected, setPost_category_selected] = useState("");
  const handNewChangeCategory = useCallback((value) => {
    setPost_category_selected(value);
  }, []);

  useEffect(() => {
    if (entitycategory) {
      if (Param) {
        setPost_category_selected(entitycategory?._id);
      } else {
        setPost_category_selected("");
      }
    }
  }, [entitycategory]);

  /**
   * Category list
   */

  const [typeList, setTypeList] = useState([]);

  useEffect(() => {
    const optionType = [
      {
        label: "",
        value: "0",
      },
    ];
    for (let { _id, name } of entitytypes) {
      optionType.push({
        label: String(name),
        value: String(_id),
      });
    }
    setTypeList(optionType);
  }, [entitytypes]);

  /**
   * Select type
   */

  const [post_type_selected, setPost_type_selected] = useState("");
  const handNewChangeType = useCallback((value) => {
    setPost_type_selected(value);
  }, []);

  useEffect(() => {
    if (entitytype) {
      if (Param) {
        setPost_type_selected(entitytype?._id);
      } else {
        setPost_type_selected("");
      }
    }
  }, [entitytype]);

  function _deleteEntityAction() {
    setOpenModal(true);
  }

  function onModalAgree() {
    dispatch(deleteEntity(Param));
    setOpenModal(false);
  }

  const useFields = {
    title: useField<string>({
      value: entity?.title ?? "",
      validates: [
        lengthLessThan(250, "No more than 250 characters."),
        lengthMoreThan(6, "No shorter than 6 characters."),
        (inputValue) => {
          if (inputValue.length < 6) {
            return "Your title is too short, or it is empty.";
          }
        },
      ],
    }),

    description: useField<string>({
      value: entity?.description ?? "",
      validates: [],
    }),

    address_full: useField<string>({
      value: entity?.address_full ?? "",
      validates: [
        lengthLessThan(250, "No more than 250 characters."),
        lengthMoreThan(6, "No shorter than 6 characters."),
        (inputValue) => {
          if (inputValue.length < 6) {
            return "Your address_full is too short, or it is empty.";
          }
        },
      ],
    }),

    interested_number: useField<string>({
      value: entity?.interested_number + "" ?? "",
      validates: [],
    }),

    like_number: useField<string>({
      value: entity?.like_number ?? "",
      validates: [],
    }),
  };

  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset,
    submitErrors,
    makeClean,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        const datestart = new Date(selectedDateStart.start).getDate();
        const dateend = new Date(selectedDateEnd.start).getDate();

        if (!Param) {
          // create new
          dispatch(
            createEntity({
              // handle_id: post_service_selected,
              title: values.title,
              address_full: values.address_full,
              description: values.description,
              category: post_category_selected,
              type: post_type_selected,
              country: country_selected,
              city: "6325788992ded1b4c0cff2c9",
              open_date: `${year_start}-${pad(month_start + 1, 2)}-${pad(datestart)}` + ` ${open_hour}:${open_minute}`,
              end_date: `${year_end}-${pad(month_end + 1, 2)}-${pad(dateend)} ` + ` ${end_hour}:${end_minute}`,
              latitude: center.lat,
              longitude: center.lng,
            })
          );
        } else {
          dispatch(
            updateEntity({
              _id: entity._id,
              title: values.title,
              address_full: values.address_full,
              description: values.description,
              category: post_category_selected,
              type: post_type_selected,
              country: country_selected,
              city: "6325788992ded1b4c0cff2c9",
              open_date: `${year_start}-${pad(month_start + 1, 2)}-${pad(datestart)}` + ` ${open_hour}:${open_minute}`,
              end_date: `${year_end}-${pad(month_end + 1, 2)}-${pad(dateend)}` + ` ${end_hour}:${end_minute}`,
              latitude: center.lat,
              longitude: center.lng,
            })
          );
        }
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message = e?.response?.data?.title ?? "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  const emptyData = (
    <Page title="Event" breadcrumbs={[{ content: "Event list", url: "/event" }]}>
      <EmptyState heading="No post here!" image={emptyIMG}>
        <p>This record maybe not exist!</p>
      </EmptyState>
    </Page>
  );

  const toastMarkup = notification ? <Toast content={notification} onDismiss={toggleActive} /> : null;

  const toastNotification = message ? <Toast content={message} onDismiss={toggleActive} /> : null;

  const Actual_page =
    entity || !Param ? (
      <>
        <Page
          title="Event"
          breadcrumbs={[{ content: "Event list", url: "/event" }]}
          primaryAction={{
            content: "Save",
            // disabled: !dirty,
            loading: updating,
            onAction: submit,
          }}
        >
          <Layout>
            <Layout.Section>
              <Form onSubmit={submit}>
                <Card>
                  <Card.Section>
                    <FormLayout>
                      <TextField
                        autoComplete="off"
                        maxLength={1000}
                        requiredIndicator
                        label="Title"
                        {...fields.title}
                      />
                      <TextField
                        autoComplete="off"
                        maxLength={1000}
                        requiredIndicator
                        label="Address_full"
                        {...fields.address_full}
                      />

                      <div>City</div>
                      <Autocomplete
                        options={optionCities}
                        selected={selectedOptionCities}
                        onSelect={updateSelectionCities}
                        textField={cityField}
                      />

                      <Select
                        requiredIndicator
                        label="Category"
                        options={categoryList}
                        onChange={handNewChangeCategory}
                        value={post_category_selected?.toString()}
                      />
                      <Select
                        requiredIndicator
                        label="Type"
                        options={typeList}
                        onChange={handNewChangeType}
                        value={post_type_selected?.toString()}
                      />
                      <TextField
                        autoComplete="off"
                        maxLength={1000}
                        multiline={true}
                        label="Description"
                        {...fields.description}
                      />
                      <TextField
                        type="number"
                        autoComplete="off"
                        requiredIndicator
                        label="Interested Number"
                        disabled={true}
                        min={0}
                        {...fields.interested_number}
                      />
                      <TextField
                        autoComplete="off"
                        requiredIndicator
                        disabled={true}
                        type="number"
                        label="Like Number"
                        min={0}
                        {...fields.like_number}
                      />
                      <TextStyle>Open Date</TextStyle>
                      <DatePicker
                        month={month_start}
                        year={year_start}
                        onChange={setSelectedDateStart}
                        onMonthChange={handleMonthChangeStart}
                        selected={selectedDateStart}
                      />
                      <Stack>
                        <Stack.Item>
                          <Select label="Hour" options={hours} onChange={handleOpenHourChange} value={open_hour} />
                        </Stack.Item>
                        <Stack.Item>
                          <Select
                            label="Minute"
                            options={minutes}
                            onChange={handleOpenMinuteChange}
                            value={open_minute}
                          />
                        </Stack.Item>
                      </Stack>
                      <TextStyle>End Date</TextStyle>
                      <DatePicker
                        month={month_end}
                        year={year_end}
                        onChange={setSelectedDateEnd}
                        onMonthChange={handleMonthChangeEnd}
                        selected={selectedDateEnd}
                        disableDatesBefore={new Date(selectedDateStart.start)}
                      />
                      <Stack>
                        <Stack.Item>
                          <Select label="Hour" options={hours} onChange={handleEndHourChange} value={end_hour} />
                        </Stack.Item>
                        <Stack.Item>
                          <Select
                            label="Minute"
                            options={minutes}
                            onChange={handleEndMinuteChange}
                            value={end_minute}
                          />
                        </Stack.Item>
                      </Stack>
                      <Select
                        label="Country"
                        options={option_country}
                        onChange={changeHandler}
                        value={country_selected}
                      />

                      <div className="row">
                        <div className="col text-center">
                          <h2>Map</h2>
                          <div className="col">
                            <MapContainer center={center} zoom={ZOOM_LEVEL} ref={setMap} fadeAnimation={true}>
                              <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              />
                              <Marker
                                position={center}
                                icon={markerIcon}
                                ref={markerRef}
                                draggable={true}
                                eventHandlers={eventHandlers}
                              >
                                <Popup>
                                  A pretty CSS3 popup. <br /> Easily customizable.
                                </Popup>
                              </Marker>
                            </MapContainer>
                          </div>
                        </div>
                      </div>

                      {/* <div className="row my-4">
                        <div className="col d-flex justify-content-center">
                          <button onClick={showMyLocation}>
                            Locate Me
                            <Icon source={GlobeMinor} color="base" />
                          </button>
                        </div>
                      </div> */}
                    </FormLayout>
                  </Card.Section>
                </Card>
              </Form>
            </Layout.Section>
          </Layout>
          <PageActions
            primaryAction={{
              content: "Save",
              onAction: submit,
              // disabled: !dirty,
              loading: updating,
            }}
            secondaryActions={
              Param
                ? [
                    {
                      content: "Delete",
                      destructive: true,
                      onAction: _deleteEntityAction,
                    },
                  ]
                : []
            }
          />
        </Page>
      </>
    ) : (
      emptyData
    );

  const skeleton_loading = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const _Modal = openModal ? (
    <Modal
      activator={null}
      open={true}
      onClose={() => setOpenModal(false)}
      title="Delete entity?"
      primaryAction={{
        content: "Delete",
        onAction: onModalAgree,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>After delete, you can not undo this action!</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  ) : null;

  return (
    <>
      {_Modal}
      {toastMarkup}
      {toastNotification}
      {loading ? skeleton_loading : Actual_page}
    </>
  );
}

