import React from "react";
import { useParams } from "react-router-dom";
import Theme404 from "../../layout/404";

import handle_service_list from "./handle_service.list";
import handle_service_edit from "./handle_service.edit";

/**
 *   Create index file for Handle_service
 */

export default function List_handle_service() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || "list";

  let ActualPage: any;
  switch (Param) {
    case "list":
      ActualPage = handle_service_list;
      break;

    case "edit":
      ActualPage = handle_service_edit;
      break;

    case "new":
      ActualPage = handle_service_edit;
      break;

    default:
      ActualPage = Theme404;
      break;
  }

  return <>{<ActualPage />}</>;
}

