import {
  Card,
  EmptyState,
  Form,
  FormLayout,
  Layout,
  Modal,
  Page,
  PageActions,
  RadioButton,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";

import emptyIMG from "../../media/empty.png";
import {
  clearError,
  getEntity,
  updateEntity,
  deleteEntity,
  createEntity,
  reset,
} from "../../store/user_question.store.reducer";
import { lengthLessThan, lengthMoreThan, useField, useForm, notEmpty } from "@shopify/react-form";
import helpers from "helpers";

export default function Edit_user_question() {
  const entity = useAppSelector((state) => state.user_question.entity);
  const updating = useAppSelector((state) => state.user_question.updating);
  const entities = useAppSelector((state) => state.user_question.entities);
  const loading = useAppSelector((state) => state.user_question.loading);
  const errorMessage = useAppSelector((state) => state.user_question.errorMessage);
  const updateSuccess = useAppSelector((state) => state.user_question.updateSuccess);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  // jamviet.com
  const [message, setMessage] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const [notification, setNotification] = useState(null);

  const toggleActive = useCallback(() => {
    setMessage(null);
    dispatch(clearError());
  }, []);

  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.user_question_slug || false;

  useEffect(() => {
    if (Param) dispatch(getEntity(Param));
    else dispatch(reset());
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      history("/user_question/edit/" + entity?._id, {
        replace: true,
      });

      setNotification("This question has been updated!");
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (errorMessage) {
      setNotification(errorMessage);
      dispatch(clearError());
    }
  }, [errorMessage]);

  function _deleteEntityAction() {
    setOpenModal(true);
  }

  function onModalAgree() {
    dispatch(deleteEntity(Param));
    setOpenModal(false);
  }

  const useFields = {
    note: useField<string>({
      value: entity?.note ?? "",
      validates: [],
    }),
  };

  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset,
    submitErrors,
    makeClean,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!Param) {
          // create new
          dispatch(
            createEntity({
              question: JSON.stringify([
                { key: "en", value: questionENG },
                { key: "vi", value: questionVI },
              ]),
              note: values.note,
              image: "",
            })
          );
        } else {
          dispatch(
            updateEntity({
              _id: entity._id,
              question: JSON.stringify([
                { key: "en", value: questionENG },
                { key: "vi", value: questionVI },
              ]),
              note: values.note,
              image: "",
            })
          );
        }
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message = e?.response?.data?.title ?? "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  const [questionENG, setQuestionENG] = useState("");
  const handleChangeENG = useCallback((newValue) => setQuestionENG(newValue), []);

  const [questionVI, setQuestionVI] = useState("");
  const handleChangeVI = useCallback((newValue) => setQuestionVI(newValue), []);

  const [userContent, setUserContent] = useState([]);

  useEffect(() => {
    if (Param) {
      setUserContent(entity?.question);
    } else {
      setUserContent([]);
    }
  }, [entity]);

  // let userContent = entity?.question;

  useEffect(() => {
    if (userContent && userContent.length > 0) {
      userContent?.map((e) => {
        if (e.key === "en") {
          setQuestionENG(e.value);
        }
        if (e.key === "vi") {
          setQuestionVI(e.value);
        }
      });
    }
  }, [userContent]);

  const emptyData = (
    <Page title="User Question" breadcrumbs={[{ content: "User Question list", url: "/user_question" }]}>
      <EmptyState heading="No post here!" image={emptyIMG}>
        <p>This record maybe not exist!</p>
      </EmptyState>
    </Page>
  );

  const toastMarkup = notification ? <Toast content={notification} onDismiss={toggleActive} /> : null;

  const toastNotification = message ? <Toast content={message} onDismiss={toggleActive} /> : null;

  const Actual_page =
    entity || !Param ? (
      <>
        <Page
          title="User Question"
          breadcrumbs={[{ content: "User Question list", url: "/user_question" }]}
          primaryAction={{
            content: "Save",
            // disabled: !dirty,
            loading: updating,
            onAction: submit,
          }}
        >
          <Layout>
            <Layout.Section>
              <Form onSubmit={submit}>
                <Card>
                  <Card.Section>
                    <FormLayout>
                      <TextField
                        autoFocus
                        autoComplete="off"
                        requiredIndicator
                        label="Question"
                        value={questionENG}
                        onChange={handleChangeENG}
                        connectedRight={<Select label="Unit of time" labelHidden options={["English"]} />}
                      />
                      <TextField
                        autoComplete="off"
                        maxLength={1000}
                        requiredIndicator
                        label="Câu hỏi"
                        value={questionVI}
                        onChange={handleChangeVI}
                        connectedRight={<Select label="Unit of time" labelHidden options={["Vietnamese"]} />}
                      />

                      <TextField autoComplete="off" maxLength={250} label="Note" multiline={2} {...fields.note} />
                    </FormLayout>
                  </Card.Section>
                </Card>
              </Form>
            </Layout.Section>
          </Layout>
          <PageActions
            primaryAction={{
              content: "Save",
              onAction: submit,
              // disabled: !dirty,
              loading: updating,
            }}
            secondaryActions={
              Param
                ? [
                    {
                      content: "Delete",
                      destructive: true,
                      onAction: _deleteEntityAction,
                    },
                  ]
                : []
            }
          />
        </Page>
      </>
    ) : (
      emptyData
    );

  const skeleton_loading = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const _Modal = openModal ? (
    <Modal
      activator={null}
      open={true}
      onClose={() => setOpenModal(false)}
      title="Delete entity?"
      primaryAction={{
        content: "Delete",
        onAction: onModalAgree,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>After delete, you can not undo this action!</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  ) : null;

  return (
    <>
      {_Modal}
      {toastMarkup}
      {toastNotification}
      {loading ? skeleton_loading : Actual_page}
    </>
  );
}

