import axios from "axios";
import { createAsyncThunk, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import helpers from "helpers";
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from "../config/reducer.utils";
import { IUser, defaultValue } from "../interface/user.model";
import { AppThunk } from "../config/store";

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [],
  cloneAdvisors: [],
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  searchEntities: "",
  searchUsers: "",
};

const apiUrl = process.env.REACT_APP_AJAX_URL;

// Actions

export const getEntities = createAsyncThunk("users/fetch_entity_list", async (_URL: IQueryParams) => {
  let __URL = helpers.buildEndUrl(_URL);
  const requestUrl = `${apiUrl}list/admin${__URL}`;
  return axios.get<any>(requestUrl);
});

export const searchUsers = createAsyncThunk("users/fetch_entity_list", async (value: string | number) => {
  const requestUrl = `${apiUrl}list/admin?search=${value}&page=1&limit=100`;
  return axios.get<any>(requestUrl);
});

export const searchDates = createAsyncThunk("users/fetch_entity_list", async (_URL: IQueryParams) => {
  let __URL = helpers.buildEndUrl(_URL);
  const requestUrl = `${apiUrl}list/admin-search${__URL}`;
  return axios.get<any>(requestUrl);
});

// export const searchUsers = createAsyncThunk("users/fetch_entity_list", async (_URL: IQueryParams) => {
//   let __URL = helpers.buildEndUrl(_URL);
//   const requestUrl = `${apiUrl}/list/admin-search${__URL}`;
//   return axios.get<any>(requestUrl);
// });

export const searchEntities = createAsyncThunk("customer/fetch_entity_list", async (value: string | number) => {
  const requestUrl = `${apiUrl}?display_name=${value}`;
  return axios.get<any>(requestUrl);
});

/**
 * Get all clone from users
 */
export const getCloneUsers = createAsyncThunk("users/fetch_entity_list", async (_URL: IQueryParams) => {
  let __URL = helpers.buildEndUrl(_URL);
  const requestUrl = `${apiUrl}${__URL}`;
  return axios.get<any>(requestUrl);
});

/**
 * Get top advisor ...
 */
export const getTopAdvisor = createAsyncThunk("users/fetch_topadvisor_list", async (ids: string) => {
  const requestUrl = `${apiUrl}?ids=${ids}`;
  return axios.get<any>(requestUrl);
});

export const getEntity = createAsyncThunk(
  "users/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}detail/${id}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntitiesAll = createAsyncThunk("users/fetch_entity_list", async (_URL: IQueryParams) => {
  let __URL = helpers.buildEndUrl(_URL);
  const requestUrl = `${apiUrl}${__URL}`;
  return axios.get<any>(requestUrl);
});

export const createEntity = createAsyncThunk(
  "users/create_entity",
  async (entity: IUser, thunkAPI) => {
    const result = await axios.post<IUser>(`${apiUrl}`, helpers.cleanEntity(entity));
    /**
     * Sau khi thực hiện xong quẻry bên trên thì bắt đầu thực hiện thêm cái quẻry bên dưới :D
     */
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "users/update_entity",
  async (entity: IUser, thunkAPI) => {
    const result = await axios.patch<IUser>(`${apiUrl}update/user`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntity(entity._id));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateUserOption = createAsyncThunk(
  "users/update_entity",
  async (entity: IUser, thunkAPI) => {
    const result = await axios.patch<IUser>(`${apiUrl}update/user-option`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntity(entity._id));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  "users/partial_update_entity",
  async (entity: IUser, thunkAPI) => {
    const result = await axios.patch<IUser>(`${apiUrl}${entity._id}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  "users/delete_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}delete/${id}`;
    return axios.delete<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const USERS = createEntitySlice({
  name: "users",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(deleteEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = "Can not delete this Entity";
      })

      .addCase(getTopAdvisor.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = "Can not get top advisors";
      })

      .addCase(getCloneUsers.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = "Can not get clone users";
      })

      .addMatcher(isFulfilled(getEntities, getTopAdvisor, getCloneUsers), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          cloneAdvisors: action.payload.data,
          totalItems: parseInt(action.payload.headers["x-total-count"], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(deleteEntity), (state) => {
        state.updating = true;
        state.loading = true;
        state.updateSuccess = false;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity, getTopAdvisor, getCloneUsers), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })

      .addMatcher(isFulfilled(searchEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          searchEntities: action.payload.data,
          totalItems: parseInt(action.payload.headers["x-total-count"], 10),
          entities: action.payload.data,
        };
      })
      .addMatcher(isPending(searchEntities), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })

      .addMatcher(isFulfilled(searchUsers), (state, action) => {
        return {
          ...state,
          loading: false,
          searchUsers: action.payload.data,
          totalItems: parseInt(action.payload.headers["x-total-count"], 10),
        };
      })

      .addMatcher(isPending(searchUsers), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })

      .addMatcher(isFulfilled(searchDates), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers["x-total-count"], 10),
        };
      })

      .addMatcher(isPending(searchDates), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { clearError, reset } = USERS.actions;

// Reducer
export default USERS.reducer;

