import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import EmailTemplateList from "./email_template_list";
import EmailTemplateEdit from "./email_template_edit";
import EmailTemplateCreate from "./email_template_create";

const EmailTemplate = () => {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || "aaaaa"; // sub_slug

  const account = useAppSelector((state) => state.user.account);
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  const dispatch = useAppDispatch();
  const isAdmin = account.user_role === "admin";

  let ActualPage: any = EmailTemplateList;

  switch (Param) {
    case "view_and_edit":
      ActualPage = EmailTemplateEdit;
      break;

    case "create_new":
      ActualPage = EmailTemplateCreate;
      break;

    default:
      break;
  }

  return (
    <>
      <iframe
        style={{
          width: "100%",
          border: 0,
          minHeight: "calc(100vh - 80px)",
          overflow: "hidden",
        }}
        src="https://email-manager.iceo.tech/"
        title="W3Schools Free Online Web Tutorials"
      ></iframe>
    </>
  );
};

export default EmailTemplate;
