import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import setupAxiosInterceptors from './config/axios';
import App from './App';
import reportWebVitals from './reportWebVitals';
import getStore from './config/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { clearAuthentication } from './store/user.store.reducer';
import './media/APP.css';

const store = getStore();



/**
 * If API header return 401 or 403, call login now!
 */
const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(
  (status: number) => {
    // not log-in
    // actions.clearAuthentication();
    // logout ? do something now, or leave it there...
  }
);



const render = (Component: any) =>
  ReactDOM.render(
    <CookiesProvider>
      <Provider store={store}>
        <GoogleOAuthProvider clientId={'769987855403-cu0v0uceea307ermg4ijrnt056tftipa.apps.googleusercontent.com'}>
          <Component />
        </GoogleOAuthProvider>
      </Provider>
    </CookiesProvider>
    ,
    document.getElementById("root")
  );

render(App);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
