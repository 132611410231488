import user from "./user.store.reducer";
import toast from "./toast.store.reducer";
import posts from "./posts.store.reducer";
import users from "./users.store.reducer";
import advisor from "./advisor.store.reduce";
import media from "./media.store.reducer";
import settings from "./settings.store.reducer";
import vn_message from "./vn_message.store.reducer";
import en_message from "./en_message.store.reducer";
import system from "./system.store.reducer";
import email from "./email.store.reducer";
import email_template from "./email_template.store.reducer";
import notification from "./notification.store.reducer";
import user_role from "./user_role.store.reducer";
import banner from "./banner.store.reducer";
import chat from "./chat.store.reducer";
import handle_service from "./handle_service.store.reducer";
import plan from "./plan.store.reducer";
import subscription from "./subscription.store.reducer";
import report from "./report.store.reducer";
import order from "./order.store.reducer";
import type from "./type.store.reducer";
import category from "./category.store.reducer";
import event from "./event.store.reducer";
import rating from "./rating.store.reducer";
import city from "./city.store.reducer";
import user_question from "./user_question.store.reducer";
import user_interest from "./user_interest.store.reducer";
import auto_message from "./auto_message.store.reducer";
import prompt from "./prompt.store.reducer";
import ads from "./ads.store.reducer";

const rootReducer = {
  email,
  email_template,
  user,
  users,
  advisor,
  media,
  settings,
  vn_message,
  en_message,
  system,
  posts,
  toast,
  notification,
  user_role,
  banner,
  chat,
  handle_service,
  plan,
  subscription,
  report,
  order,
  type,
  category,
  event,
  rating,
  city,
  user_question,
  user_interest,
  auto_message,
  prompt,
  ads,
};

export default rootReducer;
