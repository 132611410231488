import {
  DatePicker,
  FormLayout,
  Modal,
  Stack,
  TextField,
  TextStyle,
  Form,
  Card,
  Toast,
  Loading,
  Select,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "config/store";
import { clearError, createEntity, getEntity, updateEntity } from "store/type.store.reducer";
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from "@shopify/react-form";
import { useParams } from "react-router-dom";

/**
 *   Create upload Modal for Type
 */

export default function TypeUpload({ showModal, closeModal }) {
  const entity = useAppSelector((state) => state.type.entity);
  const updating = useAppSelector((state) => state.type.updating);
  const loading = useAppSelector((state) => state.type.loading);
  const errorMessage = useAppSelector((state) => state.type.errorMessage);

  const [uploading, setUploading] = useState(false);

  const dispatch = useAppDispatch();

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, [dispatch]);

  /**
   * Type channel value:
   * /topics/all, /topics/advisor, /topics/user
   */
  const [notiChannel, setNotiChannel] = useState<any>("");
  const handlenotiChannalChange = useCallback((value) => setNotiChannel(value), []);

  const optionsNotiChannel = [
    { label: "None", value: "" },
    { label: "All", value: "/topics/all" },
    { label: "Advisor only", value: "/topics/advisor" },
    { label: "User only", value: "/topics/user" },
  ];

  /**
   * Hide type user
   */

  const [hideTypeUser, setHideTypeUser] = useState<boolean>(false);

  useEffect(() => {
    if (notiChannel !== "") {
      setHideTypeUser(true);
    } else {
      setHideTypeUser(false);
    }
  }, []);

  //Set notiChannel = type_channel
  useEffect(() => {
    if (entity) {
      setNotiChannel(entity.type_channel);
    }
  }, [entity]);

  const [uploadModelactive, setUploadModelactive] = useState(false);

  const toggleUpdateActive = useCallback(() => {
    setUploadModelactive((active) => !active);
  }, []);

  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.users_slug || false;
  useEffect(() => {
    if (Param) {
      dispatch(getEntity(Param));
    } else {
      formReset();
    }
  }, []);

  /**
   * Must be improve
   * Jamviet.com
   * closeModal
   */
  useEffect(() => {
    setUploadModelactive(showModal);
    showUploadModal();
  }, [showModal]);

  function showUploadModal() {
    setUploading(false);
  }

  const [name, setName] = useState<string>("");
  const handChangeName = useCallback((newValue) => setName(newValue), []);

  const [description, setDescription] = useState<string>("");
  const handChangeDescription = useCallback((newValue) => setDescription(newValue), []);

  const [icon, setIcon] = useState<string>("");
  const handChangeIcon = useCallback((newValue) => setIcon(newValue), []);

  /* Create type form */
  const {
    fields,
    submit,
    dirty,
    reset: formReset,
  } = useForm({
    fields: {},
    async onSubmit(values) {
      try {
        if (!Param) {
          // create new
          dispatch(
            createEntity({
              name: name,
              description: description,
              icon: icon,
            })
          );
        } else {
          dispatch(updateEntity({}));
        }
        formReset();
        closeModal();
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message = e?.response?.data?.title ?? "Undefined error. Try again!";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  let notiData = null;
  try {
    notiData = JSON.parse(entity.type_data);
  } catch (e) {
    notiData = String(notiData);
  }

  const Actual_page = (
    <Modal
      open={uploadModelactive}
      onClose={toggleUpdateActive}
      title={uploading ? "Do NOT close this modal or refresh your browser!" : "Create Type"}
      primaryAction={{
        content: "Create",
        // disabled: !dirty,
        loading: updating,
        onAction: submit,
      }}
      secondaryActions={[
        {
          content: "Close",
          disabled: uploading,
          onAction: toggleUpdateActive,
        },
      ]}
    >
      <Modal.Section>
        <Form onSubmit={submit}>
          <Card sectioned>
            <FormLayout>
              <TextField
                label="Name"
                autoFocus
                autoComplete="off"
                requiredIndicator
                value={name}
                onChange={handChangeName}
                // {...fields.name}
              />

              <TextField
                label="Icon Url"
                autoComplete="off"
                requiredIndicator
                type="url"
                value={icon}
                onChange={handChangeIcon}
                // {...fields.description}
              />

              <TextField
                label="Description"
                multiline
                autoComplete="off"
                value={description}
                onChange={handChangeDescription}
                // {...fields.description}
              />
            </FormLayout>
          </Card>
        </Form>
      </Modal.Section>
    </Modal>
  );

  const toastMarkup = errorMessage ? <Toast content={errorMessage} error onDismiss={toggleActive} /> : null;

  return (
    <>
      {toastMarkup}
      {loading ? <Loading /> : null}
      {Actual_page}
    </>
  );
}

