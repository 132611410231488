import { Page } from "@shopify/polaris";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { ExportMinor } from "@shopify/polaris-icons";
import NotificationUpload from "./notification.upload";
import AppList from "./notification.list";

/**
 *   Create index file for Notification
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let useParam = {} as any;
  useParam = useLocation();

  const [uploadModelactive, setUploadModelactive] = useState(false);
  const toggleUpdateActive = useCallback(
    () => setUploadModelactive((active) => !active),
    []
  );

  return (
    <>
      <Page
        primaryAction={{
          content: `Create notification`,
          onAction: toggleUpdateActive,
          icon: ExportMinor,
        }}
        title="Notification"
      ></Page>
      <NotificationUpload
        showModal={uploadModelactive}
        closeModal={toggleUpdateActive}
      />
      {<AppList />}
    </>
  );
};
