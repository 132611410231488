import {
  Badge,
  Button,
  Card,
  DataTable,
  EmptyState,
  Layout,
  Loading,
  Modal,
  Page,
  RadioButton,
  Select,
  Stack,
  Subheading,
  TextField,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import dateandtime from "date-and-time";
import {
  TickSmallMinor,
  LockMinor,
  AlertMinor,
  CategoriesMajor,
  ArchiveMinor,
  EditMinor,
  ArrowDownMinor,
  CancelSmallMinor,
} from "@shopify/polaris-icons";
import emptyIMG from "media/empty.png";
import Pagination from "components/pagination";
import { useAppDispatch, useAppSelector } from "config/store";
import {
  clearError,
  getEntity,
  getEntities,
  partialUpdateEntity,
  deleteEntity,
} from "store/media.store.reducer";
import { getCurrentUser } from "store/user.store.reducer";
import QuickSearch from "components/quick_search";
import helpers from "../../helpers";
import VideoPlayer from "react-video-js-player";
import ReactAudioPlayer from "react-audio-player";
import videoCallImage from "../../media/images-removebg-preview.png";
import audioImage from "../../media/sound-icon-speaker-icon-sound-vector-icon-music-volume-symbol-vector-illustration-flat-design-sound-icon-speaker-icon-sound-vector-131245866.jpg";
import audioCallImage from "../../media/imgbin-interactive-voice-response-telephone-call-mobile-phones-reel-diagram-uyfD2n1iq4na5PzcLkbF1gGVX-removebg-preview.png";

export default function General() {
  const entity = useAppSelector((state) => state.media.entity);
  const entities = useAppSelector((state) => state.media.entities);
  const loading = useAppSelector((state) => state.media.loading);
  const errorMessage = useAppSelector((state) => state.media.errorMessage);
  const totalItems = useAppSelector((state) => state.media.totalItems);
  const updating = useAppSelector((state) => state.media.updating);
  const { user_id, user_role } = useAppSelector((state) => state.user.account);

  const current_session_id = helpers.get_session_id();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const toggleActive = useCallback(() => {
    dispatch(clearError());
    // dispatch(getCurrentUser());
  }, []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  const initialQuery = {
    page: 1,
    limit: 100,
    order_by: "DESC",
  };

  const [mainQuery, setMainQuery] = useState({
    // ...StringQuery,
    ...initialQuery,
  });

  const [selectedParentId, setSelectedParentId] = useState(
    StringQuery.private_access || ""
  );
  const handleSelectedChange = useCallback(
    (_value) => {
      setSelectedParentId(_value);
      setMainQuery({ ...mainQuery, ...{ media_type: _value, page: 1 } });
    },
    [mainQuery]
  );

  /**
   * Image
   */

  const [srcImage, setSrcImage] = useState("");
  useEffect(() => {
    if (entity) {
      let str = entity?.media_url;
      let words = str.split("?AWSAccessKeyId");
      setSrcImage(words[0]);
    }
  }, [entity]);

  /**
   * Sort colume
   */
  const handleSort = useCallback(
    (index, direction) => {
      let _direction = direction === "descending" ? "desc" : "asc";
      let sort = "";
      if (index === 2) {
        sort = "download_count," + _direction;
      } else {
        sort = "createdAt," + _direction;
      }
      // console.info(sort, _direction)
      setMainQuery({ ...mainQuery });
    },
    [entities]
  );

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePageNumber = useCallback(
    (numPage) => {
      setMainQuery({ ...mainQuery, page: numPage });
    },
    [mainQuery]
  );

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch)
      window.history.replaceState(null, "Media", "/media" + buildURLSearch);
    // history.push( '/media' + buildURLSearch );
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  const handleFiltersQueryChange = useCallback(
    (_value) => {
      setMainQuery({ ...mainQuery, ...{ query: _value, page: 1 } });
    },
    [mainQuery]
  );

  const showAll = () => {
    setMainQuery(initialQuery);
  };

  const showMyFile = () => {
    setMainQuery({ ...mainQuery, ...{ createBy: user_id } });
  };

  /**
   * Query media detail
   * Query media detail
   * Query media detail
   * Query media detail
   * Query media detail
   * Query media detail
   * Query media detail
   * Query media detail
   * @param _id
   */

  const [showEditModel, setShowEditModel] = useState(false);
  const [showFormEdits, setShowFormEdits] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [formvalue, setFormvalue] = useState("");
  const [optionvalue, setOptionvalue] = useState("disabled");
  const getMediaDetail = (_id: number) => {
    dispatch(getEntity(_id));
    setShowEditModel(true);
    setDeleteConfirm(false);
    setShowFormEdits(false);
  };
  const toggleEditModalActive = useCallback(() => {
    if (showEditModel) {
      // close model ? Reload data
      dispatch(getEntities(mainQuery));
    }
    setShowEditModel((active) => !active);
  }, [showEditModel]);

  const deleteFile = useCallback(() => {
    dispatch(deleteEntity(entity?._id));
    toggleEditModalActive();
    dispatch(getEntities(mainQuery));
  }, [entity]);

  const deleteFileConfirm = useCallback(() => {
    setDeleteConfirm((deleteConfirm) => !deleteConfirm);
  }, []);

  const showFormEdit = () => {
    setShowFormEdits(true);
  };

  useEffect(() => {
    if (entity) {
      setFormvalue(entity?.media_description);
      setOptionvalue(entity?.private_access === 1 ? "optional" : "disabled");
    }
  }, [entity]);

  const handChangeFormvalue = useCallback(
    (newValue) => setFormvalue(newValue),
    []
  );
  const handleOptionChange = useCallback(
    (_checked, newValue) => setOptionvalue(newValue),
    []
  );

  const saveForm = () => {
    dispatch(
      partialUpdateEntity({
        _id: entity?._id,
        media_description: formvalue,
        private_access: optionvalue === "disabled" ? 0 : 1,
      })
    );
    setShowFormEdits(false);
  };
  const cancelForm = () => {
    setShowFormEdits(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
    console.log(isOpen);
  };

  const editForm = (
    <>
      <Subheading>Access</Subheading>
      <br />
      <Stack vertical>
        <RadioButton
          label="Private Access"
          helpText="Only author can view or download file."
          checked={optionvalue === "optional"}
          id="optional"
          name="accounts"
          onChange={handleOptionChange}
        />
        <RadioButton
          label="Public Access"
          helpText="Everyone can view and download."
          id="disabled"
          name="accounts"
          checked={optionvalue === "disabled"}
          onChange={handleOptionChange}
        />
      </Stack>
      <br />
      <div id="profile_heading">
        <div className="news_thumbnail_inner">
          <div className="news_thumbnail_avatar">
            {entity?.media_type.split("/")[0] === "image" ? (
              <img src={srcImage} style={{ width: "115px" }} />
            ) : entity?.media_type.split("/")[0] === "video" ? (
              <>
                <VideoPlayer
                  controls={true}
                  src={srcImage}
                  width="580px"
                  height="115px"
                />
              </>
            ) : (
              <ReactAudioPlayer
                src={srcImage}
                autoPlay
                controls
                style={{ width: "580px", height: "115px" }}
              />
            )}
          </div>
        </div>
      </div>
      <br />
      <Subheading>NOTE</Subheading>
      <TextField
        label=""
        value={formvalue}
        onChange={handChangeFormvalue}
        maxLength={200}
        multiline={2}
        autoComplete="off"
        showCharacterCount
      />
      <Button plain onClick={saveForm} loading={updating}>
        Save
      </Button>
      &nbsp; or &nbsp;
      <Button plain onClick={cancelForm} loading={updating}>
        Cancel
      </Button>
    </>
  );

  const detailModal = (
    <Modal
      open={showEditModel}
      onClose={toggleEditModalActive}
      title="View File"
      titleHidden
      secondaryActions={[
        {
          content: "CLOSE",
          onAction: toggleEditModalActive,
        },
      ]}
    >
      <Modal.Section>
        {!entity && !loading ? (
          <Subheading>Oh! Data not found!</Subheading>
        ) : (
          <>
            <Subheading>
              <strong>{entity?.media_file_name}</strong>
            </Subheading>
            <br />
            <Stack>
              <Stack.Item>
                <Badge status="success">{entity?._id}</Badge>
              </Stack.Item>

              <Stack.Item>
                <Badge status="critical">{entity?.media_mime_type}</Badge>
              </Stack.Item>

              {/* <Stack.Item>
                <Badge status="critical">{helpers.bytesToSize(entity?.media_filesize)}</Badge>
              </Stack.Item> */}

              <Stack.Item>Download time: {entity?.download_count}</Stack.Item>
            </Stack>
            <br />
            <p>Author: {entity?.user?.display_name}</p>
            <p>
              Create at:{" "}
              <time>
                {entity?.createdAt
                  ? dateandtime.format(
                      new Date(entity?.createdAt),
                      "DD-MM-YYYY HH:mm:ss"
                    )
                  : "-"}
              </time>
            </p>
            <p>
              Update at:{" "}
              <time>
                {entity?.updatedAt
                  ? dateandtime.format(
                      new Date(entity.updatedAt),
                      "DD-MM-YYYY HH:mm:ss"
                    )
                  : "-"}
              </time>
            </p>
            <br />
            {showFormEdits ? editForm : null}
            {!showFormEdits ? (
              <>
                <div>
                  <Subheading>
                    {entity?.private_access === 1
                      ? "Private Access"
                      : "Public Access"}
                  </Subheading>
                  <p>
                    {entity?.private_access === 1
                      ? "Only author can view or download file."
                      : "Everyone can view and download."}
                  </p>
                </div>
                <br />
                <div>
                  <p>
                    Note:{" "}
                    <TextStyle variation="positive">
                      {entity?.media_description || "Nothing!"}
                    </TextStyle>
                  </p>
                </div>
              </>
            ) : null}
            <br />

            <div id="profile_heading">
              <div className="news_thumbnail_inner">
                <div className="news_thumbnail_avatar">
                  {entity?.media_type.split("/")[0] === "image" ? (
                    <img src={srcImage} style={{ width: "115px" }} />
                  ) : entity?.media_type.split("/")[0] === "video" ? (
                    <>
                      <VideoPlayer
                        controls={true}
                        src={srcImage}
                        width="580px"
                        height="115px"
                      />
                    </>
                  ) : (
                    <ReactAudioPlayer
                      src={srcImage}
                      autoPlay
                      controls
                      style={{ width: "580px", height: "115px" }}
                    />
                  )}
                </div>
              </div>
            </div>

            <br />
            <Stack>
              <Stack.Item>
                <Button
                  download
                  plain
                  icon={ArrowDownMinor}
                  url={entity?.media_url.split("?AWSAccessKeyId")[0]}
                >
                  {" "}
                  Download now
                </Button>
              </Stack.Item>
              <Stack.Item>
                {/* <Button
                  plain
                  // disabled={user_id !== entity?.createBy && user_role !== "admin"}
                  primary
                  icon={EditMinor}
                  onClick={showFormEdit}
                >
                  Edit
                </Button> */}
              </Stack.Item>
              <Stack.Item>
                <Button
                  destructive
                  // disabled={user_id !== entity?.createBy && user_role !== "admin"}
                  onClick={deleteFileConfirm}
                  plain
                  icon={CancelSmallMinor}
                >
                  {" "}
                  Move to trash
                </Button>
              </Stack.Item>
              {deleteConfirm ? (
                <Stack.Item>
                  <Button
                    destructive
                    onClick={deleteFile}
                    plain
                    icon={AlertMinor}
                    loading={updating}
                  >
                    {" "}
                    Are you sure?
                  </Button>
                </Stack.Item>
              ) : null}
            </Stack>
          </>
        )}
      </Modal.Section>
    </Modal>
  );

  /**
   * END QUERY MEDIA DETAIL
   * END QUERY MEDIA DETAIL
   * END QUERY MEDIA DETAIL
   * END QUERY MEDIA DETAIL
   * END QUERY MEDIA DETAIL
   * END QUERY MEDIA DETAIL
   * END QUERY MEDIA DETAIL
   */

  const emptyData = (
    <EmptyState heading="No media here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>
  );

  const renderItem = (media: any) => {
    const {
      _id,
      media_url,
      media_file_name,
      media_description,
      media_thumbnail,
      media_type,
      media_filesize,
      media_extention,
      download_count,
      private_access,
      user,
      createBy,
      createdAt,
      updatedAt,
    } = media;
    return [
      <div className="small-icon" key={_id} onClick={() => getMediaDetail(_id)}>
        {private_access ? <LockMinor /> : <TickSmallMinor />}
      </div>,
      <div className="clickable" key={_id} onClick={() => getMediaDetail(_id)}>
        <p>{media_file_name}</p>
        {media_description ? (
          <TextStyle variation="positive">
            {helpers.getTrimContent(media_description, 30)}
          </TextStyle>
        ) : null}
      </div>,

      <div className="clickable" key={_id} onClick={() => getMediaDetail(_id)}>
        {media_type == "image" || media_type == "video" ? (
          <div className="user_avatar">
            <img src={media_thumbnail ? media_thumbnail : null} />
          </div>
        ) : media_type == "audio" ? (
          <div className="user_avatar">
            <img src={audioImage} />
          </div>
        ) : media_type == "audio_call" ? (
          <div className="user_avatar">
            <img src={audioCallImage} />
          </div>
        ) : media_type == "video_call" ? (
          <div className="user_avatar">
            <img src={videoCallImage} />
          </div>
        ) : null}
      </div>,

      <div className="clickable" key={_id} onClick={() => getMediaDetail(_id)}>
        {media_type}
      </div>,

      <div className="clickable" key={_id} onClick={() => getMediaDetail(_id)}>
        {createBy.display_name}
      </div>,
      <div className="clickable" key={_id} onClick={() => getMediaDetail(_id)}>
        <time>
          {createdAt
            ? dateandtime.format(new Date(createdAt), "DD-MM-YYYY HH:mm:ss")
            : "-"}
        </time>
        {/* {createdAt} */}
      </div>,
    ];
  };

  const PagesList = (
    // totalItems > 0 ? (
    <>
      <DataTable
        sortable={[false, false, true, false, false, false, true]}
        defaultSortDirection="descending"
        initialSortColumnIndex={6}
        onSort={handleSort}
        columnContentTypes={["text", "text", "text", "text", "text", "text"]}
        headings={[
          "",
          "Filename",
          "Thumbnail",
          "Filetype",
          "Author",
          "CreateAt",
        ]}
        rows={entities?.map(renderItem)}
        hideScrollIndicator
        footerContent={`Display page ${
          mainQuery.page
        } of total ${5000} results...`}
      />
      <style>{`
        .small-icon {
          font-size: 12px;
          padding: 0;
          width: 15px;
          height: auto;
        }
      `}</style>
    </>
  );
  // ) : (
  //   emptyData
  // );

  const Actual_page = (
    <>
      <Page fullWidth>
        {detailModal}
        <Layout>
          <Layout.Section>
            <Card>
              <div style={{ padding: "16px" }}>
                <Stack alignment="center">
                  <Stack.Item fill>
                    {/* <QuickSearch queryValue={StringQuery?.query} onChange={handleFiltersQueryChange} /> */}
                  </Stack.Item>
                  <Stack.Item>
                    <Select
                      label=""
                      value={selectedParentId}
                      onChange={handleSelectedChange}
                      options={[
                        { label: "All", value: "" },
                        { label: "Image", value: "image" },
                        { label: "Video", value: "video" },
                        { label: "Audio", value: "audio" },
                        { label: "File", value: "file" },
                      ]}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <Button icon={CategoriesMajor} onClick={showAll}>
                      All
                    </Button>
                  </Stack.Item>
                  <Stack.Item>
                    <Button icon={ArchiveMinor} onClick={showMyFile}>
                      My Files
                    </Button>
                  </Stack.Item>
                </Stack>
              </div>
              {PagesList}
            </Card>
            <br />
            {/* {totalItems > 0 ? ( */}
            <Pagination
              TotalRecord={5000}
              onChangePage={onChangePageNumber}
              pageSize={Number(mainQuery.limit)}
              activeCurrentPage={Number(mainQuery.page)}
            />
            {/* ) : null} */}
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );

  const toastMarkup = errorMessage ? (
    <Toast content={errorMessage} error onDismiss={toggleActive} />
  ) : null;

  return (
    <>
      {toastMarkup}
      {loading ? <Loading /> : null}
      {Actual_page}
    </>
  );
}
