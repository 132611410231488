import axios from "axios";

/**
 * Timeout for 5 second
 */
const TIMEOUT = Number(process.env.REACT_APP_API_REQUEST_TIMEOUT) || 5;
axios.defaults.timeout = TIMEOUT * 1000;
axios.defaults.baseURL =
  localStorage.getItem("AJAX_URL") || process.env.REACT_APP_AJAX_URL;
axios.defaults.headers["X-Authorization"] = localStorage.getItem("session");

/**
 *  Set session down to disk
 */
function setSession(session: string) {
  const exist_session = localStorage.getItem("session");
  if (!exist_session && session) {
    localStorage.setItem("session", session);
    document.cookie = "session=" + session;
  }
}

const setupAxiosInterceptors = (onUnauthenticated: any) => {
  const onRequestSuccess = (config: any) => {
    config.headers["X-Authorization"] = localStorage.getItem("session");
    return config;
  };
  const onResponseSuccess = (response: any) => {
    // set session
    const session = response.headers["x-authorization"] || false;
    if (session) setSession(session);

    return response;
  };
  const onResponseError = (err: any) => {
    // set session
    const session = err?.response?.headers["x-authorization"];
    setSession(session);

    const status = err.status || err?.response?.status || 0;
    /***************
     * 401: User not (correctly) authenticated, the resource/page require authentication
     * 403: User's role or permissions does not allow to access requested resource, for instance user is not an administrator and requested page is for administrators.
     */
    if (status === 401 || status === 403) {
      onUnauthenticated(status);
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
