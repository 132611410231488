import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import Theme404 from "layout/404";
import EmailQueueList from "./email_queue_list";
import EmailQueueCreate from "./email_queue_create";
import EmailQueueView from "./email_queue_view";

const EmailTemplate = () => {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || "list_all"; // sub_slug

  const account = useAppSelector((state) => state.user.account);
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  const dispatch = useAppDispatch();
  const isAdmin = account.user_role === "admin";

  let ActualPage: any = "";

  switch (Param) {
    case "view":
      ActualPage = EmailQueueView;
      break;

    case "create_new":
      ActualPage = EmailQueueCreate;
      break;

    case "list_all":
      ActualPage = EmailQueueList;
      break;

    default:
      ActualPage = Theme404;
      break;
  }

  return (
    <>
      <ActualPage />
    </>
  );
};

export default EmailTemplate;
