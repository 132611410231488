import React from "react";
import { useParams } from "react-router-dom";
import Theme404 from "../../layout/404";

import category_list from "./category.list";
import category_edit from "./category.edit";
// import pages_view from './pages.view';

export default function Pagidex() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || "list";

  let ActualPage: any;
  switch (Param) {
    case "list":
      ActualPage = category_list;
      break;

    case "edit":
      ActualPage = category_edit;
      break;

    case "new":
      ActualPage = category_edit;
      break;
    // case 'view':
    //     ActualPage = pages_view;
    // break;

    default:
      ActualPage = Theme404;
      break;
  }

  return <>{<ActualPage />}</>;
}
