import {
  Button,
  Card,
  DataTable,
  DatePicker,
  EmptyState,
  Form,
  FormLayout,
  Layout,
  Loading,
  Modal,
  Page,
  Select,
  Stack,
  Subheading,
  TextContainer,
  TextField,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import helpers from "../../helpers";
import dateandtime from "date-and-time";
import { TickSmallMinor, LockMinor, EditMinor } from "@shopify/polaris-icons";
import Pagination from "components/pagination";
import emptyIMG from "../../media/empty.png";
import { useAppDispatch, useAppSelector } from "config/store";
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from "@shopify/react-form";
import { clearError, getEntity, getEntities, updateEntity, deleteEntity } from "store/type.store.reducer";

export default function Type_List() {
  const entity = useAppSelector((state) => state.type.entity);
  const entities = useAppSelector((state) => state.type.entities);
  const loading = useAppSelector((state) => state.type.loading);
  const errorMessage = useAppSelector((state) => state.type.errorMessage);
  const totalItems = useAppSelector((state) => state.type.totalItems);
  const updating = useAppSelector((state) => state.type.updating);

  // const history = useNavigate();
  const dispatch = useAppDispatch();
  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, [dispatch]);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery = helpers.ExtractUrl(useParam.search) || false;

  const initialQuery = {
    page: 1,
    limit: 20,
    order_by: "DESC",
  };

  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...initialQuery,
  });

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePageNumber = useCallback(
    (numPage) => {
      setMainQuery({ ...mainQuery, page: numPage });
    },
    [mainQuery]
  );

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch) window.history.replaceState(null, "Type", "/type" + buildURLSearch);
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  /**
   * Query NOTIFICATION DETAIL
   * @param  _id
   */

  const [showEditModel, setShowEditModel] = useState<boolean>(false);
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);

  const [idType, setIdType] = useState("");

  const getTypeDetail = (_id: string) => {
    setIdType(_id);
    dispatch(getEntity(_id));
    setShowEditModel(true);
    setDeleteConfirm(false);
  };
  const closeModal = useCallback(() => {
    if (showEditModel) {
      dispatch(getEntities(mainQuery));
    }
    setShowEditModel((active) => !active);
  }, [showEditModel]);

  const [openModal, setOpenModal] = useState(false);

  function _deleteEntityAction() {
    setOpenModal(true);
  }

  function onModalAgree() {
    dispatch(deleteEntity(idType));
    setOpenModal(false);
    setShowEditModel(false);
  }

  const _Modal = openModal ? (
    <Modal
      activator={null}
      open={true}
      onClose={() => setOpenModal(false)}
      title="Delete entity?"
      primaryAction={{
        content: "Delete",
        onAction: onModalAgree,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>After delete, you can not undo this action!</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  ) : null;

  /* Create type form */
  const {
    fields,
    submit,
    dirty,
    reset: formReset,
  } = useForm({
    fields: {
      icon: useField<string>({
        value: entity?.icon ? decodeURIComponent(entity?.icon) : "",
        validates: [
          notEmpty("URL is required!"),
          (inputValue) => {
            if (!helpers.isUrl(inputValue)) {
              return "Your url is not valid!";
            }
          },
        ],
      }),

      name: useField<string>({
        value: entity?.name ?? "",
        validates: [
          lengthLessThan(250, "No more than 250 characters."),
          lengthMoreThan(2, "No shorter than 3 characters."),
          (inputValue) => {
            if (inputValue.length < 3) {
              return "Your title is too short, or it is empty.";
            }
          },
        ],
      }),

      description: useField<string>({
        value: entity?.description ?? "",
        validates: [],
      }),
    },
    async onSubmit(values) {
      try {
        dispatch(
          updateEntity({
            _id: idType,
            name: values.name,
            description: values.description,
            icon: values.icon,
          })
        );

        formReset();
        closeModal();
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message = e?.response?.data?.title ?? "Undefined error. Try again!";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  let notiData = null;
  try {
    notiData = JSON.stringify(entity.type_data);
  } catch (e) {
    notiData = String(notiData);
  }

  const detailModal = (
    <Modal
      open={showEditModel}
      onClose={closeModal}
      title="Update Type"
      primaryAction={{
        content: "Save",
        onAction: submit,
      }}
      secondaryActions={[
        {
          content: "Delete",
          destructive: true,
          onAction: _deleteEntityAction,

          // onAction: closeModal,
        },
      ]}
    >
      <Modal.Section>
        <Form onSubmit={submit}>
          <Card sectioned>
            <FormLayout>
              <TextField label="Name" autoFocus autoComplete="off" requiredIndicator {...fields.name} />
              <TextField autoComplete="off" maxLength={1000} requiredIndicator label="Icon Url" {...fields.icon} />
              <TextField label="Description" multiline autoComplete="off" {...fields.description} />
            </FormLayout>
          </Card>
        </Form>
      </Modal.Section>
    </Modal>
  );

  /**
   * END QUERY NOTIFICATION DETAIL
   */
  const emptyData = (
    <EmptyState heading="No post here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>
  );

  const renderItem = (type: any) => {
    const { _id, name, description, createdAt } = type;
    return [
      <div className="clickable" key={_id + "_noti_id"} onClick={() => getTypeDetail(_id)}>
        {_id}
      </div>,
      <div className="clickable" key={_id + "_noti_title"} onClick={() => getTypeDetail(_id)}>
        {name}
      </div>,
      <div className="clickable" key={_id + "_noti_data"} onClick={() => getTypeDetail(_id)}>
        {description}
      </div>,
      <div className="clickable" key={_id + "_noti_createAt"} onClick={() => getTypeDetail(_id)}>
        <time>{createdAt ? dateandtime.format(new Date(createdAt), "DD-MM-YYYY HH:mm:ss") : "-"}</time>
      </div>,
    ];
  };

  const PagesList =
    totalItems > 0 ? (
      <>
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={["ID", "Name", "Description", "Create at"]}
          rows={entities?.map(renderItem)}
          hideScrollIndicator
          footerContent={`Display page ${mainQuery.page} of total ${totalItems} results...`}
        />
        <style>{`
        .small-icon {
          margin-left: 0.6rem;
          font-size: 12px;
          padding: 0;
          width: 15px;
          height: auto;
        }
      `}</style>
      </>
    ) : (
      emptyData
    );

  const Actual_page = (
    <>
      <Page fullWidth>
        {detailModal}
        <Layout>
          <Layout.Section>
            <Card>{PagesList}</Card>
            <br />
            {totalItems > 0 ? (
              <Pagination
                TotalRecord={totalItems}
                onChangePage={onChangePageNumber}
                pageSize={Number(mainQuery.limit)}
                activeCurrentPage={Number(mainQuery.page)}
              />
            ) : null}
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );

  const toastMarkup = errorMessage ? <Toast content={errorMessage} error onDismiss={toggleActive} /> : null;

  return (
    <>
      {_Modal}
      {toastMarkup}
      {loading ? <Loading /> : null}
      {Actual_page}
    </>
  );
}

