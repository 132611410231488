import {
  Badge,
  Button,
  Card,
  DataTable,
  EmptyState,
  Form,
  FormLayout,
  Layout,
  Link,
  Modal,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import debounce from "lodash.debounce";
import { CalendarMinor } from "@shopify/polaris-icons";
import emptyIMG from "../../media/empty.png";
import Pagination from "../../components/pagination";
import { clearError, getEntities } from "../../store/subscription.store.reducer";
import { getEntitiesversion as getPlans } from "../../store/plan.store.reducer";
import { searchDates } from "../../store/order.store.reducer";

import SubscriptionFilter from "./filter";
import helpers from "../../helpers";
import dateandtime from "date-and-time";

export default function General_subscription() {
  const [initial_loading, setInitial_loading] = useState<boolean>(true);

  const entity = useAppSelector((state) => state.subscription.entity);
  const entities = useAppSelector((state) => state.subscription.entities);
  const loading = useAppSelector((state) => state.subscription.loading);
  const errorMessage = useAppSelector((state) => state.subscription.errorMessage);
  const totalItems = useAppSelector((state) => state.subscription.totalItems);

  const entityplans = useAppSelector((state) => state.plan.entities);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  /**
   * Limit
   */

  const [limit, setLimit] = useState(20);
  const handleLimit = useCallback((value) => {
    setLimit(value);
  }, []);

  useEffect(() => {
    setMainQuery({
      ...mainQuery,
      ...{ limit: `${limit}`, page: 1 },
    });
  }, [limit]);

  const [mainQuery, setMainQuery] = useState({
    ...{
      page: 1,
      limit: limit,
      order_by: "DESC",
    },
    ...StringQuery,
  });
  const [queryValue, setQueryValue] = useState("");

  const [input, setInput] = useState("");
  const handleFiltersQueryChange = useCallback((value) => setInput(value), []);

  useEffect(() => {
    setMainQuery({ ...mainQuery, ...{ user_id: input } });
  }, [input]);

  const [selectedParentId, setSelectedParentId] = useState("");
  const handleSelectedChange = useCallback((_value) => {
    setSelectedParentId(_value);
  }, []);

  useEffect(() => {
    setMainQuery({
      ...mainQuery,
      ...{ webhook_method: `${selectedParentId}`, page: 1 },
    });
  }, [selectedParentId]);

  /**
   * Change page number
   */

  const [numberPage, setNumberPage] = useState(1);

  const onChangePageNumber = useCallback((numPage) => {
    setNumberPage(numPage);
  }, []);

  useEffect(() => {
    setMainQuery({ ...mainQuery, page: numberPage });
  }, [numberPage]);

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch) history("/subscription" + buildURLSearch);
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  const onChangeCallback = useMemo(
    () =>
      debounce((_value) => {
        if (_value !== "") setMainQuery({ ...mainQuery, query: _value ? _value : "" });
      }, 500),
    []
  );

  useEffect(() => {
    onChangeCallback(queryValue);
  }, [queryValue]);

  useEffect(() => {
    if (loading === false) setInitial_loading(false);
  }, [loading]);

  /**
   * Select version plan
   */

  const [selectedVersion, setSelectedVersion] = useState("");
  const handleSelectedVersionChange = useCallback((_value) => {
    setSelectedVersion(_value);
  }, []);

  const [mainQueryPlan, setMainQueryPlan] = useState({
    ...{
      version: "1.0.0",
    },
    ...StringQuery,
  });

  useEffect(() => {
    setMainQueryPlan({ ...mainQueryPlan, version: selectedVersion });
  }, [selectedVersion]);

  useEffect(() => {
    dispatch(getPlans(mainQueryPlan));
  }, [mainQueryPlan]);

  /**
   *
   * @param _id
   */
  const shortcutActions = (_id: number) => {
    history("edit/" + _id);
  };

  const emptyData = (
    <EmptyState heading="No post here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>
  );

  const renderItem = (subscription: any) => {
    const { _id, user_id, plan_id, coupon_code, status, start_at, end_at, cancel_at, createdAt } = subscription;
    return [
      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {user_id?.display_name}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {user_id?._id}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {plan_id?.name}({plan_id?.version})
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        <time>{start_at ? dateandtime.format(new Date(start_at), "DD-MM-YYYY HH:mm:ss") : "-"}</time>
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        <time>{end_at ? dateandtime.format(new Date(end_at), "DD-MM-YYYY HH:mm:ss") : "-"}</time>
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {status === "active" ? (
          <Badge status="success">Active</Badge>
        ) : status === "deactivate" ? (
          <Badge status="warning">Deactivate</Badge>
        ) : status === "expired" ? (
          <Badge status="critical">Expired</Badge>
        ) : null}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        <time>{createdAt ? dateandtime.format(new Date(createdAt), "DD-MM-YYYY HH:mm:ss") : "-"}</time>
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {_id}
      </Link>,
    ];
  };
  const PagesList = (
    // totalItems > 0 ? (
    <>
      <DataTable
        initialSortColumnIndex={7}
        defaultSortDirection="descending"
        sortable={[false, false, false, false, false, false, false, true, false]}
        columnContentTypes={["text", "text", "text", "text", "text", "text", , "text", "text"]}
        headings={["Name", "UserId", "Plan", "Start At", "End At", "Status", "Create At", "ID"]}
        rows={entities?.map(renderItem)}
        hideScrollIndicator
        footerContent={`Display page ${mainQuery.page} of total 1000 results...`}
      />
      <style>{`
        .small-icon {
          font-size: 12px;
          padding: 0;
          width: 15px;
          height: auto;
        }
      `}</style>
    </>
  );
  // ) : (
  //   emptyData
  // );

  const Actual_page = (
    <Page
      title="Subscription(Order Success)"
      fullWidth
      primaryAction={{
        content: "Create new",
        disabled: false,
        onAction: () => {
          history("new");
        },
      }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <div style={{ padding: "16px", display: "flex" }}>
              <Stack distribution="equalSpacing">
                <SubscriptionFilter queryValue={StringQuery?.query} onChange={handleFiltersQueryChange} />
                <Select
                  label=""
                  value={selectedParentId}
                  onChange={handleSelectedChange}
                  options={[
                    { label: "All", value: "" },
                    { label: "Get", value: "get" },
                    { label: "Post", value: "post" },
                    { label: "Patch", value: "patch" },
                    { label: "Delete", value: "delete" },
                  ]}
                />

                <Select
                  label=""
                  value={limit.toString()}
                  onChange={handleLimit}
                  options={[
                    { label: "Limit", value: "20" },
                    { label: "20", value: "20" },
                    { label: "50", value: "50" },
                    { label: "100", value: "100" },
                  ]}
                />
              </Stack>
            </div>
            {PagesList}
          </Card>
          <br />
          {/* {totalItems > mainQuery.limit ? ( */}
          <Pagination
            // TotalRecord={totalItems}
            TotalRecord={1000}
            activeCurrentPage={mainQuery.page}
            pageSize={mainQuery.limit}
            onChangePage={onChangePageNumber}
          />
          {/* ) : null} */}
        </Layout.Section>
      </Layout>
    </Page>
  );

  const skeleton_loading = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const toastMarkup = errorMessage ? <Toast content={errorMessage} error onDismiss={toggleActive} /> : null;

  return (
    <>
      {toastMarkup}
      {/* {loading ? skeleton_loading : Actual_page} */}
      {initial_loading ? skeleton_loading : Actual_page}
    </>
  );
}

