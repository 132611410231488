/********************************************** *
 * list of all functions include:
 * isSomething => check if is something
 * parseSomething => Parse something ...
 * getSomething => Get Something ...
 ********************************************** */

import helper_is from "./is";
import helper_get from "./get";
import helper_parse from "./parse";

/**
 * Helpers
 */

class helpers {
  constructor() {}
  /**
   * Filter object, null or undefined is ignore
   * @param _self
   * @returns
   */
  filterNullObject(_self: any) {
    var result: any = {};
    for (var key in _self) {
      /**
       * Địt mẹ Javascript, cái địt tổ nó, đổi sang
       * _self[key] !== null nó lại không được, phải
       * _self[key] === null cơ! null === null
       */
      if (_self[key] === null || _self[key] === undefined) continue;
      result[key] = _self[key];
    }
    return result;
  }

  /**
   * Pick variable from Object, like lodash/pick
   * @Param _object: object
   * @Param _PickArray: array to pick from Object
   * @McJamBi  from Jamviet.com
   * @DateTime 2021-10-06T02:22:20+0700
   */
  pick(_object: any, _PickArray: string[]) {
    let ALLOW_VARIABLE: any = {};
    for (let query_string in _object) {
      if (_PickArray.indexOf(query_string) > -1) {
        ALLOW_VARIABLE = { ...ALLOW_VARIABLE, ...{ [query_string]: _object[query_string] } };
      }
    }
    return ALLOW_VARIABLE;
  }

  /**
   * Removes fields with an 'id' field that equals ''.
   * This function was created to prevent entities to be sent to
   * the server with an empty id and thus resulting in a 500.
   *
   * @param entity Object to clean.
   */
  cleanEntity(entity: any) {
    const keysToKeep = Object.keys(entity).filter(
      (k) => !(entity[k] instanceof Object) || (entity[k]["id"] !== "" && entity[k]["id"] !== -1)
    );
    return this.pick(entity, keysToKeep);
  }

  /**
   * Return filter to save to history ...
   * @param stringQuery Object to URL query function
   * @returns
   */
  buildEndUrl(stringQuery: any) {
    // if (stringQuery === void 0) return "?query=";
    const params = [];
    for (let key in stringQuery) {
      let nameofquery = String(key || "").trim();
      let valueofquery = String(stringQuery[key]).trim();
      if (key !== "") params.push({ key: nameofquery, value: valueofquery });
    }
    if (params.length > 0) {
      return "?" + params.map(({ key, value }) => `${key}=${value}`).join("&");
    }

    return "";
    // return "?query=";
  }

  /**
   * Revert buildEndUrl, parse URL to Object
   * @param stringQuery URL SEARCH STRING
   */
  ExtractUrl(stringQuery: any): any {
    let URLWithoutQuestionMark = String(stringQuery).substr(1); // remove ? at beginer of string
    let URLToObject = String(URLWithoutQuestionMark).split("&"); // array
    let FN = URLToObject.map((r) => {
      let a = String(r).split("=");
      let y = { [a[0]]: a[1] };
      return y;
    });
    if (FN) {
      let final_object = {};
      for (var a of FN) {
        final_object = { ...final_object, ...a };
      }
      return final_object;
    }
    return {};
  }

  /**
   * Filter empty element in an array, remove empty string, null and undefined
   * @param _array array
   */
  filterEmptyArray(_array: any[]) {
    _array.filter((el) => {
      return el !== "" || el !== undefined || el !== null;
    });
    return _array;
  }

  /**
   * Get session id for current user
   * @returns session_id
   */
  get_session_id() {
    return localStorage.getItem("session");
  }

  /**
   * Trim middle string, eg: Hello xin chào...nhé bạn!
   * @param s String
   */
  trimMiddleString(s: string, front?: number, back?: number) {
    if (s === void 0) return "";
    if (front === void 0) front = 10;
    if (back === void 0) back = 10;
    if (s.length < 21) return s;

    let start = String(s || " ").substring(0, front);
    let end = String(s || " ").substring(s.length - back);

    return `${start}...${end}`;
  }

  /**
   * is object
   * @param val
   * @returns
   */
  isObject(val) {
    return val instanceof Object;
  }

  /**
   * Trim content string, eg: Hello xin chào...
   * @param s String
   */
  trimContentString(s: string, front?: number, back?: number) {
    if (s === void 0) return "";
    if (front === void 0) front = 20;
    if (back === void 0) back = 10;
    if (s.length < 21) return s;

    let start = String(s || " ").substring(0, front);

    return `${start}...`;
  }

  /**
   * Convert Bytes to KB, MB, GB
   * @param bytes
   * @param decimals
   * @returns
   */
  bytesToSize(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}

interface helpers extends helper_is, helper_get, helper_parse {}
export default new helpers();

/*******
 * Everything after here is for webpack!
 */

// copy the methods
Object.assign(helpers.prototype, new helper_is());
Object.assign(helpers.prototype, new helper_get());
Object.assign(helpers.prototype, new helper_parse());

/**
 * It is must be here because of webpack can not run without applyMixins
 * @param derivedCtor
 * @param constructors
 */

// the helper function
function applyMixins(derivedCtor: any, constructors: any[]) {
  constructors.forEach((baseCtor) => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
      Object.defineProperty(
        derivedCtor.prototype,
        name,
        Object.getOwnPropertyDescriptor(baseCtor.prototype, name) || Object.create(null)
      );
    });
  });
}

applyMixins(helpers, [helper_is, helper_get, helper_parse]);

