/**
 * Page
 */

import {
  EmptyState,
  FormLayout,
  TextField,
  Form,
  Button,
  Page,
  Layout,
  Card,
  Toast,
  Frame,
  Link,
  Stack,
  Checkbox,
  SkeletonPage,
  SkeletonDisplayText,
  SkeletonBodyText,
  TextContainer,
  Loading,
  Heading,
  FooterHelp,
  Subheading,
  Banner,
} from "@shopify/polaris";

import { ArrowDownMinor } from "@shopify/polaris-icons";
import React, { useState, useCallback, useEffect } from "react";
import LoginLogo from "../media/LoginLogo.svg";
import Theme404 from "../../layout/404";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import stripslashes from "../../helpers";
import Parser from "html-react-parser";
import dateandtime from "date-and-time";
import { getEntity } from "../../store/rating.store.reducer";

/*
      react-router-dom:
      let useParam =  {} as any;
          useParam = useParams();
          let Param = useParam.any || false;
          or
      console.log(props.match.params)
      */

export default function View_rating() {
  const data = useAppSelector((state) => state.rating.entity);
  const loading = useAppSelector((state) => state.rating.loading);
  const dispatch = useAppDispatch();
  /**
   * Lấy URL ra... để query. chú ý phải load nội dung mới
   * trong useEffect
   */
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.rating_slug || false;

  useEffect(() => {
    dispatch(getEntity(Param));
  }, [Param]);

  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card>
            <TextContainer>
              <SkeletonDisplayText size="medium" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const actual_page = data?.rating_id ? (
    <Page
      title={data?.post_title}
      subtitle={`${data?.createBy}, last update at ${dateandtime.format(
        new Date(data?.createAt),
        "DD/MM/YYYY HH:mm:ss"
      )} `}
    >
      <Layout>
        <Layout.Section>
          <Card title={null} sectioned>
            {Parser(data ? data?.post_content : " ")}
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  ) : (
    <Theme404 />
  );

  const pageMarkup = loading ? loadingPageMarkup : actual_page;

  return <div id="general_page">{pageMarkup}</div>;
}
