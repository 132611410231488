import React from "react";
import { useParams } from "react-router-dom";
import Theme404 from "../../layout/404";

import banner_list from "./banner.list";
// import banner_edit from './banner.edit';
import banner_view from "./banner.view";

/**
 *   Create index file for Banner
 */

export default function List_banner() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || "list";

  let ActualPage: any;
  switch (Param) {
    case "list":
      ActualPage = banner_list;
      break;

    // case 'edit':
    //     ActualPage = banner_edit;
    // break;

    // case 'new':
    //     ActualPage = banner_edit;
    // break;

    case "view":
      ActualPage = banner_view;
      break;

    default:
      ActualPage = Theme404;
      break;
  }

  return <>{<ActualPage />}</>;
}

