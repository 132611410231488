import "@shopify/polaris/build/esm/styles.css";
import {
  Link as ReactRouterLink,
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { hot } from "react-hot-loader";
import { useAppDispatch, useAppSelector } from "config/store";

import viTranslations from "@shopify/polaris/locales/vi.json";
import { AppProvider, Navigation } from "@shopify/polaris";
import { useCookies } from "react-cookie";
import {
  separate_route as SeparateRoute,
  public_route,
  private_route,
} from "./config/router-define";
import AppFrame from "layout";
import Theme404 from "layout/404";
import { getCurrentUser, login } from "store/user.store.reducer";
import { useCallback, useEffect, useState } from "react";

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;
function NtsLink({ children, url, external, ref, ...rest }) {
  // react-router only supports links to pages it can handle itself. It does not
  // support arbirary links, so anything that is not a path-based link should
  // use a reglar old `a` tag
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    rest.target = "_blank";
    rest.rel = "noopener noreferrer";
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
}

const baseHref = document
  .querySelector("base")
  ?.getAttribute("href")
  ?.replace(/\/$/, "");
/**
 * Dark mode system ...
 */
let CheckSystemDarkMode = window?.matchMedia(
  "(prefers-color-scheme: dark)"
)?.matches;

export const App = () => {
  const [cookies] = useCookies(["isDarkTheme"]);
  const dispatch = useAppDispatch();

  //const [{isDarkTheme}] = useSharedState();
  //console.warn(cookies.isDarkTheme);
  const isDarkTheme =
    cookies.isDarkTheme || (CheckSystemDarkMode ? "dark" : "light");
  /*
  const theme = { 
    colors: {
      surface: '#FFFFFF',
      onsurface: '#212B36',
    }
   };
*/
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
  const isAuthenticating = useAppSelector(
    (state) => state.user.isAuthenticating
  );
  const account = useAppSelector((state) => state.user.account);

  const loading = useAppSelector((state) => state.user.loading);

  useEffect(() => {
    if (!isAuthenticating) {
    }
  }, [isAuthenticating]);

  useEffect(() => {
    // dispatch(getCurrentUser(account._id));
    // dispatch(getCurrentUser());
  }, []);

  // useEffect(() => {

  //   let user_id = account?._id;
  //   let tricky_id = "640adbd540f5c327668e65fa";
  //   if (account?._id) {
  //     dispatch(getCurrentUser(user_id));
  //   } else {
  //     dispatch(getCurrentUser(tricky_id));
  //   }
  // }, [isAuthenticated]);

  // useEffect(() => {
  //   // if (account && account?._id) {
  //   //   localStorage.setItem("AJAX_USERID", account?._id);
  //   // }
  //   getCurrentUser(localStorage.getItem("AJAX_USERID"));
  // }, [isAuthenticated]);

  function PrivateRoute({ children }) {
    if (!isAuthenticated && !isAuthenticating) {
      return <Navigate to={"/login#redirect"} replace />;
    } else {
      return children;
    }
  }

  return (
    <BrowserRouter basename={baseHref}>
      <AppProvider i18n={viTranslations} linkComponent={NtsLink}>
        <Routes>
          {SeparateRoute.map((Routex, index) => (
            <Route key={index} path={Routex.path} element={<Routex.main />} />
          ))}
          {public_route.map((Routex, index) => (
            <Route
              key={`public_${index}`}
              path={Routex.path}
              element={
                <AppFrame>
                  <Routex.main />
                </AppFrame>
              }
            />
          ))}
          {private_route.map((Routex, index) => (
            <Route
              key={`private_${index}`}
              path={Routex.path}
              element={
                <AppFrame>
                  <PrivateRoute>
                    <Routex.main />
                  </PrivateRoute>
                </AppFrame>
              }
            />
          ))}
          <Route key={99999} path="*" element={<Theme404 />} />
        </Routes>
      </AppProvider>
    </BrowserRouter>
  );
};

export default hot(module)(App);
