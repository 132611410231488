import {
  Autocomplete,
  Card,
  EmptyState,
  Form,
  FormLayout,
  Icon,
  Layout,
  Modal,
  Page,
  PageActions,
  RadioButton,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  Toast,
  DatePicker,
  TextStyle,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";

import emptyIMG from "../../media/empty.png";
import {
  clearError,
  getEntity,
  updateEntity,
  deleteEntity,
  createEntity,
  reset,
} from "../../store/subscription.store.reducer";

import { getEntities as getUsers, getEntity as getUser, searchUsers as searchUsers } from "store/users.store.reducer";

import { getEntities as getPlans, getEntity as getPlan, getEntitiesversion } from "../../store/plan.store.reducer";

import { lengthLessThan, lengthMoreThan, useField, useForm, notEmpty } from "@shopify/react-form";
import { SearchMinor } from "@shopify/polaris-icons";

import helpers from "helpers";

export default function Edit_subscription() {
  const entity = useAppSelector((state) => state.subscription.entity);
  const updating = useAppSelector((state) => state.subscription.updating);
  const entities = useAppSelector((state) => state.subscription.entities);
  const loading = useAppSelector((state) => state.subscription.loading);
  const errorMessage = useAppSelector((state) => state.subscription.errorMessage);
  const updateSuccess = useAppSelector((state) => state.subscription.updateSuccess);

  const entitieuser = useAppSelector((state) => state.users.entity);
  const entitieusers = useAppSelector((state) => state.users.entities);

  const entityplan = useAppSelector((state) => state.plan.entity);
  const entityplans = useAppSelector((state) => state.plan.entities);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  // jamviet.com
  const [message, setMessage] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const [notification, setNotification] = useState(null);

  const toggleActive = useCallback(() => {
    setMessage(null);
    dispatch(clearError());
  }, []);

  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.subscription_slug || false;

  /* Date picker */
  function pad(num: number, size = 2) {
    let numbr = num.toString();
    while (numbr.length < size) numbr = "0" + numbr;
    return numbr;
  }

  /**
   * Format date and time start
   */
  function extractTimeAndDateFromSource(source: string) {
    let DateObject = new Date();
    try {
      if (source) {
        DateObject = new Date(String(source));
      }
      const _date =
        pad(DateObject.getDate(), 2) + "/" + pad(DateObject.getMonth() + 1, 2) + "/" + DateObject.getFullYear();
      const _timestamp = DateObject.getTime();
      return {
        date: _date,
        timestamp: _timestamp,
        day: pad(DateObject.getDate(), 2),
        month: DateObject.getMonth(),
        year: DateObject.getFullYear(),
      };
    } catch (_) {
      return {
        date: "",
        timestamp: 0,
        day: 0,
        month: 0,
        year: 0,
      };
    }
  }

  /**
   * Start date
   */

  const today_active = extractTimeAndDateFromSource("");
  const [{ month_start, year_start }, setDateStart] = useState({
    month_start: today_active.month,
    year_start: today_active.year,
  });
  const [selectedDateStart, setSelectedDateStart] = useState({
    start: new Date(),
    end: new Date(),
  });

  const handleMonthChangeStart = useCallback(
    (_month, _year) => setDateStart({ month_start: _month, year_start: _year }),
    []
  );

  /**
   * End date
   */

  const [{ month_end, year_end }, setDateEnd] = useState({
    month_end: today_active.month,
    year_end: today_active.year,
  });
  const [selectedDateEnd, setSelectedDateEnd] = useState({
    start: new Date(),
    end: new Date(),
  });

  const handleMonthChangeEnd = useCallback((_month, _year) => setDateEnd({ month_end: _month, year_end: _year }), []);

  /**
   * Option status
   */

  const [status, setStatus] = useState("active");

  const statusList = [
    { label: "active", value: "active" },
    { label: "deactivate", value: "deactivate" },
    { label: "expired", value: "expired" },
  ];

  const handleSelectChange = useCallback((value) => setStatus(value), []);

  /**
   * Create user
   */

  const [inputValueUsers, setInputValueUsers] = useState("");
  const [optionUsers, setOptionUsers] = useState([]);
  const [selectedOptionUsers, setSelectedOptionUsers] = useState([]);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    // dispatch(getPlans({}));
    dispatch(getEntitiesversion({}));
    // dispatch(getUsers({}));

    if (Param) dispatch(getEntity(Param));
    else dispatch(reset());
  }, []);

  useEffect(() => {
    if (entity) {
      dispatch(getPlan(entity?.plan_id));
      dispatch(getUser(entity?.user_id?._id ? entity?.user_id?._id : entity?.user_id));
      const isoStr1 = entity?.start_at;
      const dateStart = new Date(isoStr1?.slice(0, -1));

      const isoStr2 = entity?.end_at;
      const dateEnd = new Date(isoStr2?.slice(0, -1));

      if (Param) {
        setStatus(entity?.status);
        setSelectedDateStart({ start: dateStart, end: dateStart });
        setDateStart({ month_start: dateStart.getMonth(), year_start: dateStart.getFullYear() });
        setSelectedDateEnd({ start: dateEnd, end: dateEnd });
        setDateEnd({ month_end: dateEnd.getMonth(), year_end: dateEnd.getFullYear() });
        setInputValueUsers(entity?.user_id.display_name);
      } else {
        setStatus("active");
        setSelectedDateStart({ start: new Date(), end: new Date() });
        setDateStart({ month_start: today_active.month, year_start: today_active.year });
        setSelectedDateEnd({ start: new Date(), end: new Date() });
        setDateEnd({ month_end: today_active.month, year_end: today_active.year });
      }
    }
  }, [entity]);

  /**
   * Get data user
   */

  const deselectedUsers = entitieusers?.map(({ _id, display_name }) => {
    return {
      label: String(display_name),
      value: String(_id),
    };
  });

  const updateUser = useCallback(
    (value) => {
      dispatch(searchUsers(value));

      setInputValueUsers(value);

      if (value === "") {
        setOptionUsers(deselectedUsers);
        return;
      }

      // const filterRegex = new RegExp(value, "i");
      // const resultOptionUsers = deselectedUsers.filter((option) => option.label.match(filterRegex));
      // setOptionUsers(resultOptionUsers);
    },
    [deselectedUsers]
  );

  useEffect(() => {
    const filterRegex = new RegExp(inputValueUsers, "i");
    const resultOptionUsers = deselectedUsers.filter((option) => option.label.match(filterRegex));
    setOptionUsers(resultOptionUsers);
  }, [entitieusers]);

  const updateSelectionUsers = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = optionUsers.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.label;
      });

      setSelectedOptionUsers(selected);
      setInputValueUsers(selectedValue[0]);
      setUserId(selected[0]);
    },
    [optionUsers]
  );

  const userField = (
    <Autocomplete.TextField
      autoComplete={null}
      onChange={updateUser}
      label=""
      value={inputValueUsers}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder="Search user"
      disabled={Param ? true : false}
    />
  );

  /**
   * Plan list
   */

  const [planList, setPlanList] = useState([]);

  useEffect(() => {
    const optionType = [
      {
        label: "",
        value: "0",
      },
    ];
    for (let { _id, name } of entityplans) {
      optionType.push({
        label: String(name),
        value: String(_id),
      });
    }
    setPlanList(optionType);
  }, [entityplans]);

  /**
   * Select plan
   */

  const [post_plan_selected, setPost_plan_selected] = useState("");
  const handleNewChange = useCallback((value) => {
    setPost_plan_selected(value);
  }, []);

  useEffect(() => {
    if (entityplan) {
      if (Param) {
        setPost_plan_selected(entityplan?._id);
      } else {
        setPost_plan_selected("");
      }
    }
  }, [entityplan]);

  useEffect(() => {
    if (updateSuccess) {
      history("/subscription/edit/" + entity?._id, {
        replace: true,
      });
      dispatch(getEntity(Param));

      setNotification("This subscription has been updated!");
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (errorMessage) {
      setNotification(errorMessage);
      dispatch(clearError());
    }
  }, [errorMessage]);

  function _deleteEntityAction() {
    setOpenModal(true);
  }

  function onModalAgree() {
    dispatch(deleteEntity(Param));
    setOpenModal(false);
  }

  const useFields = {};

  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset,
    submitErrors,
    makeClean,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        const datestart = new Date(selectedDateStart.start).getDate();
        const dateend = new Date(selectedDateEnd.start).getDate();

        if (!Param) {
          // create new
          dispatch(
            createEntity({
              user_id: userId,
              plan_id: post_plan_selected,
              start_at: `${year_start}-${pad(month_start + 1, 2)}-${pad(datestart)}`,
              end_at: `${year_end}-${pad(month_end + 1, 2)}-${pad(dateend)}`,
              status: status,
            })
          );
        } else {
          dispatch(
            updateEntity({
              _id: entity._id,
              start_at: `${year_start}-${pad(month_start + 1, 2)}-${pad(datestart)}`,
              end_at: `${year_end}-${pad(month_end + 1, 2)}-${pad(dateend)}`,
              status: status,
            })
          );
        }
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message = e?.response?.data?.title ?? "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  const emptyData = (
    <Page title="Subscription" breadcrumbs={[{ content: "Subscription list", url: "/subscription" }]}>
      <EmptyState heading="No post here!" image={emptyIMG}>
        <p>This record maybe not exist!</p>
      </EmptyState>
    </Page>
  );

  const toastMarkup = notification ? <Toast content={notification} onDismiss={toggleActive} /> : null;

  const toastNotification = message ? <Toast content={message} onDismiss={toggleActive} /> : null;

  const Actual_page =
    entity || !Param ? (
      <>
        <Page
          title="Subscription"
          breadcrumbs={[{ content: "Subscription list", url: "/subscription" }]}
          primaryAction={{
            content: "Save",
            // disabled: !dirty,
            loading: updating,
            onAction: submit,
          }}
        >
          <Layout>
            <Layout.Section>
              <Form onSubmit={submit}>
                <Card>
                  <Card.Section>
                    <FormLayout>
                      <div>User</div>
                      <Autocomplete
                        options={optionUsers}
                        selected={selectedOptionUsers}
                        onSelect={updateSelectionUsers}
                        textField={userField}
                      />
                      <Select
                        requiredIndicator
                        label="Plan"
                        options={planList}
                        onChange={handleNewChange}
                        value={post_plan_selected?.toString()}
                        disabled={Param ? true : false}
                      />

                      <TextStyle>Start At</TextStyle>
                      <DatePicker
                        month={month_start}
                        year={year_start}
                        onChange={setSelectedDateStart}
                        onMonthChange={handleMonthChangeStart}
                        selected={selectedDateStart}
                      />

                      <TextStyle>End At</TextStyle>
                      <DatePicker
                        month={month_end}
                        year={year_end}
                        onChange={setSelectedDateEnd}
                        onMonthChange={handleMonthChangeEnd}
                        selected={selectedDateEnd}
                        disableDatesBefore={new Date(selectedDateStart.start)}
                      />

                      <Select
                        label="Status"
                        options={statusList}
                        requiredIndicator
                        onChange={handleSelectChange}
                        value={status}
                      />
                    </FormLayout>
                  </Card.Section>
                </Card>
              </Form>
            </Layout.Section>
          </Layout>
          <PageActions
            primaryAction={{
              content: "Save",
              onAction: submit,
              // disabled: !dirty,
              loading: updating,
            }}
            secondaryActions={
              Param
                ? [
                    {
                      content: "Delete",
                      destructive: true,
                      onAction: _deleteEntityAction,
                    },
                  ]
                : []
            }
          />
        </Page>
      </>
    ) : (
      emptyData
    );

  const skeleton_loading = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const _Modal = openModal ? (
    <Modal
      activator={null}
      open={true}
      onClose={() => setOpenModal(false)}
      title="Delete entity?"
      primaryAction={{
        content: "Delete",
        onAction: onModalAgree,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>After delete, you can not undo this action!</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  ) : null;

  return (
    <>
      {_Modal}
      {toastMarkup}
      {toastNotification}
      {loading ? skeleton_loading : Actual_page}
    </>
  );
}

