import React from "react";
import { useParams } from "react-router-dom";
import Theme404 from "../../layout/404";

import report_list from "./report.list";
import report_edit from "./report.edit";

/**
 *   Create index file for Report
 */

export default function List_report() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || "list";

  let ActualPage: any;
  switch (Param) {
    case "list":
      ActualPage = report_list;
      break;

    case "edit":
      ActualPage = report_edit;
      break;

    case "new":
      ActualPage = report_edit;
      break;

    default:
      ActualPage = Theme404;
      break;
  }

  return <>{<ActualPage />}</>;
}

