import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import {
  Button,
  Card,
  ChoiceList,
  Collapsible,
  FormLayout,
  Modal,
  Page,
  PageActions,
  Select,
  TextField,
  Toast,
} from "@shopify/polaris";
import {
  clearError,
  clearMessage,
  getEntities,
  reset,
  getEntity,
  createEntity,
  updateEntity,
} from "store/settings.store.reducer";

import { useField, useForm } from "@shopify/react-form";
import axios from "axios";

export default function General() {
  const entity = useAppSelector((state) => state.settings.entity);
  const entities = useAppSelector((state) => state.settings.entities);
  const loading = useAppSelector((state) => state.settings.loading);
  const updating = useAppSelector((state) => state.settings.updating);
  const message = useAppSelector((state) => state.settings.message);
  const system_information = useAppSelector((state) => state.system.entity);
  const systemloading = useAppSelector((state) => state.system.updating);
  const updateSuccess = useAppSelector(
    (state) => state.vn_message.updateSuccess
  );
  const errorMessage = useAppSelector(
    (state) => state.user_question.errorMessage
  );

  const dispatch = useAppDispatch();

  /**
   * Initial
   */
  useEffect(() => {
    // dispatch(getEntities());
    dispatch(getEntity(type_selected));
  }, []);

  const [apiType, setApiType] = useState([]);

  useEffect(() => {
    axios
      // .get(
      //   `https://mainapi.whiteg.appuni.io/api/config/list/type
      // `
      // )

      // .get(
      //   `${localStorage.getItem("AJAX_URL")}/config/list/type
      // `
      // )

      .get(
        `/config/list/type
      `
      )

      .then((res) => {
        const data = res.data.config.option_content;

        setApiType(data);
      });
  }, []);

  /**
   * Type list
   */

  const [urlType, setUrlType] = useState([]);

  useEffect(() => {
    const optionType = [
      {
        label: "type",
        value: "type",
      },
    ];
    for (let { key, value } of apiType) {
      optionType.push({
        label: String(key),
        value: String(value),
      });
    }
    setUrlType(optionType);
  }, [apiType]);

  const [type_selected, setType_selected] = useState("type");

  const handNewChange = useCallback((value) => {
    setType_selected(value);
    dispatch(getEntity(value));
    setOpenDataFilter(true);
    setOpen(true);
  }, []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  //useParam = useLocation();

  /**
   * Jamviet.com
   */

  const [optionContent, setOptionContent] = useState<any>(null);
  useEffect(() => {
    if (entity && typeof entity?.config?.option_content !== "undefined") {
      setOptionContent(entity?.config?.option_content);
    } else {
      setOptionContent(null);
    }
  }, [entity]);

  /**
   * Modal  -------------------------------
   */

  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const ModalAddContent = () => {
    setShowEditModal(true);
  };

  const closeModal = useCallback(() => {
    setShowEditModal((active) => !active);
  }, [showEditModal]);

  const [valueContent, setValueContent] = useState("");
  const handleChangeValue = useCallback((newValue) => {
    setValueContent(newValue);
  }, []);

  const [keyContent, setKeyContent] = useState("");

  const handleChangeKey = useCallback(
    (newValue) => setKeyContent(newValue),
    []
  );

  const saveForm = () => {
    let newContent = [];
    if (optionContent) {
      newContent = [
        ...optionContent,
        ...[{ key: keyContent, value: valueContent }],
      ];
    } else {
      newContent = [{ key: keyContent, value: valueContent }];
    }

    setOptionContent(newContent);
    setValueContent("");
    setKeyContent("");
    setShowEditModal(false);
  };

  const contentModal = (
    <Modal
      open={showEditModal}
      onClose={closeModal}
      title="View Notification"
      titleHidden
      primaryAction={{
        content: "Save",
        onAction: saveForm,
      }}
      secondaryActions={[
        {
          content: "Close",
          onAction: closeModal,
        },
      ]}
    >
      <Modal.Section>
        {" "}
        <FormLayout>
          <FormLayout.Group condensed>
            <TextField
              label="Key"
              value={keyContent}
              onChange={handleChangeKey}
              autoComplete="off"
            />
            <TextField
              label="Value"
              value={valueContent}
              onChange={handleChangeValue}
              autoComplete="off"
            />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  /**
   * ------------------------------- End
   */

  /**
   * Data Filter -------------------------------
   */
  const [dataFilter, setDataFilter] = useState<any>(null);

  useEffect(() => {
    if (entity) {
      setDataFilter(entity?.config?.data_filter);
    }
  }, [entity]);

  /**
   * Modal Data Filter
   */

  const [showEditModalDataFilter, setShowEditModalDataFilter] =
    useState<boolean>(false);

  const ModalAddDataFilter = () => {
    setShowEditModalDataFilter(true);
  };

  const [valueDataFilter, setValueDataFilter] = useState("");
  const handleChangeDataFilter = useCallback(
    (newValue) => setValueDataFilter(newValue),
    []
  );

  const closeModalDataFilter = useCallback(() => {
    setShowEditModalDataFilter((active) => !active);
  }, [showEditModalDataFilter]);

  const saveFormDataFilter = () => {
    let newContent = [];
    if (dataFilter) {
      newContent = [...dataFilter, ...[valueDataFilter]];
    } else {
      newContent = [valueDataFilter];
    }
    setDataFilter(newContent);
    setValueDataFilter("");
    setShowEditModalDataFilter(false);
  };

  const contentModalDataFilter = (
    <Modal
      open={showEditModalDataFilter}
      onClose={closeModalDataFilter}
      title="Add Text"
      titleHidden
      primaryAction={{
        content: "Save",
        onAction: saveFormDataFilter,
      }}
      secondaryActions={[
        {
          content: "Close",
          onAction: closeModalDataFilter,
        },
      ]}
    >
      <Modal.Section>
        {" "}
        <FormLayout>
          <FormLayout.Group condensed>
            <TextField
              label="Text"
              value={valueDataFilter}
              onChange={handleChangeDataFilter}
              autoComplete="off"
            />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  /**
   * -------------------- End
   */

  const useFields = {
    type: useField<string>({
      value: entity?.config?.type ?? "",
      validates: [],
    }),
  };
  useParam = useParams();

  const { submit, fields } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!entity?.config?._id) {
          dispatch(
            createEntity({
              type: type_selected,
              option_content: JSON.stringify(optionContent),
              data_filter: JSON.stringify(dataFilter),
            })
          );
        } else {
          dispatch(
            updateEntity({
              _id: entity?.config?._id,
              type: type_selected,
              option_content: JSON.stringify(optionContent),
              data_filter: JSON.stringify(dataFilter),
            })
          );
        }
        reset();
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message =
          e?.response?.data?.title ??
          "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  function handChangeKeyChange(e: any, _id: any) {
    let newContent = [];

    optionContent.map((element, index) => {
      if (index === _id) {
        element = {
          ...element,
          ...{
            key: e,
          },
        };
      }
      newContent.push(element);
    });

    setOptionContent(newContent);
  }

  function handChangeValueChange(e: any, _id: any) {
    let newContent = [];

    optionContent.map((element, index) => {
      if (index === _id) {
        element = {
          ...element,
          ...{
            value: e,
          },
        };
      }
      newContent.push(element);
    });

    setOptionContent(newContent);
  }

  function handChangeDescriptionChange(e: any, _id: any) {
    let newContent = [];

    optionContent.map((element, index) => {
      if (index === _id) {
        element = {
          ...element,
          ...{
            description: e,
          },
        };
      }
      newContent.push(element);
    });

    setOptionContent(newContent);
  }

  function handChangeTypeChange(e: any, _id: any) {
    let newContent = [];
    for (let optionElement of optionContent) {
      if (optionElement._id === _id) {
        optionElement = {
          ...optionElement,
          ...{
            config_type: e,
          },
        };
      }
      newContent.push(optionElement);
    }

    setOptionContent(newContent);
  }

  function handChangeDataFilter(e: any, _id: any) {
    let newContent = [];

    dataFilter.map((element, index) => {
      if (index === _id) {
        element = e;
      }
      newContent.push(element);
    });
    setDataFilter(newContent);
  }

  useEffect(() => {
    if (updateSuccess) {
      // dispatch(getEntity(type));
      dispatch(getEntity(type_selected));
      setNotification("Settings has been saved!");
    }
  }, [updateSuccess]);

  const [notification, setNotification] = useState(null);
  // const [message, setMessage] = useState(null);

  useEffect(() => {
    if (errorMessage) {
      setNotification(errorMessage);
      dispatch(clearError());
    }
  }, [errorMessage]);

  const toggleActive = useCallback(() => {
    setNotification(null);
    dispatch(clearError());
  }, []);

  const toastNotification = notification ? (
    <Toast content={notification} duration={4500} onDismiss={toggleActive} />
  ) : null;

  /**
   * Collapse Option Content
   */

  const [open, setOpen] = useState(true);

  const handleToggle = useCallback(() => setOpen((open) => !open), []);

  /**
   * Collapse Data Filter
   */

  const [openDataFilter, setOpenDataFilter] = useState(true);

  const handleToggleDataFilter = useCallback(
    () => setOpenDataFilter((openDataFilter) => !openDataFilter),
    []
  );

  return (
    <>
      {toastNotification}
      <Page
        // narrowWidth
        fullWidth
        title="Cài đặt chung"
        primaryAction={{
          content: "Save",
          onAction: submit,
          disabled: false,
          loading: updating,
        }}
      >
        <Card>
          <Card.Section title="Config">
            <FormLayout>
              <FormLayout.Group condensed>
                <Select
                  requiredIndicator
                  label="Type"
                  options={urlType}
                  onChange={handNewChange}
                  value={type_selected}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>

        <Card>
          <Card.Section title="Option Content (Cài đặt hệ thống)">
            <Button
              onClick={handleToggle}
              ariaExpanded={open}
              ariaControls="basic-collapsible"
            >
              Toggle
            </Button>

            <Collapsible
              open={open}
              id="basic-collapsible"
              transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
              expandOnPrint
            >
              {optionContent &&
                optionContent.map(
                  // ({ key, value, description, config_type, _id }) => {
                  (ele, idx) => {
                    return (
                      <>
                        <FormLayout.Group condensed>
                          <TextField
                            key={ele?._id + "key"}
                            label="Key"
                            value={ele?.key}
                            onChange={(e) => handChangeKeyChange(e, idx)}
                            autoComplete="off"
                          />

                          <TextField
                            key={ele?._id + "value"}
                            label="Value"
                            value={ele?.value}
                            multiline={true}
                            // onChange={() => {}}
                            onChange={(e) => handChangeValueChange(e, idx)}
                            autoComplete="off"
                          />

                          <TextField
                            key={ele?._id + "description"}
                            label="Description"
                            value={ele?.description}
                            multiline={true}
                            // onChange={() => {}}
                            onChange={(e) =>
                              handChangeDescriptionChange(e, idx)
                            }
                            autoComplete="off"
                          />

                          {/* <TextField
                          key={_id + "config_type"}
                          label="Type"
                          value={config_type}
                          multiline={true}
                          // onChange={() => {}}
                          onChange={(e) => handChangeTypeChange(e, _id)}
                          autoComplete="off"
                        /> */}
                        </FormLayout.Group>
                      </>
                    );
                  }
                )}
              <br />
              {contentModal}
              <Button primary onClick={ModalAddContent}>
                Add
              </Button>
            </Collapsible>
          </Card.Section>
        </Card>

        <Card>
          <Card.Section title="Data filter (Thông báo đến người dùng)">
            <Button
              onClick={handleToggleDataFilter}
              ariaExpanded={openDataFilter}
              ariaControls="basic-collapsible"
            >
              Toggle
            </Button>

            <Collapsible
              open={openDataFilter}
              id="basic-collapsible"
              transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
              expandOnPrint
            >
              {dataFilter &&
                dataFilter.map((ele, idx) => {
                  return (
                    <>
                      <TextField
                        label="Text"
                        autoComplete="off"
                        value={ele}
                        onChange={(e) => handChangeDataFilter(e, idx)}
                      />
                    </>
                  );
                })}
              <br />
              {contentModalDataFilter}
              <Button primary onClick={ModalAddDataFilter} disabled={!entity}>
                Add
              </Button>
            </Collapsible>
          </Card.Section>
        </Card>

        <PageActions
          primaryAction={{
            content: "Save",
            onAction: submit,
            disabled: false,
            loading: updating,
          }}
        />
      </Page>
    </>
  );
}
