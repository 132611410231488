import { Card, DataTable, EmptyState, Layout, Page, Toast } from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import debounce from "lodash.debounce";
import { TickSmallMinor, LockMinor } from "@shopify/polaris-icons";
import emptyIMG from "../../media/empty.png";
import Pagination from "../../components/pagination";
import { clearError, getEntities } from "../../store/chat.store.reducer";
import helpers from "helpers";
import dateandtime from "date-and-time";
import SkeletonLoading from "components/skeleton_loading";

export default function General_chat() {
  const entities = useAppSelector((state) => state.chat.entities);
  const loading = useAppSelector((state) => state.chat.loading);
  const errorMessage = useAppSelector((state) => state.chat.errorMessage);
  const totalItems = useAppSelector((state) => state.chat.totalItems);

  const [initial_loading, setInitial_loading] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery = helpers.ExtractUrl(useParam.search) || false;

  const [mainQuery, setMainQuery] = useState({
    ...{
      query: "",
      page: 1,
      limit: 20,
      order_by: "DESC",
    },
    ...StringQuery,
  });
  const [queryValue, setQueryValue] = useState("");

  const [input, setInput] = useState("");

  useEffect(() => {
    setMainQuery({ ...mainQuery, ...{ query: input } });
  }, [input]);

  /**
   * Change page number
   */

  const [numberPage, setNumberPage] = useState(1);
  const onChangePageNumber = useCallback((numPage) => {
    setNumberPage(numPage);
  }, []);

  useEffect(() => {
    setMainQuery({ ...mainQuery, page: numberPage });
  }, [numberPage]);

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch) history("/chat" + buildURLSearch);
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  const onChangeCallback = useMemo(
    () =>
      debounce((_value) => {
        if (_value !== "") setMainQuery({ ...mainQuery, query: _value ? _value : "" });
      }, 500),
    []
  );

  useEffect(() => {
    onChangeCallback(queryValue);
  }, [queryValue]);

  useEffect(() => {
    if (loading === false) setInitial_loading(false);
  }, [loading]);

  /**
   *
   * @param chat_room_user_option_id
   */
  const shortcutActions = (chat_room_user_option_id: number) => {
    history("edit/" + chat_room_user_option_id);
  };

  const emptyData = (
    <EmptyState heading="No post here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>
  );

  const renderItem = (chat: any) => {
    const { chat_room_user_option_id, chat_room_id, room_title, createAt } = chat;
    return [
      <div
        className="clickable"
        key={chat_room_user_option_id}
        // onClick={() => shortcutActions(chat_room_user_option_id)}
      >
        {chat_room_id._id}
      </div>,

      <div className="clickable" key={chat_room_user_option_id}>
        <div style={{ textAlign: "center" }}>{chat_room_id?._id}</div>
      </div>,

      <div className="clickable" key={chat_room_user_option_id}>
        {room_title}
      </div>,

      <div className="clickable" key={chat_room_user_option_id}>
        <div style={{ textAlign: "center" }}>{chat_room_id?.room_type}</div>
      </div>,

      <div className="clickable" key={chat_room_user_option_id}>
        <div style={{ textAlign: "center" }}>{chat_room_id?.duration}</div>
      </div>,

      <div className="clickable" key={chat_room_user_option_id}>
        <div style={{ textAlign: "center" }}>{chat_room_id?.room_limit_number}</div>
      </div>,

      <div className="clickable" key={chat_room_user_option_id}>
        <div style={{ textAlign: "center" }}>{chat_room_id?.chat_history_count}</div>
      </div>,

      <div className="clickable" key={chat_room_user_option_id}>
        <time>{createAt ? dateandtime.format(new Date(createAt), "DD-MM-YYYY HH:mm:ss") : "-"}</time>
      </div>,
    ];
  };
  const PagesList = (
    // totalItems > 0 ? (
    <>
      <DataTable
        columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text"]}
        headings={["ID", "RoomID", "Title", "Type", "Duration", "Limit Number", "Chat History Count", "Create At"]}
        rows={entities?.map(renderItem)}
        hideScrollIndicator
        footerContent={`Display page ${mainQuery.page} of total ${totalItems} results...`}
      />
      <style>{`
        .small-icon {
          margin-left: 0.7rem;
          font-size: 12px;
          padding: 0;
          width: 15px;
          height: auto;
        }
      `}</style>
    </>
  );
  // ) : (
  //   emptyData
  // );

  const Actual_page = (
    <>
      <Page title="Chat">
        <Layout>
          <Layout.Section>
            <Card>{PagesList}</Card>
            <br />
            {/* {totalItems > mainQuery.limit ? ( */}
            <Pagination
              TotalRecord={totalItems}
              activeCurrentPage={mainQuery.page}
              pageSize={mainQuery.limit}
              onChangePage={onChangePageNumber}
            />
            {/* ) : null} */}
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );

  const toastMarkup = errorMessage ? <Toast content={errorMessage} error onDismiss={toggleActive} /> : null;

  return (
    <>
      {toastMarkup}
      {initial_loading ? <SkeletonLoading /> : Actual_page}
    </>
  );
}

