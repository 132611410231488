import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';

export const useMUIField = (name, initialValue, validators) => {
  const { register, formState: { errors } } = useForm();
  const inputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  const validate = (value) => {
    for (const validator of validators) {
      const error = validator(value);
      if (error) {
        setErrorMessage(error);
        return false;
      }
    }
    setErrorMessage('');
    return true;
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (!validate(value)) return;
    register(name, {
      validate: validate,
      value: value,
    });
  };

  return {
    inputRef,
    errorMessage,
    handleChange,
  };
};
