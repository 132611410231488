const countriesData = [
  { id: 1, value: "", alpha3: "", label: "Country" },
  { id: 4, value: "af", alpha3: "afg", label: "Afghanistan" },
  { id: 8, value: "al", alpha3: "alb", label: "Albania" },
  { id: 12, value: "dz", alpha3: "dza", label: "Algeria" },
  { id: 20, value: "ad", alpha3: "and", label: "Andorra" },
  { id: 24, value: "ao", alpha3: "ago", label: "Angola" },
  { id: 28, value: "ag", alpha3: "atg", label: "Antigua and Barbuda" },
  { id: 32, value: "ar", alpha3: "arg", label: "Argentina" },
  { id: 51, value: "am", alpha3: "arm", label: "Armenia" },
  { id: 36, value: "au", alpha3: "aus", label: "Australia" },
  { id: 40, value: "at", alpha3: "aut", label: "Austria" },
  { id: 31, value: "az", alpha3: "aze", label: "Azerbaijan" },
  { id: 44, value: "bs", alpha3: "bhs", label: "Bahamas" },
  { id: 48, value: "bh", alpha3: "bhr", label: "Bahrain" },
  { id: 50, value: "bd", alpha3: "bgd", label: "Bangladesh" },
  { id: 52, value: "bb", alpha3: "brb", label: "Barbados" },
  { id: 112, value: "by", alpha3: "blr", label: "Belarus" },
  { id: 56, value: "be", alpha3: "bel", label: "Belgium" },
  { id: 84, value: "bz", alpha3: "blz", label: "Belize" },
  { id: 204, value: "bj", alpha3: "ben", label: "Benin" },
  { id: 64, value: "bt", alpha3: "btn", label: "Bhutan" },
  {
    id: 68,
    value: "bo",
    alpha3: "bol",
    label: "Bolivia (Plurinational State of)",
  },
  { id: 70, value: "ba", alpha3: "bih", label: "Bosnia and Herzegovina" },
  { id: 72, value: "bw", alpha3: "bwa", label: "Botswana" },
  { id: 76, value: "br", alpha3: "bra", label: "Brazil" },
  { id: 96, value: "bn", alpha3: "brn", label: "Brunei Darussalam" },
  { id: 100, value: "bg", alpha3: "bgr", label: "Bulgaria" },
  { id: 854, value: "bf", alpha3: "bfa", label: "Burkina Faso" },
  { id: 108, value: "bi", alpha3: "bdi", label: "Burundi" },
  { id: 132, value: "cv", alpha3: "cpv", label: "Cabo Verde" },
  { id: 116, value: "kh", alpha3: "khm", label: "Cambodia" },
  { id: 120, value: "cm", alpha3: "cmr", label: "Cameroon" },
  { id: 124, value: "ca", alpha3: "can", label: "Canada" },
  { id: 140, value: "cf", alpha3: "caf", label: "Central African Republic" },
  { id: 148, value: "td", alpha3: "tcd", label: "Chad" },
  { id: 152, value: "cl", alpha3: "chl", label: "Chile" },
  { id: 156, value: "cn", alpha3: "chn", label: "China" },
  { id: 170, value: "co", alpha3: "col", label: "Colombia" },
  { id: 174, value: "km", alpha3: "com", label: "Comoros" },
  { id: 178, value: "cg", alpha3: "cog", label: "Congo" },
  {
    id: 180,
    value: "cd",
    alpha3: "cod",
    label: "Congo, Democratic Republic of the",
  },
  { id: 188, value: "cr", alpha3: "cri", label: "Costa Rica" },
  { id: 384, value: "ci", alpha3: "civ", label: "Côte d'Ivoire" },
  { id: 191, value: "hr", alpha3: "hrv", label: "Croatia" },
  { id: 192, value: "cu", alpha3: "cub", label: "Cuba" },
  { id: 196, value: "cy", alpha3: "cyp", label: "Cyprus" },
  { id: 203, value: "cz", alpha3: "cze", label: "Czechia" },
  { id: 208, value: "dk", alpha3: "dnk", label: "Denmark" },
  { id: 262, value: "dj", alpha3: "dji", label: "Djibouti" },
  { id: 212, value: "dm", alpha3: "dma", label: "Dominica" },
  { id: 214, value: "do", alpha3: "dom", label: "Dominican Republic" },
  { id: 218, value: "ec", alpha3: "ecu", label: "Ecuador" },
  { id: 818, value: "eg", alpha3: "egy", label: "Egypt" },
  { id: 222, value: "sv", alpha3: "slv", label: "El Salvador" },
  { id: 226, value: "gq", alpha3: "gnq", label: "Equatorial Guinea" },
  { id: 232, value: "er", alpha3: "eri", label: "Eritrea" },
  { id: 233, value: "ee", alpha3: "est", label: "Estonia" },
  { id: 748, value: "sz", alpha3: "swz", label: "Eswatini" },
  { id: 231, value: "et", alpha3: "eth", label: "Ethiopia" },
  { id: 242, value: "fj", alpha3: "fji", label: "Fiji" },
  { id: 246, value: "fi", alpha3: "fin", label: "Finland" },
  { id: 250, value: "fr", alpha3: "fra", label: "France" },
  { id: 266, value: "ga", alpha3: "gab", label: "Gabon" },
  { id: 270, value: "gm", alpha3: "gmb", label: "Gambia" },
  { id: 268, value: "ge", alpha3: "geo", label: "Georgia" },
  { id: 276, value: "de", alpha3: "deu", label: "Germany" },
  { id: 288, value: "gh", alpha3: "gha", label: "Ghana" },
  { id: 300, value: "gr", alpha3: "grc", label: "Greece" },
  { id: 308, value: "gd", alpha3: "grd", label: "Grenada" },
  { id: 320, value: "gt", alpha3: "gtm", label: "Guatemala" },
  { id: 324, value: "gn", alpha3: "gin", label: "Guinea" },
  { id: 624, value: "gw", alpha3: "gnb", label: "Guinea-Bissau" },
  { id: 328, value: "gy", alpha3: "guy", label: "Guyana" },
  { id: 332, value: "ht", alpha3: "hti", label: "Haiti" },
  { id: 340, value: "hn", alpha3: "hnd", label: "Honduras" },
  { id: 348, value: "hu", alpha3: "hun", label: "Hungary" },
  { id: 352, value: "is", alpha3: "isl", label: "Iceland" },
  { id: 356, value: "in", alpha3: "ind", label: "India" },
  { id: 360, value: "id", alpha3: "idn", label: "Indonesia" },
  { id: 364, value: "ir", alpha3: "irn", label: "Iran (Islamic Republic of)" },
  { id: 368, value: "iq", alpha3: "irq", label: "Iraq" },
  { id: 372, value: "ie", alpha3: "irl", label: "Ireland" },
  { id: 376, value: "il", alpha3: "isr", label: "Israel" },
  { id: 380, value: "it", alpha3: "ita", label: "Italy" },
  { id: 388, value: "jm", alpha3: "jam", label: "Jamaica" },
  { id: 392, value: "jp", alpha3: "jpn", label: "Japan" },
  { id: 400, value: "jo", alpha3: "jor", label: "Jordan" },
  { id: 398, value: "kz", alpha3: "kaz", label: "Kazakhstan" },
  { id: 404, value: "ke", alpha3: "ken", label: "Kenya" },
  { id: 296, value: "ki", alpha3: "kir", label: "Kiribati" },
  {
    id: 408,
    value: "kp",
    alpha3: "prk",
    label: "Korea (Democratic People's Republic of)",
  },
  { id: 410, value: "kr", alpha3: "kor", label: "Korea, Republic of" },
  { id: 414, value: "kw", alpha3: "kwt", label: "Kuwait" },
  { id: 417, value: "kg", alpha3: "kgz", label: "Kyrgyzstan" },
  {
    id: 418,
    value: "la",
    alpha3: "lao",
    label: "Lao People's Democratic Republic",
  },
  { id: 428, value: "lv", alpha3: "lva", label: "Latvia" },
  { id: 422, value: "lb", alpha3: "lbn", label: "Lebanon" },
  { id: 426, value: "ls", alpha3: "lso", label: "Lesotho" },
  { id: 430, value: "lr", alpha3: "lbr", label: "Liberia" },
  { id: 434, value: "ly", alpha3: "lby", label: "Libya" },
  { id: 438, value: "li", alpha3: "lie", label: "Liechtenstein" },
  { id: 440, value: "lt", alpha3: "ltu", label: "Lithuania" },
  { id: 442, value: "lu", alpha3: "lux", label: "Luxembourg" },
  { id: 450, value: "mg", alpha3: "mdg", label: "Madagascar" },
  { id: 454, value: "mw", alpha3: "mwi", label: "Malawi" },
  { id: 458, value: "my", alpha3: "mys", label: "Malaysia" },
  { id: 462, value: "mv", alpha3: "mdv", label: "Maldives" },
  { id: 466, value: "ml", alpha3: "mli", label: "Mali" },
  { id: 470, value: "mt", alpha3: "mlt", label: "Malta" },
  { id: 584, value: "mh", alpha3: "mhl", label: "Marshall Islands" },
  { id: 478, value: "mr", alpha3: "mrt", label: "Mauritania" },
  { id: 480, value: "mu", alpha3: "mus", label: "Mauritius" },
  { id: 484, value: "mx", alpha3: "mex", label: "Mexico" },
  {
    id: 583,
    value: "fm",
    alpha3: "fsm",
    label: "Micronesia (Federated States of)",
  },
  { id: 498, value: "md", alpha3: "mda", label: "Moldova, Republic of" },
  { id: 492, value: "mc", alpha3: "mco", label: "Monaco" },
  { id: 496, value: "mn", alpha3: "mng", label: "Mongolia" },
  { id: 499, value: "me", alpha3: "mne", label: "Montenegro" },
  { id: 504, value: "ma", alpha3: "mar", label: "Morocco" },
  { id: 508, value: "mz", alpha3: "moz", label: "Mozambique" },
  { id: 104, value: "mm", alpha3: "mmr", label: "Myanmar" },
  { id: 516, value: "na", alpha3: "nam", label: "Namibia" },
  { id: 520, value: "nr", alpha3: "nru", label: "Nauru" },
  { id: 524, value: "np", alpha3: "npl", label: "Nepal" },
  { id: 528, value: "nl", alpha3: "nld", label: "Netherlands" },
  { id: 554, value: "nz", alpha3: "nzl", label: "New Zealand" },
  { id: 558, value: "ni", alpha3: "nic", label: "Nicaragua" },
  { id: 562, value: "ne", alpha3: "ner", label: "Niger" },
  { id: 566, value: "ng", alpha3: "nga", label: "Nigeria" },
  { id: 807, value: "mk", alpha3: "mkd", label: "North Macedonia" },
  { id: 578, value: "no", alpha3: "nor", label: "Norway" },
  { id: 512, value: "om", alpha3: "omn", label: "Oman" },
  { id: 586, value: "pk", alpha3: "pak", label: "Pakistan" },
  { id: 585, value: "pw", alpha3: "plw", label: "Palau" },
  { id: 591, value: "pa", alpha3: "pan", label: "Panama" },
  { id: 598, value: "pg", alpha3: "png", label: "Papua New Guinea" },
  { id: 600, value: "py", alpha3: "pry", label: "Paraguay" },
  { id: 604, value: "pe", alpha3: "per", label: "Peru" },
  { id: 608, value: "ph", alpha3: "phl", label: "Philippines" },
  { id: 616, value: "pl", alpha3: "pol", label: "Poland" },
  { id: 620, value: "pt", alpha3: "prt", label: "Portugal" },
  { id: 634, value: "qa", alpha3: "qat", label: "Qatar" },
  { id: 642, value: "ro", alpha3: "rou", label: "Romania" },
  { id: 643, value: "ru", alpha3: "rus", label: "Russian Federation" },
  { id: 646, value: "rw", alpha3: "rwa", label: "Rwanda" },
  { id: 659, value: "kn", alpha3: "kna", label: "Saint Kitts and Nevis" },
  { id: 662, value: "lc", alpha3: "lca", label: "Saint Lucia" },
  {
    id: 670,
    value: "vc",
    alpha3: "vct",
    label: "Saint Vincent and the Grenadines",
  },
  { id: 882, value: "ws", alpha3: "wsm", label: "Samoa" },
  { id: 674, value: "sm", alpha3: "smr", label: "San Marino" },
  { id: 678, value: "st", alpha3: "stp", label: "Sao Tome and Principe" },
  { id: 682, value: "sa", alpha3: "sau", label: "Saudi Arabia" },
  { id: 686, value: "sn", alpha3: "sen", label: "Senegal" },
  { id: 688, value: "rs", alpha3: "srb", label: "Serbia" },
  { id: 690, value: "sc", alpha3: "syc", label: "Seychelles" },
  { id: 694, value: "sl", alpha3: "sle", label: "Sierra Leone" },
  { id: 702, value: "sg", alpha3: "sgp", label: "Singapore" },
  { id: 703, value: "sk", alpha3: "svk", label: "Slovakia" },
  { id: 705, value: "si", alpha3: "svn", label: "Slovenia" },
  { id: 90, value: "sb", alpha3: "slb", label: "Solomon Islands" },
  { id: 706, value: "so", alpha3: "som", label: "Somalia" },
  { id: 710, value: "za", alpha3: "zaf", label: "South Africa" },
  { id: 728, value: "ss", alpha3: "ssd", label: "South Sudan" },
  { id: 724, value: "es", alpha3: "esp", label: "Spain" },
  { id: 144, value: "lk", alpha3: "lka", label: "Sri Lanka" },
  { id: 729, value: "sd", alpha3: "sdn", label: "Sudan" },
  { id: 740, value: "sr", alpha3: "sur", label: "Suriname" },
  { id: 752, value: "se", alpha3: "swe", label: "Sweden" },
  { id: 756, value: "ch", alpha3: "che", label: "Switzerland" },
  { id: 760, value: "sy", alpha3: "syr", label: "Syrian Arab Republic" },
  { id: 762, value: "tj", alpha3: "tjk", label: "Tajikistan" },
  {
    id: 834,
    value: "tz",
    alpha3: "tza",
    label: "Tanzania, United Republic of",
  },
  { id: 764, value: "th", alpha3: "tha", label: "Thailand" },
  { id: 626, value: "tl", alpha3: "tls", label: "Timor-Leste" },
  { id: 768, value: "tg", alpha3: "tgo", label: "Togo" },
  { id: 776, value: "to", alpha3: "ton", label: "Tonga" },
  { id: 780, value: "tt", alpha3: "tto", label: "Trinidad and Tobago" },
  { id: 788, value: "tn", alpha3: "tun", label: "Tunisia" },
  { id: 792, value: "tr", alpha3: "tur", label: "Turkey" },
  { id: 795, value: "tm", alpha3: "tkm", label: "Turkmenistan" },
  { id: 798, value: "tv", alpha3: "tuv", label: "Tuvalu" },
  { id: 800, value: "ug", alpha3: "uga", label: "Uganda" },
  { id: 804, value: "ua", alpha3: "ukr", label: "Ukraine" },
  { id: 784, value: "ae", alpha3: "are", label: "United Arab Emirates" },
  {
    id: 826,
    value: "gb",
    alpha3: "gbr",
    label: "United Kingdom of Great Britain and Northern Ireland",
  },
  { id: 840, value: "us", alpha3: "usa", label: "United States of America" },
  { id: 858, value: "uy", alpha3: "ury", label: "Uruguay" },
  { id: 860, value: "uz", alpha3: "uzb", label: "Uzbekistan" },
  { id: 548, value: "vu", alpha3: "vut", label: "Vanuatu" },
  {
    id: 862,
    value: "ve",
    alpha3: "ven",
    label: "Venezuela (Bolivarian Republic of)",
  },
  { id: 704, value: "vn", alpha3: "vnm", label: "Viet Nam" },
  { id: 887, value: "ye", alpha3: "yem", label: "Yemen" },
  { id: 894, value: "zm", alpha3: "zmb", label: "Zambia" },
  { id: 716, value: "zw", alpha3: "zwe", label: "Zimbabwe" },
];
export default { countriesData };

