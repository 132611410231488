import Textarea from '@mui/joy/Textarea'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { Spinner } from '@shopify/polaris'
import { FaUpload } from 'react-icons/fa'

const CreateCategory = ({ mediaType, mediaSrc, handleSubmit, style, handleFileChangeAndUpload, handleChangeCourse, formData, isEdit, isUploading, removeImageCover, onClose }) => {
  const loadingElement = (
    <div style={{display: 'flex', alignItems: 'center', gap: 8}}>

  <Spinner accessibilityLabel="Spinner example" size="large" />
  <p>Uploading</p>
    </div>
  )
    return (
    <Box sx={style}>
        <Typography variant='h6' mb={2}>{isEdit ? 'Sửa' : 'Thêm mới'} danh mục Podcast</Typography>
        <Stack>
            <Box>
                <Stack direction='column' gap={1} >
                    <Typography variant='body1' fontWeight={550}>Tiêu đề:</Typography>
                    <TextField value={formData.category_title} onChange={(e) => handleChangeCourse("category_title", e.target.value)} name="title" fullWidth placeholder='Điền tiêu đề' size="small" sx={{ mb: 1 }}/>
                </Stack>
                

                <Stack direction='column' gap={1} >
                    <Typography variant='body1' fontWeight={550}>Mô tả:</Typography>
                    <Textarea color="neutral" value={formData.category_content} onChange={(e) => handleChangeCourse("category_content", e.target.value)} name="description" minRows={3} placeholder='Mô tả' sx={{ mb: 2 }}/>
                </Stack>

            </Box>
            <Box>
                <Typography variant='body1' fontWeight={550} mb={1}>Ảnh bìa danh mục:</Typography>
                <Box position={'relative'}>
                {
                    isUploading ? loadingElement : mediaType === 'image' && <>
                    <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                    <img src={mediaSrc} alt="Media preview" width={250} />
                    <p style={{textDecoration: 'underline'}} onClick={removeImageCover}>Xoá</p>
                    </div>
                    </>
                }
                {mediaType === 'video' && <video src={mediaSrc} controls width={250}></video>}
                </Box>
                {
                    mediaSrc == '' && !isUploading ? (
                        <Button
                    component="label"
                    variant="contained"
                    startIcon={<FaUpload />}
                    sx={{
                    width: '150px',
                    height: '80px',
                    background: 'transparent',
                    color: '#333',
                    fontWeight: 550,
                    border: '1px dashed',
                    marginBottom: 2,
                    '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.1)',
                    },
                        }}
                    >
                        Tải Ảnh bìa
                        <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChangeAndUpload}
                    />
                </Button>
                    ) : null
                }
            </Box>
              <Stack direction="row" gap={2} justifyContent="space-between" mt={2}>
            <Button fullWidth size='small' variant='outlined' onClick={onClose}>Thoát</Button>
            <Button fullWidth size='small' onClick={handleSubmit} variant='contained'>{isEdit ? 'Cập nhật' : 'Tạo'}</Button>
        </Stack>
        </Stack>
      
    </Box>
  )
}

export default CreateCategory