import axios from "axios";
import { createAsyncThunk, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import helpers from "helpers";
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from "../config/reducer.utils";
import { IUser_role, defaultIUser_role } from "../interface/user_role.model";
import { AppThunk } from "../config/store";

/**
 *   Reducer used for front-end, with user_role.model.ts
 *   Interface.ts can be use in both front-end and back-end! But prefer using user_role.model.ts
 */

const initialState = {
  loading: false,
  errorMessage: [] as any,
  entities: [],
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  user_id: "",
};

const apiUrl = "user-permission";

// Actions

export const getEntities = createAsyncThunk("user_role/fetch_entity_list", async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}`;
  return axios.get<any>(requestUrl);
});

export const getEntity = createAsyncThunk(
  "user_role/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/user/${id}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  "user_role/create_entity",
  async (entity: IUser_role, thunkAPI) => {
    let userId = entity.user_id;
    delete entity.user_id;
    const result = await axios.post<IUser_role>(`${apiUrl}/create/${userId}`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "user_role/update_entity",
  async (entity: IUser_role, thunkAPI) => {
    const result = await axios.patch<IUser_role>(`${apiUrl}/${entity.role_id}`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  "user_role/partial_update_entity",
  async (entity: IUser_role, thunkAPI) => {
    const result = await axios.patch<IUser_role>(`${apiUrl}/${entity.role_id}`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  "user_role/delete_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/delete/${id}`;
    return axios.delete<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_User_role = createEntitySlice({
  name: "user_role",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(createEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = action.payload;
      })

      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers["x-total-count"], 10),
        };
      })
      .addMatcher(isRejected(createEntity, updateEntity, partialUpdateEntity, deleteEntity), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.payload;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(deleteEntity), (state) => {
        state.updating = true;
        state.loading = true;
        state.updateSuccess = false;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { clearError } = Reducer_User_role.actions;

// Reducer
export default Reducer_User_role.reducer;

