import { useCallback, useEffect, useState } from "react";
import { Form, useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import {
  Button,
  Card,
  ChoiceList,
  FormLayout,
  Icon,
  Label,
  Modal,
  Page,
  PageActions,
  RadioButton,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { clearError, clearMessage, getEntities, getEntity, reset, updateEntity } from "store/en_message.store.reducer";
import { MobilePlusMajor } from "@shopify/polaris-icons";

import { lengthLessThan, notEmpty, useField, useForm } from "@shopify/react-form";
import helpers from "../../helpers";

export default function General() {
  const entity = useAppSelector((state) => state.en_message.entity);
  const entities = useAppSelector((state) => state.en_message.entities);
  const loading = useAppSelector((state) => state.en_message.loading);
  const updating = useAppSelector((state) => state.en_message.updating);
  const message = useAppSelector((state) => state.en_message.message);
  const system_information = useAppSelector((state) => state.system.entity);
  const systemloading = useAppSelector((state) => state.system.updating);

  const dispatch = useAppDispatch();

  /**
   * Notification
   */

  const toggleToastActive = useCallback(() => {
    dispatch(clearError());
    dispatch(clearMessage());
  }, []);

  /**
   * Initial
   */
  useEffect(() => {
    dispatch(getEntities());
  }, []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  //useParam = useLocation();

  const validateText = (text) => {
    return String(text)
      .toLowerCase()
      .match(/^[a-zA-Z0-9]*$/);
  };

  const useFields = {
    type: useField<string>({
      value: entities?.config?.type ?? "",
      validates: [],
    }),
    data_content: useField<string>({
      value: entities?.config?.data_content ?? "",
      validates: [],
    }),
    role: useField<string>({
      value: entities?.config?.data_filter[0] ?? "",
      validates: [
        (inputValue) => {
          if (!validateText(inputValue)) {
            return "Role must not contain special characters";
          }
        },
      ],
    }),
    age: useField<string>({
      value: entities?.config?.data_filter[1] ?? "",
      validates: [],
    }),
    height: useField<string>({
      value: entities?.config?.data_filter[2] ?? "",
      validates: [],
    }),
    weight: useField<string>({
      value: entities?.config?.data_filter[3] ?? "",
      validates: [],
    }),
    time: useField<string>({
      value: entities?.config?.data_filter[4] ?? "",
      validates: [],
    }),

    // start_time: useField<string>({
    //   value: entities?.config?.data_filter[4] ?? "",
    //   validates: [],
    // }),
    // end_time: useField<string>({
    //   value: entities?.config?.data_filter[5] ?? "",
    //   validates: [],
    // }),
  };
  useParam = useParams();

  const { submit, fields } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        // const time = values.start_time + ", " + values.end_time;

        const str = values.role + "," + values.age + "," + values.height + "," + values.weight + "," + values.time;
        let arr = str.split(`,`);
        console.log(arr);

        let data = [];
        arr.map((e, i) => {
          if (arr.length == 6) {
            if (i == arr.length - 1) {
              data[data.length - 1] = data[data.length - 1] + "," + e;
              return;
            }
          }

          data[i] = e;
        });

        // console.log(JSON.stringify(data));

        dispatch(
          updateEntity({
            _id: entities?.config?._id,
            type: `US_message`,
            data_content: values.data_content,
            data_filter: JSON.stringify(data),
          })
        );
        reset();
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message = e?.response?.data?.title ?? "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  const toastMarkup = message ? <Toast content={message} onDismiss={toggleToastActive} duration={4500} /> : null;

  return (
    <>
      {toastMarkup}
      <Page
        narrowWidth
        title="EN Message"
        primaryAction={{
          content: "Save",
          onAction: submit,
          disabled: false,
          loading: updating,
        }}
      >
        <Card>
          <Card.Section title="Data filter">
            <FormLayout>
              <FormLayout.Group condensed>
                <TextField label="Base Role" autoComplete="off" {...fields.role} />
                <TextField label="Age" autoComplete="off" {...fields.age} type="number" />
                <TextField label="Height" autoComplete="off" {...fields.height} type="number" />
                <TextField label="Weight" autoComplete="off" {...fields.weight} type="number" />
                <TextField
                  requiredIndicator
                  label="Time"
                  autoComplete="off"
                  {...fields.time}
                  helpText="yyyy-mm-dd hh:mm:ss, yyyy-mm-dd hh:mm:ss"
                />
                {/* <TextField label="Start time" autoComplete="off" {...fields.start_time} />
                <TextField label="End time" autoComplete="off" {...fields.end_time} /> */}
              </FormLayout.Group>

              <TextField label="Data Content" autoComplete="off" {...fields.data_content} multiline={2} />
            </FormLayout>
          </Card.Section>
        </Card>

        <PageActions
          primaryAction={{
            content: "Save",
            onAction: submit,
            disabled: false,
            loading: updating,
          }}
        />
      </Page>
    </>
  );
}

