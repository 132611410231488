import {
  AppliedFilterInterface,
  Avatar,
  Badge,
  Card,
  ChoiceList,
  DataTable,
  EmptyState,
  FilterInterface,
  Filters,
  FormLayout,
  Layout,
  Link,
  Modal,
  Page,
  ResourceItem,
  ResourceList,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import debounce from "lodash.debounce";
import { TickSmallMinor, LockMinor } from "@shopify/polaris-icons";
import emptyIMG from "../../media/empty.png";
import Pagination from "../../components/pagination";
import {
  clearError,
  getEntities,
  reset as resetAdsData,
} from "../../store/ads.store.reducer";
import AdsFilter from "./filter";
import helpers from "../../helpers";

export default function General_ads() {
  const [initial_loading, setInitial_loading] = useState<boolean>(true);

  const entity = useAppSelector((state) => state.ads.entity);
  const entities = useAppSelector((state) => state.ads.entities);
  const loading = useAppSelector((state) => state.ads.loading);
  const errorMessage = useAppSelector((state) => state.ads.errorMessage);
  const totalItems = useAppSelector((state) => state.ads.totalItems);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  const toggleActive = useCallback(() => {
    setNotification(errorMessage);
    dispatch(clearError());
  }, []);

  useEffect(() => {
    dispatch(resetAdsData());
  }, []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  const [mainQuery, setMainQuery] = useState({
    ...{
      type: "ads",
      page: 1,
      limit: 100,
    },
    ...StringQuery,
  });
  const [queryValue, setQueryValue] = useState("");

  const [input, setInput] = useState("");
  const handleFiltersQueryChange = useCallback((value) => setInput(value), []);

  useEffect(() => {
    setMainQuery({ ...mainQuery, ...{ search: input } });
  }, [input]);

  /**
   * Change page number
   */

  const [numberPage, setNumberPage] = useState(1);

  const onChangePageNumber = useCallback((numPage) => {
    setNumberPage(numPage);
  }, []);

  useEffect(() => {
    setMainQuery({ ...mainQuery, page: numberPage });
  }, [numberPage]);

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch) history("/ads" + buildURLSearch);
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  const onChangeCallback = useMemo(
    () =>
      debounce((_value) => {
        if (_value !== "")
          setMainQuery({ ...mainQuery, query: _value ? _value : "" });
      }, 500),
    []
  );

  useEffect(() => {
    onChangeCallback(queryValue);
  }, [queryValue]);

  useEffect(() => {
    if (loading === false) setInitial_loading(false);
  }, [loading]);

  /**
   * Modal  -------------------------------
   */

  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const ModalAddContent = () => {
    setShowEditModal(true);
  };

  const closeModal = useCallback(() => {
    setShowEditModal((active) => !active);
  }, [showEditModal]);

  const [notification, setNotification] = useState<string | null>(null);

  const [valueContent, setValueContent] = useState("");
  const handleChangeValue = useCallback((newValue) => {
    setValueContent(newValue);
  }, []);

  const saveForm = () => {
    if (valueContent) {
      history("edit/" + valueContent);
    } else {
      // setShowEditModal(false);

      setNotification("Vui lòng nhập Package!");
    }
    // history("edit/" + valueContent);
  };

  const toastMarkup = notification ? (
    <Toast content={notification} onDismiss={toggleActive} duration={1000} />
  ) : null;

  const contentModal = (
    <Modal
      open={showEditModal}
      onClose={closeModal}
      title="View Notification"
      titleHidden
      primaryAction={{
        content: "Save",
        onAction: saveForm,
      }}
      secondaryActions={[
        {
          content: "Close",
          onAction: closeModal,
        },
      ]}
    >
      <Modal.Section>
        {" "}
        <FormLayout>
          <FormLayout.Group condensed>
            <TextField
              label="Package"
              value={valueContent}
              onChange={handleChangeValue}
              autoComplete="off"
            />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  const renderItem = (ads: any) => {
    // let userContent = ads.interest;

    const { _id, type, package_name } = ads;
    return [
      <Link removeUnderline monochrome url={"edit/" + package_name} key={_id}>
        {type}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + package_name} key={_id}>
        {package_name}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + package_name} key={_id}>
        {_id}
      </Link>,
    ];
  };

  const PagesList = (
    <>
      <DataTable
        columnContentTypes={["text", "text", "text"]}
        headings={["Type", "Package", "ID"]}
        rows={entities ? entities.map(renderItem) : []}
        hideScrollIndicator
        footerContent={`Display page ${mainQuery.page} of total ${totalItems} results...`}
      />
      <style>{`
        .small-icon {
          font-size: 12px;
          padding: 0;
          width: 15px;
          height: auto;
        }
      `}</style>
    </>
  );

  const Actual_page = (
    <Page
      title="Ads"
      fullWidth
      primaryAction={{
        content: "Create new",
        disabled: false,
        onAction: ModalAddContent,
      }}
      // secondaryActions={[{ content: "Print" }]}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <div style={{ padding: "16px", display: "flex" }}>
              <Stack distribution="equalSpacing">
                <AdsFilter
                  queryValue={StringQuery?.query}
                  onChange={handleFiltersQueryChange}
                />
                {/* <Select
                  label=""
                  value={selectedParentId}
                  onChange={handleSelectedChange}
                  options={[
                    { label: "All", value: "" },
                    { label: "Get", value: "get" },
                    { label: "Post", value: "post" },
                    { label: "Patch", value: "patch" },
                    { label: "Delete", value: "delete" },
                  ]}
                /> */}
              </Stack>
            </div>
            {PagesList}
          </Card>
          <br />
          {/* {totalItems > mainQuery.limit ? ( */}
          <Pagination
            TotalRecord={totalItems}
            // TotalRecord={100}
            activeCurrentPage={mainQuery.page}
            pageSize={mainQuery.limit}
            onChangePage={onChangePageNumber}
          />
          {/* ) : null} */}
        </Layout.Section>
      </Layout>
    </Page>
  );

  const skeleton_loading = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  // const toastMarkup = errorMessage ? (
  //   <Toast content={errorMessage} error onDismiss={toggleActive} />
  // ) : null;

  return (
    <>
      {contentModal}
      {toastMarkup}
      {/* {loading ? skeleton_loading : Actual_page} */}
      {initial_loading ? skeleton_loading : Actual_page}
    </>
  );
}
