import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from '../config/reducer.utils';
import { IEmail, defaultIEmail } from '../interface/email.model';
import { AppThunk } from '../config/store';


/**
*   Reducer used for front-end, with email_template.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using email_template.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: [] as any,
  entities: [],
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'email';

// Actions

export const getEntities = createAsyncThunk('media/fetch_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}&cacheBuster=${new Date().getTime()}`;
  return axios.get<any>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'email/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}?cacheBuster=${new Date().getTime()}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'email/create_entity',
  async (entity: IEmail) => {
    const result = await axios.post<IEmail>(`${apiUrl}?cacheBuster=${new Date().getTime()}`, helpers.cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'email/update_entity',
  async (entity: IEmail, thunkAPI) => {
    const result = await axios.put<IEmail>(`${apiUrl}/${entity.email_id}?cacheBuster=${new Date().getTime()}`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntity(entity.email_id));
    return result;
  }, 
  { serializeError: serializeAxiosError }
);

/**
 * Update many email
 */
export const partialUpdateEntity = createAsyncThunk(
  'email/partial_update_entity',
  async (entity: any , thunkAPI) => {
    return await axios.patch<any>(`${apiUrl}/?cacheBuster=${new Date().getTime()}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);


export const deleteEntity = createAsyncThunk(
  'email/delete_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_Email = createEntitySlice({
  name: 'email',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage  = null;
      state.updateSuccess = false;
    },
    reset: (state) => {
      state = initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(createEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = action.payload
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updateSuccess = true;
      })
      .addMatcher(isRejected(createEntity, updateEntity, partialUpdateEntity, deleteEntity), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.payload
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(deleteEntity), state => {
        state.updating = true;
        state.loading = true;
        state.updateSuccess = false;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { clearError, reset } = Reducer_Email.actions;

// Reducer
export default Reducer_Email.reducer;
