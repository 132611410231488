import { useNavigate } from 'react-router-dom';
import React, { useState, useCallback, useEffect } from "react";
import LoginLogo from '../media/LoginLogo.svg';
import {
lengthLessThan,
lengthMoreThan,
notEmptyString,
useField,
useForm
} from '@shopify/react-form';
  
import helpers from '../helpers';
import { useAppDispatch, useAppSelector } from '../config/store';
import { clearError, recoverPassword } from '../store/user.store.reducer';
import { Box, Button, CircularProgress, Divider, Stack, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from "../media/homepage_app_logo.png"
import MyReCAPTCHA from 'components/MyReCAPTCHA';
import ReCAPTCHA from 'react-google-recaptcha';



  function _RecoverPassword() {
    /**
     * Kéo kho tổng ra...
     */
     const dispatch = useAppDispatch();
     const history = useNavigate();
     const error = useAppSelector(state => state.user.errorMessage );
     const account = useAppSelector(state => state.user.account );
    const [notification, setNotification] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentForm, setCurrentForm] = useState('email');
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);

     useEffect( () => {
      dispatch( clearError() );
     }, []);


     useEffect( () => {
       if ( ! helpers.isEmpty(account ) ) {
        dispatch( clearError() );
        setNotification("Successful! Please check your email!");        
       }
     }, [account]);


  /**
   * Khai báo field cho form!
   */
    const useFields = {
        user_email: useField<string>({
          value: '',
          validates: [
            notEmptyString('Trường này không được để trống.'),
            lengthLessThan(50, 'Quá dài!'),
            lengthMoreThan(6, 'Quá ngắn!'),
            inputValue => {
              if (  ! helpers.isEmail(inputValue) ) {
                return "Định dạng Email không hợp lệ! Vui lòng kiểm tra lại email của bạn!";
              }
              if (  helpers.isUTF8(inputValue) ) {
                return "Email không nên có mã Unicode, bạn vui lòng kiểm tra!";
              }
            }
          ],
        }),
    };
  
  
    const {
      fields,
      submit,
      submitting,
      dirty,
      reset: resetForm,
      submitErrors,
      makeClean,
    } = useForm({
      fields: useFields,
      async onSubmit(form) {
        dispatch( recoverPassword( {user_email: form.user_email}) );
        resetForm();
        return {status: 'success'};
      },
    });

  

    const toggleToastActive2 = useCallback(() => { 
      setNotification('');
      history('/login');
    }, []);
  
    const handleToggleForm = () => {
      if (currentForm === 'email') {
        setCurrentForm('phone');
      } else if (currentForm === 'phone') {
        setCurrentForm('email');
      }
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (!captchaToken) {
        alert('Please complete the CAPTCHA');
        return;
      }
      setLoading(true);
      // Simulate API call
      setTimeout(() => {
        setLoading(false);
        setCurrentForm('code'); // switch to code form on successful API call
      }, 2000);
      console.log('Form submitted with CAPTCHA token:', captchaToken);
    };
  
    const handleVerify = (token: string | null) => {
      setCaptchaToken(token);
    };
    
      return(
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column !important',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', 
          }}
        >
          <Box  sx={{ width: '500px', padding: "20px 30px", borderRadius: '4px', backgroundColor: '#fff', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
          <img src={logo} alt="logo" width={150} />
            <Box>
              <Typography variant='h6' fontWeight={550} mt={3} mb={3}>Recover Password</Typography>
            </Box>
            {currentForm === 'email' && (
              <form onSubmit={handleSubmit}>
                <TextField
                  placeholder="Enter your email address"
                  label="Email Address"
                  type="email"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // error={!!emailError}
                  // helperText={emailError}
                />

                {loading ? (
                  <CircularProgress sx={{ display: 'block', margin: 'auto', mt: 2, mb: 2 }} />
                ) : (
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        width: '100%',
                        height: '55px',
                        borderRadius: '5px',
                        mb: 1,
                        fontWeight: 550,
                        backgroundColor: '#2e3192',
                        transition: '0.2s ease-in',
                        boxShadow: 'unset',
                        '&:hover': { backgroundColor: 'rgba(46, 49, 146, 0.8)' },
                      }}
                    >
                      Send code to recover password
                    </Button>
                    <Typography variant="body2" ml={5} mb={2}>
                      Use phone number to recover password{' '}
                      <Link to="#" onClick={handleToggleForm}>
                        Change now!
                      </Link>
                    </Typography>
                <ReCAPTCHA sitekey="6Lda2twpAAAAAJnsTsIUYHslJtT5DLAKwfar4x6Z" />

                  </Box>
                )}
              </form>
            )}

            {currentForm === 'phone' && (
              <form onSubmit={handleSubmit}>
                <TextField
                  placeholder="Enter your phone number"
                  label="Phone Number"
                  type="text"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // error={!!emailError}
                  // helperText={emailError}
                />

                {loading ? (
                  <CircularProgress sx={{ display: 'block', margin: 'auto', mt: 2, mb: 2 }} />
                ) : (
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        width: '100%',
                        height: '55px',
                        borderRadius: '5px',
                        mb: 1,
                        fontWeight: 550,
                        backgroundColor: '#2e3192',
                        transition: '0.2s ease-in',
                        boxShadow: 'unset',
                        '&:hover': { backgroundColor: 'rgba(46, 49, 146, 0.8)' },
                      }}
                    >
                      Send code to recover password
                    </Button>
                    <Typography variant="body2" ml={5} mb={2}>
                      Use email address to recover password{' '}
                      <Link to="#" onClick={handleToggleForm}>
                        Change now!
                      </Link>
                    </Typography>
                    <MyReCAPTCHA onVerify={handleVerify} />

                  </Box>
                )}
              </form>
            )}

            {currentForm === 'code' && (
              <form onSubmit={(e) => e.preventDefault()}>
                <TextField
                  placeholder="Enter the code"
                  label="Verification Code"
                  type="text"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                {loading ? (
                  <CircularProgress sx={{ display: 'block', margin: 'auto', mt: 2, mb: 2 }} />
                ) : (
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        width: '100%',
                        height: '55px',
                        borderRadius: '5px',
                        mb: 1,
                        fontWeight: 550,
                        backgroundColor: '#2e3192',
                        transition: '0.2s ease-in',
                        boxShadow: 'unset',
                        '&:hover': { backgroundColor: 'rgba(46, 49, 146, 0.8)' },
                      }}
                    >
                      Verify code
                    </Button>
                  </Box>
                )}
              </form>
            )}
            <Divider> Or continue with </Divider>
            <Typography variant='body2' ml={5} mt={2}>Go back to <Link to='/login#redirect'>Login</Link> Or <Link to='/register'>Register</Link></Typography>

          </Box>
        </Box>
      );
    }
  
  export default _RecoverPassword;