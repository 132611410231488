export const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '800px',
    maxHeight: '600px',
    overflowY: 'auto',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    bgcolor: 'background.paper',
    borderRadius: '3px',
    p: 2,
  };
  
export const styleModule = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '600px',
    height: '600px',
    overflowY: 'auto',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    bgcolor: 'background.paper',
    borderRadius: '3px',
    p: 2,
};

export const styleButtonUpload = {
    width: '100%',
    height: '50px',
    background: 'transparent',
    color: '#333',
    fontWeight: 550,
    border: '1px dashed',
    mt: 2,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  };