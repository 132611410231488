import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useCallback, useEffect } from "react";
import {
  lengthLessThan,
  lengthMoreThan,
  notEmptyString,
} from "@shopify/react-form";

import helpers from "../helpers";
import { useAppDispatch, useAppSelector } from "../config/store";
import { register, logout, clearError } from "../store/user.store.reducer";
import { useMUIField } from "hooks/useField";
import { Box, Button, CardActions, CardContent, FormControl, Stack, TextField, Typography, Card, CircularProgress, Divider } from "@mui/material";
import { registerApi } from "service/register";
import { Link } from "react-router-dom";

import logo from "../media/logo-ikigaicoach-ngang.png"
import google from "../media/social/google.png"
import CustomSnackbar from 'components/snackbar';

import '../media/homepage_app_logo.png'
// import  { TextField } from "@mui/material";

function Register() {
  /**
   * Kéo kho tổng ra...
   */
  const [toastActive, setToastActive] = useState("");
  const account = useAppSelector((state) => state.user.account);
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
  const navigate = useNavigate()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullname, setFullName] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [passwordError, setPasswordError] = useState('');
  const { inputRef, errorMessage, handleChange } = useMUIField('displayName', '', [
    notEmptyString('Trường này không được để trống.'),
    lengthLessThan(100, 'Tên của bạn quá dài!'),
    lengthMoreThan(4, 'Tên của bạn quá ngắn!'),
  ]);

  /**
   * Start at first time...
   */

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!rePassword) {
      setSnackbarMessage('Trường Mật Khẩu và Xác nhận mật khẩu không được bỏ trống');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (password !== rePassword) {
      setSnackbarMessage('Mật khẩu và Xác nhận mật khẩu không khớp');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await registerApi(email, password, fullname, rePassword);
      if (response.status) {
        navigate('/login#redirect')
      }
    setLoading(false);
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      if (errorMessage) {
          const normalizedErrorMessage = Array.isArray(errorMessage) ? errorMessage[0].trim().toLowerCase() : errorMessage.trim().toLowerCase();

          if (normalizedErrorMessage === "user_email must be an email") {
              setSnackbarMessage('Không được bỏ trống trường Tên, Email và Mật khẩu');
          } else if (normalizedErrorMessage === "your password is too weak!") {
              setSnackbarMessage('Không được bỏ trống trường Mật khẩu');
          } else if (normalizedErrorMessage === "your email already taken") {
            setSnackbarMessage('Email đã có người sử dụng');
          } else {
              setSnackbarMessage('Đã xảy ra lỗi, vui lòng thử lại sau.');
          }
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  /**
   * Khai báo field cho form!
   */
  // const useFields = {
  //   user_email: useField<string>({
  //     value: "",
  //     validates: [
  //       notEmptyString("Trường này không được để trống."),
  //       lengthLessThan(50, "Quá dài!"),
  //       lengthMoreThan(6, "Quá ngắn!"),
  //       (inputValue) => {
  //         if (! helpers.isEmail(inputValue)) {
  //           return "Định dạng Email không hợp lệ! Vui lòng kiểm tra lại email của bạn!";
  //         }
  //         if ( helpers.isUTF8(inputValue)) {
  //           return "Email không nên có mã Unicode, bạn vui lòng kiểm tra!";
  //         }
  //       },
  //     ],
  //   }),

  //   display_name: useField<string>({
  //     value: "",
  //     validates: [
  //       notEmptyString("Trường này không được để trống."),
  //       lengthLessThan(100, "Tên của bạn quá dài!"),
  //       lengthMoreThan(4, "Tên của bạn quá ngắn!"),
  //     ],
  //   }),

  //   password: useField<string>({
  //     value: "",
  //     validates: [
  //       notEmptyString("Trường này không được để trống."),
  //       lengthMoreThan(6, "Mật khẩu quá ngắn!"),
  //       (inputValue) => {
  //         if ( helpers.isUTF8(inputValue)) {
  //           return "Không nên dùng mã Unicode trong mật khẩu của bạn!";
  //         }
  //         if ( helpers.getPasswordStrength(inputValue) < 2) {
  //           return "Mật khẩu của bạn quá yếu, hãy trộn lẫn cả số, chữ và các ký tự đặc biệt khó đoán.";
  //         }
  //       },
  //     ],
  //   }),

  //   repeatpassword: useField<string>({
  //     value: "",
  //     validates: [
  //       notEmptyString("Trường này không được để trống."),
  //       lengthMoreThan(6, "Mật khẩu quá ngắn!"),
  //       (inputValue) => {
  //         if ( helpers.isUTF8(inputValue)) {
  //           return "Không nên dùng mã Unicode trong mật khẩu của bạn!";
  //         }
  //       },
  //     ],
  //   }),

  //   user_referrer: useField<string>({
  //     value: user_referrer.ref || '',
  //     validates: [
  //       // notEmptyString('Trường này không được để trống.'),
  //     ],
  //   }),

  //   agreement: useField<boolean>({
  //     value: false,
  //     validates: [
  //       (inputValue) => {
  //         if (!inputValue) {
  //           return "You must agree with Term of Use";
  //         }
  //       },
  //     ],
  //   }),
  // };




  return (
      // <div id="register_page">
      // <TextField
      //     inputRef={inputRef}
      //     label="Tên của bạn"
      //     onChange={handleChange}
      // />
      //   {errorMessage && <span className="error">{errorMessage}</span>}
      // </div>
      <Box
      sx={{
        display: 'flex',
        flexDirection: 'column !important',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', 
      }}
    >
      <Box  sx={{ width: '500px', padding: "20px 30px", borderRadius: '4px', backgroundColor: '#fff', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
      <img src={logo} alt="logo" width={200} />
        <Box>
          <Typography variant='h6' fontWeight={550} mt={3} mb={3}>Sign Up</Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            placeholder="Enter your full name"
            label="Fullname"
            type="email"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            // error={!!emailError}
            // helperText={emailError}
            value={fullname}
            onChange={(e) => {
              setFullName(e.target.value);
              // if (emailError) setEmailError('');
            }}
            sx={{ mb: 2 }}
          />

          <TextField
            placeholder="Enter your email address"
            label="Email Address"
            type="email"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            // error={!!emailError}
            // helperText={emailError}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              // if (emailError) setEmailError('');
            }}
            sx={{ mb: 2 }}
          />

            <Stack direction="row" gap={2} mb={2}>
              <TextField
                placeholder="Enter your password"
                label="Password"
                type="password"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                
                value={password}
                onChange={(e) => {
                    setPassword(e.target.value)
                    }}
                
              />
              <TextField
                placeholder="Enter your re-password"
                label="Confirm Password"
                type="password"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                
                value={rePassword}
                onChange={(e) => {
                    setRePassword(e.target.value)
                    }}
                
              />
            </Stack>
            
            {loading ? (
            <CircularProgress sx={{ display: 'block', margin: 'auto', mt: 2, mb: 2 }} />
            ) : (
            <Box sx={{ }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: '100%', height: '55px', borderRadius: '5px', fontWeight: 550, backgroundColor: '#2e3192', transition: '0.2s ease-in', mb: 2, boxShadow: 'unset', '&:hover': {backgroundColor: 'rgba(46, 49, 146, 0.8)'} }}
                onClick={handleSubmit}
              >
                Register
              </Button>

            </Box>
            )}
        </form>
        {/* <Divider> Or continue with </Divider>
        <Box mt={2}>
            <Stack direction="column" gap={1} alignItems="center" justifyContent="space-between">
              <a className='social' href='#' ><img alt="logo" src={google} width={40}/> Continue with Google</a>
            </Stack>
        </Box> */}
        <Typography variant='body2' ml={5} mt={2}>Already have an account? <Link to='/login#redirect'>Sign in!</Link></Typography>

      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
}

export default Register;
