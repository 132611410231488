const getErrorMessage = (errorData: any) => {
  let message = errorData.message;
  if (errorData.fieldErrors) {
    errorData.fieldErrors.forEach((fErr: any) => {
      message += `\nfield: ${fErr.field},  Object: ${fErr.objectName}, message: ${fErr.message}\n`;
    });
  }
  return message;
};

export default () => (next) => (action) => {
  /**
   *
   * The error middleware serves to log error messages from dispatch
   * It need not run in production
   */
  //console.info("===>" + JSON.stringify(action, null, 6) );
  //if (process.env.NODE_ENV === 'development') {
  const { error } = action;
  if (error) {
    console.error(`${action.type} caught at middleware with reason: ${JSON.stringify(error.message)}.`);
    if (!error.response) {
      console.log(action.error);
      // action.error.message = "YourNetworkisDown";
    }
    if (error && error.response && error.response.data) {
      const message = getErrorMessage(error.response.data);
      //console.error(`Actual cause: ${message}`);
      // if ( message )
      action.error.message = message || "";
    }
  }
  //}

  // Dispatch initial action
  return next(action);
};

