import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import Theme404 from "layout/404";
import General from "./general";

export default function VN_Settings() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || "general";
  const account = useAppSelector((state) => state.user.account);
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  const dispatch = useAppDispatch();
  const isAdmin = account.user_role === "admin";

  let ActualPage: any;
  switch (Param) {
    case "general":
      ActualPage = General;
      break;

    default:
      ActualPage = Theme404;
      break;
  }

  return (
    <>
      <ActualPage />
    </>
  );
}

