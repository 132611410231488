import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface MyReCAPTCHAProps {
  onVerify: (token: string | null) => void;
}

const MyReCAPTCHA: React.FC<MyReCAPTCHAProps> = ({ onVerify }) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleVerify = (token: string | null) => {
    onVerify(token);
  };

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey="6Lda2twpAAAAAJnsTsIUYHslJtT5DLAKwfar4x6Z" // Thay thế bằng site key của bạn
      onChange={handleVerify}
    />
  );
};

export default MyReCAPTCHA;
