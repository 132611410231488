import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "config/store";
import {
  Card,
  Page,
  PageActions,
  RadioButton,
  Stack,
  TextField,
  Tabs,
  FormLayout,
  ChoiceList,
} from "@shopify/polaris";
import { getEntity, updateEntity } from "store/settings.store.reducer";
import {
  asChoiceField,
  lengthLessThan,
  notEmpty,
  useField,
  useForm,
} from "@shopify/react-form";
import helpers from "helpers";

/**
 * Setting emails
 * For internal use, eg: System sent notification ...
 */
export default function SettingEmails() {
  const entity = useAppSelector((state) => state.settings.entity);
  const updating = useAppSelector((state) => state.settings.updating);
  const dispatch = useAppDispatch();

  const [tabselected, setTabselected] = useState(0);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setTabselected(selectedTabIndex),
    []
  );

  /**
   * Base on tabs, get setting
   */
  useEffect(() => {
    if (tabselected === 0) dispatch(getEntity("email_setting"));
    else dispatch(getEntity("email_smtp"));
  }, [tabselected]);

  const tabs = [
    {
      id: "general-setting",
      content: "Cài đặt chung",
      accessibilityLabel: "Cài đặt chung",
      panelID: "general-setting-1",
    },
    {
      id: "email-setting",
      content: "Cài đặt email",
      panelID: "email-setting-1",
    },
  ];

  /**
   * General setting
   */

  const [choiselistselected, setChoiselistselected] = useState([
    "welcome_new_user",
  ]);
  const handleChoiseListChange = useCallback(
    (value) => setChoiselistselected(value),
    []
  );
  const general_setting_field = (
    <FormLayout>
      <ChoiceList
        allowMultiple
        title=""
        choices={[
          {
            label: "Welcome new user",
            value: "welcome_new_user",
            helpText: "Send an email to new user.",
          },
          {
            label: "Welcome new customer",
            value: "welcome_new_customer",
            helpText: "Send an email to new customer if they have an email.",
          },
        ]}
        selected={choiselistselected}
        onChange={handleChoiseListChange}
      />
      <TextField
        label="Email nhận thông tin từ hệ thống"
        onChange={() => {}}
        helpText="Là email nhận các thông báo tự động từ hệ thống. Email có thể chứa các thông tin quan trọng."
        autoComplete="off"
      />
    </FormLayout>
  );

  /**
   * Email...
   */

  const useFields = {
    smtp_sender_email: useField<string>({
      value: entity?.smtp_sender_email ?? "",
      validates: [
        notEmpty("Email is required!"),
        (inputValue) => {
          if (!helpers.isEmail(inputValue)) {
            return "Your email is not valid!";
          }
        },
      ],
    }),
    smtp_sender_name: useField<string>({
      value: entity?.smtp_sender_name ?? "",
      validates: [
        lengthLessThan(250, "No more than 250 characters."),
        (inputValue) => {
          if (inputValue.length < 2) {
            return "Your display name is too short!";
          }
        },
      ],
    }),
    smtp_username: useField<string>({
      value: entity?.smtp_username ?? "",
      validates: [
        lengthLessThan(50, "Your content is too long."),
        (inputValue) => {
          if (helpers.isUTF8(inputValue)) {
            return "Do NOT use UTF8 here, smtp_username should be A-Z, 0-9 and (.)";
          }
        },
      ],
    }),
    smtp_password: useField<string>({
      value: entity?.smtp_password ?? "",
      validates: [
        (inputValue) => {
          if (helpers.isUTF8(inputValue)) {
            return "Không nên dùng mã Unicode trong mật khẩu của bạn!";
          }
        },
      ],
    }),
    smtp_encryption: useField<string>({
      value: entity?.smtp_encryption ?? "NONE",
      validates: [
        (inputValue) => {
          if (["NONE", "SSL", "TLS"].indexOf(inputValue) < 0) {
            return "Không đúng!";
          }
        },
      ],
    }),

    smtp_port: useField<string>({
      value: entity?.smtp_port ?? "",
      validates: [
        (inputValue) => {
          if (inputValue) {
            if (!helpers.isNumeric(inputValue)) {
              return "Định dạng không hợp lệ. Chỉ dùng số!";
            }
          }
        },
      ],
    }),

    smtp_host: useField<string>({
      value: entity?.smtp_host ?? "",
      validates: [
        (inputValue) => {
          if (helpers.isUrl(inputValue)) {
            return "Địa chỉ host của SMTP không hợp lệ!";
          }
        },
      ],
    }),
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset,
    submitErrors,
    makeClean,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        dispatch(
          updateEntity({
            setting_name: "email_smtp",
            setting_value: JSON.stringify(values),
          })
        );
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message =
          e?.response?.data?.title ??
          "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  const email_setting_field = (
    <FormLayout>
      <p>
        Cài đặt tài khoản SMTP để gửi email hệ thống. Lưu ý đây là Email cho hệ
        thống gửi các báo cáo, chào mừng người dùng / khách hàng mới. Ngoài ra
        thi thoảng hệ thống gửi các thông báo chung cho admin.
      </p>
      <TextField
        label="SMTP sender email"
        autoComplete="off"
        {...fields.smtp_sender_email}
      />
      <TextField
        label="SMTP sender name"
        autoComplete="off"
        {...fields.smtp_sender_name}
      />
      <TextField label="SMTP host" autoComplete="off" {...fields.smtp_host} />
      <Stack vertical>
        <RadioButton
          label="NONE"
          {...asChoiceField(fields.smtp_encryption, "NONE")}
          helpText="Không có phương thức bảo mật nào!"
        />
        <RadioButton
          label="SSL"
          {...asChoiceField(fields.smtp_encryption, "SSL")}
          helpText="Bảo mật bằng phương thức SSL"
        />
        <RadioButton
          label="TLS"
          {...asChoiceField(fields.smtp_encryption, "TLS")}
          helpText="Bảo mật bằng phương pháp TLS(Phổ biến)"
        />
      </Stack>
      <p>
        * For most servers TLS is the recommended option. If your SMTP provider
        offers both SSL and TLS options, we recommend using TLS.{" "}
      </p>
      <TextField
        label="SMTP username"
        autoComplete="off"
        {...fields.smtp_username}
      />
      <TextField
        label="SMTP password"
        autoComplete="off"
        {...fields.smtp_password}
      />
      <TextField label="SMTP port" autoComplete="off" {...fields.smtp_port} />
    </FormLayout>
  );

  return (
    <>
      <Page narrowWidth title="Cài đặt email hệ thống">
        <Card>
          <Tabs tabs={tabs} selected={tabselected} onSelect={handleTabChange}>
            <Card.Section>
              {tabselected === 1 ? email_setting_field : general_setting_field}
            </Card.Section>
          </Tabs>
        </Card>
        <PageActions
          primaryAction={{
            content: "Save",
            onAction: submit,
            disabled: updating,
            loading: updating,
          }}
        />
      </Page>
    </>
  );
}
