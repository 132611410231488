import {
    Autocomplete,
    Badge,
    Button,
    ButtonGroup,
    Card,
    DataTable,
    EmptyState,
    Form,
    FormLayout,
    Icon,
    Layout,
    Link,
    Modal,
    Page,
    SkeletonBodyText,
    SkeletonDisplayText,
    SkeletonPage,
    Stack,
    TextContainer,
    TextField,
    Toast,
  } from "@shopify/polaris";
  import { useCallback, useEffect, useMemo, useState } from "react";
  import { useNavigate, useLocation } from "react-router-dom";
  import { useAppDispatch, useAppSelector } from "../../config/store";
  import emptyIMG from "../../media/empty.png";
  import Pagination from "../../components/pagination";
  import { clearError, getEntities, searchDates } from "../../store/order.store.reducer";
//   import OrderFilter from "./filter";
  import helpers from "../../helpers";
  import dateandtime from "date-and-time";
  import CountryName from "components/countries";
  import "react-datepicker/dist/react-datepicker.css";
  import { getListTransaction, updateTransaction } from "./service";
  import { FaEdit } from "react-icons/fa";
  import { MenuItem, Select, Typography } from "@mui/material";
  import CustomSnackbar from "components/snackbar";
import QuickSearch from "./filter";
import '../../styles/custom-style.css'
  export default function ManageTransaction() {
    const [initial_loading, setInitial_loading] = useState<boolean>(true);
  
    const errorMessage = useAppSelector((state) => state.order.errorMessage);
  
  
    const dispatch = useAppDispatch();
    const history = useNavigate();
  
    const toggleActive = useCallback(() => {
      dispatch(clearError());
    }, []);
  
    /**
     * If user apply filter, it will add to URL, then parse URL back to initial state
     */
    let useParam = {} as any;
    useParam = useLocation();
    let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;
  
    // const [mainQuery, setMainQuery] = useState({
    //   ...{
    //     page: 1,
    //     limit: 20,
    //     order_by: "DESC",
    //   },
    //   ...StringQuery,
    // });
    const [queryValue, setQueryValue] = useState("");
  
    /**
     * Change page number
     */
  
    const [numberPage, setNumberPage] = useState(1);
  
    const onChangePageNumber = useCallback((numPage) => {
      setNumberPage(numPage);
    }, []);
  
    useEffect(() => {
      setMainQueryDate({ ...mainQueryDate, page: numberPage });
    }, [numberPage]);
  
  
    
  
    const [selectedVersion, setSelectedVersion] = useState("");
   
  
    const [post_plan_selected, setPost_plan_selected] = useState("");
  
  
    /**
     * Status
     */
  
    const [status, setStatus] = useState("all");
   
  
    /**
     * Limit
     */
  
    const [limit, setLimit] = useState(100);
    const handleLimit = useCallback((value) => {
      setLimit(value);
    }, []);
  
    /**
     * Input
     */
    const [input, setInput] = useState("");
    const handleFiltersQueryChange = useCallback((value) => setInput(value), []);
  
    useEffect(() => {
      setMainQueryDate({ ...mainQueryDate, ...{ user_id: input } });
    }, [input]);
  
    /**
     * Date Filter
     */
  
    
    const [mainQueryDate, setMainQueryDate] = useState({
      ...{
        page: 1,
        limit: limit,
        type: "order",
        select: "_id",
        order_by: "DESC",
        from: new Date("01/01/2022").toISOString(),
        to: new Date().toISOString(),
      },
    });
  
    
    const [entities, setEntities] = useState([])
    const [totalItems, setTotalItem] = useState(0)
    const [openModalUpdate, setModalUpdate] = useState(false);
    const [orderSelected, setOrderSelected] = useState<any>({})
  
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
    const [updateStatus, setUpdateStatus] = useState('')
    const filterDataAction = async (value?: '') => {
      const data = {
        method: value
      }
        setInitial_loading(true)
        setStatus(value)
        if(data.method == '' || data.method == 'all') delete data.method
      try {
        const dataOrder = await getListTransaction(data);
        const orderList = dataOrder.data;
        orderList.reverse();
        setEntities(orderList);
        setInitial_loading(false);
        setTotalItem(orderList.length)
       } 
       catch(err) {
        console.log(err);
        
       }
    }
    const clearDate = useCallback(async() => {
      // dispatch(getEntities(mainQuery));
  
      setStatus("");
      setLimit(100);
      setInitial_loading(true)
      try {
        const dataOrder = await getListTransaction();
        const orderList = dataOrder.data;
        orderList.reverse();
        setEntities(orderList);
        setInitial_loading(false);
        setTotalItem(orderList.length)
       } 
       catch(err) {
        console.log(err);
        
       }
    }, []);
    const updateOrderAction = async() => {
      try {
        const data = {
          _id: orderSelected._id,
          status: updateStatus == '' ? orderSelected.status : updateStatus
        }
        const dataOrder = await updateTransaction(data);
        setSnackbarMessage('Cập nhật giao dịch thành công!');
        setSnackbarSeverity('success')
        setSnackbarOpen(true)
        setModalUpdate(false)
        filterDataAction();
       } 
       catch(err) {
        console.log(err);
        setSnackbarMessage('Cập nhật giao dịch thất bại!');
        setSnackbarOpen(true)
        setModalUpdate(false)
        setSnackbarSeverity('error')
       }
    }
    useEffect(() => {
      const fetchData = async() => {
    
       try {
        const dataOrder = await getListTransaction();
        const orderList = dataOrder.data;
        orderList.reverse();
        setEntities(orderList);
        setInitial_loading(false);
        setTotalItem(orderList.length)
       } 
       catch(err) {
        console.log(err);
        
       }
      }
      fetchData()
    },[])
    // useEffect(() => {
    //   dispatch(searchDates(mainQueryDate));
    // }, [mainQueryDate]);
  
  
    const renderItem = (order: any) => {
      const {
        _id,
        user_id,
        transaction_value,
        payment_method,
        status,
        createdAt,
        updatedAt
      } = order;
  
  let formatter = new Intl.NumberFormat('vi-VN');
      return [     
          _id
        ,     
          user_id?.display_name ||  user_id[0]?.display_name
        ,      
          user_id?._id || user_id[0]?._id
        ,
          formatter.format(transaction_value)+ ' đ'
        ,     
          status === "pending" ? (
            <Badge status="warning" progress="complete">
              Pending
            </Badge>
          ) : status === "processing" ? (
            <Badge status="attention" progress="partiallyComplete">
              Processing
            </Badge>
          ) : status === "fraud" ? (
            <Badge status="warning" progress="partiallyComplete">
              Fraud
            </Badge>
          ) : status === "draft" ? (
            <Badge status="info" progress="complete">
              Draft
            </Badge>
          ) : status === "close" ? (
            <Badge status="critical" progress="complete">
              Close
            </Badge>
          ) : status === "reject" ? (
            <Badge status="critical" progress="incomplete">
              Reject
            </Badge>
          ) : status === "trial_false" ? (
            <Badge progress="partiallyComplete" status="critical">
              Trial False
            </Badge>
          ) : status === "trial" ? (
            <Badge progress="incomplete" status="success">
              Trial
            </Badge>
          ) : status === "success" ? (
            <Badge status="success" progress="complete">
              Success
            </Badge>
          ) : status === "done" ? (
            <Badge status="success" progress="partiallyComplete">
              Done
            </Badge>
          ) : null,
          <time>{createdAt ? dateandtime.format(new Date(createdAt), "DD-MM-YYYY HH:mm:ss") : "-"}</time>
        ,
          <time>{updatedAt ? dateandtime.format(new Date(updatedAt), "DD-MM-YYYY HH:mm:ss") : "-"}</time>
        ,
          <div style={{cursor: 'pointer'}} onClick={()=> {
          setModalUpdate(true)
          setOrderSelected(order)
          setUpdateStatus(status)
          }}>
            <FaEdit />
          </div>
      ];
    };
    const PagesList = (
      totalItems > 0 ? (
      <>
        <DataTable
          // sortable={[false, false, true, false, false, false, false, true, false]}
          // defaultSortDirection="descending"
          // initialSortColumnIndex={7}
          // onSort={handleSort}
          columnContentTypes={["text", "text", "text", "text", "text", "text", "text"]}
          headings={[
            "ID",
            "Họ tên",
            // "User Login",
            // "User Country",
            "User ID",
            "Số tiền",
            // "Age",
            "Trạng thái",
            // "Plan Name",
            "Thời gian tạo",
            "Thời gian cập nhật",
            "Hành động"
          ]}
          // totals={["", "", `${totalItems}`, `${price}$`, "", "", "", "", "", ""]}
          rows={entities?.map(renderItem)}
          hideScrollIndicator
          footerContent={`Display page ${mainQueryDate.page} of total ${totalItems} results...`}
        />
        <style>{`
          .small-icon {
            font-size: 12px;
            padding :0 ;
            width: 15px;
            height: auto;
          }
        `}</style>
      </>
    ) : (
        <EmptyState heading="Chưa có giao dịch nào!" image={emptyIMG}>
    </EmptyState>
    )
    );
  
    const Actual_page = (
      <Page fullWidth>
          <Typography variant="h5" mb={2}>Transaction</Typography>
        <Layout>
          <Layout.Section>
            <Card>
            <div style={{ padding: "16px" }}>
            <Typography variant="h6" mb={1} fontSize={'normal !important'} style={{fontSize: 'normal'}} fontWeight={'700'}>Loại giao dịch</Typography>

            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={status} // Use formData.type as the value
                                onChange={(e: any) => filterDataAction(e.target.value)}
                                name="type"
                                fullWidth
                                size="small"
                                placeholder="Loại giao dịch"
                                style={{width: '250px'}}
                            >
                                
                                    <MenuItem value="all">Tất cả giao dịch</MenuItem>
                                    <MenuItem value="plus">Giao dịch cộng tiền</MenuItem>
                                    <MenuItem value="minus">Giao dịch rút tiền</MenuItem>
                                   
                            </Select>
               
            </div>
              {PagesList}
            </Card>
            <br />
            {/* {totalItems > mainQuery.limit ? ( */}
            <Pagination
              TotalRecord={totalItems}
              activeCurrentPage={mainQueryDate.page}
              pageSize={mainQueryDate.limit}
              onChangePage={onChangePageNumber}
            />
            {/* ) : null} */}
          </Layout.Section>
          <Modal
          title={`Cập nhật giao dịch ${orderSelected?.method == 'minus' ? "rút tiền" : "cộng tiền"}`}
          open={openModalUpdate}
          onClose={() => setModalUpdate(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          
          >
           <div style={{padding: "8px 20px"}}>
           <Typography id="modal-modal-title" variant="h6" component="h6">
                    Transaction ID: {orderSelected?._id}
              </Typography>
              {/* <Typography id="modal-modal-title" variant="h6" component="h6">
                    Người tạo: {orderSelected?.user_id?.display_name}
              </Typography> */}
              <Typography id="modal-modal-title" variant="h6" component="h6">
                  Số tiền rút: {Number(orderSelected?.transaction_value).toLocaleString('vi-VN')} đ
              </Typography>
              {/* <Typography id="modal-modal-title" variant="h6" component="h6">
                  Thông tin tài khoản
              </Typography>
              <p style={{fontSize: '1.20rem', lineHeight: 1.6}}>Ngân hàng: </p>
              <p style={{fontSize: '1.20rem', lineHeight: 1.6}}>Số tài khoản: </p>
              <p style={{fontSize: '1.20rem', lineHeight: 1.6}}>Tên chủ tài khoản: </p> */}
              <Typography id="modal-modal-title" variant="h6" component="h6" mt={2} mb={1} fontWeight={'bold'}>
                Cập nhật trạng thái
              </Typography>
              {/* <Select
              
                    label=""
                    value={updateStatus}
                    onChange={(e) => setUpdateStatus(e)}
                    options={[
                      { label: "Pending", value: "pending" },
                      { label: "Done", value: "done" },
                      { label: "Reject", value: "reject" },
                    ]}
                    
                  />
   */}
   <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={updateStatus} // Use formData.type as the value
                                onChange={(e: any) => setUpdateStatus(e.target.value)}
                                name="type"
                                fullWidth
                                size="small"
                                placeholder="Loại giao dịch"
                            >
                                
                                    <MenuItem value="pending">Pending</MenuItem>
                                    <MenuItem value="done">Done</MenuItem>
                                    <MenuItem value="reject">Reject</MenuItem>
                                   
                            </Select>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 4, margin: '12px 0px'}}>
                    <Button onClick={() => setModalUpdate(false)} >Thoát</Button>
                    <Button primary onClick={() => updateOrderAction()}>Lưu lại</Button>
                  </div>
           </div>
          </Modal>
          <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
        </Layout>
      </Page>
    );
  
    const skeleton_loading = (
      <SkeletonPage primaryAction>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <SkeletonBodyText />
            </Card>
            <Card sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Card>
            <Card sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card>
              <Card.Section>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={2} />
                </TextContainer>
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={1} />
              </Card.Section>
            </Card>
            <Card subdued>
              <Card.Section>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={2} />
                </TextContainer>
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={2} />
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );
  
    const toastMarkup = errorMessage ? <Toast content={errorMessage} error onDismiss={toggleActive} /> : null;
  
    return (
      <>
        {toastMarkup}
        {/* {loading ? skeleton_loading : Actual_page} */}
        {initial_loading ? skeleton_loading : Actual_page}
      </>
    );
  }
  
  