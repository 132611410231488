import axios from "axios";
import {
  createAsyncThunk,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import helpers from "helpers";
import {
  IQueryParams,
  createEntitySlice,
  EntityState,
  serializeAxiosError,
} from "../config/reducer.utils";
import { IBanner, defaultIBanner } from "../interface/banner.model";
import { AppThunk } from "../config/store";

/**
 *   Reducer used for front-end, with banner.model.ts
 *   Interface.ts can be use in both front-end and back-end! But prefer using banner.model.ts
 */

const initialState = {
  loading: false,
  errorMessage: [] as any,
  entities: [],
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = "banner";

// Actions

export const getEntities = createAsyncThunk(
  "banner/fetch_entity_list",
  async (object: any) => {
    const EndURL = helpers.buildEndUrl(object);
    const requestUrl = `${apiUrl}${EndURL}&cacheBuster=${new Date().getTime()}`;
    return axios.get<any>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  "banner/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}?cacheBuster=${new Date().getTime()}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  "banner/create_entity",
  async (entity: IBanner, thunkAPI) => {
    const result = await axios.post<IBanner>(
      `${apiUrl}?cacheBuster=${new Date().getTime()}`,
      helpers.cleanEntity(entity)
    );
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "banner/update_entity",
  async (entity: IBanner, thunkAPI) => {
    const result = await axios.patch<IBanner>(
      `${apiUrl}/${entity.banner_id}?cacheBuster=${new Date().getTime()}`,
      helpers.cleanEntity(entity)
    );
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  "banner/partial_update_entity",
  async (entity: IBanner, thunkAPI) => {
    const result = await axios.patch<IBanner>(
      `${apiUrl}/${entity.banner_id}?cacheBuster=${new Date().getTime()}`,
      helpers.cleanEntity(entity)
    );
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  "banner/delete_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_Banner = createEntitySlice({
  name: "banner",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(createEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = action.payload;
      })

      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers["x-total-count"], 10),
        };
      })
      .addMatcher(
        isRejected(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity
        ),
        (state, action) => {
          state.loading = false;
          state.updating = false;
          state.updateSuccess = false;
          state.errorMessage = action.payload;
        }
      )
      .addMatcher(
        isFulfilled(createEntity, updateEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(deleteEntity), (state) => {
        state.updating = true;
        state.loading = true;
        state.updateSuccess = false;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(createEntity, updateEntity, partialUpdateEntity),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { clearError, reset } = Reducer_Banner.actions;

// Reducer
export default Reducer_Banner.reducer;
