import React from "react";
import { useParams } from "react-router-dom";
import Theme404 from "../../layout/404";

import rating_list from "./rating.list";
import rating_view from "./rating.view";

/**
 *   Create index file for Rating
 */

export default function List_rating() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || "list";

  let ActualPage: any;
  switch (Param) {
    case "list":
      ActualPage = rating_list;
      break;

    case "view":
      ActualPage = rating_view;
      break;

    default:
      ActualPage = Theme404;
      break;
  }

  return <>{<ActualPage />}</>;
}

