import axios from "axios";
import { createAsyncThunk, isFulfilled, isPending } from "@reduxjs/toolkit";
import helpers from "helpers";
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from "../config/reducer.utils";
import { IPosts } from "../interface/post.model";

const initialState: EntityState<IPosts> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = "post";

export const getEntities = createAsyncThunk("posts/fetch_entity_list", async (_URL: IQueryParams) => {
  let __URL = helpers.buildEndUrl(_URL);
  const requestUrl = `${apiUrl}/list${__URL}`;
  return axios.get<any>(requestUrl);
});

export const getEntity = createAsyncThunk(
  "posts/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/detail/${id}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  "posts/create_entity",
  async (entity: IPosts, thunkAPI) => {
    const result = await axios.post<IPosts>(`${apiUrl}/create`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "posts/update_entity",
  async (entity: IPosts, thunkAPI) => {
    const result = await axios.patch<IPosts>(`${apiUrl}/update`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntity(entity._id));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  "posts/partial_update_entity",
  async (entity: IPosts, thunkAPI) => {
    const result = await axios.patch<IPosts>(`${apiUrl}/${entity._id}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  "posts/delete_entity",
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/delete/${id}`;
    thunkAPI.dispatch(getEntities({}));
    return axios.delete<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const POSTS = createEntitySlice({
  name: "posts",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })

      .addCase(deleteEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = "Can not delete this Entity";
      })
      .addCase(updateEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = action.error.message;
      })

      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers["x-total-count"], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
        state.entity = null;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { clearError, reset } = POSTS.actions;

// Reducer
export default POSTS.reducer;

