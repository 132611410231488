import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import dateandtime from "date-and-time";
import {
  Button,
  Card,
  ContextualSaveBar,
  DataTable,
  Form,
  FormLayout,
  Layout,
  List,
  Loading,
  Modal,
  Page,
  RadioButton,
  Stack,
  TextField,
  Toast,
} from "@shopify/polaris";
import {
  clearError,
  getEntity,
  updateEntity,
  createEntity,
} from "store/email_template.store.reducer";
import {
  asChoiceField,
  lengthLessThan,
  lengthMoreThan,
  useField,
  useForm,
} from "@shopify/react-form";
import Parser from "html-react-parser";

export default function EmailTemplateEdit() {
  let useParam = {} as any;
  useParam = useParams();
  let emailTemplateID = useParam.sub_slug || false;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const entity = useAppSelector((state) => state.email_template.entity);
  const loading = useAppSelector((state) => state.email_template.loading);
  const errorMessage = useAppSelector(
    (state) => state.email_template.errorMessage
  );
  const updating = useAppSelector((state) => state.email_template.updating);
  const updateSuccess = useAppSelector(
    (state) => state.email_template.updateSuccess
  );

  const [toastit, setToastit] = useState(null);

  useEffect(() => {
    if (updateSuccess === true) {
      setToastit("Template has been updated!");
      dispatch(clearError());
    }
  }, [updateSuccess]);

  const [activeModal, setActiveModal] = useState(false);

  useEffect(() => {
    dispatch(getEntity(emailTemplateID));
  }, []);

  function showPreviewPopUp() {
    const HTML = entity?.template_content;
    setActiveModal(true);
  }

  const useFields = {
    template_title: useField<string>({
      value: entity?.template_title ?? "",
      validates: [
        lengthLessThan(200, "No more than 200 characters."),
        lengthMoreThan(2, "No shorter than 2 characters."),
        (inputValue) => {
          if (inputValue.length < 2) {
            return "Your title is too short, or it is empty.";
          }
        },
      ],
    }),
    template_slug: useField<string>({
      value: entity?.template_slug ?? "",
      validates: [
        lengthLessThan(250, "No more than 250 characters."),
        lengthMoreThan(2, "No shorter than 2 characters."),
        (inputValue) => {
          if (inputValue.length < 2) {
            return "Your title is too short, or it is empty.";
          }
        },
      ],
    }),
    template_content: useField<string>({
      value: entity?.template_content ?? "",
      validates: [
        lengthLessThan(50000, "Your content is too long."),
        lengthMoreThan(2, "Your content is too short."),
      ],
    }),
    template_status: useField<string>({
      value: entity?.template_status === 1 ? "1" : "0",
      validates: [],
    }),
  };

  const { fields, submit, submitting, dirty, reset, submitErrors, makeClean } =
    useForm({
      fields: useFields,
      async onSubmit(values) {
        try {
          if (!emailTemplateID) {
            // create new
            dispatch(
              createEntity({
                template_title: values.template_title,
                template_slug: values.template_slug,
                template_content: values.template_content,
                template_status: Number(values.template_status),
              })
            );
          } else {
            dispatch(
              updateEntity({
                template_id: emailTemplateID,
                template_title: values.template_title,
                template_content: values.template_content,
                template_status: Number(values.template_status),
              })
            );
          }
          return { status: "success" };
        } catch (e: any) {
          console.error(`Submit error`, e);
          const message =
            e?.response?.data?.title ??
            "Lỗi không xác định, vui lòng thử lại sau.";
          const field = e?.response?.data?.errorKey ?? "base";
          return { status: "fail", errors: [{ field, message }] };
        }
      },
    });

  const contextBar = dirty ? (
    <ContextualSaveBar
      message="Unsaved email template"
      saveAction={{
        onAction: submit,
        loading: submitting,
        disabled: false,
      }}
      discardAction={{
        onAction: reset,
      }}
    />
  ) : null;

  const popupPreview = (
    <Modal
      large
      activator={null}
      open={activeModal}
      title="Email Preview"
      onClose={() => setActiveModal(!activeModal)}
      onScrolledToBottom={null}
      secondaryActions={[
        {
          content: "Close",
          onAction: () => setActiveModal(false),
        },
      ]}
    >
      {Parser(entity?.template_content || "")}
    </Modal>
  );

  const toastMarkup = toastit ? (
    <Toast
      content={toastit}
      onDismiss={() => {
        setToastit(null);
        dispatch(clearError());
      }}
    />
  ) : null;

  return (
    <>
      {loading ? (
        <div style={{ height: "100px" }}>
          <Loading />
        </div>
      ) : null}
      <Page
        breadcrumbs={[{ content: "All template", url: "/email_template" }]}
        primaryAction={{
          content: "Save",
          disabled: !dirty,
          onAction: submit,
          loading: submitting,
        }}
        title={`Edit: ` + entity?.template_title}
      >
        {toastMarkup}
        {contextBar}
        {popupPreview}
        <Layout>
          <Layout.Section>
            <Card title="Email template details" sectioned>
              <Form onSubmit={submit}>
                <FormLayout>
                  <TextField
                    autoComplete="off"
                    autoFocus
                    label="Template Title"
                    {...fields.template_title}
                  />
                  <TextField
                    autoComplete="off"
                    disabled
                    maxLength={250}
                    label="Template Slug"
                    {...fields.template_slug}
                    helpText={
                      "Slug will be use to show in other select form. Once created, this field can not be changed!"
                    }
                  />
                  <p>Template status</p>
                  <Stack vertical>
                    <RadioButton
                      label="Disabled"
                      {...asChoiceField(fields.template_status, "0")}
                      helpText="Archived, don't use"
                    />
                    <RadioButton
                      label="Enable"
                      {...asChoiceField(fields.template_status, "1")}
                      helpText="Can be used in Email Setting's section"
                    />
                  </Stack>
                  <TextField
                    autoComplete="off"
                    showCharacterCount
                    max={50000}
                    maxLength={50000}
                    label="Template content"
                    {...fields.template_content}
                    multiline={4}
                    helpText={
                      <>
                        <p>
                          HTML is supported, Use these placeholders in your
                          template HTML:
                        </p>
                        <br />
                        <List type="bullet">
                          <List.Item>
                            {`{{customer_name}}`} for customer name
                          </List.Item>
                          <List.Item>{`{{user_name}}`} for user name</List.Item>
                          <List.Item>
                            {`{{content}}`} for main content
                          </List.Item>
                        </List>
                      </>
                    }
                  />
                </FormLayout>
              </Form>
            </Card>
          </Layout.Section>

          <Layout.Section secondary>
            <Card title="Sumary" sectioned>
              <DataTable
                columnContentTypes={["text", "text"]}
                headings={[]}
                rows={[
                  ["Create by", entity?.users?.user_email],
                  [
                    "Create at",
                    entity?.createAt
                      ? dateandtime.format(
                          new Date(Number(entity.createAt)),
                          "MMM DD YYYY"
                        )
                      : "",
                  ],
                  [
                    "Status",
                    entity?.template_status === 1 ? "active" : "disabled",
                  ],
                ]}
                showTotalsInFooter={false}
                hideScrollIndicator
              />
              <Button fullWidth external outline onClick={showPreviewPopUp}>
                Preview this template
              </Button>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
}
