import {
  Button,
  Card,
  DataTable,
  DatePicker,
  DisplayText,
  EmptyState,
  Layout,
  Loading,
  Modal,
  Page,
  Select,
  Stack,
  Subheading,
  TextField,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import helpers from "../../helpers";
import dateandtime from "date-and-time";
import { TickSmallMinor, LockMinor, EditMinor } from "@shopify/polaris-icons";
import Pagination from "components/pagination";
import emptyIMG from "../../media/empty.png";
import { useAppDispatch, useAppSelector } from "config/store";
import {
  clearError,
  getEntity,
  getEntities,
  partialUpdateEntity,
  updateEntity,
  deleteEntity,
} from "store/notification.store.reducer";
import QuickUploadImage from "components/oneclick-upload";

export default function Notification_List() {
  const entity = useAppSelector((state) => state.notification.entity);
  const entities = useAppSelector((state) => state.notification.entities);
  const loading = useAppSelector((state) => state.notification.loading);
  const errorMessage = useAppSelector((state) => state.notification.errorMessage);
  const totalItems = useAppSelector((state) => state.notification.totalItems);
  const updating = useAppSelector((state) => state.notification.updating);

  // const history = useNavigate();
  const dispatch = useAppDispatch();
  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, [dispatch]);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery = helpers.ExtractUrl(useParam.search) || false;

  const initialQuery = {
    page: 1,
    limit: 20,
    order_by: "DESC",
  };

  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...initialQuery,
  });

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePageNumber = useCallback(
    (numPage) => {
      setMainQuery({ ...mainQuery, page: numPage });
    },
    [mainQuery]
  );

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch)
      window.history.replaceState(null, "Notification", "/notification" + buildURLSearch);
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  /**
   * Query NOTIFICATION DETAIL
   * @param  _id
   */

  const [showEditModel, setShowEditModel] = useState<boolean>(false);
  const [showFormEdits, setShowFormEdits] = useState<boolean>(false);
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);

  const getNotificationDetail = (_id: number) => {
    dispatch(getEntity(_id));
    setShowEditModel(true);
    setDeleteConfirm(false);
  };
  const closeModal = useCallback(() => {
    if (showEditModel) {
      // close model ? Reload data
      dispatch(getEntities(mainQuery));
    }
    setShowEditModel((active) => !active);
  }, [showEditModel]);

  // Discard schedule notification
  const discardContent = useCallback(() => {
    dispatch(deleteEntity(entity?._id));
    closeModal();
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  // Confirm discard schedule notification
  const discardContentConfirm = useCallback(() => {
    setDeleteConfirm((deleteConfirm) => !deleteConfirm);
  }, []);

  const [title, setTitle] = useState<string>("");
  const handChangeTitle = useCallback((newValue) => setTitle(newValue), []);

  const [content, setContent] = useState<string>("");
  const handChangeContent = useCallback((newValue) => setContent(newValue), []);

  const [userID, setUserID] = useState<string>("");
  const handChangeUserID = useCallback((newValue) => setUserID(newValue), []);

  /**
   * Image
   */

  const [srcImage, setSrcImage] = useState("");
  const [internalErrorNotice, setInternalErrorNotice] = useState("");

  function handQuickUpdateSuccess(res: any) {
    setSrcImage(res[0].thumbnail);
  }

  function handUploadError(err: any) {
    setInternalErrorNotice("Upload Fail! Image is too large!");
  }

  /**
   * Option mode
   */

  const [manual, setManual] = useState("0");

  const mode = [
    { label: "", value: "0" },
    { label: "Right now", value: "2" },
    { label: "Set time", value: "1" },
  ];

  const handleSelectChange = useCallback((value) => setManual(value), []);

  useEffect(() => {
    if (entity) {
      setTitle(entity?.title);
      setContent(entity?.content);
      setManual(entity?.manual_mode);
      setSrcImage(entity?.image);
    }
  }, [entity]);

  /* Date picker */
  function pad(num: number, size = 2) {
    let numbr = num.toString();
    while (numbr.length < size) numbr = "0" + numbr;
    return numbr;
  }

  /**
   * Format date and time
   */
  function extractTimeAndDateFromSource(source: string) {
    let DateObject = new Date();
    try {
      if (source) {
        DateObject = new Date(String(source));
      }
      const _date =
        pad(DateObject.getDate(), 2) + "/" + pad(DateObject.getMonth() + 1, 2) + "/" + DateObject.getFullYear();
      const _timestamp = DateObject.getTime();
      return {
        date: _date,
        timestamp: _timestamp,
        day: pad(DateObject.getDate(), 2),
        month: DateObject.getMonth(),
        year: DateObject.getFullYear(),
      };
    } catch (_) {
      return {
        date: "",
        timestamp: 0,
        day: 0,
        month: 0,
        year: 0,
      };
    }
  }

  const today_active = extractTimeAndDateFromSource("");
  const [{ month, year }, setDate] = useState({
    month: today_active.month,
    year: today_active.year,
  });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  const handleMonthChange = useCallback((_month, _year) => setDate({ month: _month, year: _year }), []);

  /* Hours */
  const [notification_hour, setNotification_hour] = useState("00");
  const handleHourChange = useCallback((value) => setNotification_hour(value), []);
  const hours: any = [];
  for (let i = 0; i < 24; i++) {
    hours.push({ label: pad(i), value: pad(i) });
  }

  /* Minutes */
  const [notification_minute, setNotification_minute] = useState("00");
  const handleMinuteChange = useCallback((value) => setNotification_minute(value), []);
  const minutes = [
    { label: "00", value: "00" },
    { label: "15", value: "15" },
    { label: "30", value: "30" },
    { label: "45", value: "45" },
  ];

  const datety = new Date(selectedDates.start).getDate();

  const saveForm = () => {
    dispatch(
      updateEntity({
        _id: entity?._id,
        title: title,
        content: content,
        image: srcImage,
        manual_mode: manual,
        send_start: `${year}-${pad(month + 1, 2)}-${pad(datety)}` + ` ${notification_hour}:${notification_minute}:00`,
      })
    );
    setShowFormEdits(false);
  };

  let notiData = null;
  try {
    notiData = JSON.stringify(entity.data);
  } catch (e) {
    notiData = String(notiData);
  }

  let notiParam = null;
  try {
    notiParam = JSON.parse(entity.param);
  } catch (e) {
    notiParam = String(notiParam);
  }

  const detailModal = (
    <Modal
      open={showEditModel}
      onClose={closeModal}
      title="View Notification"
      titleHidden
      primaryAction={{
        content: "Save",
        onAction: saveForm,
      }}
      secondaryActions={[
        {
          content: "Close",
          onAction: closeModal,
        },
      ]}
    >
      <Modal.Section>
        {!entity && !loading ? (
          <Subheading>Oh! Data not found!</Subheading>
        ) : (
          <>
            <strong>{entity?.title}</strong>
            <br />

            {/* <p style={{ fontWeight: "bold" }}>{entity?.content}</p> */}
            <div style={{ display: "flex" }}>
              <p style={{ fontWeight: "bold" }}>Publish date:&nbsp;</p>
              <p>
                <time>
                  {entity?.send_start ? dateandtime.format(new Date(entity?.send_start), "DD-MM-YYYY HH:mm:ss") : "-"}
                </time>
              </p>
            </div>
            <br />
            <div style={{ display: "flex", float: "right", marginRight: "1.6rem" }}>
              {/* <p style={{ fontWeight: "bold" }}>Created by:&nbsp;</p> */}
              <p>{notiParam?.createBy?.display_name}</p>
            </div>
            <br />

            <DisplayText size="small">Image</DisplayText>
            <div id="profile_heading">
              <div className="banner_thumbnail_inner">
                <div className="banner_thumbnail_avatar">
                  <QuickUploadImage onSuccess={handQuickUpdateSuccess} onError={handUploadError} />
                  <img
                    src={
                      srcImage
                        ? srcImage
                        : "https://lgbtapp.s3.ap-southeast-1.amazonaws.com/2022/10/13/6331de50394dbf50eedb4242-IMG_0003.PNG"
                    }
                    // alt="Upload banner here"
                    style={{ width: "115px" }}
                  />
                </div>
              </div>
            </div>

            <TextField
              label="Title"
              requiredIndicator
              value={title}
              onChange={handChangeTitle}
              autoComplete="off"
              autoFocus
            />
            <TextField
              label="Content"
              requiredIndicator
              value={content}
              onChange={handChangeContent}
              maxLength={1000}
              multiline={2}
              autoComplete="off"
              showCharacterCount
            />

            <Select label="Type" options={mode} requiredIndicator onChange={handleSelectChange} value={manual} />

            <TextStyle>Publish date</TextStyle>
            <DatePicker
              month={month}
              year={year}
              onChange={setSelectedDates}
              onMonthChange={handleMonthChange}
              selected={selectedDates}
            />
            <Stack>
              <Stack.Item>
                <Select label="Hour" options={hours} onChange={handleHourChange} value={notification_hour} />
              </Stack.Item>
              <Stack.Item>
                <Select label="Minute" options={minutes} onChange={handleMinuteChange} value={notification_minute} />
              </Stack.Item>
            </Stack>
          </>
        )}
      </Modal.Section>
    </Modal>
  );

  /**
   * END QUERY NOTIFICATION DETAIL
   */
  const emptyData = (
    <EmptyState heading="No post here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>
  );

  const renderItem = (notification: any) => {
    const { _id, title, content, user_id, channel, type_action, createdAt } = notification;
    return [
      <div className="clickable" key={_id + "_noti_title"} onClick={() => getNotificationDetail(_id)}>
        {title}
      </div>,
      <div className="clickable" key={_id + "_noti_content"} onClick={() => getNotificationDetail(_id)}>
        <p>{helpers.trimContentString(content)}</p>
      </div>,
      <div className="clickable" key={_id + "_noti_data"} onClick={() => getNotificationDetail(_id)}>
        {type_action}
      </div>,
      <div className="clickable" key={_id + "_noti_channel"} onClick={() => getNotificationDetail(_id)}>
        {channel}
      </div>,
      <div className="clickable" key={_id + "_noti_channel"} onClick={() => getNotificationDetail(_id)}>
        <div style={{ textAlign: "center" }}>{user_id?.display_name}</div>
      </div>,
      <div className="clickable" key={_id + "_noti_createAt"} onClick={() => getNotificationDetail(_id)}>
        <time>{createdAt ? dateandtime.format(new Date(createdAt), "DD-MM-YYYY HH:mm:ss") : "-"}</time>
      </div>,
      <div className="clickable" key={_id + "_noti_id"} onClick={() => getNotificationDetail(_id)}>
        {_id}
      </div>,
    ];
  };

  const PagesList =
    totalItems > 0 ? (
      <>
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text", "text", "text"]}
          headings={["Title", "Content", "Data", "Channel", "User", "Create at", "ID"]}
          rows={entities?.map(renderItem)}
          hideScrollIndicator
          footerContent={`Display page ${mainQuery.page} of total ${totalItems} results...`}
        />
        <style>{`
        .small-icon {
          margin-left: 0.6rem;
          font-size: 12px;
          padding: 0;
          width: 15px;
          height: auto;
        }
      `}</style>
      </>
    ) : (
      emptyData
    );

  const Actual_page = (
    <>
      <Page fullWidth>
        {detailModal}
        <Layout>
          <Layout.Section>
            <Card>{PagesList}</Card>
            <br />
            {totalItems > 0 ? (
              <Pagination
                TotalRecord={totalItems}
                onChangePage={onChangePageNumber}
                pageSize={Number(mainQuery.limit)}
                activeCurrentPage={Number(mainQuery.page)}
              />
            ) : null}
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );

  const toastMarkup = errorMessage ? <Toast content={errorMessage} error onDismiss={toggleActive} /> : null;

  return (
    <>
      {toastMarkup}
      {loading ? <Loading /> : null}
      {Actual_page}
    </>
  );
}

