import { Box, Button, Card, CardActions, CardContent, CircularProgress, Divider, FormControl, InputLabel, Stack, TextField, Typography } from "@mui/material";
import { HomeMajor } from "@shopify/polaris-icons";

import { useCallback, useEffect, useState } from "react";
import LoginLogo from "../media/LoginLogo.svg";
import { useAppDispatch, useAppSelector } from "../config/store";
import { useNavigate } from "react-router-dom";

import { notEmpty, useField, useForm } from "@shopify/react-form";
import { gapi } from "gapi-script";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import axios from "axios";
import { useGoogleLogin } from '@react-oauth/google';
import { ChecklistMajor } from "@shopify/polaris-icons";

import helpers from "../helpers";
import {
  login,
  logout,
  clearError,
  // createSession,
} from "../store/user.store.reducer";
import '../styles/login.css'
import CustomSnackbar from 'components/snackbar';

import logo from "../media/logo-ikigaicoach-ngang.png"
import google from "../media/social/google.png"
import facebook from "../media/social/facebook.png"

import { loginApi, loginGoogle } from "service/login";
import { Link } from "react-router-dom";

export default function Login(props: any) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [getTokenGG, setGetTokenGG] = useState<string | undefined>(undefined);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await loginApi(email, password);
      const session = response.headers['x-authorization'];
      localStorage.setItem("AJAX_URL", session)
      localStorage.setItem("AJAX_USERID", response.data._id);

      window.location.href = '/';
      setLoading(false);
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      if (errorMessage) {
          const normalizedErrorMessage = Array.isArray(errorMessage) ? errorMessage[0].trim().toLowerCase() : errorMessage.trim().toLowerCase();

          if (normalizedErrorMessage === "user_email must be an email") {
              setSnackbarMessage('Không được bỏ trống trường Email và Password');
          } else if (normalizedErrorMessage === "e-mail or password is not correct!") {
              setSnackbarMessage('Thông tin đăng nhập sai, xin kiểm tra lại!');
          } else {
              setSnackbarMessage('Đã xảy ra lỗi, vui lòng thử lại sau.');
          }
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };



  // const clientId =
  //   "1010938218852-k595iror2jvjkl53unq0407p14pajhkv.apps.googleusercontent.com";


  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setGetTokenGG(tokenResponse.access_token)
      console.log(tokenResponse.access_token)
    },
    onError: (error) => {
      console.log('Login Failed:', error);
    },
  });

  useEffect(() => {
    const handleLogin = async () => {
      try {
        const accessToken = getTokenGG
        if (accessToken) {
          const response = await loginGoogle(accessToken);
          console.log(response)
          const session = response.headers['X-Authorization'];
          localStorage.setItem("AJAX_URL", session)
          localStorage.setItem("AJAX_USERID", response.data._id);

          window.location.href = '/';
        } else {
          
        }
      } catch (error) {
          setSnackbarMessage('Lỗi đăng nhập');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
      }
    };

    handleLogin();
  }, [getTokenGG]);

  /**
   * Nếu đăng nhập thành công!
   */
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
      //window.location.reload();
    }
  }, [isAuthenticated]);

  // const toggleBannerActive = useCallback(() => {
  //   dispatch(clearError());
  // }, []);


  // const onSuccessLoginGoogle = (response: any) => {
  //   dispatch(
  //     login({
  //       user_token: "eyJhbGciOiJSUzI1NiIsImtpZCI6IjE1NDllMGFlZjU3NGQxYzdiZGQxMzZjMjAyYjhkMjkwNTgwYjE2NWMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2NTk0MTIxODUsImF1ZCI6IjE1OTYzNjMxNjYxMC11dWd1dGRpcDQxcW84OHRvdm50aGxvbW4xMG01OG4yYi5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjEwNDcyNjE4OTUwMTM5NTAzNzczNiIsImVtYWlsIjoiYW55ZW50aHV5QGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhenAiOiIxNTk2MzYzMTY2MTAtdXVndXRkaXA0MXFvODh0b3ZudGhsb21uMTBtNThuMmIuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJuYW1lIjoiVG9ueSBWdSIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS0vQUZkWnVjclZ6XzJNR0dYU29lVllaVVJmZGF3cHlLTDd2WXVqeWEtLThFcG1MQT1zOTYtYyIsImdpdmVuX25hbWUiOiJUb255IiwiZmFtaWx5X25hbWUiOiJWdSIsImlhdCI6MTY1OTQxMjQ4NSwiZXhwIjoxNjU5NDE2MDg1LCJqdGkiOiJlZWU1NjEyYTg2MGM5MzhlYTJhMGVmNzViYzU4YjQ4NWRhZWY0N2IxIn0.IkiAZGr1AtkbfM13pBu8zUHjun91pQAsVVUO2URGIZRfyW5nNF4mPKY3l9rHveIKkGM1b3OjUU00-eqna221wF-4QvZYFj5iJpN9YZYZHJ75OZknRW_DsXt6eqEqWRc58IaXhokbj_hR6h-nfhsB7MewzAfHNFOQPFf3JvblMxxze9hJHZoKhk2vEy0vCeyIEQWXhFLheZnwXCU8JVCVt0X950DZ1wwpXbzmYU4LNGFR78jGsGOcI98JUATsN_XGuPJEkZULwK-GGcJb4hqUPLyqmelAuXPXjTun96PzM183lJyVWxm5fKEW373oNf5enHdhtVxNJu-Dc0gzL2w31w",
  //     })
  //   );
  // };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column !important',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Box sx={{ width: '500px', padding: "20px 30px", borderRadius: '4px', backgroundColor: '#fff', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
        <img src={logo} alt="logo" width={200} />
        <Box>
          <Typography variant='h6' fontWeight={550} mt={3} mb={3}>Sign In</Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            placeholder="Enter your email address"
            label="Email Address"
            type="email"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            // error={!!emailError}
            // helperText={emailError}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              // if (emailError) setEmailError('');
            }}
            sx={{ mb: 2 }}
          />

          <TextField
            placeholder="Enter your password"
            label="Password"
            type="password"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            // error={!!passwordError}
            // helperText={passwordError}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
              // if (passwordError) setPasswordError('')
            }}
            sx={{ mb: 2 }}

          />

          {loading ? (
            <CircularProgress sx={{ display: 'block', margin: 'auto', mt: 2, mb: 2 }} />
          ) : (
            <Box sx={{ display: 'flex', direction: 'row', gap: 2, alignItems: 'center', mb: 2, justifyContent: 'center' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: '100%', height: '55px', borderRadius: '5px', fontWeight: 550, backgroundColor: '#2e3192', transition: '0.2s ease-in', boxShadow: 'unset', '&:hover': { backgroundColor: 'rgba(46, 49, 146, 0.8)' } }}
                onClick={handleSubmit}
              >
                Login
              </Button>
              <Link to='/recover_password' style={{ width: '100%', textDecoration: 'none', float: 'right', color: '#2e3192', textAlign: 'center', fontSize: '15px' }}>Forgot password</Link>

            </Box>
          )}
        </form>
        <Divider onClick={() => login()}> Or continue with </Divider>
        <Box mt={2}>
          <Stack direction="column" gap={1} alignItems="center" justifyContent="space-between">
            <div
              className="social"
              onClick={() => login()}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px 20px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                marginTop: '20px'
              }}
            >
              <img alt="logo" src={google} width={40} style={{ marginRight: 10 }} />
              Continue with Google
            </div>
          </Stack>
        </Box>
        <Typography variant='body2' ml={5} mt={2}>New to ....? <Link to='/register'>Create an account</Link></Typography>

      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
}
