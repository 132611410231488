import axios from "axios";
import { getCookie } from "utils/setCookie";

export const getListTransaction = async (data?: any): Promise<any> => {
    const url = process.env.REACT_APP_AJAX_URL + 'transaction/v2/admin-list?order_by=DESC&limit=10';
    try {
        const axiosConfig = {
            headers: {
                "X-Authorization": getCookie("session")
            },
            params: data
        };

        return await axios.get(url ,axiosConfig);
    } catch (error) {
        throw new Error('Failed to fetch user data');
    }
}

export const updateTransaction = async (data?: any): Promise<any> => {
    const url = process.env.REACT_APP_AJAX_URL + 'transaction/admin-update';
    try {
        const axiosConfig = {
            headers: {
                "X-Authorization": getCookie('session'),
            },
        };

        return await axios.patch(url, data ,axiosConfig);
    } catch (error) {
        throw new Error('Failed to fetch user data');
    }
}