import axios from "axios";
import { ILogin, ILoginGoogle } from "interface/user.model";

export const loginApi = async (
  user_email: string,
  user_password: string,
): Promise<any> => {
  const requestData: ILogin = { user_email, user_password };
  const url = process.env.REACT_APP_AJAX_URL + "user/login/password";
  return axios.post(url, requestData);
};

export const loginGoogle = async (userToken: string): Promise<any> => {
  try {
    const requestData = { user_token: userToken };
    const url = process.env.REACT_APP_AJAX_URL + "user/login/google";
    const response = await axios.post(url, requestData);
    return response;
  } catch (error) {
    throw new Error(`Error in loginGoogle service: ${error.message}`);
  }
};