import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from '../config/reducer.utils';
import { IUser, defaultValue } from '../interface/user.model';
import { AppThunk } from '../config/store';


const initialState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  searchEntities:""
};

const apiUrl = 'users';

// Actions

export const getEntities = createAsyncThunk('advisor/fetch_entity_list', async (_URL: IQueryParams) => {
  let __URL = helpers.buildEndUrl(_URL);
  const requestUrl = `${apiUrl}${__URL}&cacheBuster=${new Date().getTime()}`;
  return axios.get<any>(requestUrl);
});

export const searchEntities = createAsyncThunk('advisor/fetch_entity_list', async (value: string | number) => {
  const requestUrl = `${apiUrl}?display_name=${value}&user_role=advisor&cacheBuster=${new Date().getTime()}`;
  return axios.get<any>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'advisor/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}?cacheBuster=${new Date().getTime()}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'advisor/create_entity',
  async (entity: IUser,thunkAPI) => {
    const result = await axios.post<IUser>(`${apiUrl}?cacheBuster=${new Date().getTime()}`, helpers.cleanEntity(entity));
    /**
     * Sau khi thực hiện xong quẻry bên trên thì bắt đầu thực hiện thêm cái quẻry bên dưới :D 
     */
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'advisor/update_entity',
  async (entity: IUser, thunkAPI) => {
    const result = await axios.patch<IUser>(`${apiUrl}/${entity.user_id}?cacheBuster=${new Date().getTime()}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntity(entity.user_id));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'advisor/partial_update_entity',
  async (entity: IUser, thunkAPI) => {
    const result = await axios.patch<IUser>(`${apiUrl}/${entity.user_id}?cacheBuster=${new Date().getTime()}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);


export const deleteEntity = createAsyncThunk(
  'advisor/delete_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);


// slice

export const ADVISOR = createEntitySlice({
  name: 'advisor',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage  = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(deleteEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = "Can not delete this Entity"
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(deleteEntity), state => {
        state.updating = true;
        state.loading = true;
        state.updateSuccess = false;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })

      .addMatcher(isFulfilled(searchEntities), (state,action) => {
        return {
          ...state,
          loading: false,
          searchEntities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      // .addMatcher(isRejected(searchEntities), state => {
      //   state.errorMessage = null;
      //   state.updateSuccess = false;
      //   state.updating = true;
      // })
      .addMatcher(isPending(searchEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { clearError, reset } = ADVISOR.actions;

// Reducer
export default ADVISOR.reducer;
