import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, IconButton, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { CiEdit } from 'react-icons/ci'
import { FiPlus } from 'react-icons/fi'
import { IoIosArrowDown } from "react-icons/io";
import { FaPhotoVideo, FaFile } from "react-icons/fa";
import { AddModuleMedia, updateModuleMedia, updateModuleTitle, uploadMedia } from '../service';
const ModuleCourse = ({
    styleModule,
    showNewModuleBox,
    formDataModule,
    setFormDataModule,
    isEditingTitle,
    handleChangeCourseModule,
    mediaUrl,
    setIsEditingTitle,
    styleButtonUpload,
    handleFileChangeAndUpload,
    modules,
    handleSubmit,
    handleShowNewModuleBox
  }) => {
    const [expanded, setExpanded] = useState(null);
    const [headerText, setHeaderText] = useState<{ [key: string]: string }>({});
    const [editingModuleId, setEditingModuleId] = useState<string | null>(null);
    const [mediaType, setMediaType] = useState(null);
    const [selectedModule, setSelectedModule] = useState(null);

    const handleExpandClick = (moduleId) => (event, isExpanded) => {
        if (!isExpanded) {
            event.stopPropagation(); 
            setExpanded(expanded === moduleId ? null : moduleId);
        }
    };
    

    const handleEditClick = (moduleId) => (event) => {
        event.stopPropagation(); 
        setEditingModuleId(moduleId);
    };

    const handleSaveClick = async (moduleId: string) => {
        try {
          const newTitle = headerText[moduleId] || modules.find(module => module._id === moduleId)?.title || '';
          await updateModuleTitle(moduleId, newTitle);
          setEditingModuleId(null);
          // Handle success
        } catch (error) {
          // Handle error
        }
      };

      const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, moduleId: string) => {
        if (event.target.files && event.target.files[0]) {
          const file = event.target.files[0];
          const headers = {
            'X-Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQ1Nzc3MDAsImRhdGEiOnsiX2lkIjoiNjU4MjVhYTQ5ZDY2YWM4YzQ3YzZiMDFkIiwia2V5IjoiOTNiOTgxN2VkZmQ1ZjU1NDBkZTI2MDNiM2M3N2JlZmEiLCJzaWduYXR1cmUiOiI2ZGI5M2RhMzE1YzRjNzBkNjY2YjNiNWRjZjIwYzUzMiIsInNlc3Npb24iOiI2NTgyNWFhNDlkNjZhYzhjNDdjNmIwMWYifSwiaWF0IjoxNzAzMDQxNzAwfQ.R0CrGfSMvi_V3T445vlp75Eetz_x6RHCrWtaSkXo0A8'
          };
          try {
            const { id } = await uploadMedia(file, headers);
            await updateModuleMedia(moduleId, id);
          } catch (error) {
            console.error('Error updating module media:', error);
          }
        }
      };

      const AddMoreMedia = async (event: React.ChangeEvent<HTMLInputElement>, moduleId: string) => {
        if (event.target.files && event.target.files[0]) {
          const file = event.target.files[0];
          const headers = {
            'X-Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQ1Nzc3MDAsImRhdGEiOnsiX2lkIjoiNjU4MjVhYTQ5ZDY2YWM4YzQ3YzZiMDFkIiwia2V5IjoiOTNiOTgxN2VkZmQ1ZjU1NDBkZTI2MDNiM2M3N2JlZmEiLCJzaWduYXR1cmUiOiI2ZGI5M2RhMzE1YzRjNzBkNjY2YjNiNWRjZjIwYzUzMiIsInNlc3Npb24iOiI2NTgyNWFhNDlkNjZhYzhjNDdjNmIwMWYifSwiaWF0IjoxNzAzMDQxNzAwfQ.R0CrGfSMvi_V3T445vlp75Eetz_x6RHCrWtaSkXo0A8'
          };
          try {
            const { id } = await uploadMedia(file, headers);
            await AddModuleMedia(moduleId, id);
          } catch (error) {
            console.error('Error updating module media:', error);
          }
        }
      };
    
      const handleBlur = (moduleId: string) => async (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        await handleSaveClick(moduleId);
      };

    const handleTextChange = (moduleId) => (event) => {
        setHeaderText((prevState) => ({
        ...prevState,
        [moduleId]: event.target.value,
        }));
    };

    const handleModuleClick = (module) => {
        if (selectedModule === module) {
            setSelectedModule(null);
        } else {
            setSelectedModule(module); 
        }
    };
      
  
  return (
    <Box sx={styleModule}>
        {showNewModuleBox && (
        <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px', height: 'auto', width: '100%', p: 2, mb: 2  }}>
            <TextField 
            value={formDataModule.course_id}
            onChange={(e) => setFormDataModule((prevState) => ({
                ...prevState,
                course_id: e.target.value,
            }))}
            sx={{ display: 'none' }}/>
            {isEditingTitle ? (
            <TextField
                fullWidth
                name='title'
                value={formDataModule.title}
                onChange={(e) => handleChangeCourseModule("title", e.target.value)}
                size='small'
                placeholder='Điền tên học phần'
                onBlur={() => setIsEditingTitle(false)}
            />
            ) : (
            <Stack direction='row' gap={2} alignItems='center'>
                <Typography>
                    {formDataModule.title || 'Học phần 1: Giới thiệu'}
                </Typography>
                <CiEdit style={{ cursor: 'pointer' }} onClick={() => setIsEditingTitle(true)}/>
            </Stack>
            )}
            <Box>
                {formDataModule.type === 'file' && mediaUrl.endsWith('.pdf') && (
                    <embed src={mediaUrl} type="application/pdf" width="100%" height="600px" />
                )}
                {formDataModule.type === 'file' && mediaUrl.endsWith('.pptx') && (
                    <iframe src={`https://docs.google.com/gview?url=${encodeURIComponent(mediaUrl)}&embedded=true`} width="100%" height="600px" scrolling="no"></iframe>
                )}
                {formDataModule.type === 'file' && (mediaUrl.endsWith('.doc') || mediaUrl.endsWith('.docx')) && (
                    <iframe src={`https://docs.google.com/gview?url=${encodeURIComponent(mediaUrl)}&embedded=true`} width="100%" height="600px" scrolling="no"></iframe>
                )}
                {mediaUrl && formDataModule.type === 'video' && (
                    <Box mt={1} mb={1}>
                        <video style={{ width: '100%' }} height="240" controls>
                        <source src={mediaUrl} type="video/mp4" />
                        Trình duyệt của bạn không hỗ trợ thẻ video.
                        </video>
                    </Box>
                )}
            </Box>
            <Stack direction='row' gap={1} alignItems='center'>
                <Button
                    component="label"
                    variant="contained"
                    startIcon={<FiPlus />}
                    sx={styleButtonUpload}
                    data-type="video"
                >
                    Thêm video
                    <input
                    type="file"
                    accept="video/*"
                    style={{ display: 'none' }}
                    onChange={handleFileChangeAndUpload}
                    />
                </Button>
                <Button
                    component="label"
                    variant="contained"
                    startIcon={<FiPlus />}
                    sx={styleButtonUpload}
                    data-type="file"
                >
                    Thêm tài liệu
                    <input
                    type="file"
                    accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    style={{ display: 'none' }}
                    onChange={handleFileChangeAndUpload}
                    />
                </Button>
                <Button
                    component="label"
                    variant="contained"
                    startIcon={<FiPlus />}
                    sx={styleButtonUpload}
                    data-type="exam"
                >
                    Thêm bài thi
                    <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileChangeAndUpload}
                    />
                </Button>
            </Stack>
            <Button variant='contained' onClick={handleSubmit} sx={{ mt: 1 }}>Thêm</Button>
        </Box>
        )}
        <Button variant='contained' startIcon={<FiPlus />} onClick={handleShowNewModuleBox}>Thêm học phần mới</Button>

        <Divider sx={{ mt: 1, mb: 1 }}></Divider>

        <Box>
            <Typography variant='body1' fontWeight={550} mt={2} mb={2}>Danh sách học phần</Typography>
            {Array.isArray(modules) && modules.length > 0 ? (
                modules.map((module) => (
                <Accordion
                    key={module._id}
                    expanded={expanded === module._id}
                    onChange={(event, isExpanded) => handleExpandClick(module._id)(event, isExpanded)}
                  >
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id={`panel1a-header-${module._id}`}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      {editingModuleId === module._id ? (
                            <TextField
                            value={headerText[module._id] || module.title}
                            onChange={(event) => setHeaderText({ ...headerText, [module._id]: event.target.value })}
                            onBlur={handleBlur(module._id)}
                            variant="standard"
                            autoFocus
                            sx={{ flexGrow: 1 }}
                            />
                        ) : (
                            <Typography sx={{ flexGrow: 1 }} onClick={() => setEditingModuleId(module._id)}>
                            {headerText[module._id] || module.title}
                            </Typography>
                        )}
                        <IconButton onClick={handleEditClick(module._id)} sx={{ ml: 1 }}>
                          <CiEdit />
                        </IconButton>
                        <IconButton onClick={(event) => handleExpandClick(module._id)(event, expanded === module._id)} sx={{ ml: 1 }}>
                            <IoIosArrowDown />
                        </IconButton>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack direction='row' gap={2} alignItems='center'>
                            {module.media_ids?.map((media, index) => (
                                <React.Fragment key={index}>
                                    {media.media_mime_type.split('/')[0] === 'video' ? (
                                        <FaPhotoVideo />
                                    ) : (
                                        <FaFile />
                                    )}
                                    <Typography variant='body2' onClick={() => handleModuleClick(module)}>
                                        {media.media_file_name}
                                    </Typography>
                                </React.Fragment>
                            ))}
                            <input
                                type="file"
                                accept="video/*,application/pdf"
                                onChange={(event) => handleFileChange(event, module._id)}
                                />

                        </Stack>
                        {selectedModule === module && (
                        <>
                            {mediaType === 'video' ? (
                            <video width="320" height="240" controls>
                                <source src={module.media_id?.media_url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            ) : (
                            <embed src={module.media_id?.media_url} type="application/pdf" width="100%" height="600px" />
                            )}
                        </>
                        )}
                    </AccordionDetails>
                    <Button
                        component="label"
                        variant="contained"
                        startIcon={<FiPlus />}
                        sx={styleButtonUpload}
                    >
                        Update thêm Video/File
                        <input
                            type="file"
                            accept="video/*, image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            style={{ display: 'none' }}
                            onChange={(event) => AddMoreMedia(event, module._id)}
                            multiple 
                        />
                    </Button>
                </Accordion>
                ))
            ) : (
                <Typography>Không có học phần nào.</Typography>
            )}
        </Box>
    </Box>
  )
}

export default ModuleCourse