import {
  Card,
  EmptyState,
  Form,
  FormLayout,
  Icon,
  IndexTable,
  Layout,
  Modal,
  Page,
  Select,
  Stack,
  TextContainer,
  TextField,
  Toast,
  useIndexResourceState,
} from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import debounce from "lodash.debounce";
import emptyIMG from "../../media/empty.png";
import Pagination from "../../components/pagination";
import { clearError, getEntities, deleteEntity, updateEntity } from "../../store/rating.store.reducer";
import RatingFilter from "./filter";
import helpers from "helpers";
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from "@shopify/react-form";
import { StarFilledMinor } from "@shopify/polaris-icons";
import SkeletonLoading from "components/skeleton_loading";
import dateandtime from "date-and-time";

// import { getEntitiesAll as getUsers } from "store/users.store.reducer";

export default function General_rating() {
  const entity = useAppSelector((state) => state.rating.entity);
  const entities = useAppSelector((state) => state.rating.entities);
  const loading = useAppSelector((state) => state.rating.loading);
  const errorMessage = useAppSelector((state) => state.rating.errorMessage);
  const totalItems = useAppSelector((state) => state.rating.totalItems);
  const updating = useAppSelector((state) => state.rating.updating);
  const updateSuccess = useAppSelector((state) => state.rating.updateSuccess);

  // const entitieusers = useAppSelector((state) => state.users.entities);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);

  const [initial_loading, setInitial_loading] = useState<boolean>(true);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery = helpers.ExtractUrl(useParam.search) || false;

  const [mainQuery, setMainQuery] = useState({
    ...{
      page: 1,
      limit: 20,
    },
    ...StringQuery,
  });
  const [queryValue, setQueryValue] = useState<string>("");

  const handleFiltersQueryChange = useCallback((value) => setQueryValue(value), []);

  // const handleFiltersQueryChange = useCallback(
  //   (_value) => {
  //     setMainQuery({ ...mainQuery, ...{ query: _value, page: 1 } });
  //   },
  //   [mainQuery]
  // );

  /**
   * Change page number
   */
  const onChangePageNumber = useCallback((numPage) => {
    setMainQuery({ ...mainQuery, page: numPage });
  }, []);

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch) history("/rating" + buildURLSearch);
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  const onChangeCallback = useMemo(
    () =>
      debounce((_value) => {
        if (_value !== "") setMainQuery({ ...mainQuery, query: _value ? _value : "" });
      }, 500),
    []
  );

  useEffect(() => {
    // dispatch(getUsers({}));
    // dispatch(getOrders({}));
  }, []);

  const [orderId, setOrderId] = useState([]);

  useEffect(() => {
    const optionType = [];
    if (entities) {
      entities.filter((e, i) => {
        optionType.push(e);
      });
      setOrderId(optionType);
    }
  }, [entities]);

  useEffect(() => {
    onChangeCallback(queryValue);
  }, [queryValue]);

  const [showEditModel, setShowEditModel] = useState<any>(false);

  const closeModal = useCallback(() => {
    setShowEditModel((active) => !active);
  }, [showEditModel]);

  useEffect(() => {
    if (loading === false) setInitial_loading(false);
  }, [loading]);

  /**
   * Delete rating
   */

  const [openModal, setOpenModal] = useState<any>(false);

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(entities, {
    resourceIDResolver,
  });
  function resourceIDResolver(rating_data: any) {
    return rating_data._id ?? null;
  }

  function _deleteEntityAction() {
    setOpenModal(true);
  }

  /**
   * Delete modal ...
   */
  const onModalAgree = useCallback(() => {
    dispatch(deleteEntity(selectedResources.join(",")));
    setOpenModal(false);
    // @ts-ignore
    handleSelectionChange("all", false); // <~~ This will trigger the recalculation
  }, [selectedResources]);

  useEffect(() => {
    dispatch(getEntities(mainQuery));
  }, [updateSuccess]);

  /**
   * must use callback function ...
   */
  const [commentDetail, setCommentDetail] = useState<any>(null);
  const getCommentDetail = useCallback((comment_data: any) => {
    setCommentDetail(comment_data);
    setShowEditModel(true);
  }, []);

  const { fields, dirty, submit, submitting } = useForm({
    fields: {
      rating_content: useField<string>({
        value: commentDetail?.rating_content ?? "",
        validates: [
          notEmpty("Content is required!"),
          lengthMoreThan(5, "Content must be more than 5 characters"),
          lengthLessThan(200, "No more than 200 characters."),
        ],
      }),
    },
    async onSubmit(values) {
      try {
        dispatch(
          updateEntity({
            // _id: commentDetail._id,
            // rating_content: values.rating_content,
          })
        );
        setShowEditModel(false);
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message = e?.response?.data?.title ?? "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  /**
   * read out ratingDetail
   */

  const detailModal = (
    <Modal
      activator={null}
      open={showEditModel}
      onClose={closeModal}
      title="Edit Rating"
      primaryAction={{
        content: "Save",
        disabled: !dirty,
        loading: submitting,
        onAction: submit,
      }}
      secondaryActions={[
        {
          content: "Close",
          onAction: closeModal,
        },
      ]}
    >
      <Modal.Section>
        <Form onSubmit={submit}>
          <Card.Section>
            <FormLayout>
              <TextField
                autoFocus
                disabled
                autoComplete="off"
                requiredIndicator
                label="Content"
                {...fields.rating_content}
              />
            </FormLayout>
          </Card.Section>
        </Form>
      </Modal.Section>
    </Modal>
  );

  /**
   *  Index table
   */
  function IndexTableComment() {
    const resourceName = {
      singular: "comment",
      plural: "comments",
    };

    const promotedBulkActions = [
      {
        content: "Delete rating",
        onAction: _deleteEntityAction,
      },
    ];

    const rowMarkup = entities?.map((props, index) => {
      const {
        _id,
        rating_media,
        description,
        number_value,
        number_accuracy,
        number_communication,
        number_location,
        number_check_in,
        number_for_value,
        event_id,
        createBy,
        createdAt,
        updatedAt,
      } = props;
      return (
        <>
          <IndexTable.Row id={_id} key={_id} selected={selectedResources.includes(_id)} position={index}>
            <IndexTable.Cell>
              <div
                key={_id}
                //  onClick={() => getCommentDetail(props)}
              >
                <p>{createBy}</p>
              </div>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <div
                key={_id}
                // onClick={() => getCommentDetail(props)}
              >
                <p>{event_id}</p>
              </div>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <div style={{ width: "15px" }} key={_id} onClick={() => getCommentDetail(props)}>
                {number_value === 1 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_value === 2 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_value === 3 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_value === 4 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                    </Stack>
                  </>
                )}
              </div>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <div style={{ width: "15px" }} key={_id} onClick={() => getCommentDetail(props)}>
                {number_accuracy === 1 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_accuracy === 2 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_accuracy === 3 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_accuracy === 4 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                    </Stack>
                  </>
                )}
              </div>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <div style={{ width: "15px" }} key={_id} onClick={() => getCommentDetail(props)}>
                {number_communication === 1 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_communication === 2 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_communication === 3 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_communication === 4 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                    </Stack>
                  </>
                )}
              </div>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <div style={{ width: "15px" }} key={_id} onClick={() => getCommentDetail(props)}>
                {number_location === 1 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_location === 2 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_location === 3 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_location === 4 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                    </Stack>
                  </>
                )}
              </div>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <div style={{ width: "15px" }} key={_id} onClick={() => getCommentDetail(props)}>
                {number_check_in === 1 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_check_in === 2 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_check_in === 3 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_check_in === 4 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                    </Stack>
                  </>
                )}
              </div>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <div style={{ width: "15px" }} key={_id} onClick={() => getCommentDetail(props)}>
                {number_for_value === 1 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_for_value === 2 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_for_value === 3 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : number_for_value === 4 ? (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="subdued" />
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack wrap={false} spacing="extraTight">
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                      <Icon source={StarFilledMinor} color="warning" />
                    </Stack>
                  </>
                )}
              </div>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <div key={_id} onClick={() => getCommentDetail(props)}>
                <p>{helpers.trimContentString(description)}</p>
              </div>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <time>{createdAt ? dateandtime.format(new Date(createdAt), "DD-MM-YYYY HH:mm:ss") : "-"}</time>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <time>{updatedAt ? dateandtime.format(new Date(updatedAt), "DD-MM-YYYY HH:mm:ss") : "-"}</time>
            </IndexTable.Cell>
          </IndexTable.Row>
        </>
      );
    });

    return (
      <Card>
        <IndexTable
          loading={loading}
          resourceName={resourceName}
          itemCount={entities.length}
          selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
          onSelectionChange={handleSelectionChange}
          // bulkActions={bulkActions}
          promotedBulkActions={promotedBulkActions}
          headings={[
            { title: "Create By" },
            { title: "Event" },
            { title: "Number Value" },
            { title: "Number Accuracy" },
            { title: "Number Communication" },
            { title: "Number Location" },
            { title: "Number Check In" },
            { title: "Number For Value" },
            { title: "Description" },
            { title: "Create At" },
            { title: "Update At" },
          ]}
        >
          {rowMarkup}
        </IndexTable>
      </Card>
    );
  }

  const emptyData = (
    <EmptyState heading="No rating here!" image={emptyIMG}>
      <p>Oh! There is no rating here! Try remove filter or add a new record!</p>
    </EmptyState>
  );

  const PagesList =
    totalItems > 0 ? (
      <>
        <IndexTableComment />
      </>
    ) : (
      emptyData
    );

  const Actual_page = (
    <Page title="Rating" fullWidth>
      {detailModal}
      <Layout>
        <Layout.Section>
          <Card>
            <div style={{ padding: "16px", display: "flex" }}>
              <Stack alignment="center">
                <Stack.Item fill>
                  <RatingFilter queryValue={StringQuery?.query} onChange={handleFiltersQueryChange} />
                </Stack.Item>
              </Stack>
            </div>
            {PagesList}
          </Card>
          <br />
          {totalItems > mainQuery.limit ? (
            <Pagination
              TotalRecord={totalItems}
              activeCurrentPage={mainQuery.page}
              pageSize={mainQuery.limit}
              onChangePage={onChangePageNumber}
            />
          ) : null}
        </Layout.Section>
      </Layout>
    </Page>
  );

  /**
   * Delete modal
   * selectedResources <------ Toan bo ID checked o day = array
   */
  const commentArray = [selectedResources];
  commentArray.join(",");

  const _Modal = (
    <Modal
      activator={null}
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      title="Delete rating?"
      primaryAction={{
        content: "Delete",
        onAction: onModalAgree,
        destructive: true,
        loading: updating,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>Are you sure want to delete {selectedResources.length} rating?</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );

  const toastMarkup = errorMessage ? <Toast content={errorMessage} error onDismiss={toggleActive} /> : null;

  return (
    <>
      {_Modal}
      {toastMarkup}
      {initial_loading ? <SkeletonLoading /> : Actual_page}
    </>
  );
}

