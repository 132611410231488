import { Card, Layout, Page, TextStyle } from "@shopify/polaris";
import { useCallback, useState, useEffect } from "react";
import { ExportMinor } from "@shopify/polaris-icons";
import { useLocation } from "react-router-dom";
import App_list from "./media.list";
import MediaUpload from "./media.upload";

import helpers from "../../helpers";
import { useAppDispatch, useAppSelector } from "config/store";
import { clearError, getEntity, getEntities, partialUpdateEntity, deleteEntity } from "store/media.store.reducer";

/**
 *   Create index file for Media
 */

export default () => {
  const entity = useAppSelector((state) => state.media.entity);
  const entities = useAppSelector((state) => state.media.entities);
  const loading = useAppSelector((state) => state.media.loading);
  const totalItems = useAppSelector((state) => state.media.totalItems);

  const dispatch = useAppDispatch();

  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  const initialQuery = {
    page: 1,
    limit: 100,
  };

  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...initialQuery,
  });

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch) window.history.replaceState(null, "Media", "/media" + buildURLSearch);
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  /**
   * OPEN UPLOAD MODAL
   */
  const [uploadModelactive, setUploadModelactive] = useState<boolean>(false);
  const toggleUpdateActive = useCallback(() => setUploadModelactive((active) => !active), []);

  /**
   * END UPLOAD FORM
   */

  return (
    <>
      <Page
        primaryAction={{
          content: `Upload`,
          onAction: toggleUpdateActive,
          icon: ExportMinor,
        }}
        title="Media"
      >
        {/* <Layout>
          <Layout.Section oneThird>
            <Card title="Image" actions={[{ content: "Manage" }]}>
              <Card.Section>
                <TextStyle variation="subdued">455 units available</TextStyle>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section oneThird>
            <Card title="Video" actions={[{ content: "Manage" }]}>
              <Card.Section>
                <TextStyle variation="subdued">301 units available</TextStyle>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section oneThird>
            <Card title="Audio" actions={[{ content: "Manage" }]}>
              <Card.Section>
                <TextStyle variation="subdued">{totalItems} units available</TextStyle>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout> */}
      </Page>

      <MediaUpload show={uploadModelactive} onClose={toggleUpdateActive} />

      {<App_list />}
    </>
  );
};

