import { Box, Stack, Typography, Button, Card, CardContent, Menu, MenuItem, IconButton, Tooltip, Modal, TextField, Select, LinearProgress   } from '@mui/material'
import Masonry from '@mui/lab/Masonry';
import React, { useState, useEffect } from 'react'
import { createRedeem, deleteRedeem, getListRedeemUser, getListUser } from './service';
import moment from "moment"
import { HiOutlineDotsVertical } from "react-icons/hi";
import coins from "../../media/coin.png"
import reward from "../../media/reward.png"

import './styles/mission.css'
import CreateRedeem from './components/misson.createRedeem';

import results from './media/actions/results.png'
import buy from './media/actions/buy.png'
import comments from './media/actions/comments.png'
import complete from './media/actions/complete.png'
import join from './media/actions/join.png'
import like from './media/actions/like.png'
import post from './media/actions/post.png'
import referral from './media/actions/referral.png'
import share from './media/actions/share.png'
import view from './media/actions/view.png'
import watch from './media/actions/watch.png'
import ListRedeemMission from './components/mission.listRedeemMission';
import EditRedeem from './components/mission.editRedeem';

import CustomSnackbar from 'components/snackbar';

import empty from '../../media/empty.png'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0,0,0,0.5)',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };


const Mission = () => {
    const [loading, setLoading] = useState(true);
    const [getDataRedeemListUser, setGetDataRedeemListUser] = useState([]);
    const [getDataUser, setGetDataUser] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openModalMission, setOpenModalMission] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [title, setTitle] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [point, setPoint] = useState(0);
    const [coin, setCoin] = useState(0);
    const [list, setList] = useState('all');
    const [selectedMission, setSelectedMission] = useState([]);
    const [selectedMissionId, setSelectedMissionId] = useState();
    const [selectedDataMission, setSelectedDataMission] = useState(null);
    const [dataRedeem, setDataRedeem] = useState(null);
    const [filteredRedeemList, setFilteredRedeemList] = useState([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRedeemId, setSelectedRedeemId] = useState<string | null>(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
    
    const open = Boolean(anchorEl);

    useEffect(() => {
      const userId = localStorage.getItem('AJAX_USERID');
      setCurrentUser(userId);
    }, []);

    const handleChange = (event) => {
        setList(event.target.value as string);
    };

    const handleOpenModal = () => setOpenModal(true);
  
    const handleCloseModal = () => setOpenModal(false);

    const handleOpenModalEdit = (redeem) => {
      setDataRedeem(redeem);

      setOpenModalEdit(true);
    };

    const updateRedeemList = (updatedRedeem) => {
      setFilteredRedeemList((prevList) => 
        prevList.map((redeem) => 
          redeem._id === updatedRedeem._id ? updatedRedeem : redeem
        )
      );
    };
  
    const handleCloseModalEdit = () => setOpenModalEdit(false);

    const handleOpenModalMission = (_id, missions, redeem) => {
      setSelectedMission(missions);
      setOpenModalMission(true);
      setSelectedMissionId(_id);
      setSelectedDataMission(redeem)
    };
  
    const handleCloseModalMission = () => setOpenModalMission(false);


  
    useEffect(() => {
      const fetchData = async () => {
          setLoading(true);
          try {
              const dataRedeem = await getListRedeemUser();
              const redeemList = dataRedeem.data;
              redeemList.reverse();
              setGetDataRedeemListUser(redeemList);
  
              const getUsersDataPromises = redeemList.map(item => getUserData(item.user_id));
              const usersData = await Promise.all(getUsersDataPromises);
  
              setGetDataUser(usersData);
          } catch (error) {
              setLoading(false);
              console.error('Failed to fetch data', error);
          }
      };
  
      fetchData();
  }, []);
  
  const getUserData = async (userId) => {
      try {
          const response = await getListUser(userId);
          return response.data;
      } catch (error) {
          return null;
      }
  };
  

      
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      await createRedeem(title, startTime, endTime, point, coin);
      setTitle('');
      setStartTime('');
      setEndTime('');
      setPoint(0);
      setCoin(0);
  
      setSnackbarMessage('Tạo thành công');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
  
      setTimeout(async () => {
        try {
          const updatedDataRedeem = await getListRedeemUser();
          const updatedRedeemList = updatedDataRedeem.data.reverse();
          setGetDataRedeemListUser(updatedRedeemList);
  
          const userDetailsPromises = updatedRedeemList.map(item => getListUser(item.user_id));
          const userDetailsResponses = await Promise.all(userDetailsPromises);
          const usersData = userDetailsResponses.map(response => response.data);
          setGetDataUser(usersData);
        } catch (error) {
          setSnackbarMessage('Failed to fetch data.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        } finally {
          setLoading(false);
        }
      }, 1000);
    } catch (error) {
      setSnackbarMessage('Failed to create redeem.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setLoading(false);
    }
  };
  
  

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const getTimeFromString = (dateTime: string) => {
      return moment(dateTime).format("MMMM DD");
  };

  const getTimeFromStringMission = (dateTime: string) => {
      return moment(dateTime).format("MMMM DD, YYYY");
  };



  const handleClick = (event: React.MouseEvent<HTMLElement>, redeemId: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedRedeemId(redeemId);
  };

    const handleClose = () => {
      setAnchorEl(null);
      setSelectedRedeemId(null);
  };
  
  const handleDeleteRedeem = async (id: string) => {
    try {
      await deleteRedeem(id);
      setGetDataRedeemListUser(prevRedeems => prevRedeems.filter(redeem => redeem._id !== id));
      setSnackbarMessage('Xóa thành công');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to delete redeem', error);
      setSnackbarMessage('Xóa thất bại');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const getActionType = (type: string): string => {
      switch (type) {
        case 'results':
          return results;
        case 'buy':
          return buy;
        case 'comment':
          return comments;
        case 'complete':
          return complete;
        case 'join':
          return join;
        case 'like':
          return like;
        case 'post':
          return post;
        case 'referral':
          return referral;
        case 'share':
          return share;
        case 'view':
          return view;
        case 'watch':
          return watch;
        default:
          return "Khác";
      }
  }

  const getUserIdFromSession = () => {
    return localStorage.getItem('AJAX_USERID');
  };

  const filterData = (data) => {
    const userId = getUserIdFromSession();
    if (list === 'myself') {
        return data.filter(redeem => redeem.user_id === userId);
    } else {
        return data;
    }
  };

  useEffect(() => {
    setFilteredRedeemList(filterData(getDataRedeemListUser));
}, [list, getDataRedeemListUser]);

  return (
    <Box sx={{ width: "100%", maxWidth: "1000px", margin: "0 auto", padding: "30px 0px" }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant='h5'>Thưởng</Typography>
            <Button onClick={handleOpenModal} variant='contained' color="success" sx={{ fontWeight: 550, height: '45px' }}>Tạo nhiệm vụ thưởng</Button>
        </Stack>

        {/**Modal Create Redeem */}
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <CreateRedeem handleCloseModal={handleCloseModal} handleSubmit={handleSubmit} point={point} coin={coin} setPoint={setPoint} setCoin={setCoin} title={title} setTitle={setTitle} style={style} />
        </Modal>

        {/**Modal Edit Redeem */}
        <Modal
            open={openModalEdit}
            onClose={handleCloseModalEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <EditRedeem setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} setSnackbarSeverity={setSnackbarSeverity} dataRedeem={dataRedeem} handleCloseModalEdit={handleCloseModalEdit} updateRedeemList={updateRedeemList} style={style} />
        </Modal>



        <Box sx={{ backgroundColor: '#E9EAF2', width: "100%", mt: 3, padding: 3, borderRadius: '3px', boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
            {/* <Box>
                <Box className="header_mission" sx={{ backgroundColor: "#333" }}>
                    <div></div>
                    <Typography variant='body1'>Mission name</Typography>
                    <Typography variant='body1'>Due in</Typography>

                </Box>
            </Box> */}

            {/**Filter */}
            <Stack direction="row" gap={2} alignItems="center" justifyContent="end">
                <Typography variant='body1'>Hiển thị</Typography>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={list}
                    onChange={handleChange}
                    sx={{ height: '30px', width: '100px' }}
                >
                    <MenuItem value='all'>Tất cả</MenuItem>
                    <MenuItem value='myself'>Tôi</MenuItem>
                </Select>
            </Stack>

            {/**List Redeem */}
            <Masonry columns={3} spacing={2} sx={{ margin: '0 auto !important', }}>
            {filteredRedeemList.length === 0 ? (
              <Stack justifyContent='center' sx={{ width: '100% !important', textAlign: 'center', opacity: '0.7' }}>
                <img src={empty} alt="No data" width={350} style={{ margin: '0 auto', marginBottom: '20px' }}/>
                <Typography variant="body1" fontWeight={600} fontSize={25}>Không có dữ liệu</Typography>
              </Stack>
              ) : (
                  filteredRedeemList.map((redeem, index) => (
                      <>
                      <Card key={redeem._id} onClick={() => handleOpenModalMission(redeem._id, redeem.missions, redeem)} sx={{ maxWidth: 400, backgroundColor: '#fff', borderRadius: '12px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.7)' }, cursor: 'pointer', zIndex: '1' }}>
                          <CardContent sx={{ color: '#333' }}>
                              <Stack direction="row" justifyContent="space-between" alignItems="center">
                                  <Typography variant="body1" color="text.secondary" sx={{ color: '#333' }}>
                                    Số nhiệm vụ của phần thưởng này là: {redeem.missions && redeem.missions.length}
                                  </Typography>
                                  {currentUser && currentUser === redeem.user_id ? (
                                  <div style={{ zIndex: '2' }} onClick={(e) => {e.stopPropagation()}}>
                                      <IconButton
                                          aria-label="more"
                                          aria-controls={open ? 'basic-menu' : undefined}
                                          aria-haspopup="true"
                                          aria-expanded={open ? 'true' : undefined}
                                          onClick={(e) => handleClick(e, redeem._id)}
                                      >
                                          <HiOutlineDotsVertical style={{ color: '#333' }} />
                                      </IconButton>
                                      <Menu
                                          id="basic-menu"
                                          anchorEl={anchorEl}
                                          open={open && selectedRedeemId === redeem._id}
                                          onClose={handleClose}
                                          MenuListProps={{
                                          'aria-labelledby': 'basic-button',
                                          }}
                                      >
                                          <MenuItem onClick={(e) => handleOpenModalEdit(redeem)}>Chỉnh sửa</MenuItem>
                                          <MenuItem onClick={(e) => handleDeleteRedeem(redeem._id)}>Xóa</MenuItem>
                                      </Menu>
                                  </div>
                                  ) : null}
                              </Stack>
                              <Stack direction="row" justifyContent="space-between" alignItems="center" mt={1} mb={2}>
                                  {getDataUser[index] && (
                                      <Stack direction="column" gap={2}>
                                          <Typography variant="body1" color="text.secondary" sx={{ color: '#333' }} fontWeight={550}>
                                          {redeem.title}
                                          </Typography>
                                          <Stack direction="row" gap={1} alignItems="center">
                                              <img src={getDataUser[index].user_avatar} alt={getDataUser[index].display_name} width={25} style={{ borderRadius: '50%' }}/>
                                              <Typography variant="body2" fontWeight={500}>
                                                  {getDataUser[index].display_name}
                                              </Typography>
                                          </Stack>
                                      </Stack>
                                  )}
                                  <Typography variant="body2" color="text.secondary" sx={{ color: '#fff', fontWeight: 500, width: '40px', textAlign: 'center', fontSize: '15px', backgroundColor: '#2e3192', padding: '5px', borderRadius: '3px' }} fontSize={14}>
                                      {getTimeFromString(redeem.createdAt)}
                                  </Typography>
                              </Stack>
                              <Typography variant="body2" color="text.secondary" sx={{ color: '#333' }} fontSize={14}>
                                  Thưởng thêm: <img src={reward} alt="reward" /> {redeem.point} ,  <img src={coins} alt="coin" /> {redeem.coin}
                              </Typography>
                          </CardContent>
                      </Card>
                  </>
                  ))
              )}
            </Masonry>
            <Modal
                open={openModalMission}
                onClose={handleCloseModalMission}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ListRedeemMission selectedDataMission={selectedDataMission} selectedMission={selectedMission} getActionType={getActionType} getTimeFromStringMission={getTimeFromStringMission} setGetDataRedeemListUser={setGetDataRedeemListUser}  selectedMissionId={selectedMissionId} />
            </Modal>
        </Box>
        <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  )
}

export default Mission