import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";

/**
 * Setting emails
 * For internal use, eg: System sent notification ...
 */
const EmailMarketing = () => {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || "aaaaa"; // sub_slug

  const account = useAppSelector((state) => state.user.account);
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  const dispatch = useAppDispatch();
  const isAdmin = account.user_role === "admin";

  return (
    <>
      <iframe
        style={{
          width: "100%",
          border: 0,
          minHeight: "calc(100vh - 80px)",
          overflow: "hidden",
        }}
        src="https://email-marketing.iceo.tech/api/view#"
        title="W3Schools Free Online Web Tutorials"
      ></iframe>
    </>
  );
};

export default EmailMarketing;
