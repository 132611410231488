import axios from "axios";
import { createAsyncThunk, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import helpers from "helpers";
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from "../config/reducer.utils";
import { IMedia, defaultIMedia } from "../interface/media.model";
import { AppThunk } from "../config/store";

/**
 *   Reducer used for front-end, with media.model.ts
 *   Interface.ts can be use in both front-end and back-end! But prefer using media.model.ts
 */

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};
const apiUrl = "chat-media";

// Actions

export const getEntities = createAsyncThunk("media/fetch_entity_list", async (object: any) => {
  // const EndURL = helpers.buildEndUrl(object);
  let __URL = helpers.buildEndUrl(object);

  const requestUrl = `${apiUrl}/list/admin${__URL}`;
  return axios.get<any>(requestUrl);
});

/**
 * Upload
 * loaded = 1 mean 100%
 * abord, do not use
 */

export const uploadFile = createAsyncThunk(
  "media/upload_entity",
  async (urldata: any) => {
    const requestUrl = `/upload`;
    return axios.post<any>(requestUrl, urldata, {
      onUploadProgress: (progressEvent: any) => {
        //updateProgress = Math.floor(progressEvent.loaded / progressEvent.total) * 100;
      },
    });
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  "media/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/detail-media/${id}?cacheBuster=${new Date().getTime()}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  "media/create_entity",
  async (entity: IMedia, thunkAPI) => {
    const result = await axios.post<IMedia>(
      `${apiUrl}?cacheBuster=${new Date().getTime()}`,
      helpers.cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "media/update_entity",
  async (entity: IMedia, thunkAPI) => {
    const result = await axios.put<IMedia>(
      `${apiUrl}/${entity._id}?cacheBuster=${new Date().getTime()}`,
      helpers.cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  "media/partial_update_entity",
  async (entity: IMedia, thunkAPI) => {
    const result = await axios.patch<IMedia>(
      `${apiUrl}/${entity._id}?cacheBuster=${new Date().getTime()}`,
      helpers.cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntity(entity._id));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  "media/delete_entity",
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = axios.delete<any>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_Media = createEntitySlice({
  name: "media",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(deleteEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = "Can not delete this Entity";
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          entity: null,
          totalItems: parseInt(action.payload.headers["x-total-count"], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(deleteEntity), (state) => {
        state.updating = true;
        state.loading = true;
        state.updateSuccess = false;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { clearError, reset } = Reducer_Media.actions;

// Reducer
export default Reducer_Media.reducer;

