import {
  Card,
  DisplayText,
  EmptyState,
  Form,
  FormLayout,
  Layout,
  Modal,
  Page,
  PageActions,
  RadioButton,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";

import emptyIMG from "../../media/empty.png";
import {
  clearError,
  getEntity,
  updateEntity,
  deleteEntity,
  createEntity,
  reset,
} from "../../store/report.store.reducer";
import { lengthLessThan, lengthMoreThan, useField, useForm, notEmpty } from "@shopify/react-form";
import helpers from "helpers";
import countriesData from "components/countryData";
import QuickUploadImage from "components/oneclick-upload";
import uploadimage from "../../media/choose-file.png";

export default function Edit_report() {
  const entity = useAppSelector((state) => state.report.entity);
  const updating = useAppSelector((state) => state.report.updating);
  const entities = useAppSelector((state) => state.report.entities);
  const loading = useAppSelector((state) => state.report.loading);
  const errorMessage = useAppSelector((state) => state.report.errorMessage);
  const updateSuccess = useAppSelector((state) => state.report.updateSuccess);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  // jamviet.com
  const [message, setMessage] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const [notification, setNotification] = useState(null);

  const toggleActive = useCallback(() => {
    setMessage(null);
    dispatch(clearError());
  }, []);

  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.report_slug || false;

  useEffect(() => {
    if (Param) dispatch(getEntity(Param));
    else dispatch(reset());
  }, []);

  /**
   * Option type
   */

  const [type, setType] = useState("recurring");

  const method = [
    { label: "recurring", value: "recurring" },
    { label: "one_time", value: "one_time" },
  ];

  const handleSelectChange = useCallback((value) => setType(value), []);

  /**
   * Coutry
   */

  const [country_selected, setCountry_selected] = useState("");
  const option_country = countriesData?.countriesData;

  const changeHandler = (value) => {
    setCountry_selected(value);
  };

  /**
   * Button value
   */

  const [reportActive, setReportActive] = useState(1);

  const [optionButtonValue, setOptionButtonValue] = useState(true);

  const handleRadioButtonChange = useCallback((_checked, newValue) => {
    setOptionButtonValue(newValue === "enabled");
  }, []);

  /**
   * Upload image
   */
  const [srcImage, setSrcImage] = useState(uploadimage);

  const [internalErrorNotice, setInternalErrorNotice] = useState("");

  useEffect(() => {
    if (entity && entity?.image) {
      if (entity?.handle_id !== null) {
      }
      if (Param) {
        // srcImage(entity?.image);
        setType(entity?.type);
      } else {
        // srcImage(uploadimage);
        setType("recurring");
      }
      setReportActive(entity?.status);
      setCountry_selected(entity?.country);
      if (entity?.status === 0) {
        setOptionButtonValue(false);
      } else {
        setOptionButtonValue(true);
      }
    }
  }, [entity]);

  function handQuickUpdateSuccess(res: any) {
    let media_root = process.env.REACT_APP_AJAX_UPLOAD_IMAGE;
    setSrcImage(media_root + "" + res.media_file_name);
  }

  function handUploadError(err: any) {
    setInternalErrorNotice("Upload Fail! Image is too large!");
  }

  useEffect(() => {
    if (optionButtonValue === false) {
      setReportActive(0);
    } else {
      setReportActive(1);
    }
  }, [optionButtonValue]);

  useEffect(() => {
    if (updateSuccess) {
      history("/report/edit/" + entity?.report_id, {
        replace: true,
      });

      setNotification("This report has been updated!");
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (Param) {
      setType(entity?.type);
    }
  }, [history]);

  useEffect(() => {
    if (errorMessage) {
      setNotification(errorMessage);
      dispatch(clearError());
    }
  }, [errorMessage]);

  function _deleteEntityAction() {
    setOpenModal(true);
  }

  function onModalAgree() {
    dispatch(deleteEntity(Param));
    setOpenModal(false);
  }

  const useFields = {
    report_type: useField<string>({
      value: entity?.report_type ?? "",
      validates: [],
    }),

    report_email: useField<string>({
      value: entity?.report_email ?? "",
      validates: [],
    }),

    report_name: useField<string>({
      value: entity?.report_name ?? "",
      validates: [],
    }),

    report_content: useField<string>({
      value: entity?.report_content ?? "",
      validates: [],
    }),
  };

  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset,
    submitErrors,
    makeClean,
  } = useForm({
    fields: useFields,
  });

  const emptyData = (
    <Page title="Report" breadcrumbs={[{ content: "Report list", url: "/report" }]}>
      <EmptyState heading="No post here!" image={emptyIMG}>
        <p>This record maybe not exist!</p>
      </EmptyState>
    </Page>
  );

  const toastMarkup = notification ? <Toast content={notification} onDismiss={toggleActive} /> : null;

  const toastNotification = message ? <Toast content={message} onDismiss={toggleActive} /> : null;

  const Actual_page =
    entity || !Param ? (
      <>
        <Page
          title="Report"
          breadcrumbs={[{ content: "Report list", url: "/report" }]}
          primaryAction={{
            content: "Save",
            disabled: !dirty,
            loading: updating,
            onAction: submit,
          }}
        >
          <Layout>
            <Layout.Section>
              <Form onSubmit={submit}>
                <Card>
                  <Card.Section>
                    <FormLayout>
                      <TextField
                        autoComplete="off"
                        maxLength={1000}
                        requiredIndicator
                        label="Form Name"
                        {...fields.report_type}
                        disabled
                      />

                      <TextField
                        autoComplete="off"
                        maxLength={1000}
                        requiredIndicator
                        label="Full Name"
                        {...fields.report_name}
                        disabled
                      />

                      <TextField
                        autoComplete="off"
                        maxLength={1000}
                        requiredIndicator
                        label="Email"
                        {...fields.report_email}
                        disabled
                      />

                      <TextField
                        autoComplete="off"
                        maxLength={1000}
                        requiredIndicator
                        label="Content"
                        {...fields.report_content}
                        multiline={true}
                        disabled
                      />
                    </FormLayout>
                  </Card.Section>
                </Card>
              </Form>
            </Layout.Section>
          </Layout>
          <PageActions
            primaryAction={{
              content: "Save",
              onAction: submit,
              disabled: !dirty,
              loading: updating,
            }}
            secondaryActions={
              Param
                ? [
                    {
                      content: "Delete",
                      destructive: true,
                      onAction: _deleteEntityAction,
                    },
                  ]
                : []
            }
          />
        </Page>
      </>
    ) : (
      emptyData
    );

  const skeleton_loading = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const _Modal = openModal ? (
    <Modal
      activator={null}
      open={true}
      onClose={() => setOpenModal(false)}
      title="Delete entity?"
      primaryAction={{
        content: "Delete",
        onAction: onModalAgree,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>After delete, you can not undo this action!</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  ) : null;

  return (
    <>
      {_Modal}
      {toastMarkup}
      {toastNotification}
      {loading ? skeleton_loading : Actual_page}
    </>
  );
}

