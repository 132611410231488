import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AppliedFilterInterface,
  FilterInterface,
  Filters,
  Select,
} from "@shopify/polaris";
import debounce from "lodash.debounce";

/**
 *   Create template for Media
 */

export interface IMediaFiltersProps {
  queryValue?: string;
  onChange(query: string): void;
}

export const QuickSearch = (props: IMediaFiltersProps) => {
  const { onChange } = props;

  const [queryValue, setQueryValue] = useState<string | null>(
    props?.queryValue ?? ""
  );
  const setQueryValueCallback = useCallback(
    (_value) => setQueryValue(_value),
    []
  );

  const onChangeCallback = useMemo(
    () => debounce((_value) => onChange?.call(this, _value), 300),
    []
  );
  useEffect(() => {
    onChangeCallback(queryValue);
  }, [onChangeCallback, queryValue]);

  const filters = [] as FilterInterface[];
  const appliedFilters = [] as AppliedFilterInterface[];

  return (
    <Filters
      queryPlaceholder="Search by title"
      queryValue={queryValue}
      onQueryChange={setQueryValueCallback}
      onQueryClear={() => setQueryValueCallback("")}
      filters={filters}
      appliedFilters={appliedFilters}
      onClearAll={() => setQueryValueCallback(null)}
    ></Filters>
  );
};

export default QuickSearch;
