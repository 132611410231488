import {
  AppliedFilterInterface,
  Avatar,
  Card,
  ChoiceList,
  DataTable,
  EmptyState,
  FilterInterface,
  Filters,
  Layout,
  Link,
  Modal,
  Page,
  ResourceItem,
  ResourceList,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import debounce from "lodash.debounce";
import { TickSmallMinor, LockMinor } from "@shopify/polaris-icons";
import emptyIMG from "../../media/empty.png";
import Pagination from "../../components/pagination";
import { clearError, getEntities } from "../../store/banner.store.reducer";
import BannerFilter from "./filter";
import helpers from "helpers";
import dateandtime from "date-and-time";

export default function General_banner() {
  const [initial_loading, setInitial_loading] = useState<boolean>(true);

  const entity = useAppSelector((state) => state.banner.entity);
  const entities = useAppSelector((state) => state.banner.entities);
  const loading = useAppSelector((state) => state.banner.loading);
  const errorMessage = useAppSelector((state) => state.banner.errorMessage);
  const totalItems = useAppSelector((state) => state.banner.totalItems);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery = helpers.ExtractUrl(useParam.search) || false;

  const [mainQuery, setMainQuery] = useState({
    ...{
      query: "",
      page: 1,
      limit: 20,
      sort: "banner_id,desc",
    },
    ...StringQuery,
  });
  const [queryValue, setQueryValue] = useState("");

  const [input, setInput] = useState("");
  const handleFiltersQueryChange = useCallback((value) => setInput(value), []);

  useEffect(() => {
    setMainQuery({ ...mainQuery, ...{ query: input } });
  }, [input]);

  const [selectedParentId, setSelectedParentId] = useState("");
  const handleSelectedChange = useCallback((_value) => {
    setSelectedParentId(_value);
  }, []);

  useEffect(() => {
    setMainQuery({
      ...mainQuery,
      ...{ banner_active: `${selectedParentId}`, page: 1 },
    });
  }, [selectedParentId]);

  /**
   * Change page number
   */

  const [numberPage, setNumberPage] = useState(1);

  const onChangePageNumber = useCallback((numPage) => {
    setNumberPage(numPage);
  }, []);

  useEffect(() => {
    setMainQuery({ ...mainQuery, page: numberPage });
  }, [numberPage]);

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch) history("/banner" + buildURLSearch);
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  const onChangeCallback = useMemo(
    () =>
      debounce((_value) => {
        if (_value !== "") setMainQuery({ ...mainQuery, query: _value ? _value : "" });
      }, 500),
    []
  );

  useEffect(() => {
    onChangeCallback(queryValue);
  }, [queryValue]);

  useEffect(() => {
    if (loading === false) setInitial_loading(false);
  }, [loading]);

  /**
   *
   * @param banner_id
   */
  const shortcutActions = (banner_id: number) => {
    history("edit/" + banner_id);
  };

  const emptyData = (
    <EmptyState heading="No post here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>
  );

  const renderItem = (banner: any) => {
    const { banner_id, banner_content, banner_title, banner_active, banner_media, createAt, updateAt, createBy } =
      banner;
    return [
      <div className="clickable" key={banner_id} onClick={() => shortcutActions(banner_id)}>
        {banner_id}
      </div>,

      <div className="clickable" key={banner_id} onClick={() => shortcutActions(banner_id)}>
        {banner_content}
      </div>,
      <div className="clickable" key={banner_id} onClick={() => shortcutActions(banner_id)}>
        {banner_title}
      </div>,
      <div className="small-icon" key={banner_id} onClick={() => shortcutActions(banner_id)}>
        {banner_active === 1 ? <TickSmallMinor /> : <LockMinor />}
      </div>,
      <div className="clickable" key={banner_id} onClick={() => shortcutActions(banner_id)}>
        <time>{createAt ? dateandtime.format(new Date(Number(createAt)), "DD-MM-YYYY HH:mm:ss") : "-"}</time>
      </div>,
      <div className="clickable" key={banner_id} onClick={() => shortcutActions(banner_id)}>
        <time>{updateAt ? dateandtime.format(new Date(Number(updateAt)), "DD-MM-YYYY HH:mm:ss") : "-"}</time>
      </div>,
    ];
  };
  const PagesList =
    totalItems > 0 ? (
      <>
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text", "text"]}
          headings={["ID", "Banner Title", "Banner Content", "Banner Active", "createAt", "updateAt"]}
          rows={entities?.map(renderItem)}
          hideScrollIndicator
          footerContent={`Display page ${mainQuery.page} of total ${totalItems} results...`}
        />
        <style>{`
        .small-icon {
          font-size: 12px;
          padding: 0;
          width: 15px;
          height: auto;
        }
      `}</style>
      </>
    ) : (
      emptyData
    );

  const Actual_page = (
    <Page
      title="Banner"
      primaryAction={{
        content: "Create new",
        disabled: false,
        onAction: () => {
          history("new");
        },
      }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <div style={{ padding: "16px", display: "flex" }}>
              <Stack distribution="equalSpacing">
                <BannerFilter queryValue={StringQuery?.query} onChange={handleFiltersQueryChange} />
                <Select
                  label=""
                  value={selectedParentId}
                  onChange={handleSelectedChange}
                  options={[
                    { label: "All", value: "" },
                    { label: "Active", value: "active" },
                    { label: "Inactive", value: "inactive" },
                  ]}
                />
              </Stack>
            </div>
            {PagesList}
          </Card>
          <br />
          {totalItems > mainQuery.limit ? (
            <Pagination
              TotalRecord={totalItems}
              activeCurrentPage={mainQuery.page}
              pageSize={mainQuery.limit}
              onChangePage={onChangePageNumber}
            />
          ) : null}
        </Layout.Section>
      </Layout>
    </Page>
  );

  const skeleton_loading = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const toastMarkup = errorMessage ? <Toast content={errorMessage} error onDismiss={toggleActive} /> : null;

  return (
    <>
      {toastMarkup}
      {/* {loading ? skeleton_loading : Actual_page} */}
      {initial_loading ? skeleton_loading : Actual_page}
    </>
  );
}

