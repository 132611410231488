import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "config/store";
import {
  Card,
  Page,
  PageActions,
  RadioButton,
  Stack,
  TextField,
  Tabs,
  FormLayout,
  ChoiceList,
  Toast,
  Select,
  ButtonGroup,
  Button,
  Badge,
} from "@shopify/polaris";
import { getEntity, updateEntity, createChat, clearError } from "store/settings.store.reducer";
import { asChoiceField, lengthLessThan, notEmpty, useField, useForm } from "@shopify/react-form";
import { useNavigate, useLocation } from "react-router-dom";
import helpers from "helpers";
import { getEntitiesversion as getPlans } from "../../store/plan.store.reducer";
import { searchDates } from "../../store/order.store.reducer";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/**
 * Setting emails
 * For internal use, eg: System sent notification ...
 */
export default function SettingEmails() {
  const entity = useAppSelector((state) => state.settings.entity);
  const updating = useAppSelector((state) => state.settings.updating);
  const updateSuccess = useAppSelector((state) => state.settings.updateSuccess);
  const errorMessage = useAppSelector((state) => state.settings.errorMessage);

  const entityplans = useAppSelector((state) => state.plan.entities);
  const entities = useAppSelector((state) => state.order.entities);
  const totalItems = useAppSelector((state) => state.order.totalItems);

  const dispatch = useAppDispatch();

  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  useEffect(() => {
    dispatch(getPlans({}));
  }, []);

  /**
   * Select version plan
   */

  const [selectedVersion, setSelectedVersion] = useState("");
  const handleSelectedVersionChange = useCallback((_value) => {
    setSelectedVersion(_value);
  }, []);

  const [mainQueryPlan, setMainQueryPlan] = useState({
    ...{
      version: "1.0.0",
    },
    ...StringQuery,
  });

  useEffect(() => {
    setMainQueryPlan({ ...mainQueryPlan, version: selectedVersion });
  }, [selectedVersion]);

  useEffect(() => {
    dispatch(getPlans(mainQueryPlan));
  }, [mainQueryPlan]);

  /**
   * Plan list
   */

  const [planList, setPlanList] = useState([]);

  useEffect(() => {
    const optionType = [
      {
        label: "Plan",
        value: "",
      },
    ];
    for (let { _id, name } of entityplans) {
      optionType.push({
        label: String(name),
        value: String(_id),
      });
    }
    setPlanList(optionType);
  }, [entityplans]);

  /**
   * Select plan
   */

  const [post_plan_selected, setPost_plan_selected] = useState("");
  const handleNewChange = useCallback((value) => {
    setPost_plan_selected(value);
  }, []);

  /**
   * Status
   */

  const [status, setStatus] = useState("");
  const handleStatus = useCallback((_value) => {
    setStatus(_value);
  }, []);

  /**
   * Payment
   */

  const [payment, setPayment] = useState("");
  const handlePayment = useCallback((_value) => {
    setPayment(_value);
  }, []);

  // const [userId, setUserId] = useState("");
  const handleUserIdChange = useCallback((value) => {
    setUserId(value);
  }, []);

  /**
   * Content
   */

  const [content, setContent] = useState("");
  const handleContentChange = useCallback((value) => {
    setContent(value);
  }, []);

  /**
   * Date Filter
   */

  const [startDate, setStartDate] = useState(new Date("01/01/2022"));
  const [endDate, setEndDate] = useState(new Date());

  const [mainQueryDate, setMainQueryDate] = useState({
    ...{
      page: 1,
      limit: 100,
      type: "order",
      select: "_id",
      order_by: "DESC",
      from: new Date("01/01/2022").toISOString(),
      to: new Date().toISOString(),
    },
  });

  useEffect(() => {
    dispatch(searchDates(mainQueryDate));
  }, [mainQueryDate]);

  const filterDate = () => {
    setMainQueryDate({
      ...mainQueryDate,
      ...{
        from: new Date(startDate).toISOString(),
        to: new Date(endDate).toISOString(),
        status: status,
        payment_method: payment,
        plan_id: post_plan_selected,
      },
    });
  };

  const clearDate = useCallback(() => {
    setStatus("");
    setPayment("");
    setSelectedVersion("");

    setStartDate(new Date("01/01/2022"));
    setEndDate(new Date());
    setMainQueryDate({
      ...mainQueryDate,
      ...{
        from: new Date("01/01/2022").toISOString(),
      },
    });
  }, []);

  const [userId, setUserId] = useState("");

  useEffect(() => {
    let temp = "";
    entities?.map((e, i) => {
      if (i > 0) {
        temp += "," + e?.user_id?._id;
      } else {
        temp += e?.user_id?._id;
      }
    });
    setUserId(temp);
  }, [entities]);

  const handleSubmit = () => {
    dispatch(
      createChat({
        partner_id: userId,
        chat_content: content,
      })
    );
    setUserId("");
    setContent("");
  };

  const [notification, setNotification] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (errorMessage) {
      setNotification(errorMessage);
    }
  }, [errorMessage]);

  const toggleActive = useCallback(() => {
    setMessage(null);
    dispatch(clearError());
  }, []);

  const toastMarkup = notification ? <Toast content={notification} onDismiss={toggleActive} /> : null;

  const toastNotification = message ? <Toast content={message} onDismiss={toggleActive} /> : null;

  useEffect(() => {
    if (updateSuccess) {
      setNotification("This message has been sent successfully!");
    }
  }, [updateSuccess]);

  return (
    <>
      <Page narrowWidth title="Chat System">
        <FormLayout>
          <Card>
            <Card.Section>
              <Stack distribution="equalSpacing">
                <Select
                  label=""
                  value={status}
                  onChange={handleStatus}
                  options={[
                    { label: "Status", value: "" },
                    { label: "Draft", value: "draft" },
                    { label: "Pending", value: "pending" },
                    { label: "Fraud", value: "fraud" },
                    { label: "Close", value: "close" },
                    { label: "Error", value: "error" },
                    { label: "Processing", value: "processing" },
                    { label: "Trial False", value: "trial_false" },
                    { label: "Trial", value: "trial" },
                    { label: "Success", value: "success" },
                    { label: "Done", value: "done" },
                  ]}
                />

                <Select
                  label=""
                  value={payment}
                  onChange={handlePayment}
                  options={[
                    { label: "Payment", value: "" },
                    { label: "Google", value: "google_payment" },
                    { label: "Apple", value: "apple_payment" },
                  ]}
                />

                <Select
                  label=""
                  value={selectedVersion}
                  onChange={handleSelectedVersionChange}
                  options={[
                    { label: "Version", value: "" },
                    { label: "1.0.0", value: "1.0.0" },
                    { label: "1.0.1", value: "1.0.1" },
                    { label: "1.0.2", value: "1.0.2" },
                    { label: "android-1.0.3", value: "android-1.0.3" },
                    { label: "ios-1.0.3", value: "ios-1.0.3" },
                    { label: "android-1.0.4", value: "android-1.0.4" },
                    { label: "ios-1.0.4", value: "ios-1.0.4" },
                  ]}
                />

                <Select label="" options={planList} onChange={handleNewChange} value={post_plan_selected?.toString()} />
              </Stack>
              <br />

              <Stack distribution="equalSpacing">
                <DatePicker
                  wrapperClassName="datePicker"
                  dateFormat="dd/MM/yyyy, h:mm aa"
                  selected={startDate}
                  showTimeSelect
                  onChange={(date: Date) => setStartDate(date)}
                />

                <DatePicker
                  dateFormat="dd/MM/yyyy, h:mm aa"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  showTimeSelect
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />

                <ButtonGroup>
                  <Button primary onClick={filterDate}>
                    Search
                  </Button>
                  <Button onClick={clearDate}>Clear</Button>
                </ButtonGroup>
              </Stack>

              <br />

              <Stack.Item>
                <p>Total</p>
                <Badge status="success">{totalItems.toString()}</Badge>
              </Stack.Item>
              <br />

              <TextField
                label="UserId"
                value={userId}
                onChange={handleUserIdChange}
                multiline={true}
                autoComplete="off"
              />

              <TextField
                label="Content"
                requiredIndicator
                value={content}
                onChange={handleContentChange}
                multiline={2}
                autoComplete="off"
              />
            </Card.Section>
          </Card>
        </FormLayout>

        <PageActions
          primaryAction={{
            content: "Send",
            onAction: handleSubmit,
            disabled: updating,
            loading: updating,
          }}
        />
      </Page>
      {toastMarkup}
      {toastNotification}
    </>
  );
}

