import {
  Button,
  Card,
  EmptyState,
  Form,
  FormLayout,
  Layout,
  Modal,
  Page,
  PageActions,
  RadioButton,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import { MobilePlusMajor } from "@shopify/polaris-icons";

import emptyIMG from "../../media/empty.png";
import {
  clearError,
  getEntity,
  updateEntity,
  deleteEntity,
  createEntity,
  reset,
  updateEntityNew,
  createEntityNew,
} from "../../store/prompt.store.reducer";
import {
  lengthLessThan,
  lengthMoreThan,
  useField,
  useForm,
  notEmpty,
} from "@shopify/react-form";
import helpers from "helpers";
import QuickUploadImage from "components/oneclick-upload";
import uploadimage from "../../media/choose-file.png";
import {
  getEntities as getCategories,
  getEntity as getCategory,
} from "../../store/category.store.reducer";

export default function Edit_prompt() {
  const entity = useAppSelector((state) => state.prompt.entity);
  const updating = useAppSelector((state) => state.prompt.updating);
  const entities = useAppSelector((state) => state.prompt.entities);
  const loading = useAppSelector((state) => state.prompt.loading);
  const errorMessage = useAppSelector((state) => state.prompt.errorMessage);
  const updateSuccess = useAppSelector((state) => state.prompt.updateSuccess);
  const updateSuccessNew = useAppSelector(
    (state) => state.prompt.updateSuccessNew
  );

  const entitycategory = useAppSelector((state) => state.category.entity);
  const entitycategories = useAppSelector((state) => state.category.entities);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  // jamviet.com
  const [message, setMessage] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const [notification, setNotification] = useState(null);

  const toggleActive = useCallback(() => {
    setNotification(null);
    setMessage(null);
    dispatch(clearError());
  }, []);

  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.prompt_slug || false;

  const [mainQueryCategory, setMainQueryCategory] = useState({
    category_type: localStorage.getItem("CATEGORY_TYPE"),
  });

  useEffect(() => {
    dispatch(getCategories(mainQueryCategory));

    if (Param) dispatch(getEntity(Param));
    else dispatch(reset());
  }, []);

  /**
   * Language type
   */
  const [languageType, setLanguageType] = useState("en");
  const handleLanguageChange = useCallback(
    (value) => setLanguageType(value),
    []
  );
  const languages = [
    {
      value: "ab",
      label: "Abkhaz",
    },
    {
      value: "aa",
      label: "Afar",
    },
    {
      value: "af",
      label: "Afrikaans",
    },
    {
      value: "ak",
      label: "Akan",
    },
    {
      value: "sq",
      label: "Albanian",
    },
    {
      value: "am",
      label: "Amharic",
    },
    {
      value: "ar",
      label: "Arabic",
    },
    {
      value: "an",
      label: "Aragonese",
    },
    {
      value: "hy",
      label: "Armenian",
    },
    {
      value: "as",
      label: "Assamese",
    },
    {
      value: "av",
      label: "Avaric",
    },
    {
      value: "ae",
      label: "Avestan",
    },
    {
      value: "ay",
      label: "Aymara",
    },
    {
      value: "az",
      label: "Azerbaijani",
    },
    {
      value: "bm",
      label: "Bambara",
    },
    {
      value: "ba",
      label: "Bashkir",
    },
    {
      value: "eu",
      label: "Basque",
    },
    {
      value: "be",
      label: "Belarusian",
    },
    {
      value: "bn",
      label: "Bengali",
    },
    {
      value: "bh",
      label: "Bihari",
    },
    {
      value: "bi",
      label: "Bislama",
    },
    {
      value: "bs",
      label: "Bosnian",
    },
    {
      value: "br",
      label: "Breton",
    },
    {
      value: "bg",
      label: "Bulgarian",
    },
    {
      value: "my",
      label: "Burmese",
    },
    {
      value: "ca",
      label: "Catalan; Valencian",
    },
    {
      value: "ch",
      label: "Chamorro",
    },
    {
      value: "ce",
      label: "Chechen",
    },
    {
      value: "ny",
      label: "Chichewa; Chewa; Nyanja",
    },
    {
      value: "zh",
      label: "Chinese",
    },
    {
      value: "cv",
      label: "Chuvash",
    },
    {
      value: "kw",
      label: "Cornish",
    },
    {
      value: "co",
      label: "Corsican",
    },
    {
      value: "cr",
      label: "Cree",
    },
    {
      value: "hr",
      label: "Croatian",
    },
    {
      value: "cs",
      label: "Czech",
    },
    {
      value: "da",
      label: "Danish",
    },
    {
      value: "dv",
      label: "Divehi; Dhivehi; Maldivian;",
    },
    {
      value: "nl",
      label: "Dutch",
    },
    {
      value: "en",
      label: "English",
    },
    {
      value: "eo",
      label: "Esperanto",
    },
    {
      value: "et",
      label: "Estonian",
    },
    {
      value: "ee",
      label: "Ewe",
    },
    {
      value: "fo",
      label: "Faroese",
    },
    {
      value: "fj",
      label: "Fijian",
    },
    {
      value: "fi",
      label: "Finnish",
    },
    {
      value: "fr",
      label: "French",
    },
    {
      value: "ff",
      label: "Fula; Fulah; Pulaar; Pular",
    },
    {
      value: "gl",
      label: "Galician",
    },
    {
      value: "ka",
      label: "Georgian",
    },
    {
      value: "de",
      label: "German",
    },
    {
      value: "el",
      label: "Greek, Modern",
    },
    {
      value: "gn",
      label: "Guaraní",
    },
    {
      value: "gu",
      label: "Gujarati",
    },
    {
      value: "ht",
      label: "Haitian; Haitian Creole",
    },
    {
      value: "ha",
      label: "Hausa",
    },
    {
      value: "he",
      label: "Hebrew (modern)",
    },
    {
      value: "hz",
      label: "Herero",
    },
    {
      value: "hi",
      label: "Hindi",
    },
    {
      value: "ho",
      label: "Hiri Motu",
    },
    {
      value: "hu",
      label: "Hungarian",
    },
    {
      value: "ia",
      label: "Interlingua",
    },
    {
      value: "id",
      label: "Indonesian",
    },
    {
      value: "ie",
      label: "Interlingue",
    },
    {
      value: "ga",
      label: "Irish",
    },
    {
      value: "ig",
      label: "Igbo",
    },
    {
      value: "ik",
      label: "Inupiaq",
    },
    {
      value: "io",
      label: "Ido",
    },
    {
      value: "is",
      label: "Icelandic",
    },
    {
      value: "it",
      label: "Italian",
    },
    {
      value: "iu",
      label: "Inuktitut",
    },
    {
      value: "ja",
      label: "Japanese",
    },
    {
      value: "jv",
      label: "Javanese",
    },
    {
      value: "kl",
      label: "Kalaallisut, Greenlandic",
    },
    {
      value: "kn",
      label: "Kannada",
    },
    {
      value: "kr",
      label: "Kanuri",
    },
    {
      value: "ks",
      label: "Kashmiri",
    },
    {
      value: "kk",
      label: "Kazakh",
    },
    {
      value: "km",
      label: "Khmer",
    },
    {
      value: "ki",
      label: "Kikuyu, Gikuyu",
    },
    {
      value: "rw",
      label: "Kinyarwanda",
    },
    {
      value: "ky",
      label: "Kirghiz, Kyrgyz",
    },
    {
      value: "kv",
      label: "Komi",
    },
    {
      value: "kg",
      label: "Kongo",
    },
    {
      value: "ko",
      label: "Korean",
    },
    {
      value: "ku",
      label: "Kurdish",
    },
    {
      value: "kj",
      label: "Kwanyama, Kuanyama",
    },
    {
      value: "la",
      label: "Latin",
    },
    {
      value: "lb",
      label: "Luxembourgish, Letzeburgesch",
    },
    {
      value: "lg",
      label: "Luganda",
    },
    {
      value: "li",
      label: "Limburgish, Limburgan, Limburger",
    },
    {
      value: "ln",
      label: "Lingala",
    },
    {
      value: "lo",
      label: "Lao",
    },
    {
      value: "lt",
      label: "Lithuanian",
    },
    {
      value: "lu",
      label: "Luba-Katanga",
    },
    {
      value: "lv",
      label: "Latvian",
    },
    {
      value: "gv",
      label: "Manx",
    },
    {
      value: "mk",
      label: "Macedonian",
    },
    {
      value: "mg",
      label: "Malagasy",
    },
    {
      value: "ms",
      label: "Malay",
    },
    {
      value: "ml",
      label: "Malayalam",
    },
    {
      value: "mt",
      label: "Maltese",
    },
    {
      value: "mi",
      label: "Māori",
    },
    {
      value: "mr",
      label: "Marathi (Marāṭhī)",
    },
    {
      value: "mh",
      label: "Marshallese",
    },
    {
      value: "mn",
      label: "Mongolian",
    },
    {
      value: "na",
      label: "Nauru",
    },
    {
      value: "nv",
      label: "Navajo, Navaho",
    },
    {
      value: "nb",
      label: "Norwegian Bokmål",
    },
    {
      value: "nd",
      label: "North Ndebele",
    },
    {
      value: "ne",
      label: "Nepali",
    },
    {
      value: "ng",
      label: "Ndonga",
    },
    {
      value: "nn",
      label: "Norwegian Nynorsk",
    },
    {
      value: "no",
      label: "Norwegian",
    },
    {
      value: "ii",
      label: "Nuosu",
    },
    {
      value: "nr",
      label: "South Ndebele",
    },
    {
      value: "oc",
      label: "Occitan",
    },
    {
      value: "oj",
      label: "Ojibwe, Ojibwa",
    },
    {
      value: "cu",
      label:
        "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    },
    {
      value: "om",
      label: "Oromo",
    },
    {
      value: "or",
      label: "Oriya",
    },
    {
      value: "os",
      label: "Ossetian, Ossetic",
    },
    {
      value: "pa",
      label: "Panjabi, Punjabi",
    },
    {
      value: "pi",
      label: "Pāli",
    },
    {
      value: "fa",
      label: "Persian",
    },
    {
      value: "pl",
      label: "Polish",
    },
    {
      value: "ps",
      label: "Pashto, Pushto",
    },
    {
      value: "pt",
      label: "Portuguese",
    },
    {
      value: "qu",
      label: "Quechua",
    },
    {
      value: "rm",
      label: "Romansh",
    },
    {
      value: "rn",
      label: "Kirundi",
    },
    {
      value: "ro",
      label: "Romanian, Moldavian, Moldovan",
    },
    {
      value: "ru",
      label: "Russian",
    },
    {
      value: "sa",
      label: "Sanskrit (Saṁskṛta)",
    },
    {
      value: "sc",
      label: "Sardinian",
    },
    {
      value: "sd",
      label: "Sindhi",
    },
    {
      value: "se",
      label: "Northern Sami",
    },
    {
      value: "sm",
      label: "Samoan",
    },
    {
      value: "sg",
      label: "Sango",
    },
    {
      value: "sr",
      label: "Serbian",
    },
    {
      value: "gd",
      label: "Scottish Gaelic; Gaelic",
    },
    {
      value: "sn",
      label: "Shona",
    },
    {
      value: "si",
      label: "Sinhala, Sinhalese",
    },
    {
      value: "sk",
      label: "Slovak",
    },
    {
      value: "sl",
      label: "Slovene",
    },
    {
      value: "so",
      label: "Somali",
    },
    {
      value: "st",
      label: "Southern Sotho",
    },
    {
      value: "es",
      label: "Spanish; Castilian",
    },
    {
      value: "su",
      label: "Sundanese",
    },
    {
      value: "sw",
      label: "Swahili",
    },
    {
      value: "ss",
      label: "Swati",
    },
    {
      value: "sv",
      label: "Swedish",
    },
    {
      value: "ta",
      label: "Tamil",
    },
    {
      value: "te",
      label: "Telugu",
    },
    {
      value: "tg",
      label: "Tajik",
    },
    {
      value: "th",
      label: "Thai",
    },
    {
      value: "ti",
      label: "Tigrinya",
    },
    {
      value: "bo",
      label: "Tibetan Standard, Tibetan, Central",
    },
    {
      value: "tk",
      label: "Turkmen",
    },
    {
      value: "tl",
      label: "Tagalog",
    },
    {
      value: "tn",
      label: "Tswana",
    },
    {
      value: "to",
      label: "Tonga (Tonga Islands)",
    },
    {
      value: "tr",
      label: "Turkish",
    },
    {
      value: "ts",
      label: "Tsonga",
    },
    {
      value: "tt",
      label: "Tatar",
    },
    {
      value: "tw",
      label: "Twi",
    },
    {
      value: "ty",
      label: "Tahitian",
    },
    {
      value: "ug",
      label: "Uighur, Uyghur",
    },
    {
      value: "uk",
      label: "Ukrainian",
    },
    {
      value: "ur",
      label: "Urdu",
    },
    {
      value: "uz",
      label: "Uzbek",
    },
    {
      value: "ve",
      label: "Venda",
    },
    {
      value: "vi",
      label: "Vietnamese",
    },
    {
      value: "vo",
      label: "Volapük",
    },
    {
      value: "wa",
      label: "Walloon",
    },
    {
      value: "cy",
      label: "Welsh",
    },
    {
      value: "wo",
      label: "Wolof",
    },
    {
      value: "fy",
      label: "Western Frisian",
    },
    {
      value: "xh",
      label: "Xhosa",
    },
    {
      value: "yi",
      label: "Yiddish",
    },
    {
      value: "yo",
      label: "Yoruba",
    },
    {
      value: "za",
      label: "Zhuang, Chuang",
    },
  ];

  /**
   * Post Type
   */
  const [postType, setPostType] = useState("en");
  const handleTypeChange = useCallback((value) => setPostType(value), []);
  const Types = [
    { label: "prompt", value: "prompt" },
    { label: "multiple", value: "multiple" },
  ];

  useEffect(() => {
    if (entity) {
      dispatch(getCategory(entity?._id));

      if (Param) {
        setLanguageType(entity?.post_language);
        setPostType(entity?.post_type);
        setPost_category_selected(entity?.post_category?._id);
      } else {
        setLanguageType("en");
        setPostType("prompt");
      }
    }
  }, [entity]);

  /**
   * Category list
   */

  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    const optionType = [
      {
        label: "",
        value: "0",
      },
    ];
    for (let { _id, category_title } of entitycategories) {
      optionType.push({
        label: String(category_title),
        value: String(_id),
      });
    }
    setCategoryList(optionType);
  }, [entitycategories]);

  /**
   * Select category
   */

  const [post_category_selected, setPost_category_selected] = useState("");
  const handNewChangeCategory = useCallback((value) => {
    setPost_category_selected(value);
  }, []);

  useEffect(() => {
    if (entitycategory) {
      if (Param) {
        setPost_category_selected(entitycategory?._id);
      } else {
        setPost_category_selected("");
      }
    }
  }, [entitycategory]);

  /**
   * Placeholder
   */

  const [dataFilter, setDataFilter] = useState<any>(null);

  useEffect(() => {
    console.log(entity, "entity");
    setValueView(entity?.text_to_view);
    setValueAI(entity?.text_to_ai);
    setDataFilter(entity?.placeholder);
  }, [entity]);

  function handChangeValueChange(e: any, _id: any) {
    let newContent = [];

    dataFilter.map((element, index) => {
      if (index === _id) {
        element = e;
      }
      newContent.push(element);
    });
    setDataFilter(newContent);
  }

  /**
   * Modal Placeholder
   */

  const [valueContent, setValueContent] = useState("");
  const handleChangeValue = useCallback(
    (newValue) => setValueContent(newValue),
    []
  );

  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const ModalAddContent = () => {
    setShowEditModal(true);
  };

  const closeModal = useCallback(() => {
    setShowEditModal((active) => !active);
  }, [showEditModal]);

  const saveForm = () => {
    let newContent = [];
    if (dataFilter) {
      newContent = [...dataFilter, ...[valueContent]];
    } else {
      newContent = [valueContent];
    }
    setDataFilter(newContent);
    setValueContent("");
    setShowEditModal(false);
  };

  const contentModal = (
    <Modal
      open={showEditModal}
      onClose={closeModal}
      title="Add Text"
      titleHidden
      primaryAction={{
        content: "Save",
        onAction: saveForm,
      }}
      secondaryActions={[
        {
          content: "Close",
          onAction: closeModal,
        },
      ]}
    >
      <Modal.Section>
        {" "}
        <FormLayout>
          <FormLayout.Group condensed>
            <TextField
              label="Placeholder"
              value={valueContent}
              onChange={handleChangeValue}
              autoComplete="off"
              multiline={true}
            />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  useEffect(() => {
    if (updateSuccess) {
      history("/prompt/edit/" + entity?._id, {
        replace: true,
      });
      dispatch(getEntity(Param));

      setNotification("This prompt has been updated!");
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (errorMessage) {
      setNotification(errorMessage);
      dispatch(clearError());
    }
  }, [errorMessage]);

  function _deleteEntityAction() {
    setOpenModal(true);
  }

  function onModalAgree() {
    dispatch(deleteEntity(Param));
    setOpenModal(false);
  }

  /**
   * Value
   *
   *
   *
   *
   *   Xin chào tôi là một Prompt mới [đây là placeholder] và đây là số [placeholder 2] tôi rất vui [xin chao] aieieieie
   *
   */

  const [valueView, setValueView] = useState("");

  const handleChangeView = useCallback((newValue: string) => {
    setValueView(newValue);
  }, []);

  const [valueAI, setValueAI] = useState("");

  const handleChangeAI = useCallback((newValue: string) => {
    setValueAI(newValue);
  }, []);

  useEffect(() => {
    if (valueView) {
      let searchArray = valueView?.match(/\[(.*?)\]/g);
      let text = valueView?.replace(/\[(.*?)\]/g, "{data_1}");
      if (searchArray !== null) {
        setDataFilter(searchArray);
      }
      let textArrayBefore = text?.split("{data_1}");

      let dataTextArrayAfter = [];
      for (let item of textArrayBefore) {
        if (item.trim()) {
          dataTextArrayAfter.push(item.trim());
        }
      }

      if (searchArray?.length > 0) {
        let textStringToShow = "";

        for (let dataText in dataTextArrayAfter) {
          let dataNumber = Number(dataText) + 1;
          if (dataNumber <= searchArray?.length) {
            textStringToShow =
              textStringToShow +
              dataTextArrayAfter[dataText] +
              " {data_" +
              dataNumber +
              "} ";
          } else {
            textStringToShow = textStringToShow + dataTextArrayAfter[dataText];
          }
        }
        setValueView(textStringToShow);
        setValueAI(textStringToShow);
      }
    }
  }, [valueView]);

  const useFields = {
    text_to_view: useField<string>({
      value: entity?.text_to_view ?? "",
      validates: [],
    }),
    text_to_image: useField<string>({
      value: entity?.text_to_image ?? "",
      validates: [],
    }),
    post_status: useField<string>({
      value: entity?.post_status ?? "",
      validates: [],
    }),
    text_to_ai: useField<string>({
      value: entity?.text_to_ai ?? "",
      validates: [],
    }),
  };

  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset,
    submitErrors,
    makeClean,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!Param) {
          // create new
          dispatch(
            createEntity({
              // text_to_view: values.text_to_view,
              // text_to_ai: values.text_to_ai,
              text_to_view: valueView,
              text_to_ai: valueAI,
              text_to_image: values.text_to_image,
              post_status: values.post_status,
              post_language: languageType,
              post_type: postType,
              post_category: post_category_selected,
              placeholder: JSON.stringify(dataFilter),
            })
          );
        } else {
          dispatch(
            updateEntity({
              _id: entity._id,
              text_to_view: valueView,
              text_to_ai: valueAI,
              text_to_image: values.text_to_image,
              post_status: values.post_status,
              post_language: languageType,
              post_type: postType,
              post_category: post_category_selected,
              placeholder: JSON.stringify(dataFilter),
            })
          );
        }
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message =
          e?.response?.data?.title ??
          "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  const { fields: fieldNew, submit: submitNew } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!Param) {
          // create new
          dispatch(
            createEntityNew({
              // text_to_view: values.text_to_view,
              // text_to_ai: values.text_to_ai,
              text_to_view: valueView,
              text_to_ai: valueAI,
              text_to_image: values.text_to_image,
              post_status: values.post_status,
              post_language: languageType,
              post_type: postType,
              post_category: post_category_selected,
              placeholder: JSON.stringify(dataFilter),
            })
          );
        } else {
          dispatch(
            updateEntityNew({
              _id: entity._id,
              text_to_view: valueView,
              text_to_ai: valueAI,
              text_to_image: values.text_to_image,
              post_status: values.post_status,
              post_language: languageType,
              post_type: postType,
              post_category: post_category_selected,
              placeholder: JSON.stringify(dataFilter),
            })
          );
        }
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message =
          e?.response?.data?.title ??
          "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  useEffect(() => {
    console.log(updateSuccessNew, "updateSuccessNew");
    if (updateSuccessNew) {
      console.log("123");
      history("/prompt/new", {
        replace: true,
      });

      setNotification("This prompt has been updated!");
    }
  }, [updateSuccessNew]);

  const emptyData = (
    <Page
      title="Prompt"
      breadcrumbs={[{ content: "Prompt list", url: "/prompt" }]}
    >
      <EmptyState heading="No post here!" image={emptyIMG}>
        <p>This record maybe not exist!</p>
      </EmptyState>
    </Page>
  );

  const toastMarkup = notification ? (
    <Toast content={notification} onDismiss={toggleActive} duration={1000} />
  ) : null;

  const toastNotification = message ? (
    <Toast content={message} onDismiss={toggleActive} duration={1000} />
  ) : null;

  const Actual_page =
    entity || !Param ? (
      <>
        <Page
          title="Prompt List"
          breadcrumbs={[{ content: "Prompt list", url: "/prompt" }]}
          // primaryAction={{
          //   content: "Save",
          //   // disabled: !dirty,
          //   loading: updating,
          //   onAction: submit,
          // }}
          primaryAction={
            <Button
              primary
              onClick={submit}
              loading={updating}
              // connectedDisclosure={{
              //   accessibilityLabel: "Other save actions",
              //   actions: [{ content: "Save as new", onAction: submitNew }],
              // }}
            >
              Save
            </Button>
          }
          secondaryActions={[
            {
              content: "Save as new",
              onAction: submitNew,
            },
          ]}
        >
          <Layout>
            <Layout.Section>
              <Form onSubmit={submit}>
                <Card>
                  <Card.Section>
                    <FormLayout>
                      {/* <TextField
                        requiredIndicator
                        autoComplete="off"
                        label="Text to View"
                        {...fields.text_to_view}
                        multiline={2}
                      /> */}

                      {/* <TextField
                        requiredIndicator
                        autoComplete="off"
                        label="Text to AI"
                        {...fields.text_to_ai}
                        multiline={2}
                      /> */}

                      <TextField
                        label="Text to View"
                        value={valueView}
                        onChange={handleChangeView}
                        autoComplete="off"
                        multiline={2}
                      />

                      <TextField
                        label="Text to AI"
                        value={valueAI}
                        onChange={handleChangeAI}
                        autoComplete="off"
                        multiline={2}
                      />

                      <FormLayout.Group>
                        <TextField
                          autoComplete="off"
                          maxLength={250}
                          label="Status"
                          {...fields.post_status}
                        />
                        <TextField
                          autoComplete="off"
                          maxLength={250}
                          label="Text to Image"
                          {...fields.text_to_image}
                        />
                      </FormLayout.Group>

                      <FormLayout.Group>
                        <Select
                          requiredIndicator
                          label="Category"
                          options={categoryList}
                          onChange={handNewChangeCategory}
                          value={post_category_selected?.toString()}
                        />
                        <Select
                          label="Language"
                          options={languages}
                          requiredIndicator
                          onChange={handleLanguageChange}
                          value={languageType}
                        />

                        <Select
                          label="Type"
                          options={Types}
                          requiredIndicator
                          onChange={handleTypeChange}
                          value={postType}
                        />
                      </FormLayout.Group>

                      <FormLayout.Group>
                        {dataFilter &&
                          dataFilter.map((ele, idx) => {
                            return (
                              <>
                                <TextField
                                  label="Placeholder"
                                  autoComplete="off"
                                  value={ele}
                                  multiline={true}
                                  onChange={(e) =>
                                    handChangeValueChange(e, idx)
                                  }
                                />
                              </>
                            );
                          })}
                        <div style={{ marginTop: "25px" }}>
                          <Button
                            primary
                            onClick={ModalAddContent}
                            icon={MobilePlusMajor}
                          ></Button>
                        </div>
                        {contentModal}
                      </FormLayout.Group>
                    </FormLayout>
                  </Card.Section>
                </Card>
              </Form>
            </Layout.Section>
          </Layout>
          <PageActions
            primaryAction={{
              content: "Save",
              onAction: submit,
              // disabled: !dirty,
              loading: updating,
            }}
            secondaryActions={
              Param
                ? [
                    {
                      content: "Delete",
                      destructive: true,
                      onAction: _deleteEntityAction,
                    },
                  ]
                : []
            }
          />
        </Page>
      </>
    ) : (
      emptyData
    );

  const skeleton_loading = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const _Modal = openModal ? (
    <Modal
      activator={null}
      open={true}
      onClose={() => setOpenModal(false)}
      title="Delete entity?"
      primaryAction={{
        content: "Delete",
        onAction: onModalAgree,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>After delete, you can not undo this action!</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  ) : null;

  return (
    <>
      {_Modal}
      {toastMarkup}
      {toastNotification}
      {loading ? skeleton_loading : Actual_page}
    </>
  );
}
