import { Box, Button, IconButton, LinearProgress, Menu, MenuItem, Modal, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { GrInProgress } from 'react-icons/gr'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import coins from "../../../media/coin.png"
import reward from "../../../media/reward.png"
import { createRedeemMission, deleteRedeemMission } from '../service'
import CreateMission from './mission.createRedeemMission'
import EditMission from './mission.editRedeemMission'
import CustomSnackbar from 'components/snackbar'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0,0,0,0.5)',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

const ListRedeemMission = ({ selectedDataMission, selectedMission, getActionType, getTimeFromStringMission, setGetDataRedeemListUser, selectedMissionId }) => {
    const [openModalCreateMission, setOpenModalCreateMission] = useState(false);
    const [openModalEditMission, setOpenModalEditMission] = useState(false);
    const [dataMission, setDataMission] = useState(null);
    const [dataMissionId, setDataMissionId] = useState(null);
    const [selectedRedeemMissionId, setSelectedRedeemMissionId] = useState<string | null>(null);
    const [missions, setMissions] = useState([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

    const { action_counter = 0, action_amount = 1 } = selectedMission || {};
    const progress = (action_counter / action_amount) * 100;
    

    useEffect(() => {
        setMissions(selectedMission);
    }, [selectedMission]);

    const updateMissionList = (newMission) => {
        setMissions(prevMissions => [...prevMissions, newMission]);
    };

    const handleOpenModalCreateMission = () => {
        setOpenModalCreateMission(true);
    };
    
    const handleCloseModalCreateMission = () => {
        setOpenModalCreateMission(false);
    };

    const handleOpenModalEditMission = (id: string, data) => {
        setDataMission(data)
        setDataMissionId(id)
        setOpenModalEditMission(true);
    };
    
    const handleCloseModalEditMission = () => {
        setOpenModalEditMission(false);
    };

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>, missionRedeemId: string) => {
      setAnchorEl(event.currentTarget);
      setSelectedRedeemMissionId(missionRedeemId);
  };

    const handleClose = () => {
      setAnchorEl(null);
      setSelectedRedeemMissionId(null);
  };

  const handleDelete = async (id) => {
    try {
        await deleteRedeemMission(id);
        
        const updatedMissions = selectedMission.filter(mission => mission._id !== id);
        setMissions(updatedMissions);
        setSnackbarMessage('Xóa thành công');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
    } catch (error) {
        setSnackbarMessage('Xóa thất bại');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        console.error('Failed to delete redeem', error);
    }
};

const updateEditMissionList = (updatedMission) => {
    setMissions((prevList) => 
      prevList.map((mission) => 
        mission._id === updatedMission._id ? updatedMission : mission
      )
    );
  };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
      };
    

    return (
    <Box className="redeem_mission" >
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
            <Typography variant='h5'>Nhiệm vụ</Typography>
            <Button onClick={handleOpenModalCreateMission} variant='contained' color="success" sx={{ fontWeight: 550, height: '45px' }}>Tạo nhiệm vụ</Button>
        </Stack>

    <Modal
        open={openModalCreateMission}
        onClose={handleCloseModalCreateMission}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <CreateMission setSnackbarOpen={setSnackbarOpen} setSnackbarMessage={setSnackbarMessage} setSnackbarSeverity={setSnackbarSeverity} updateMissionList={updateMissionList} selectedMissionId={selectedMissionId} handleCloseModalCreateMission={handleCloseModalCreateMission}/>
        </Box>
    </Modal>

    <Modal
        open={openModalEditMission}
        onClose={handleCloseModalEditMission}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <EditMission setSnackbarOpen={setSnackbarOpen} setSnackbarMessage={setSnackbarMessage} setSnackbarSeverity={setSnackbarSeverity} updateEditMissionList={updateEditMissionList}  dataMission={dataMission} dataMissionId={dataMissionId} selectedDataMission={selectedDataMission} selectedMissionId={selectedMissionId} selectedMission={selectedMission} handleCloseModalEditMission={handleCloseModalEditMission}/>
        </Box>
    </Modal>
        {/**Modal Create Redeem Mission */}
        {missions?.length > 0 ? (
            missions?.map((data, index) => (
                <Box key={data._id} sx={{ backgroundColor: '#fff', color: '#333', padding: '20px', borderRadius: '6px', mb: 2, boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Box sx={{ width: '300px' }}>
                            <Stack direction="row" gap={2} alignItems="center">
                                
                                <img src={getActionType(data.action_type)} alt={data.action_type} />
                                <Stack direction="column">
                                    <Typography variant="body1">{data.title}</Typography>
                                    <Typography variant="body2" sx={{ opacity: 0.5 }}>{getTimeFromStringMission(data.createdAt)}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                        <Box sx={{ width: '150px' }}>
                            <Stack direction="row" gap={1} alignItems="center">
                                <Typography variant="body2">Hành động: </Typography>
                                <Stack>
                                    <Typography variant="body2" fontWeight={550}>{data.action_type}</Typography>
                                    <Typography variant="body2" fontWeight={550}>{data.action_target}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                        <Stack sx={{ width: '80px' }}>
                            <Typography variant="body1" fontWeight={550}>{data.action_counter}/{data.action_amount}</Typography>
                            <Typography variant="body2">Hoàn thành</Typography>
                        </Stack>
                        <Stack sx={{ width: '100px' }}>
                            <Typography variant="body2" mb={1}><GrInProgress/> Quá trình</Typography>
                            <LinearProgress variant="determinate" value={progress}/>
                        </Stack>
                        <Box sx={{ width: '75px' }}>
                            <Stack direction="row" gap={1} alignItems="center">
                                <Box>
                                    <Typography sx={{ width: '55px' }} variant="body2"><img src={reward} alt="point" />  {data.point}</Typography>
                                    <Typography sx={{ width: '55px' }} variant="body2"><img src={coins} alt="point" />  {data.coin}</Typography>
                                </Box>
                                <Box sx={{ width: '40px' }} mr={1}>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => handleClick(e, data._id)}
                                    >
                                        <HiOutlineDotsVertical style={{ color: '#333' }} />
                                    </IconButton>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open && selectedRedeemMissionId === data._id}
                                        onClose={handleClose}
                                        MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={(e) => handleOpenModalEditMission(data._id, data)}>Chỉnh sửa</MenuItem>
                                        <MenuItem onClick={(e) => handleDelete(data._id)}>Xóa</MenuItem>
                                    </Menu>
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            ))
        ) : (
            <Box>Không có dữ liệu!</Box>  
        )}
        <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
    )
}

export default ListRedeemMission