import {
  Card,
  EmptyState,
  Form,
  FormLayout,
  Layout,
  Modal,
  Page,
  PageActions,
  TextContainer,
  TextField,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import emptyIMG from "../../media/empty.png";
import {
  clearError,
  getEntity,
  updateEntity,
  deleteEntity,
  createEntity,
  reset,
} from "../../store/posts.store.reducer";
import { lengthLessThan, lengthMoreThan, useField, useForm } from "@shopify/react-form";
import SleketonLoading from "components/skeleton_loading";

export default function PageEdit() {
  const entity = useAppSelector((state) => state.posts.entity);
  const updating = useAppSelector((state) => state.posts.updating);
  const loading = useAppSelector((state) => state.posts.loading);
  const errorMessage = useAppSelector((state) => state.posts.errorMessage);
  const updateSuccess = useAppSelector((state) => state.posts.updateSuccess);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (errorMessage) {
      setNotification(errorMessage);
      dispatch(clearError());
    }
  }, [errorMessage]);

  const toggleActive = useCallback(() => {
    setNotification(null);
  }, []);

  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.pages_slug || false;
  useEffect(() => {
    if (Param) dispatch(getEntity(Param));
    else dispatch(reset());
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      setNotification("This page has been updated!");
    }
  }, [updateSuccess]);

  function _deleteEntityAction() {
    setOpenModal(true);
  }
  function onModalAgree() {
    dispatch(deleteEntity(Param));
    navigate(-1);
  }

  const useFields = {
    post_title: useField<string>({
      value: entity?.post_title ?? "",
      validates: [
        lengthLessThan(200, "No more than 200 characters."),
        lengthMoreThan(2, "No shorter than 2 characters."),
        (inputValue) => {
          if (inputValue.length < 2) {
            return "Your title is too short, or it is empty.";
          }
        },
      ],
    }),

    post_excerpt: useField<string>({
      value: entity?.post_excerpt ?? "",
      validates: [
        lengthLessThan(250, "No more than 250 characters."),
        lengthMoreThan(2, "No shorter than 2 characters."),
      ],
    }),

    post_content: useField<string>({
      value: entity?.post_content ?? "",
      validates: [lengthLessThan(10000, "Your content is too long."), lengthMoreThan(2, "Your content is too short.")],
    }),
  };

  const { fields, submit, dirty } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!Param) {
          // create new
          dispatch(
            createEntity({
              post_title: values.post_title,
              post_content: values.post_content,
              post_excerpt: values.post_excerpt,
            })
          );
        } else {
          dispatch(
            updateEntity({
              _id: entity._id,
              post_title: values.post_title,
              post_excerpt: values.post_excerpt,
              post_content: values.post_content,
            })
          );
        }
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message = e?.response?.data?.title ?? "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  const emptyData = (
    <Page title="Pages" breadcrumbs={[{ content: "Page list", url: "/pages" }]}>
      <EmptyState heading="No post here!" image={emptyIMG}>
        <p>This record is not existed!</p>
      </EmptyState>
    </Page>
  );
  const toastMarkup = notification ? <Toast content={notification} onDismiss={toggleActive} /> : null;

  const Actual_page =
    entity || !Param ? (
      <>
        <Page
          title="Pages"
          breadcrumbs={[{ content: "Page list", url: "/pages" }]}
          primaryAction={{
            content: "Save",
            disabled: !dirty,
            loading: updating,
            onAction: submit,
          }}
        >
          <Layout>
            <Layout.Section>
              <Form onSubmit={submit}>
                <Card>
                  <Card.Section>
                    <FormLayout>
                      <TextField autoComplete="off" autoFocus label="Title" {...fields.post_title} />
                      <TextField
                        autoComplete="off"
                        maxLength={250}
                        label="Excerpt"
                        {...fields.post_excerpt}
                        multiline={2}
                      />
                      <TextField
                        autoComplete="off"
                        maxLength={2000}
                        label="Content"
                        {...fields.post_content}
                        multiline={4}
                      />
                    </FormLayout>
                  </Card.Section>
                </Card>
              </Form>
            </Layout.Section>
          </Layout>
          <PageActions
            primaryAction={{
              content: "Save",
              onAction: submit,
              disabled: !dirty,
              loading: updating,
            }}
            secondaryActions={
              Param
                ? [
                    {
                      content: "Delete",
                      destructive: true,
                      onAction: _deleteEntityAction,
                    },
                  ]
                : []
            }
          />
        </Page>
      </>
    ) : (
      emptyData
    );

  const _Modal = openModal ? (
    <Modal
      activator={null}
      open={true}
      onClose={() => {
        setOpenModal(false);
      }}
      title="Delete entity?"
      primaryAction={{
        content: "Delete",
        onAction: onModalAgree,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>After delete, you can not undo this action!</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  ) : null;

  return (
    <>
      {_Modal}
      {toastMarkup}
      {loading ? <SleketonLoading /> : Actual_page}
    </>
  );
}

