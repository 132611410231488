import React from "react";
import { useParams } from "react-router-dom";
import Theme404 from "../../layout/404";

import user_question_list from "./user_question.list";
import user_question_edit from "./user_question.edit";

/**
 *   Create index file for User_question
 */

export default function List_user_question() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || "list";

  let ActualPage: any;
  switch (Param) {
    case "list":
      ActualPage = user_question_list;
      break;

    case "edit":
      ActualPage = user_question_edit;
      break;

    case "new":
      ActualPage = user_question_edit;
      break;

    default:
      ActualPage = Theme404;
      break;
  }

  return <>{<ActualPage />}</>;
}
