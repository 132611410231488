import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import Theme404 from "layout/404";
import EmailQueueList from "./email_queue_list";

const EmailTemplate = () => {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || "list_all"; // sub_slug

  const account = useAppSelector((state) => state.user.account);
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  const dispatch = useAppDispatch();
  const isAdmin = account.user_role === "admin";

  let ActualPage: any = "";

  switch (Param) {
    case "list_all":
      ActualPage = EmailQueueList;
      break;

    default:
      ActualPage = Theme404;
      break;
  }

  return (
    <>
      <iframe
        style={{ width: "100%", border: 0, minHeight: "100vh" }}
        src={`https://eco-system.iceo.tech/?auth= ${localStorage.getItem(
          "session"
        )}&url=${localStorage.getItem("AJAX_URL")}`}
        title="W3Schools Free Online Web Tutorials"
      ></iframe>
    </>
  );
};

export default EmailTemplate;
