import {
  Card,
  EmptyState,
  Form,
  FormLayout,
  Layout,
  Modal,
  Page,
  PageActions,
  RadioButton,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";

import emptyIMG from "../../media/empty.png";
import {
  clearError,
  getEntity,
  updateEntity,
  deleteEntity,
  createEntity,
  reset,
} from "../../store/user_interest.store.reducer";
import { lengthLessThan, lengthMoreThan, useField, useForm, notEmpty } from "@shopify/react-form";
import helpers from "helpers";
import QuickUploadImage from "components/oneclick-upload";
import uploadimage from "../../media/choose-file.png";

export default function Edit_user_interest() {
  const entity = useAppSelector((state) => state.user_interest.entity);
  const updating = useAppSelector((state) => state.user_interest.updating);
  const entities = useAppSelector((state) => state.user_interest.entities);
  const loading = useAppSelector((state) => state.user_interest.loading);
  const errorMessage = useAppSelector((state) => state.user_interest.errorMessage);
  const updateSuccess = useAppSelector((state) => state.user_interest.updateSuccess);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  // jamviet.com
  const [message, setMessage] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const [notification, setNotification] = useState(null);

  const toggleActive = useCallback(() => {
    setMessage(null);
    dispatch(clearError());
  }, []);

  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.user_interest_slug || false;

  useEffect(() => {
    if (Param) dispatch(getEntity(Param));
    else dispatch(reset());
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      history("/user_interest/edit/" + entity?._id, {
        replace: true,
      });

      setNotification("This interest has been updated!");
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (errorMessage) {
      setNotification(errorMessage);
      dispatch(clearError());
    }
  }, [errorMessage]);

  function _deleteEntityAction() {
    setOpenModal(true);
  }

  function onModalAgree() {
    dispatch(deleteEntity(Param));
    setOpenModal(false);
  }

  /**
   * upload image
   */
  const [srcImage, setSrcImage] = useState(uploadimage);
  const [srcImageId, setSrcImageId] = useState("");

  useEffect(() => {
    if (Param) {
      if (entity) {
        setSrcImage(entity?.image);
        setSrcImageId(entity?.image);
      } else {
        setSrcImage(uploadimage);
        setSrcImageId("");
      }
    } else {
      setSrcImage(uploadimage);
      setSrcImageId("");
    }
  }, [entity]);

  function handQuickUpdateSuccess(res: any) {
    setSrcImageId(res[0].callback._id);
    setSrcImage(res[0].thumbnail);
  }

  const [internalErrorNotice, setInternalErrorNotice] = useState("");
  function handUploadError(err: any) {
    setInternalErrorNotice("Tải ảnh thất bại! Ảnh quá lớn!");
  }

  const useFields = {
    name: useField<string>({
      value: entity?.name ?? "",
      validates: [],
    }),
    description: useField<string>({
      value: entity?.description ?? "",
      validates: [],
    }),
    interest_key: useField<string>({
      value: entity?.interest_key ?? "",
      validates: [],
    }),
    color: useField<string>({
      value: entity?.color ?? "",
      validates: [],
    }),
  };

  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset,
    submitErrors,
    makeClean,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!Param) {
          // create new
          dispatch(
            createEntity({
              name: values.name,
              description: values.description,
              interest_key: values.interest_key,
              color: values.color,

              name_object: JSON.stringify([
                { key: "en", value: interestENG },
                { key: "vi", value: interestVI },
                { key: "sp", value: interestSP },
              ]),
              // image: srcImageId,
            })
          );
        } else {
          dispatch(
            updateEntity({
              _id: entity._id,
              name: values.name,
              description: values.description,
              interest_key: values.interest_key,
              color: values.color,

              name_object: JSON.stringify([
                { key: "en", value: interestENG },
                { key: "vi", value: interestVI },
                { key: "sp", value: interestSP },
              ]),
              // image: srcImageId,
            })
          );
        }
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message = e?.response?.data?.title ?? "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  const [interestENG, setInterestENG] = useState("");
  const handleChangeENG = useCallback((newValue) => setInterestENG(newValue), []);

  const [interestVI, setInterestVI] = useState("");
  const handleChangeVI = useCallback((newValue) => setInterestVI(newValue), []);

  const [interestSP, setInterestSP] = useState("");
  const handleChangeSP = useCallback((newValue) => setInterestSP(newValue), []);

  const [userContent, setUserContent] = useState([]);

  useEffect(() => {
    if (Param) {
      setUserContent(entity?.name_object);
    } else {
      setUserContent([]);
    }
  }, [entity]);

  // let userContent = entity?.interest;

  useEffect(() => {
    if (userContent && userContent.length > 0) {
      userContent?.map((e) => {
        if (e.key === "en") {
          setInterestENG(e.value);
        }
        if (e.key === "vi") {
          setInterestVI(e.value);
        }
        if (e.key === "sp") {
          setInterestSP(e.value);
        }
      });
    }
  }, [userContent]);

  const emptyData = (
    <Page title="User Interest" breadcrumbs={[{ content: "User Interest list", url: "/user_interest" }]}>
      <EmptyState heading="No post here!" image={emptyIMG}>
        <p>This record maybe not exist!</p>
      </EmptyState>
    </Page>
  );

  const toastMarkup = notification ? <Toast content={notification} onDismiss={toggleActive} /> : null;

  const toastNotification = message ? <Toast content={message} onDismiss={toggleActive} /> : null;

  const Actual_page =
    entity || !Param ? (
      <>
        <Page
          title="User Interest"
          breadcrumbs={[{ content: "User Interest list", url: "/user_interest" }]}
          primaryAction={{
            content: "Save",
            // disabled: !dirty,
            loading: updating,
            onAction: submit,
          }}
        >
          <Layout>
            <Layout.Section>
              <Form onSubmit={submit}>
                <Card>
                  <Card.Section>
                    <FormLayout>
                      <p>Thumbnail</p>
                      <div id="profile_heading">
                        <div className="news_thumbnail_inner">
                          <div className="news_thumbnail_avatar">
                            <QuickUploadImage onSuccess={handQuickUpdateSuccess} onError={handUploadError} />
                            <img src={srcImage} style={{ width: "115px" }} />
                          </div>
                        </div>
                      </div>

                      <TextField requiredIndicator autoComplete="off" maxLength={250} label="Name" {...fields.name} />
                      <TextField
                        requiredIndicator
                        autoComplete="off"
                        maxLength={250}
                        label="Interest Key"
                        {...fields.interest_key}
                      />
                      <TextField requiredIndicator autoComplete="off" maxLength={250} label="Color" {...fields.color} />

                      <TextField
                        autoFocus
                        autoComplete="off"
                        requiredIndicator
                        label="Interest"
                        value={interestENG}
                        onChange={handleChangeENG}
                        connectedRight={<Select label="Unit of time" labelHidden options={["English"]} />}
                      />
                      <TextField
                        autoComplete="off"
                        maxLength={1000}
                        requiredIndicator
                        label="Sở thích"
                        value={interestVI}
                        onChange={handleChangeVI}
                        connectedRight={<Select label="Unit of time" labelHidden options={["Vietnamese"]} />}
                      />
                      <TextField
                        autoComplete="off"
                        maxLength={1000}
                        label="Intereses"
                        value={interestSP}
                        onChange={handleChangeSP}
                        connectedRight={<Select label="Unit of time" labelHidden options={["Spanish"]} />}
                      />

                      <TextField autoComplete="off" maxLength={250} label="Description" {...fields.description} />
                    </FormLayout>
                  </Card.Section>
                </Card>
              </Form>
            </Layout.Section>
          </Layout>
          <PageActions
            primaryAction={{
              content: "Save",
              onAction: submit,
              // disabled: !dirty,
              loading: updating,
            }}
            secondaryActions={
              Param
                ? [
                    {
                      content: "Delete",
                      destructive: true,
                      onAction: _deleteEntityAction,
                    },
                  ]
                : []
            }
          />
        </Page>
      </>
    ) : (
      emptyData
    );

  const skeleton_loading = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const _Modal = openModal ? (
    <Modal
      activator={null}
      open={true}
      onClose={() => setOpenModal(false)}
      title="Delete entity?"
      primaryAction={{
        content: "Delete",
        onAction: onModalAgree,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>After delete, you can not undo this action!</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  ) : null;

  return (
    <>
      {_Modal}
      {toastMarkup}
      {toastNotification}
      {loading ? skeleton_loading : Actual_page}
    </>
  );
}

