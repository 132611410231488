import {
  Card,
  DisplayText,
  EmptyState,
  Form,
  FormLayout,
  Layout,
  Modal,
  Page,
  PageActions,
  RadioButton,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";

import emptyIMG from "../../media/empty.png";
import { clearError, getEntity, updateEntity, deleteEntity, createEntity, reset } from "../../store/plan.store.reducer";
import { lengthLessThan, lengthMoreThan, useField, useForm, notEmpty } from "@shopify/react-form";
import helpers from "helpers";
import countriesData from "components/countryData";
import QuickUploadImage from "components/oneclick-upload";
import uploadimage from "../../media/choose-file.png";

import { getEntities as getServices, getEntity as getService } from "../../store/handle_service.store.reducer";

export default function Edit_plan() {
  const entity = useAppSelector((state) => state.plan.entity);
  const updating = useAppSelector((state) => state.plan.updating);
  const entities = useAppSelector((state) => state.plan.entities);
  const loading = useAppSelector((state) => state.plan.loading);
  const errorMessage = useAppSelector((state) => state.plan.errorMessage);
  const updateSuccess = useAppSelector((state) => state.plan.updateSuccess);

  const entityservice = useAppSelector((state) => state.handle_service.entity);
  const entityservices = useAppSelector((state) => state.handle_service.entities);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  // jamviet.com
  const [message, setMessage] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const [notification, setNotification] = useState(null);

  const toggleActive = useCallback(() => {
    setMessage(null);
    dispatch(clearError());
  }, []);

  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.plan_slug || false;

  useEffect(() => {
    dispatch(getServices({}));

    if (Param) dispatch(getEntity(Param));
    else dispatch(reset());
  }, []);

  /**
   * Option type
   */

  const [type, setType] = useState("recurring");

  const method = [
    { label: "recurring", value: "recurring" },
    { label: "one_time", value: "one_time" },
  ];

  const handleSelectChange = useCallback((value) => setType(value), []);

  /**
   * Coutry
   */

  const [country_selected, setCountry_selected] = useState("");
  const option_country = countriesData?.countriesData;

  const changeHandler = (value) => {
    setCountry_selected(value);
  };

  /**
   * Upload image
   */
  const [srcImage, setSrcImage] = useState(uploadimage);

  const [internalErrorNotice, setInternalErrorNotice] = useState("");

  useEffect(() => {
    if (entity) {
      if (entity?.handle_id !== null) {
        dispatch(getService(entity?.handle_id));
      }
      if (Param) {
        setType(entity?.type);
        setSrcImage(entity?.image);
      } else {
        setType("recurring");
        setSrcImage(entity?.uploadimage);
      }
      // setPlanActive(entity?.status);
      // setValue(entity?.status);
      setValueButton((entity?.status + 2).toString());
      setCountry_selected(entity?.country);
    }
  }, [entity]);

  function handQuickUpdateSuccess(res: any) {
    setSrcImage(res[0].thumbnail);
  }

  function handUploadError(err: any) {
    setInternalErrorNotice("Upload Fail! Image is too large!");
  }

  useEffect(() => {
    if (updateSuccess) {
      history("/plan/edit/" + entity?._id, {
        replace: true,
      });

      setNotification("This plan has been updated!");
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (errorMessage) {
      setNotification(errorMessage);
      dispatch(clearError());
    }
  }, [errorMessage]);

  /**
   * Service list
   */

  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    const optionType = [
      {
        label: "",
        value: "0",
      },
    ];
    for (let { _id, handle } of entityservices) {
      optionType.push({
        label: String(handle),
        value: String(_id),
      });
    }
    setServiceList(optionType);
  }, [entityservices]);

  /**
   * Select service
   */

  const [post_service_selected, setPost_service_selected] = useState("");
  const handNewChange = useCallback((value) => {
    setPost_service_selected(value);
  }, []);

  useEffect(() => {
    if (entityservice) {
      if (Param) {
        setPost_service_selected(entityservice?._id);
      } else {
        setPost_service_selected("");
      }
    }
  }, [entityservice]);

  function _deleteEntityAction() {
    setOpenModal(true);
  }

  function onModalAgree() {
    dispatch(deleteEntity(Param));
    setOpenModal(false);
  }

  const useFields = {
    name: useField<string>({
      value: entity?.name ?? "",
      validates: [
        lengthLessThan(250, "No more than 250 characters."),
        lengthMoreThan(6, "No shorter than 6 characters."),
        (inputValue) => {
          if (inputValue.length < 6) {
            return "Your title is too short, or it is empty.";
          }
        },
      ],
    }),

    google_store_product_id: useField<string>({
      value: entity?.google_store_product_id ?? "",
      validates: [],
    }),

    price: useField<string>({
      value: entity?.price + "" ?? "",
      validates: [],
    }),

    version: useField<string>({
      value: entity?.version + "" ?? "",
      validates: [
        // (inputValue) => {
        //   if (inputValue) {
        //     if (!helpers.isNumeric(inputValue)) {
        //       return "Định dạng không hợp lệ. Chỉ dùng số!";
        //     }
        //   }
        // },
      ],
    }),

    amount_of_day: useField<string>({
      value: entity?.amount_of_day ?? "",
      validates: [],
    }),
  };

  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset,
    submitErrors,
    makeClean,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        const buttonSpotlight = Number(valueButton) - 2;
        if (!Param) {
          // create new
          dispatch(
            createEntity({
              handle_id: post_service_selected,
              name: values.name,
              image: srcImage,
              price: values.price.toString(),
              amount_of_day: values.amount_of_day.toString(),
              version: values.version.toString(),
              type: type,
              google_store_product_id: values.google_store_product_id,
              country: country_selected,
              // status: value,
              // status: buttonSpotlight,
            })
          );
        } else {
          dispatch(
            updateEntity({
              _id: entity._id,
              handle_id: post_service_selected,
              name: values.name,
              image: srcImage,
              price: values.price.toString(),
              version: values.version.toString(),
              amount_of_day: values.amount_of_day.toString(),
              type: type,
              google_store_product_id: values.google_store_product_id,
              country: country_selected,
              // status: value,
              // status: buttonSpotlight,
            })
          );
        }
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message = e?.response?.data?.title ?? "Lỗi không xác định, vui lòng thử lại sau.";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  const emptyData = (
    <Page title="Plan" breadcrumbs={[{ content: "Plan list", url: "/plan" }]}>
      <EmptyState heading="No post here!" image={emptyIMG}>
        <p>This record maybe not exist!</p>
      </EmptyState>
    </Page>
  );

  const toastMarkup = notification ? <Toast content={notification} onDismiss={toggleActive} /> : null;

  const toastNotification = message ? <Toast content={message} onDismiss={toggleActive} /> : null;

  /**
   * Button value
   */

  const [valueButton, setValueButton] = useState("3");

  const handleChange = useCallback((_checked, newValue) => {
    console.log(newValue);
    setValueButton(newValue);
  }, []);

  const Actual_page =
    entity || !Param ? (
      <>
        <Page
          title="Plan"
          breadcrumbs={[{ content: "Plan list", url: "/plan" }]}
          primaryAction={{
            content: "Save",
            // disabled: !dirty,
            loading: updating,
            onAction: submit,
          }}
        >
          <Layout>
            <Layout.Section>
              <Form onSubmit={submit}>
                <Card>
                  <Card.Section>
                    <FormLayout>
                      <DisplayText size="small">Image</DisplayText>
                      <div id="profile_heading">
                        <div className="banner_thumbnail_inner">
                          <div className="banner_thumbnail_avatar">
                            <QuickUploadImage onSuccess={handQuickUpdateSuccess} onError={handUploadError} />
                            <img
                              src={srcImage}
                              // alt="Upload banner here"
                              style={{ width: "115px" }}
                            />
                          </div>
                        </div>
                      </div>

                      <Select
                        requiredIndicator
                        label="Service"
                        options={serviceList}
                        onChange={handNewChange}
                        value={post_service_selected?.toString()}
                      />
                      <TextField autoComplete="off" maxLength={1000} requiredIndicator label="Name" {...fields.name} />
                      <TextField
                        type="number"
                        autoComplete="off"
                        requiredIndicator
                        label="Price"
                        min={0}
                        {...fields.price}
                      />

                      <TextField
                        autoComplete="off"
                        requiredIndicator
                        type="number"
                        label="Amount of day"
                        min={0}
                        {...fields.amount_of_day}
                      />

                      <Select
                        label="Type"
                        options={method}
                        requiredIndicator
                        onChange={handleSelectChange}
                        value={type}
                      />

                      <TextField
                        autoComplete="off"
                        maxLength={1000}
                        label="Product ID"
                        {...fields.google_store_product_id}
                      />

                      <Select
                        label="Country"
                        options={option_country}
                        onChange={changeHandler}
                        value={country_selected}
                      />

                      <TextField
                        // type="number"
                        autoComplete="off"
                        requiredIndicator
                        label="Version"
                        min={0}
                        {...fields.version}
                      />

                      {/* <Stack vertical>
                        <RadioButton
                          label="Accounts are disabled"
                          helpText="Customers will only be able to check out as guests."
                          checked={valueButton === "2"}
                          id="2"
                          name="accounts"
                          onChange={handleChange}
                        />
                        <RadioButton
                          label="Accounts are optional"
                          helpText="Customers will be able to check out with a customer account or as a guest."
                          id="3"
                          name="accounts"
                          checked={valueButton === "3"}
                          onChange={handleChange}
                        />
                      </Stack> */}
                    </FormLayout>
                  </Card.Section>
                </Card>
              </Form>
            </Layout.Section>
          </Layout>
          <PageActions
            primaryAction={{
              content: "Save",
              onAction: submit,
              // disabled: !dirty,
              loading: updating,
            }}
            secondaryActions={
              Param
                ? [
                    {
                      content: "Delete",
                      destructive: true,
                      onAction: _deleteEntityAction,
                    },
                  ]
                : []
            }
          />
        </Page>
      </>
    ) : (
      emptyData
    );

  const skeleton_loading = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const _Modal = openModal ? (
    <Modal
      activator={null}
      open={true}
      onClose={() => setOpenModal(false)}
      title="Delete entity?"
      primaryAction={{
        content: "Delete",
        onAction: onModalAgree,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>After delete, you can not undo this action!</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  ) : null;

  return (
    <>
      {_Modal}
      {toastMarkup}
      {toastNotification}
      {loading ? skeleton_loading : Actual_page}
    </>
  );
}

