import React from 'react';
import { useParams } from "react-router-dom";
import Theme404 from '../../layout/404';

const Tenant = () => {
    let useParam = {} as any;
    useParam = useParams();
    let Param = useParam.any || false;

    console.log('File dashboard/index said: ' + Param);
    //let Param = match.params.any || false;
    return (
        <>
            Chào bạn nha: {Param}
        </>
    );
}

export default Tenant;
