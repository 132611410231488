import {
  AppliedFilterInterface,
  Avatar,
  Badge,
  Card,
  ChoiceList,
  DataTable,
  EmptyState,
  FilterInterface,
  Filters,
  Layout,
  Link,
  Modal,
  Page,
  ResourceItem,
  ResourceList,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import debounce from "lodash.debounce";
import { TickSmallMinor, LockMinor } from "@shopify/polaris-icons";
import emptyIMG from "../../media/empty.png";
import Pagination from "../../components/pagination";
import { clearError, getEntities } from "../../store/city.store.reducer";
import CityFilter from "./filter";
import helpers from "../../helpers";
import datetime from "date-and-time";
import countriesData from "components/countryData";

export default function General_city() {
  const [initial_loading, setInitial_loading] = useState<boolean>(true);

  const entity = useAppSelector((state) => state.city.entity);
  const entities = useAppSelector((state) => state.city.entities);
  const loading = useAppSelector((state) => state.city.loading);
  const errorMessage = useAppSelector((state) => state.city.errorMessage);
  const totalItems = useAppSelector((state) => state.city.totalItems);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  const [mainQuery, setMainQuery] = useState({
    ...{
      page: 1,
      limit: 20,
      order_by: "DESC",
    },
    ...StringQuery,
  });
  const [queryValue, setQueryValue] = useState("");

  const [input, setInput] = useState("");
  const handleFiltersQueryChange = useCallback((value) => setInput(value), []);

  useEffect(() => {
    setMainQuery({ ...mainQuery, ...{ search: input } });
  }, [input]);

  /**
   * Country
   */

  const [country_selected, setCountry_selected] = useState("");
  const option_country = countriesData?.countriesData;

  const changeHandler = (value) => {
    setCountry_selected(value);
  };

  useEffect(() => {
    setMainQuery({
      ...mainQuery,
      ...{ country_iso2: `${country_selected.toLocaleUpperCase()}`, page: 1 },
    });
  }, [country_selected]);

  const [selectedParentId, setSelectedParentId] = useState("");
  const handleSelectedChange = useCallback((_value) => {
    setSelectedParentId(_value);
  }, []);

  useEffect(() => {
    setMainQuery({
      ...mainQuery,
      ...{ webhook_method: `${selectedParentId}`, page: 1 },
    });
  }, [selectedParentId]);

  /**
   * Change page number
   */

  const [numberPage, setNumberPage] = useState(1);

  const onChangePageNumber = useCallback((numPage) => {
    setNumberPage(numPage);
  }, []);

  useEffect(() => {
    setMainQuery({ ...mainQuery, page: numberPage });
  }, [numberPage]);

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch) history("/city" + buildURLSearch);
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  const onChangeCallback = useMemo(
    () =>
      debounce((_value) => {
        if (_value !== "") setMainQuery({ ...mainQuery, query: _value ? _value : "" });
      }, 500),
    []
  );

  useEffect(() => {
    onChangeCallback(queryValue);
  }, [queryValue]);

  useEffect(() => {
    if (loading === false) setInitial_loading(false);
  }, [loading]);

  /**
   *
   * @param _id
   */
  const shortcutActions = (_id: string) => {
    history("edit/" + _id);
  };

  const emptyData = (
    <EmptyState heading="No post here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>
  );

  const renderItem = (city: any) => {
    const {
      _id,
      city_name,
      city_ascii,
      capital,
      country,
      country_code,
      localname,
      user_number,
      visit_number,
      index_name,
      is_viewable,
      distance,
      is_join,
      createdAt,
      updatedAt,
    } = city;
    return [
      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {city_name}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {country}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {localname}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {user_number}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {visit_number}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {is_viewable}
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        <time>{createdAt ? datetime.format(new Date(createdAt), "DD-MM-YYYY HH:mm:ss") : "-"}</time>
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        <time>{updatedAt ? datetime.format(new Date(updatedAt), "DD-MM-YYYY HH:mm:ss") : "-"}</time>
      </Link>,

      <Link removeUnderline monochrome url={"edit/" + _id} key={_id}>
        {_id}
      </Link>,
    ];
  };
  const PagesList = (
    // totalItems > 0 ? (
    <>
      <DataTable
        columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text", "text", "text", "text"]}
        headings={[
          "City",
          "Country",
          "Local Name",
          "User Number",
          "Visit Number",
          "Is Viewable",
          "Create At",
          "Update At",
          "ID",
        ]}
        rows={entities?.map(renderItem)}
        hideScrollIndicator
        footerContent={`Display page ${mainQuery.page} of total 1000 results...`}
      />
      <style>{`
        .small-icon {
          font-size: 12px;
          padding: 0;
          width: 15px;
          height: auto;
        }
      `}</style>
    </>
  );
  // ) : (
  //   emptyData
  // );

  const Actual_page = (
    <Page
      title="City"
      fullWidth
      primaryAction={{
        content: "Create new",
        disabled: false,
        onAction: () => {
          history("new");
        },
      }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <div style={{ padding: "16px", display: "flex" }}>
              <Stack distribution="equalSpacing">
                <CityFilter queryValue={StringQuery?.query} onChange={handleFiltersQueryChange} />
                {/* <Select
                  label=""
                  value={selectedParentId}
                  onChange={handleSelectedChange}
                  options={[
                    { label: "All", value: "" },
                    { label: "Get", value: "get" },
                    { label: "Post", value: "post" },
                    { label: "Patch", value: "patch" },
                    { label: "Delete", value: "delete" },
                  ]}
                /> */}

                <Select label="" options={option_country} onChange={changeHandler} value={country_selected} />
              </Stack>
            </div>
            {PagesList}
          </Card>
          <br />
          {/* {totalItems > mainQuery.limit ? ( */}
          <Pagination
            // TotalRecord={totalItems}
            TotalRecord={1000}
            activeCurrentPage={mainQuery.page}
            pageSize={mainQuery.limit}
            onChangePage={onChangePageNumber}
          />
          {/* ) : null} */}
        </Layout.Section>
      </Layout>
    </Page>
  );

  const skeleton_loading = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const toastMarkup = errorMessage ? <Toast content={errorMessage} error onDismiss={toggleActive} /> : null;

  return (
    <>
      {toastMarkup}
      {/* {loading ? skeleton_loading : Actual_page} */}
      {initial_loading ? skeleton_loading : Actual_page}
    </>
  );
}

