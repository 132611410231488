import axios, { AxiosRequestConfig } from 'axios'
import { getCookie } from 'utils/setCookie';

export const getListUser = async (): Promise<any> => {
    const url = process.env.REACT_APP_AJAX_URL + 'user/list';
    try {
        const axiosConfig = {
            headers: {
                "X-Authorization": getCookie('session'),
            },
        };

        return await axios.get(url, axiosConfig);
    } catch (error) {
        throw new Error('Failed to fetch user data');
    }
}

export const getUserById = async (id: string): Promise<any> => {
    const url = process.env.REACT_APP_AJAX_URL + `user/detail/${id}`;
    try {
        const axiosConfig = {
            headers: {
                "X-Authorization": getCookie('session'),
            },
        };

        return await axios.get(url, axiosConfig);
    } catch (error) {
        throw new Error('Failed to fetch user data');
    }
}

export const uploadAvatar = async (
    file: File,
    headers: AxiosRequestConfig["headers"]
  ): Promise<any> => {
    try {
      const formData = new FormData();
      formData.append('file[]', file);
  
      const config: AxiosRequestConfig = {
        method: 'post',
        url: `https://media.exam24h.com/upload-file?callback=${process.env.REACT_APP_AJAX_URL}media/create`,
        headers,
        data: formData
      };
  
      const response = await axios.request(config);
      if (response.data && response.data[0] && response.data[0].src) {
        return response.data[0].src;
      } else {
        throw new Error('Upload failed: Invalid response');
      }
    } catch (error) {
      throw new Error(`Upload failed: ${error.message}`);
    }
};

export const uploadUser = async (
  _id: string,
  bio: string,
  link: string,
  user_avatar: string,
  display_name: string,
  user_address: string,
  official_status: string,
  phone_number: string,
  badge: string,
  user_role: string,
): Promise<any> => {
  const requestData = { _id, bio, user_avatar, display_name, user_address, official_status, link, phone_number, badge, user_role };
  const url = process.env.REACT_APP_AJAX_URL + `user/admin/update/user/${_id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie('session'),
      },
    };
    return await axios.patch(url, requestData, axiosConfig);
  } catch (error) {
    throw new Error('Failed to fetch user data');
  }
}
